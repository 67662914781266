import cookie from 'react-cookies';
import { reactLocalStorage } from 'reactjs-localstorage';

const AuthService =  {
    checkProtected: () => {
        var access_token = cookie.load('access_token');
				var userObj = reactLocalStorage.getObject('userObj');

        if(access_token === undefined || access_token  == ""){
					window.location = "/logout"
					return;
				}

				if(JSON.stringify(userObj) === '{}'){
					window.location = "/logout";
					return;
          
				}else if(!userObj.onboarding_status){
          window.location = "/onboarding";
          return;

        }
				
    },

    checkerProtected: () => {
      var checker_token = cookie.load('checker_token');
      var checkerObj = reactLocalStorage.getObject('checkerObj');

      if(checker_token === undefined || checker_token  == ""){
        window.location = "/checker-logout"
        return;
      }

      if(JSON.stringify(checkerObj) === '{}'){
        window.location = "/checker-logout";
        return;
        
      }
      
  },

		checkOnboardingProtected: () => {
			var access_token = cookie.load('access_token');
			var userObj = reactLocalStorage.getObject('userObj');

			if(access_token === undefined || access_token  == ""){
				window.location = "/logout"
				return;
			}

			if(JSON.stringify(userObj) === '{}'){
				window.location = "/logout";
				return;
			}

			else if(userObj.onboarding_status){
				window.location = "/dashboard";
				return;

			}
			
	},

    getAuth: () => {
        var userObj = reactLocalStorage.getObject('userObj');
        var access_token = cookie.load('access_token');
        if(JSON.stringify(userObj) === '{}' || access_token === ''){
            window.location = "/logout";
						return;
        }   
        
        return {
          "user": userObj,
          "access_token": access_token
        }
    },

    getChecker: () => {
      var checkerObj = reactLocalStorage.getObject('checkerObj');
      var checker_token = cookie.load('checker_token');
      if(JSON.stringify(checkerObj) === '{}' || checker_token === ''){
          window.location = "/checker-logout";
          return;
      }   
      
      return {
        "checker": checkerObj,
        "checker_token": checker_token
      }
  },

    checkGreeter: () => {
        var access_token = cookie.load('access_token');
        var userObj = reactLocalStorage.getObject('userObj');
        if(access_token !== undefined && userObj.account_status){
          window.location = "/dashboard";
          return;
        }
    },

    checkerGreeter: () => {
      var checker_token = cookie.load('checker_token');
      var checkerObj = reactLocalStorage.getObject('checkerObj');
      if(checker_token !== undefined){
        window.location = "/choice-guide";
        return;
      }
  },

    getAxiosHeaders: () => {
        var access_token = cookie.load('access_token');
        return { headers: {"Authorization" : `Bearer ${access_token}`} }
    },

    getCheckerAxiosHeaders: () => {
        var checker_token = cookie.load('checker_token');
        return { headers: {"Authorization" : `Bearer ${checker_token}`} }
    },

    getAxiosUploadHeaders: () => {
        var access_token = cookie.load('access_token');
        return { 
            headers: {
                "Authorization" : `Bearer ${access_token}`, 
                "content-type": "multipart/form-data"
            } 
        }
    }
};

export default AuthService;