import React, { Fragment } from "react";
import MetaTags from "react-meta-tags";
import AuthService from '../../misc/AuthService';
import CONSTANTS from '../../misc/Constants';
import cookie from 'react-cookies';
import { reactLocalStorage } from 'reactjs-localstorage';
// import Footer from "./includes/Footer";
import axios from 'axios';
import { 
  Input,
} from 'antd';
import 'antd/dist/antd.css';

class CheckerRegister extends React.Component {

  constructor(props){
		AuthService.checkerGreeter();
    super(props);
    this.state = {
			isLoading: false,
			isRequesting: false,
			showPassword: false,
			registrationForm: {
				firstName: '',
				lastName: '',
        password: '',
        phoneNumber: ''
			},
			registrationFormErrors: {
        firstName: '',
				lastName: '',
        password: '',
        phoneNumber: ''
      },
      errorMessage: ''
    }
	}
	
	registrationForm(event, name) {
    let value = event.target.value;
    var oldData = this.state.registrationForm;
    oldData[name] = value;
    this.setState({
			...this.state,
      registrationForm: oldData,
    });
	}
	
	submitRegistrationForm = e => {
		this.setState({
			...this.state,
			isRequesting: true,
			errorMessage: '',
			registrationFormErrors: {
        firstName: '',
				lastName: '',
        password: '',
        phoneNumber: ''
      }
		});

		e.preventDefault();

		axios.post(CONSTANTS.API_BASE_URL + "/auth/register-checker", {...this.state.registrationForm})
		.then((response) => {
			let data = response.data;
			let expirationDate = new Date();
			let validDays = 30;
			expirationDate.setTime(expirationDate.getTime() + (validDays*24*60*60*1000));

			cookie.save('checker_token', data.access_token, { path: '/', expires: expirationDate })
			reactLocalStorage.setObject('checkerObj', data.user_obj);

      window.location = "/choice-guide";

		}).catch((error) => {
      try{
        let errorResponse = error.response.data;
				let registrationFormErrors = this.state.registrationFormErrors;

				if(errorResponse.hasOwnProperty("errors")){
					if(errorResponse.errors.hasOwnProperty("firstName")){
						registrationFormErrors.firstName = errorResponse.errors.firstName;
					}

					if(errorResponse.errors.hasOwnProperty("lastName")){
						registrationFormErrors.lastName = errorResponse.errors.lastName;
					}

					if(errorResponse.errors.hasOwnProperty("password")){
						registrationFormErrors.password = errorResponse.errors.password;
					}

					if(errorResponse.errors.hasOwnProperty("phoneNumber")){
						registrationFormErrors.phoneNumber = errorResponse.errors.phoneNumber;
					}

				}

				let errorMessage = 'Error: Could not connect to server';
				if(errorResponse.hasOwnProperty("message")){
					errorMessage = errorResponse.message;
				}

				this.setState({
					...this.state,
					isRequesting: false,
					errorMessage: errorMessage,
					registrationFormErrors: registrationFormErrors
				});
      }catch(e){
        window.location = "/server-offline";
      }
		});

	}


  render(){
    return (
      <Fragment>
          <MetaTags>
          <title>Register - BECE Stats</title>
          </MetaTags>
					
					<div className="d-md-flex flex-row-reverse">
						<div className="signin-right">
							<div className="signin-box signin">
								<h3 className="signin-title-primary">Get Started!</h3>
								<h5 className="signin-title-secondary lh-4">
									Create an account, only takes a minute.
								</h5>
								{
									this.state.errorMessage.length > 0 &&
									<div className="example-alert nk-block-head">
										<div className="alert alert-solid alert-danger"> 
											{this.state.errorMessage}
										</div>
									</div>
								}
								<form onSubmit={this.submitRegistrationForm} method="POST">
									<div className="row row-xs">
										<div className="col-md-6">
											<div className="form-group">
												<div className="form-label-group">
														<label className="form-label" htmlFor="default-01">First Name</label>
												</div>
												<Input size="large" required type="text" id="firstName" className="form-control form-control-lg" placeholder="Enter your first name" name="firstName" value={this.state.registrationForm.firstName} onChange={(e) => {this.registrationForm(e,"firstName");}} />
												{
													this.state.registrationFormErrors.firstName.length > 0 && 
													<p className="text-danger fs-12px">{this.state.registrationFormErrors.firstName}</p>
												}
											</div>
										</div>
										<div className="col-md-6">
											<div className="form-group">
												<div className="form-label-group">
														<label className="form-label" htmlFor="default-01">Last Name</label>
												</div>
												<Input size="large" required type="text" id="lastName" className="form-control form-control-lg" placeholder="Enter your last name" name="lastName" value={this.state.registrationForm.lastName} onChange={(e) => {this.registrationForm(e,"lastName");}} />
												{
													this.state.registrationFormErrors.lastName.length > 0 && 
													<p className="text-danger fs-12px">{this.state.registrationFormErrors.lastName}</p>
												}
											</div>
										</div>
									</div>
									<div className="form-group">
                    <div className="form-label-group">
                        <label className="form-label" htmlFor="default-01">Phone Number</label>
                    </div>
                    <Input addonBefore="+233" maxLength="10" size="large" required type="tel" id="phone_number" placeholder="Enter your phone number" name="phoneNumber" value={this.state.registrationForm.phoneNumber} onChange={(e) => {this.registrationForm(e,"phoneNumber");}} />
                    {
											this.state.registrationFormErrors.phoneNumber.length > 0 && 
											<p className="text-danger fs-12px">{this.state.registrationFormErrors.phoneNumber}</p>
										}
									</div>
									<div className="form-group">
										<div className="form-label-group">
												<label className="form-label" htmlFor="password">Password</label>
										</div>
										<div className="form-control-wrap">
											<Input.Password size="large" required type="password" className="form-control form-control-lg" id="password" name="password" value={this.state.registrationForm.password} onChange={(e) => {this.registrationForm(e,"password");}} placeholder="Enter your password" />																	
											{
												this.state.registrationFormErrors.password.length > 0 && 
												<p className="text-danger fs-12px">{this.state.registrationFormErrors.password}</p>
											}
										</div>
									</div>
									<div className="form-group text-center">
                    { 
                      !this.state.isRequesting ? 
                      <button type="submit" className="btn btn-lg btn-block btn-primary btn-signin">
                        <span>Register</span> 
                      </button>
                      :
                      <button type="button" disabled className="btn btn-lg btn-block btn-primary btn-signin">
                        <span>Please Wait...</span> 
                      </button>
                    }
                  </div>
								</form>
								<p className="mg-t-40 mg-b-0">
									Already have an account? <a href="/checker-login">Login</a>
								</p>
							</div>
						</div>
						<div className="signin-left">
							<div className="signin-box">
								<h2 className="slim-logo">
									<a href={CONSTANTS.WEB_URL}>
                    <img src="/assets/img/becestats_black.png" width={"200px"} />
                  </a>
								</h2>
								<p>
                Welcome to BECE Stats <b>Choice Guide</b>. Register and use the Senior High School Acceptance Statistics Checker to help you choose your schools.
                </p>
								{/* <p>Browse our site and see for yourself why you need Slim.</p> */}
								<p className="tx-12">© Copyright 2022. All Rights Reserved.</p>
							</div>
						</div>
					</div>
      </Fragment>
    );
  }
}

export default CheckerRegister;