import React, { Fragment } from "react";
import MetaTags from "react-meta-tags";
import AuthService from '../../misc/AuthService';
import CONSTANTS from '../../misc/Constants';
import SHS from '../../misc/SHS';
import axios from 'axios';
import cookie from 'react-cookies';
import { reactLocalStorage } from 'reactjs-localstorage';
import { 
  Input,
  InputNumber,
  Select, 
} from 'antd';
import 'antd/dist/antd.css';
import SideNav from "./include/SideNav";
import Header from "./include/Header";
import Footer from "./include/Footer";
import moment from 'moment';
import Numeral from 'react-numeral';
const { Option } = Select;

class ChoiceGuide extends React.Component {

  constructor(props){
		AuthService.checkerProtected();
    super(props);
    this.state = {
      userInfo: AuthService.getChecker().checker,
			isLoading: true,
			isRequesting: false,
      shs: [],
      upgrade: false,
      year: 2021,
      school_code: '',
      program: '',
      residence: 'Day',
      aggregate: '',
      program_list: [],
      school_gender: '',
      no_result: false,
      new_search: true,
      choice_info: {
        school_info: {
          school_code: '',
          school_name: '',
          program: '',
          residence: '',
        },
        stats: {
          year: '',
          subscription: 0,
          placed: 0,
          min_aggregate: 0,
          max_aggregate: 0,
          acceptance_chance: true
        },
      }
    }
	}

  filterForm(event, name) {
    let value;
    let program_list = this.state.program_list;
    let school_gender = this.state.school_gender;
    if(name === "year" || name === "school_code" || name === "program" || name === "aggregate"){
      value = event;

      if(name === "school_code"){
        program_list = SHS.filter(data => data.school_code == value);
        school_gender = program_list[0].gender;
      }
    }else{
      value = event.target.value;
    }

    this.setState({
      ...this.state,
      [name]: value,
      program_list: program_list,
      school_gender: school_gender
    });
	}

  getChoiceInfo () {
    this.setState({
      ...this.state,
      no_result: false,
      isRequesting: true
    });

    axios.post(CONSTANTS.API_BASE_URL + "/checker/search", {
      year: this.state.year,
      school_code: this.state.school_code,
      program: this.state.program,
      residence: this.state.residence,
      aggregate: this.state.aggregate,
    }, AuthService.getCheckerAxiosHeaders())
    .then((response) => {
      let responseInfo = response.data;

      var checkerObj = reactLocalStorage.getObject('checkerObj');
      checkerObj.credit = responseInfo.data.stats.credit_balance;
      reactLocalStorage.setObject('checkerObj', checkerObj);

      this.setState({
        ...this.state,
        isRequesting: false,
        userInfo: checkerObj,
        new_search: false,
        upgrade: false,
        choice_info: responseInfo.data
      });
           
    }).catch((error) => {
      try{

        if(error.response.status === 401){
          window.location = "/checker-logout";
          return;
        }

        let upgrade = false;

        if(error.response.status === 402){
          upgrade = true;
        }

        let errorResponse = error.response.data;

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("message")){
          errorMessage = errorResponse.message;
        }


        this.setState({
          ...this.state,
          isRequesting: false,
          upgrade: upgrade,
          no_result: true,
          new_search: false,
          errorMessage: errorMessage
        });
      }catch(e){
        console.log(error)
        // window.location = "/server-offline";
      }
    });
  }
	
  componentDidMount(){
    let schools = SHS.filter((obj, index, self) =>
      index === self.findIndex((t) => (
          t.school_code === obj.school_code
      ))
    )

    // get credit balance
    axios.post(CONSTANTS.API_BASE_URL + "/checker/balance", null, AuthService.getCheckerAxiosHeaders())
    .then((response) => {
      let responseInfo = response.data;
      var checkerObj = reactLocalStorage.getObject('checkerObj');
      checkerObj.credit = responseInfo.data;
      reactLocalStorage.setObject('checkerObj', checkerObj);

      this.setState({
        ...this.state,
        isLoading: false,
        userInfo: checkerObj,
        shs: schools
      })
           
    }).catch((error) => {
      try{

        if(error.response.status === 401){
          window.location = "/checker-logout";
          return;
        }

        let errorResponse = error.response.data;

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("message")){
          errorMessage = errorResponse.message;
        }


        this.setState({
          ...this.state,
          isLoading: false,
          errorMessage: errorMessage
        });
      }catch(e){
        console.log(error)
        // window.location = "/server-offline";
      }
    });

    
  }

  render(){
    return (
      <Fragment>
          <MetaTags>
          <title>Choice Guide - BECE Stats</title>
          </MetaTags>
          <Header activePage={'choice'} />
          <div className="slim-mainpanel">
            {
              this.state.isLoading ?
              <div className="container">
                <div className="d-flex ht-300 pos-relative align-items-center">
                  <div className="sk-wave">
                    <div className="sk-rect sk-rect1 bg-primary" />
                    <div className="sk-rect sk-rect2 bg-primary" />
                    <div className="sk-rect sk-rect3 bg-primary" />
                    <div className="sk-rect sk-rect4 bg-primary" />
                    <div className="sk-rect sk-rect5 bg-primary" />
                  </div>
                </div>
              </div>
              :
              <div className="container">
                <div className="slim-pageheader">
                  <ol className="breadcrumb slim-breadcrumb">
                    <li className="breadcrumb-item">
                      {/* <a href="#">Home</a> */}
                    </li>
                  </ol>
                  <h6 className="slim-pagetitle">Choice Guide</h6>
                </div>
                <div className="row">
                  <div className="col-lg-6">
                    <h3 className="tx-inverse mg-b-15">Welcome, <span className="text-primary">{this.state.userInfo.first_name} {this.state.userInfo.last_name}</span></h3>
                    <p className="mg-b-40">
                      Use the form below to help you choose your schools by entering your preferred school, program, residence, and year to obtain subscription and acceptance statistics for the selected school.
                    </p>
                  </div>
                  <div class="col-lg-6">
                    <div class="d-h-t-right justify-content-lg-end" onClick={() => {window.location = "/credit";}}>
                      <div class="summary-item pointer-cursor">
                        <h1 className="text-primary">{this.state.userInfo.credit}</h1>
                        <span><b>Credit Balance</b> <br/> <span className="text-primary m-0">Top-up</span></span>                        
                      </div>                    
                    </div>
                  </div>
                </div>
                <div className="card card-dash-chart-one mg-t-20 mg-sm-t-30">
                  <div className="row no-gutters">
                    <div className="col-lg-4 bg-light">
                      <div className="left-panel">
                        <h6 className="slim-card-title mb-4">Search</h6>
                        <div className="form-group">
                          <div className="form-label-group">
                              <label className="form-label" htmlFor="default-01">Year</label>
                          </div>
                          <Select size="large" defaultValue={this.state.year} style={{ width: "100%" }} onChange={(e) => {this.filterForm(e,"year");}}>
                            <Option value={2021}>2021</Option>
                            <Option value={2020}>2020</Option>
                            <Option value={2019}>2019</Option>
                          </Select>
                        </div>
                        <div className="form-group">
                          <div className="form-label-group">
                              <label className="form-label" htmlFor="default-01">School</label>
                          </div>
                          <Select showSearch size="large" defaultValue={this.state.school_code} style={{ width: "100%" }} onChange={(e) => {this.filterForm(e,"school_code");}}
                          // onSearch={(e) => {this.filterForm(e,"school_code");}}
                          optionFilterProp="children"
                          filterOption={(input, option) => option.children.toString().toLowerCase().includes(input.toLowerCase())}>
                            {
                              this.state.shs.map((data, index) => {
                                return(
                                  <Option value={data.school_code} key={index}>{data.school_name} <small className="text-muted"><i> - Category {data.category}</i></small></Option>
                                )
                              })
                            }
                          </Select>
                        </div>
                        <div className="form-group">
                          <div className="form-label-group">
                              <label className="form-label" htmlFor="default-01">Program</label>
                          </div>
                          <Select size="large" defaultValue={this.state.program} style={{ width: "100%" }} onChange={(e) => {this.filterForm(e,"program");}}>
                            {
                              this.state.program_list.map((data, index) => {
                                return(
                                  <Option value={data.program} key={index}>{data.program}</Option>
                                )
                              })
                            }
                          </Select>
                        </div>
                        
                        <div className="form-group">
                          <div className="form-label-group">
                              <label className="form-label" htmlFor="default-01">Residence</label>
                          </div>
                          <label className="rdiobox">
                            <input type="radio" value={"Day"} name="residence" defaultChecked={true} onChange={(e) => {this.filterForm(e,"residence");}} />
                            <span>Day</span>
                          </label>
                          <label className="rdiobox mg-t-15">
                            <input type="radio" name="residence" value={"Female Boarding"} disabled={this.state.school_gender == "Boys" ? true : false} onChange={(e) => {this.filterForm(e,"residence");}} />
                            <span>Female Boarding</span>
                          </label>
                          <label className="rdiobox mg-t-15">
                            <input type="radio" value={"Male Boarding"} name="residence" disabled={this.state.school_gender == "Girls" ? true : false} onChange={(e) => {this.filterForm(e,"residence");}} />
                            <span>Male Boarding</span>
                          </label>
                          
                        </div>
                        {/* <div className="form-group">
                          <div className="form-label-group">
                              <label className="form-label" htmlFor="default-01">Aggregate</label>
                          </div>
                          <InputNumber size="large" min={6} max={54} defaultValue={this.state.aggregate} style={{ width: "100%" }} id="aggregate" className="form-control form-control-lg" onChange={(e) => {this.filterForm(e,"aggregate");}} placeholder="Enter your expected aggregate" name="aggregate" />
                        </div> */}
                        {
                          this.state.isRequesting ?
                          <button type="button" disabled className="btn btn-block btn-primary mg-b-10 mt-5">
                            <span>Please Wait...</span> 
                          </button>
                          :
                          <button onClick={() => {this.getChoiceInfo()}} className="btn btn-primary btn-block mg-b-10 mt-5">Submit</button>
                        }
                        
                      </div>
                    </div>
                    <div className="col-lg-8">
                      {
                        this.state.isRequesting ?
                        <div className="right-panel">
                          <div className="d-flex ht-300 pos-relative align-items-center">
                            <div className="sk-wave">
                              <div className="sk-rect sk-rect1 bg-primary" />
                              <div className="sk-rect sk-rect2 bg-primary" />
                              <div className="sk-rect sk-rect3 bg-primary" />
                              <div className="sk-rect sk-rect4 bg-primary" />
                              <div className="sk-rect sk-rect5 bg-primary" />
                            </div>
                          </div>
                        </div>
                        :
                        <>
                        {
                          this.state.upgrade ?
                          <div className="right-panel">
                            <div className="row">
                              <div className="col-md-6 offset-3 mb-5 mt-5" style={{border: "none"}}>
                                <div className="pd-25 tx-center">
                                  <div className="card-icon-wrapper primary">
                                    <i className="icon ion-ios-color-wand-outline" />
                                  </div>
                                  <h4 className="tx-gray-800 mg-b-5">Out of Credit</h4>
                                  <p className="mg-b-50">
                                    {this.state.errorMessage}    
                                  </p>
                                  <a href="/credit" className="btn btn-dark btn-block text-uppercase tx-bold">
                                    Buy Credit
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                          :
                          <div className="right-panel">
                            {
                              this.state.no_result ?
                              <div className="row">
                                <div className="col-md-6 offset-3 mb-5" style={{border: "none"}}>
                                  <div className="pd-25 tx-center">
                                    <div className="card-icon-wrapper primary">
                                      <i className="icon ion-ios-information-outline" />
                                    </div>
                                    {/* <h4 className="tx-gray-800 mg-b-5">No result found</h4> */}
                                    <p className="mg-b-50">
                                      No result found    
                                    </p>
                                  </div>
                                </div>
                              </div>
                              :
                              <>
                                {
                                  this.state.new_search ?
                                  <div className="row">
                                    <div className="col-md-6 offset-3 mb-5 mt-5" style={{border: "none"}}>
                                      <div className="pd-25 tx-center">
                                        <div className="card-icon-wrapper primary">
                                          <i className="icon ion-ios-search" />
                                        </div>
                                        {/* <h4 className="tx-gray-800 mg-b-5">No result found</h4> */}
                                        <p className="mg-b-50">
                                          Search for a school   
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  :
                                  <>
                                    <div className="card-body pt-5">
                                      <h6 className="tx-inverse">{this.state.choice_info.stats.year}</h6>
                                      <h1 className="tx-inverse tx-lato tx-bold mb-3">{this.state.choice_info.school_info.school_name}</h1>
                                      <h3 className="tx-inverse">{this.state.choice_info.school_info.program}</h3>
                                      <h5 className="tx-inverse mb-4">{this.state.choice_info.school_info.residence}</h5>
                                      <div className="row no-gutters">
                                        <div className="col-md-6">
                                          <div className="card card-earning-summary">
                                            <h6>Entry Aggregate</h6>
                                            <h1 className="text-primary">{this.state.choice_info.stats.min_aggregate}</h1>
                                          </div>
                                        </div>
                                        <div className="col-md-6">
                                          <div className="card card-earning-summary">
                                            <h6>Cut-off Aggregate</h6>
                                            <h1 className="text-danger">{this.state.choice_info.stats.max_aggregate}</h1>
                                          </div>
                                        </div>
                                        <div className="col-md-6">
                                          <div className="card card-earning-summary">
                                            <h6>Subscription</h6>
                                            <h1>{this.state.choice_info.stats.subscription}</h1>
                                            <span>The number of students that chose this school, program and residence.</span>
                                          </div>
                                        </div>
                                        <div className="col-md-6">
                                          <div className="card card-earning-summary h-100">
                                            <h6>Placed</h6>
                                            <h1 className="text-success">{this.state.choice_info.stats.placed}</h1>
                                            <span>The number of students that were placed by automatic placement.</span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    
                                  </>
                                }
                              </>
                            }
                            
                          </div>
                        }
                        </>
                      }
                      
                    </div>
                  </div>
                </div>


              </div>
            }
          </div>
          <Footer />
      </Fragment>
    );
  }
}

export default ChoiceGuide;