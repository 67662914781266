import React, { Fragment } from "react";
import classNames from "classnames";
import AuthService from '../../../misc/AuthService';
class SideNav extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      checkerInfo: AuthService.getChecker().checker,
      notification: []
    }
  }

  render(){

    return (
      <>
      <div className="slim-header">
        <div className="container">
          <div className="slim-header-left">
            <h2 className="slim-logo">
              <a href="/choice-guide"><img src="/assets/img/becestats_black.png" width={"170px"} /></a>
            </h2>
            {/* <div className="search-box">
              <input type="text" className="form-control" placeholder="Search" />
              <button className="btn btn-primary">
                <i className="fa fa-search" />
              </button>
            </div> */}
          </div>
          <div className="slim-header-right">
            {/* <div className="dropdown dropdown-b">
              <a href="#" className="header-notification" data-toggle="dropdown">
                <i className="icon ion-ios-bell-outline" />
                <span className="indicator" />
              </a>
              <div className="dropdown-menu">
                <div className="dropdown-menu-header">
                  <h6 className="dropdown-menu-title">Notifications</h6>
                  <div>
                    <a href="#">Mark All as Read</a>
                    <a href="#">Settings</a>
                  </div>
                </div>
                <div className="dropdown-list">
                  <a href="#" className="dropdown-link">
                    <div className="media">
                      <img src="../img/img8.jpg" alt="" />
                      <div className="media-body">
                        <p>
                          <strong>Suzzeth Bungaos</strong> tagged you and 18 others
                          in a post.
                        </p>
                        <span>October 03, 2017 8:45am</span>
                      </div>
                    </div>
                  </a>
                  <a href="#" className="dropdown-link">
                    <div className="media">
                      <img src="../img/img9.jpg" alt="" />
                      <div className="media-body">
                        <p>
                          <strong>Mellisa Brown</strong> appreciated your work{" "}
                          <strong>The Social Network</strong>
                        </p>
                        <span>October 02, 2017 12:44am</span>
                      </div>
                    </div>
                  </a>
                  <a href="#" className="dropdown-link read">
                    <div className="media">
                      <img src="../img/img10.jpg" alt="" />
                      <div className="media-body">
                        <p>
                          20+ new items added are for sale in your{" "}
                          <strong>Sale Group</strong>
                        </p>
                        <span>October 01, 2017 10:20pm</span>
                      </div>
                    </div>
                  </a>
                  <a href="#" className="dropdown-link read">
                    <div className="media">
                      <img src="../img/img2.jpg" alt="" />
                      <div className="media-body">
                        <p>
                          <strong>Julius Erving</strong> wants to connect with you
                          on your conversation with <strong>Ronnie Mara</strong>
                        </p>
                        <span>October 01, 2017 6:08pm</span>
                      </div>
                    </div>
                  </a>
                  <div className="dropdown-list-footer">
                    <a href="page-notifications.html">
                      <i className="fa fa-angle-down" /> Show All Notifications
                    </a>
                  </div>
                </div>
              </div>
            </div> */}
            <div className="dropdown dropdown-c">
              <a href="#" className="logged-user" data-toggle="dropdown">
                <img src="/assets/img/crown.png" />
                <span>{this.state.checkerInfo.first_name}</span>
                <i className="fa fa-angle-down" />
              </a>
              <div className="dropdown-menu dropdown-menu-right">
                <nav className="nav">
                  {/* <a href="page-profile.html" className="nav-link">
                    <i className="icon ion-person" /> View Profile
                  </a>
                  <a href="page-edit-profile.html" className="nav-link">
                    <i className="icon ion-compose" /> Edit Profile
                  </a>
                  <a href="page-activity.html" className="nav-link">
                    <i className="icon ion-ios-bolt" /> Activity Log
                  </a> */}
                  {/* <a href="/account-settings" className="nav-link">
                    <i className="icon ion-ios-gear" /> Account Settings
                  </a> */}
                  <a href="/checker-logout" className="nav-link">
                    <i className="icon ion-forward" /> Sign Out
                  </a>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="slim-navbar">
        <div className="container">
          <ul className="nav">
            <li className={classNames("nav-item", {"active" : this.props.activePage === "choice"})}>
              <a className="nav-link" href="/choice-guide">
                <i className="icon ion-ios-home-outline" />
                <span>Choice Guide</span>
              </a>
            </li>
            {/* <li className={classNames("nav-item", {"active" : this.props.activePage === "history"})}>
              <a className="nav-link" href="/history">
                <i className="icon ion-ios-list-outline" />
                <span>Search History</span>
              </a>
            </li> */}
            <li className={classNames("nav-item", {"active" : this.props.activePage === "credit"})}>
              <a className="nav-link" href="/credit">
                <i className="icon ion-ios-browsers-outline" />
                <span>Credit Top-up</span>
                {/* <span className="square-8" /> */}
              </a>
            </li>
            {/* <li className={classNames("nav-item", {"active" : this.props.activePage === "settings"})}>
              <a className="nav-link" href="/settings">
                <i className="icon ion-ios-gear-outline" />
                <span>Settings</span>
              </a>
            </li> */}
          </ul>
        </div>
      </div>
      </>
    )
  }
}

export default SideNav;