import React, { Fragment } from "react";
import MetaTags from "react-meta-tags";
import AuthService from '../../misc/AuthService';
import CONSTANTS from '../../misc/Constants';
import axios from 'axios';
import cookie from 'react-cookies';
import { reactLocalStorage } from 'reactjs-localstorage';
import { 
  Input,
  Select, 
} from 'antd';
import 'antd/dist/antd.css';
import Header from "./include/Header";
import Footer from "./include/Footer";
import moment from 'moment';
import Numeral from 'react-numeral';
const { Option } = Select;

class Analytics extends React.Component {

  constructor(props){
		AuthService.checkProtected();
    super(props);
    this.state = {
      userInfo: AuthService.getAuth().user,
			isLoading: true,
			isRequesting: false,
      upgrade: false,
      year: '',
      analytics_info: {
        current_year: '',
        stats_years: [],
        stats: {
          total_students: 0,
          passed_students: 0,
          failed_students: 0
        },
        analytics: {}
      }
    }
	}

  getAnalyticsInfo () {
    this.setState({
      ...this.state,
      isRequesting: true
    });

    axios.post(CONSTANTS.API_BASE_URL + "/user/get-analytics", {
      year: this.state.year
    }, AuthService.getAxiosHeaders())
    .then((response) => {
      let responseInfo = response.data;

      this.setState({
        ...this.state,
        isLoading: false,
        isRequesting: false,
        upgrade: false,
        analytics_info: responseInfo.data,
        year: responseInfo.data.current_year
      });
           
    }).catch((error) => {
      try{

        if(error.response.status === 401){
          window.location = "/logout";
          return;
        }

        let errorResponse = error.response.data;
        let upgrade = false;

        if(error.response.status === 402){
          upgrade = true;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("message")){
          errorMessage = errorResponse.message;
        }


        this.setState({
          ...this.state,
          isLoading: false,
          isRequesting: false,
          upgrade: upgrade,
          errorMessage: errorMessage
        });
      }catch(e){
        console.log(error)
        // window.location = "/server-offline";
      }
    });
  }

  componentDidMount () {
    this.getAnalyticsInfo()
    
  }
	

  render(){
    return (
      <Fragment>
          <MetaTags>
          <title>Analytics - BECE Stats</title>
          </MetaTags>
          <Header activePage={'analytics'} />
          <div className="slim-mainpanel">
            {
              this.state.isLoading ?
              <div className="container">
                <div className="d-flex ht-300 pos-relative align-items-center">
                  <div className="sk-wave">
                    <div className="sk-rect sk-rect1 bg-primary" />
                    <div className="sk-rect sk-rect2 bg-primary" />
                    <div className="sk-rect sk-rect3 bg-primary" />
                    <div className="sk-rect sk-rect4 bg-primary" />
                    <div className="sk-rect sk-rect5 bg-primary" />
                  </div>
                </div>
              </div>
              :
              <div className="container">
                <div className="slim-pageheader">
                  <ol className="breadcrumb slim-breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="/dashboard">Home</a>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">Analytics</li>
                  </ol>
                  <h6 className="slim-pagetitle">Analytics</h6>
                </div>

                <div className="card card-dash-chart-one mg-t-20 mg-sm-t-30">
                  <div className="row no-gutters">
                    <div className="col-lg-4 bg-light">
                      <div className="left-panel">
                      <h6 className="slim-card-title mb-4">Filter</h6>
                        <div className="form-group">
                          <div className="form-label-group">
                              <label className="form-label" htmlFor="default-01">Year</label>
                          </div>
                          <Select className="mb-2 w-100" defaultValue={this.state.year} onChange={(e) => {
                            this.setState({
                              ...this.state,
                              year: e
                            })

                            setTimeout(() => {
                              this.getAnalyticsInfo();
                            }, 800);
                          }}>
                            {
                              this.state.analytics_info.stats_years.map((data, index) => {
                                return(
                                  <Option value={data} key={index}>{data}</Option>
                                )
                              })
                            }
                          </Select>
                        </div>
                        {
                          this.state.isRequesting ?
                          <button type="button" disabled className="btn btn-block btn-primary mg-b-10 mt-2">
                            <span>Please Wait...</span> 
                          </button>
                          :
                          <button onClick={() => {this.getAnalyticsInfo()}} className="btn btn-primary btn-block mg-b-10 mt-2">Submit</button>
                        }
                        
                      </div>
                    </div>
                    <div className="col-lg-8">
                      {
                        this.state.isRequesting ?
                        <div className="right-panel">
                          <div className="d-flex ht-300 pos-relative align-items-center">
                            <div className="sk-wave">
                              <div className="sk-rect sk-rect1 bg-primary" />
                              <div className="sk-rect sk-rect2 bg-primary" />
                              <div className="sk-rect sk-rect3 bg-primary" />
                              <div className="sk-rect sk-rect4 bg-primary" />
                              <div className="sk-rect sk-rect5 bg-primary" />
                            </div>
                          </div>
                        </div>
                        :
                        <>
                          {
                            this.state.upgrade ?
                            <div className="right-panel">
                              <div className="row">
                                <div className="col-md-6 offset-3 mb-5" style={{border: "none"}}>
                                  <div className="pd-25 tx-center">
                                    <div className="card-icon-wrapper primary">
                                      <i className="icon ion-ios-color-wand-outline" />
                                    </div>
                                    <h4 className="tx-gray-800 mg-b-5">Upgrade Plan</h4>
                                    <p className="mg-b-50">
                                      Upgrade your plan to get access to this feature.    
                                    </p>
                                    <a href="/subscription" className="btn btn-dark btn-block text-uppercase tx-bold">
                                      Upgrade plan
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                            :
                            <div className="right-panel">
                              <div className="card-body p-0">
                                <h6 className="slim-card-title tx-dark mb-2">Total Students</h6>
                                <h2 className="tx-lato tx-inverse"><Numeral value={this.state.analytics_info.stats.total_students.toString()} format={"0,0"}/></h2>
                              </div>
                              <div className="card-sales p-0">
                                <div className="row">
                                  <div className="col tx-18 tx-success border-bottom-0">
                                    <label className="tx-12 mb-0">Passed</label>
                                    <p><b><Numeral value={this.state.analytics_info.stats.passed_students.toString()} format={"0,0"}/></b></p>
                                  </div>
                                  <div className="col tx-18 tx-danger">
                                    <label className="tx-12 mb-0">Failed</label>
                                    <p><b><Numeral value={this.state.analytics_info.stats.failed_students.toString()} format={"0,0"}/></b></p>
                                  </div>
                                </div>
                              </div>
                              <hr className="mg-t-30 mg-b-40" />
                              <a href={`/placement?y=${this.state.year}&c=a`}><div className="card-status">
                                <div className="media">
                                  <span className="icon tx-primary tx-50-force">A</span>
                                  <div className="media-body">
                                    <h3 className="mb-2">Category A Placement</h3>
                                    <p className="text-dark tx-18 mb-2">Total Students: <b>{this.state.analytics_info.analytics.catA.total_students}</b> <small className="text-muted">({this.state.analytics_info.analytics.catA.percentage}%)</small></p>
                                    <div className="progress mb-1">
                                      <div style={{width: this.state.analytics_info.analytics.catA.percentage+"%"}}
                                        className="progress-bar bg-primary progress-bar-xs"
                                        role="progressbar"
                                        aria-valuenow={this.state.analytics_info.analytics.catA.percentage}
                                        aria-valuemin={0}
                                        aria-valuemax={100}
                                      />
                                    </div>
                                    <p className="text-muted mg-b-15">Click to view student list.</p>
                                  </div>
                                </div>
                              </div></a>
                              <hr/>
                              <a href={`/placement?y=${this.state.year}&c=b`}><div className="card-status">
                                <div className="media">
                                  <span className="icon tx-primary tx-50-force">B</span>
                                  <div className="media-body">
                                    <h3 className="mb-2">Category B Placement</h3>
                                    <p className="text-dark tx-18 mb-2">Total Students: <b>{this.state.analytics_info.analytics.catB.total_students}</b> <small className="text-muted">({this.state.analytics_info.analytics.catB.percentage}%)</small></p>
                                    <div className="progress mb-1">
                                      <div style={{width: this.state.analytics_info.analytics.catB.percentage+"%"}}
                                        className="progress-bar bg-primary progress-bar-xs"
                                        role="progressbar"
                                        aria-valuenow={this.state.analytics_info.analytics.catB.percentage}
                                        aria-valuemin={0}
                                        aria-valuemax={100}
                                      />
                                    </div>
                                    <p className="text-muted mg-b-15">Click to view student list.</p>
                                  </div>
                                </div>
                              </div></a>
                              <hr/>
                              <a href={`/placement?y=${this.state.year}&c=c`}><div className="card-status">
                                <div className="media">
                                  <span className="icon tx-primary tx-50-force">C</span>
                                  <div className="media-body">
                                    <h3 className="mb-2">Category C Placement</h3>
                                    <p className="text-dark tx-18 mb-2">Total Students: <b>{this.state.analytics_info.analytics.catC.total_students}</b> <small className="text-muted">({this.state.analytics_info.analytics.catC.percentage}%)</small></p>
                                    <div className="progress mb-1">
                                      <div style={{width: this.state.analytics_info.analytics.catC.percentage+"%"}}
                                        className="progress-bar bg-primary progress-bar-xs"
                                        role="progressbar"
                                        aria-valuenow={this.state.analytics_info.analytics.catC.percentage}
                                        aria-valuemin={0}
                                        aria-valuemax={100}
                                      />
                                    </div>
                                    <p className="text-muted mg-b-15">Click to view student list.</p>
                                  </div>
                                </div>
                              </div></a>
                            </div>
                          }
                      </>
                    }
                    </div>
                  </div>
                </div>


              </div>
            }
          </div>
          <Footer />
      </Fragment>
    );
  }
}

export default Analytics;