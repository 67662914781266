import React, { Fragment } from "react";
import MetaTags from "react-meta-tags";

class NotFound extends React.Component {

  render(){
    return (
      <Fragment>
          <MetaTags>
          <title>404 - BECE Stats</title>
          </MetaTags>
          <div className="page-error-wrapper">
            <div>
              <h1 className="error-title mb-5 border-bottom-0">404</h1>
              <h5 className="tx-sm-24 tx-normal">
                Oopps. The page you were looking for doesn't exist.
              </h5>
              <p className="mg-b-50">
                You may have mistyped the address or the page may have moved.
              </p>
              <p className="mg-b-50">
                <a href="/dashboard" className="btn btn-error">
                  Back to Home
                </a>
              </p>
              <p className="error-footer">
                © Copyright 2022. All Rights Reserved. BECE Stats.
              </p>
            </div>
          </div>

      </Fragment>
    );
  }
}

export default NotFound;