import React, { Fragment } from "react";
import MetaTags from "react-meta-tags";
import AuthService from '../../misc/AuthService';
import CONSTANTS from '../../misc/Constants';
// import Footer from "./includes/Footer";
import axios from 'axios';
import cookie from 'react-cookies';
import { reactLocalStorage } from 'reactjs-localstorage';
import { 
  Input,
} from 'antd';
import 'antd/dist/antd.css';

class Login extends React.Component {

  constructor(props){
		AuthService.checkGreeter();
    super(props);
    this.state = {
			isLoading: false,
			isRequesting: false,
      showPassword: false,
			loginForm: {
				email: '',
      	password: '',
			},
      errorMessage: ''
    }
	}
	
	loginForm(event, name) {
    let value = event.target.value;
    var loginForm = this.state.loginForm;
    loginForm[name] = value;
    this.setState({
			...this.state,
      loginForm: loginForm,
    });
	}
	
	submitLoginForm = e => {
		this.setState({
			...this.state,
			isRequesting: true,
			errorMessage: '',
		 });
		e.preventDefault();

		axios.post(CONSTANTS.API_BASE_URL + "/auth/login", {...this.state.loginForm})
		.then((response) => {
      let data = response.data;
      let expirationDate = new Date();
      let validDays = 30;
      expirationDate.setTime(expirationDate.getTime() + (validDays*24*60*60*1000));

      cookie.save('access_token', data.access_token, { path: '/', expires: expirationDate })
      reactLocalStorage.setObject('userObj', data.user_obj);

      if(!data.user_obj.onboarding_status){
        window.location = "/onboarding";

      }else{
        window.location = "/dashboard";
      }
      

		}).catch((error) => {
      try{
        let errorResponse = error.response.data;

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("message")){
          errorMessage = errorResponse.message;
        }

        this.setState({
          ...this.state,
          isRequesting: false,
          errorMessage: errorMessage,
        });
      }catch(e){
        window.location = "/server-offline";
      }
		});

	}

  componentDidMount (){
		// window.initScript(window.NioApp, window.jQuery);
  }

  render(){
    return (
      <Fragment>
          <MetaTags>
          <title>Login - BECE Stats</title>
          </MetaTags>
          <div className="d-md-flex flex-row-reverse">
            <div className="signin-right">
              <div className="signin-box">
                <h2 className="signin-title-primary">Welcome back!</h2>
                <h3 className="signin-title-secondary">Login to continue.</h3>
                {
                  this.state.errorMessage.length > 0 &&
                  <div className="example-alert nk-block-head">
										<div className="alert alert-solid alert-danger"> 
											{this.state.errorMessage}
										</div>
									</div>
                }
                <form onSubmit={this.submitLoginForm} method="POST">
                  <div className="form-group">
                    <div className="form-label-group">
                        <label className="form-label" htmlFor="default-01">Email</label>
                    </div>
                    <Input size="large" required type="email" id="email" className="form-control form-control-lg" placeholder="Enter your email address" name="email" value={this.state.loginForm.email} onChange={(e) => {this.loginForm(e,"email");}} />
                  </div>
                  <div className="form-group mg-b-50">
                    <div className="form-label-group d-flex justify-content-between">
                        <label className="form-label" htmlFor="password">Password</label>
                        <a className="link link-primary link-sm" tabIndex="-1" href="/forgot-password">Forgot Password?</a>
                    </div>
                    <div className="form-control-wrap">
                      <Input.Password size="large" required type="password" className="form-control form-control-lg" id="password" name="password" value={this.state.loginForm.password} onChange={(e) => {this.loginForm(e,"password");}} placeholder="Enter your password" />
                    </div>
                  </div>
                  <div className="form-group text-center">
                    { 
                      !this.state.isRequesting ? 
                      <button type="submit" className="btn btn-lg btn-block btn-primary btn-signin">
                        <span>Login</span> 
                      </button>
                      :
                      <button type="button" disabled className="btn btn-lg btn-block btn-primary btn-signin">
                        <span>Please Wait...</span> 
                      </button>
                    }
                  </div>
                </form>
                <p className="mg-b-0">
                  Don't have an account? <a href="/register">Register</a>
                </p>
              </div>
            </div>
            <div className="signin-left">
              <div className="signin-box">
                <h2 className="slim-logo">
                  <a href={CONSTANTS.WEB_URL}>
                    <img src="/assets/img/becestats_black.png" width={"200px"} />
                  </a>
                </h2>
                <p>
                Welcome to BECE Stats. Login to view the breakdown of your Junior High School's students BECE performance and school placement analytics.
                </p>
                {/* <p>Browse our site and see for yourself why you need Slim.</p> */}
                <p className="tx-12">© Copyright 2022. All Rights Reserved.</p>
              </div>
            </div>
          </div>

      </Fragment>
    );
  }
}

export default Login;