import React, { Fragment } from "react";
import MetaTags from "react-meta-tags";
import AuthService from '../../misc/AuthService';
import CONSTANTS from '../../misc/Constants';
import axios from 'axios';
import cookie from 'react-cookies';
import { reactLocalStorage } from 'reactjs-localstorage';
import { 
  Input,
  Select, 
} from 'antd';
import 'antd/dist/antd.css';
import SideNav from "./include/SideNav";
import Header from "./include/Header";
import Footer from "./include/Footer";
import moment from 'moment';

const { Option } = Select;

class Onboarding extends React.Component {

  constructor(props){
		AuthService.checkOnboardingProtected();
    super(props);
    this.state = {
      userInfo: AuthService.getAuth().user,
			isLoading: true,
			isRequesting: false,
      logoName: 'Upload Logo',
      documentName: 'Upload Document',
      logo: null,
      document: null,
      errorMessage: '',
      onboardingErrors: {
        logo: '',
        document: '',
      }
    }
	}

  handleLogoUpload = e => {
    
    if (e.target.files && e.target.files.length > 0){
      this.setState({
        ...this.state,
        logo: e.target.files[0],
        logoName: e.target.files[0].name
      });
    }
    
  }

  handleDocumentUpload = e => {
    
    if (e.target.files && e.target.files.length > 0){
      this.setState({
        ...this.state,
        document: e.target.files[0],
        documentName: e.target.files[0].name
      });
    }
    
  }

  submitVerificationForm = (e) => {
    this.setState({
      ...this.state,
      isRequesting: true,
      errorMessage: '',
      onboardingErrors: {
        logo: '',
        document: '',
      }
    });

    e.preventDefault();

    const formData = new FormData();
    formData.append('logo',this.state.logo);
    formData.append('document',this.state.document);

    axios.post(CONSTANTS.API_BASE_URL + "/user/update-school-info", formData, AuthService.getAxiosUploadHeaders())
    .then((response) => {
      let responseInfo = response.data;
      let userObj = reactLocalStorage.getObject('userObj');
      userObj.account_status = responseInfo.data.account_status;
      userObj.school_logo = responseInfo.data.school_logo;
      userObj.verification_doc_type = responseInfo.data.verification_doc_type;
      userObj.verification_doc_link = responseInfo.data.verification_doc_link;
      reactLocalStorage.setObject('userObj', userObj);
      
      this.setState({
        ...this.state,
        isRequesting: false,
        userInfo: userObj
      })

    }).catch((error) => {
      try{
        console.log(error);
        let errorResponse = error.response.data;
        let onboardingErrors = this.state.onboardingErrors;

        if(errorResponse.hasOwnProperty("errors")){
          if(errorResponse.errors.hasOwnProperty("logo")){
            onboardingErrors.logo = errorResponse.errors.logo;
          }

          if(errorResponse.errors.hasOwnProperty("document")){
            onboardingErrors.document = errorResponse.errors.document;
          }

        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("message")){
          errorMessage = errorResponse.message;
        }

        window.scrollTo({top: 0, behavior: 'smooth'});

        this.setState({
          ...this.state,
          isRequesting: false,
          errorMessage: errorMessage,
          onboardingErrors: onboardingErrors
        });
      }catch(e){
        window.location = "/server-offline";
      }
    });

    
  }

  getSchoolInfo () {
    this.setState({
      ...this.state,
      isLoading: true
    });

    axios.post(CONSTANTS.API_BASE_URL + "/user/get-school-info", null, AuthService.getAxiosHeaders())
    .then((response) => {
      let responseInfo = response.data;

      let userObj = reactLocalStorage.getObject('userObj');
      userObj = responseInfo.data;
      reactLocalStorage.setObject('userObj', userObj);
      this.setState({
        ...this.state,
        isLoading: false,
        userInfo: userObj
      });
           
    }).catch((error) => {
      try{

        if(error.response.status === 401){
          window.location = "/logout";
          return;
        }

        let errorResponse = error.response.data;

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("message")){
          errorMessage = errorResponse.message;
        }


        this.setState({
          ...this.state,
          isLoading: false,
          errorMessage: errorMessage
        });
      }catch(e){
        console.log(error)
        // window.location = "/server-offline";
      }
    });
  }

  componentDidMount () {
    // load UI
    this.getSchoolInfo();
  }
	

  render(){
    return (
      <Fragment>
          <MetaTags>
          <title>Onboarding - BECE Stats</title>
          </MetaTags>
          <Header activePage={''} />
          <div className="slim-mainpanel">
            {
              this.state.isLoading ?
              <div className="container">
                <div className="d-flex ht-300 pos-relative align-items-center">
                  <div className="sk-wave">
                    <div className="sk-rect sk-rect1 bg-primary" />
                    <div className="sk-rect sk-rect2 bg-primary" />
                    <div className="sk-rect sk-rect3 bg-primary" />
                    <div className="sk-rect sk-rect4 bg-primary" />
                    <div className="sk-rect sk-rect5 bg-primary" />
                  </div>
                </div>
              </div>
              :
              <div className="container">
                {/* <div className="slim-pageheader">
                  <ol className="breadcrumb slim-breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="#">Home</a>
                    </li>
                  </ol>
                  <h6 className="slim-pagetitle">Onboarding</h6>
                </div> */}
                {
                  this.state.userInfo.account_status === 'new' ?
                  <div className="row mt-5">
                    <div className="col-md-6 offset-md-3">
                      {
                        this.state.errorMessage.length > 0 &&
                        <div className="example-alert nk-block-head">
                          <div className="alert alert-solid alert-danger"> 
                            {this.state.errorMessage}
                          </div>
                        </div>
                      }
                      <form onSubmit={this.submitVerificationForm} method="POST">
                        <div className="card card-body pd-25 tx-center">
                          <div className="card-icon-wrapper primary">
                            <i className="icon ion-ios-paper-outline" />
                          </div>
                          <h4 className="tx-gray-800 mg-b-5">School Verification</h4>
                          <p className="mg-b-50">
                            Fill the form below to verify your school's information 
                          </p>
                          <div className="text-left mb-4">
                            <div className="form-group">
                              <div className="form-label-group">
                                  <label className="form-label" htmlFor="default-01">School Code</label>
                              </div>
                              <Input size="large" disabled={true} required type="text" id="school_code" className="form-control form-control-lg" placeholder="Enter your school code" name="code" value={this.state.userInfo.school_code} />
                            </div>
                            <div className="form-group">
                              <div className="form-label-group">
                                  <label className="form-label" htmlFor="default-01">School Name</label>
                              </div>
                              <Input size="large" disabled={true} required type="text" id="school_code" className="form-control form-control-lg" placeholder="Enter your school code" name="code" value={this.state.userInfo.school_name} />
                            </div>
                            <div className="form-group">
                              <div className="form-label-group">
                                <label className="form-label" htmlFor="default-01">Upload School Logo</label>
                              </div>
                              <div class="form-control-wrap">
                                <div class="custom-file">
                                  <input 
                                  onChange={this.handleLogoUpload} 
                                  accept="image/*"
                                  type="file" 
                                  className="custom-file-input" 
                                  id="logo"
                                  required 
                                  />
                                  <label class="custom-file-label" for="logo">{this.state.logoName}</label>
                                </div>
                                {
                                  this.state.onboardingErrors.logo.length > 0 && 
                                  <p className="text-danger fs-12px">{this.state.onboardingErrors.logo}</p>
                                }
                              </div>
                            </div>
                            <div className="form-group">
                              <div className="form-label-group">
                                {
                                  this.state.userInfo.school_type === 'private' ?
                                  <label className="form-label" htmlFor="default-01">Upload School Registration Certificate</label>
                                  :
                                  <label className="form-label" htmlFor="default-01">Upload Head Master/Mistress National ID</label>
                                }
                              </div>
                              <div class="form-control-wrap">
                                <div class="custom-file">
                                  <input 
                                  onChange={this.handleDocumentUpload} 
                                  accept="image/*,.pdf"
                                  type="file" 
                                  className="custom-file-input" 
                                  id="document" 
                                  required
                                  />
                                  <label class="custom-file-label" for="document">{this.state.documentName}</label>
                                </div>
                                {
                                  this.state.onboardingErrors.document.length > 0 && 
                                  <p className="text-danger fs-12px">{this.state.onboardingErrors.document}</p>
                                }
                              </div>
                            </div>
                          </div>
                          {
                            this.state.isRequesting ?
                            <button type="button" disabled className="btn btn-block btn-primary">
                              <span>Please Wait...</span> 
                            </button>
                            :
                            <button type="submit" className="btn btn-primary btn-block">
                              Submit
                            </button>
                          }
                        </div>
                      </form>
                    </div>
                  </div>
                  :
                  <div className="row mt-5">
                    <div className="col-md-6 offset-md-3">
                      {
                        this.state.errorMessage.length > 0 &&
                        <div className="example-alert nk-block-head">
                          <div className="alert alert-solid alert-danger"> 
                            {this.state.errorMessage}
                          </div>
                        </div>
                      }
                      <div className="card card-contact card-body pd-25 tx-center">
                        <div className="card-icon-wrapper primary">
                          <i className="icon ion-ios-timer-outline" />
                        </div>
                        <h4 className="tx-gray-800 mg-b-5">Pending Verification</h4>
                        <p className="mg-b-50">
                          Your school document is being reviewed for verification and you will be notified via email when your school has been verified.
                        </p>
                        <div className="media align-items-center text-left mg-y-25">
                          <img src={this.state.userInfo.school_logo} className="wd-40 rounded-circle" alt="" />
                          <div className="media-body mg-l-15">
                            <h6 className="tx-14 mg-b-2">
                              <span>{this.state.userInfo.school_name}</span>
                            </h6>
                            <small className="mg-b-0 text-capitalize">{this.state.userInfo.school_type}</small>
                          </div>
                        </div>
                        <p className="contact-item">
                          <span>School Code:</span>
                          <span>{this.state.userInfo.school_code}</span>
                        </p>
                        <p className="contact-item">
                          <span>District:</span>
                          <span>{this.state.userInfo.school_district}</span>
                        </p>
                        <p className="contact-item">
                          <span>School Document:</span>
                          <a href={this.state.userInfo.verification_doc_link} target="_blank">{this.state.userInfo.verification_doc_type}</a>
                        </p>

                      </div>
                    </div>
                  </div>
                }
                
              </div>
            }
          </div>
          <Footer />
      </Fragment>
    );
  }
}

export default Onboarding;