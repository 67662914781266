import React, { Fragment } from "react";
import MetaTags from "react-meta-tags";
import AuthService from '../../misc/AuthService';
import CONSTANTS from '../../misc/Constants';
// import Footer from "./includes/Footer";
import axios from 'axios';
import { 
  Input
} from 'antd';
import 'antd/dist/antd.css';
class ResetPassword extends React.Component {

  constructor(props){
		AuthService.checkGreeter();
    super(props);
    this.state = {
			isLoading: true,
      showForgotPasswordForm: true,
      isRequesting: false,
			first_name: '',
      password: '',
      token: '',
      errorMessage: ''
    }
	}
	
	resetPasswordForm(event, name) {
    let value = event.target.value;
    this.setState({
      ...this.state,
      password: value,
    });
  }
	
	submitResetPasswordForm = e => {
    this.setState({
      ...this.state,
      isRequesting: true,
      errorMessage: '',
    });
    e.preventDefault();

    axios.post(CONSTANTS.API_BASE_URL + "/auth/reset-password", {token: this.state.token, password: this.state.password})
    .then((response) => {
      let result = response.data;
      this.setState({
        ...this.state,
        isRequesting: false,
        showForgotPasswordForm: false
      });

    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("message")){
          errorMessage = errorResponse.message;
        }

        this.setState({
          ...this.state,
          isRequesting: false,
          errorMessage: errorMessage
        });
      }catch(e){
        window.location = "/server-offline";
      }
    });

  }

  componentDidMount (){
		let url_string = window.location.href;
    let url = new URL(url_string);
    let params = window.parse_query_string(url.search.replace(/^(\?)/,""));
    
    let token = params.hasOwnProperty('token') ? params.token : '';

    if(token.length > 0){

      axios.post(CONSTANTS.API_BASE_URL + "/auth/reset-password-token-check", {token: params.token})
      .then((response) => {
        let result = response.data;

        this.setState({
          ...this.state,
          token: params.token,
          isLoading: false,
          first_name:result.first_name
        })
    
      }).catch((error) => {
          window.location = '/not-found';
      });

		}else{
			window.location = '/not-found';
		}
  }

  render(){
    return (
      <Fragment>
          <MetaTags>
          <title>Reset Password - BECE Stats</title>
          </MetaTags>
          {
            this.state.isLoading ?
            <div className="signin-wrapper">
              <div className="d-flex ht-300 pos-relative align-items-center">
                <div className="sk-wave">
                  <div className="sk-rect sk-rect1 bg-primary" />
                  <div className="sk-rect sk-rect2 bg-primary" />
                  <div className="sk-rect sk-rect3 bg-primary" />
                  <div className="sk-rect sk-rect4 bg-primary" />
                  <div className="sk-rect sk-rect5 bg-primary" />
                </div>
              </div>
            </div>
            :
            <div className="signin-wrapper">
            {
              this.state.showForgotPasswordForm ?
              <div className="signin-box">
                <h2 className="slim-logo">
                  <a href={CONSTANTS.WEB_URL}>
                  <img src="/assets/img/becestats_black.png" width={"200px"} />
                  </a>
                </h2>
                <h2 className="signin-title-primary">Setup New Password</h2>
                <h5 className="signin-title-secondary">Hi <b>{this.state.first_name}</b>, set a new password for your account.</h5>
                {
                  this.state.errorMessage.length > 0 &&
                  <div className="example-alert nk-block-head">
                    <div className="alert alert-solid alert-danger"> 
                      {this.state.errorMessage}
                    </div>
                  </div>
                }
                <form onSubmit={this.submitResetPasswordForm} method="POST">
                  <div className="form-group">
                    <div className="form-label-group">
                        <label className="form-label" htmlFor="default-01">New Password</label>
                    </div>
                    <Input.Password size="large" required type="password" className="form-control form-control-lg" id="password" name="password" value={this.state.password} onChange={(e) => {this.resetPasswordForm(e,"password");}} placeholder="Enter your password" />
                  </div>
                  <div className="form-group text-center">
                    { 
                      !this.state.isRequesting ? 
                      <button type="submit" className="btn btn-lg btn-block btn-primary btn-signin">
                        <span>Submit</span> 
                      </button>
                      :
                      <button type="button" disabled className="btn btn-lg btn-block btn-primary btn-signin">
                        <span>Please Wait...</span> 
                      </button>
                    }
                  </div>
                  {/* <button className="btn btn-primary btn-block btn-signin">Sign In</button> */}
                </form>
                <p className="mg-b-0">
                  Remember your password? <a href="/login">Login</a>
                </p>
              </div>
              :
              <div className="signin-box">
                <h2 className="slim-logo">
                  <a href={CONSTANTS.WEB_URL}>
                  <img src="/assets/img/becestats_black.png" width={"200px"} />
                  </a>
                </h2>
                <h3 className="signin-title-primary mb-5">
                Password Reset Successful! 🎉
                  <br />Login to access your account. 
                </h3>
                <a href="/" className="btn btn-lg btn-block btn-primary btn-signin">
                  <span >Login</span>
                </a>
              </div>
            }
            </div>
          }
      </Fragment>
    );
  }
}

export default ResetPassword;