import React, { Fragment } from "react";
import MetaTags from "react-meta-tags";
import AuthService from '../../misc/AuthService';
import CONSTANTS from '../../misc/Constants';
import SHS from '../../misc/SHS';
import axios from 'axios';
import cookie from 'react-cookies';
import { reactLocalStorage } from 'reactjs-localstorage';
import { 
  Input,
  InputNumber,
  Select, 
} from 'antd';
import 'antd/dist/antd.css';
import SideNav from "./include/SideNav";
import Header from "./include/Header";
import Footer from "./include/Footer";
import Pagination from "../client/include/Pagination";
import moment from 'moment';
import Numeral from 'react-numeral';
import { PaystackButton } from 'react-paystack';
const { Option } = Select;

class CreditTopUp extends React.Component {

  constructor(props){
		AuthService.checkerProtected();
    super(props);
    this.state = {
      userInfo: AuthService.getChecker().checker,
			isLoading: true,
			isRequesting: false,
      isRequestingPayment: false,
      initPayment: false,
      selectedPlan: '',
      totalList: 1,
      currentPage: 1,
      page: 1,
      currentPlan: 'bronze',
      expirationDate: '',
      transactions: {
        meta: {},
        data: []
      },
      paymentConfig: {},
      paymentStatus: '',
      paymentErrorMessage: '',
      errorMessage: ''
    }
	}

  getTransaction () {
    axios.post(CONSTANTS.API_BASE_URL + "/checker/get-subscription-transactions", {
      page: this.state.page
    }, AuthService.getCheckerAxiosHeaders())
    .then((response) => {
      let responseInfo = response.data;

      var checkerObj = reactLocalStorage.getObject('checkerObj');
      checkerObj.credit = responseInfo.data.credit;
      reactLocalStorage.setObject('checkerObj', checkerObj);

      this.setState({
        ...this.state,
        isLoading: false,
        isRequesting: false,
        userInfo: checkerObj,
        currentPage: this.state.page,
        totalList:  Math.ceil(responseInfo.data.transactions_list.meta.result_total / 10),
        transactions: responseInfo.data.transactions_list,
      });   

    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401 || error.response.status === 404){
          window.location = "/not-found";
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("message")){
          errorMessage = errorResponse.message;
        }

        this.setState({
          ...this.state,
          isLoading: false,
          isRequesting: false,
          errorMessage: errorMessage
        });
      }catch(e){
        console.log(error)
        // window.location = "/server-offline";
      }
    });
  }

  validatePayment(response){
    if(response.status === "failed"){
      this.setState({
        ...this.state,
        paymentErrorMessage: 'Error: Payment failed or something went wrong while processing payment. Try again'
      })
      window.modalShow('response-modal');
      return;
    }

    this.setState({
      ...this.state,
      isRequestingPayment: true,
      paymentErrorMessage: ''
    })

    window.modalShow('response-modal');

    axios.post(CONSTANTS.API_BASE_URL + "/checker/verify-payment", {
      tx_ref: this.state.paymentConfig.reference
    }, AuthService.getCheckerAxiosHeaders())
    .then((response) => {
      let responseInfo = response.data;
      if(responseInfo.data.payment_status === "success"){
        
        this.setState({
          ...this.state,
          isRequestingPayment: false,
          paymentStatus: responseInfo.data.payment_status,
        });

        setTimeout(() => {
          this.getTransaction();
        }, 1000);
        
      }else{
        this.setState({
          ...this.state,
          isRequestingPayment: false,
          paymentStatus: responseInfo.data.payment_status,
          paymentErrorMessage: responseInfo.data.payment_status_message
        });
        
      }

    }).catch((error) => {
      try{
        console.log(error);
        let errorResponse = error.response.data;

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("message")){
          errorMessage = errorResponse.message;
        }

        window.scrollTo({top: 0, behavior: 'smooth'});

        this.setState({
          ...this.state,
          isRequestingPayment: false,
          paymentStatus: "failed",
          paymentErrorMessage: errorMessage
        });

        window.modalShow('response-modal');
      }catch(e){
        console.log(e)
        // window.location = "/server-offline";
      }
    });
  }  

  initSubscriptionPayment (plan) {

    this.setState({
      ...this.state,
      initPayment: true,
      selectedPlan: plan,
      paymentErrorMessage: ''
    })

    window.modalShow('subscription-modal');

    axios.post(CONSTANTS.API_BASE_URL + "/checker/init-subscription-payment", {
      plan: plan
    }, AuthService.getCheckerAxiosHeaders())
    .then((response) => {
      let responseInfo = response.data;

      if(plan === 'free'){
        window.modalHide();
        this.getTransaction();

        setTimeout(() => {
          window.modalShow('response-modal');
        }, 500);
      }else{
        this.setState({
          ...this.state,
          paymentConfig: responseInfo.data,
          initPayment: false,
        });   
      }

         
    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 307){
          window.location = "/invitations";
          return;
        }

        if(error.response.status === 401 || error.response.status === 404){
          window.location = "/not-found";
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("message")){
          errorMessage = errorResponse.message;
        }

        this.setState({
          ...this.state,
          initPayment: false,
          paymentErrorMessage: errorMessage
        });
      }catch(e){
        console.log(error)
        // window.location = "/server-offline";
      }
    });

    setTimeout(() => {
      this.setState({
        ...this.state,
        initPayment: false
      })
    }, 2500);
  }

  componentDidMount () {
    let url_string = window.location.href;
    let url = new URL(url_string);
    let params = window.parse_query_string(url.search.replace(/^(\?)/,""));
    
    let page = params.hasOwnProperty('p') ? params.p : 1;
    
    this.setState({
      ...this.state,
      page: page
    })

    setTimeout(() => {
      this.getTransaction();
    }, 800);
  }

  render(){

    let paymentConfig = {};

    if(!this.state.isLoading){
      // payStack paymentConfig
      paymentConfig = {
        ...this.state.paymentConfig,
        text: 'Make Payment',
        onSuccess: (response) => {
          console.log(response);
          window.modalHide();
          this.validatePayment(response);
        },
        onClose: () => {
          console.log("payment modal closed");
        },
      };
    }
    
    return (
      <Fragment>
          <MetaTags>
          <title>Credit Top-Up - BECE Stats</title>
          </MetaTags>
          <Header activePage={'credit'} />
          <div className="slim-mainpanel">
            {
              this.state.isLoading ?
              <div className="container">
                <div className="d-flex ht-300 pos-relative align-items-center">
                  <div className="sk-wave">
                    <div className="sk-rect sk-rect1 bg-primary" />
                    <div className="sk-rect sk-rect2 bg-primary" />
                    <div className="sk-rect sk-rect3 bg-primary" />
                    <div className="sk-rect sk-rect4 bg-primary" />
                    <div className="sk-rect sk-rect5 bg-primary" />
                  </div>
                </div>
              </div>
              :
              <div className="container">
                <div className="slim-pageheader">
                  <ol className="breadcrumb slim-breadcrumb">
                    <li className="breadcrumb-item">
                      {/* <a href="#">Home</a> */}
                    </li>
                  </ol>
                  <h6 className="slim-pagetitle">Credit Top-Up</h6>
                </div>
                <div className="row">
                  <div class="col-lg-6">
                    <div class="d-h-t-right my-4" onClick={() => {window.location = "/credit";}}>
                      <div class="summary-item pointer-cursor">
                        <h1 className="text-primary">{this.state.userInfo.credit}</h1>
                        <span><b>Credit Balance</b> <br/> <span className="text-primary m-0">Top-up</span></span>                        
                      </div>                    
                    </div>
                  </div>
                </div>
                
                <div className="card card-pricing-one px-5">
                  <div className="row">
                    <div className="col-lg">
                      <div className="pricing-item">
                        <h5 className="pricing-title">Bronze</h5>
                        <div className="pricing-icon">
                          <i className="icon ion-wand" />
                        </div>
                        <h2 className="pricing-price mt-5 mb-0"><small>GHS </small> 10</h2>
                        <p className="mb-4 fs-18px text-primary">10 credit</p>
                        
                        <ul className="pricing-list">
                          <li><i className="icon ion-ios-checkmark mr-2 tx-24 text-success" />Search for 10 Schools</li>
                        </ul>
                        <button onClick={() => {this.initSubscriptionPayment('bronze')}} className="btn btn-primary btn-pricing d-inline">
                          Buy Credit
                        </button>
                      </div>
                    </div>
                    <div className="col-lg mg-t-30 mg-lg-t-0">
                      <div className="pricing-item">
                        <h5 className="pricing-title">Gold</h5>
                        <div className="pricing-icon">
                          <i className="icon ion-ribbon-a" />
                        </div>
                        <h2 className="pricing-price mt-5 mb-0"><small>GHS </small> 50</h2>
                        <p className="mb-4 fs-18px text-primary">60 credit</p>
                        
                        <ul className="pricing-list">
                          <li><i className="icon ion-ios-checkmark mr-2 tx-24 text-success" />Search for 60 Schools</li>
                        </ul>

                        <button onClick={() => {this.initSubscriptionPayment('gold')}} className="btn btn-primary btn-pricing d-inline">
                          Buy Credit
                        </button>
                      </div>
                    </div>
                    <div className="col-lg mg-t-30 mg-lg-t-0">
                      <div className="pricing-item">
                        <h5 className="pricing-title">Platinum</h5>
                        <div className="pricing-icon">
                          <i className="icon ion-star" />
                        </div>
                        <h2 className="pricing-price mt-5 mb-0"><small>GHS </small> 100</h2>
                        <p className="mb-4 fs-18px text-primary">150 credit</p>
                        
                        <ul className="pricing-list">
                          <li><i className="icon ion-ios-checkmark mr-2 tx-24 text-success" />Search for 150 Schools</li>
                        </ul>
                        <button onClick={() => {this.initSubscriptionPayment('platinum')}} className="btn btn-primary btn-pricing d-inline">
                          Buy Credit
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="section-wrapper mg-t-20 mt-5">
                  <label className="section-title">Transactions</label>
                  <p className="mg-b-20 mg-sm-b-40">List of credit top-up transactions.</p>
                  {
                    this.state.transactions.data.length === 0 ?
                    <div className="row">
                      <div className="col-md-6 offset-3 mb-5" style={{border: "none"}}>
                        <div className="pd-25 tx-center">
                          <div className="card-icon-wrapper primary">
                            <i className="icon ion-ios-browsers-outline" />
                          </div>
                          <p className="mg-b-50">
                            No transactions found.   
                          </p>
                        </div>
                      </div>
                    </div>
                    :
                    <div className="table-responsive">
                      <table className="table table-striped mg-b-0">
                        <thead>
                          <tr>
                            <th>Package</th>
                            <th>Amount</th>
                            <th>Invoice No.#</th>
                            <th>Status</th>
                            <th>Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            this.state.transactions.data.map((data, index) => {
                              let status = <span className="text-warning"><span className="square-8 bg-warning mg-r-5 rounded-circle" /> Pending</span>
                              
                              if(data.status == 's'){
                                status = <span className="text-success"><span className="square-8 bg-success mg-r-5 rounded-circle" /> Successful</span>
                              
                              }else if(data.status == 'f'){
                                status = <span className="text-danger"><span className="square-8 bg-danger mg-r-5 rounded-circle" /> Failed</span>
                              }else if(data.status == 'r'){
                                status = <span className="text-dark"><span className="square-8 bg-danger mg-r-5 rounded-circle" /> Refunded</span>
                              }

                              return (
                                <tr>
                                  <td className="text-capitalize">{data.package_name}</td>
                                  <td>{data.amount}</td>
                                  <td>{data.invoice_number}</td>
                                  <td>{status}</td>
                                  <td>{data.date}</td>
                                </tr>
                              )
                            })
                          }
                        </tbody>
                      </table>
                      <div class="pt-5">
                        <Pagination pageCount={this.state.totalList} currentPage={this.state.currentPage} link={`/subscription?`} paramsValue={``} />
                      </div>
                    </div>
                  }
                </div>


              </div>
            }
          </div>

          <div id="subscription-modal" className="modal fade">
            <div className="modal-dialog modal-md" role="document">
              <div className="modal-content tx-size-sm">
                <div className="modal-body tx-center pd-y-20 pd-x-20">
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                  {
                    this.state.initPayment ?
                    <>
                      <div className="right-panel">
                        <div className="d-flex ht-100 pos-relative align-items-center">
                          <div className="sk-wave">
                            <div className="sk-rect sk-rect1 bg-primary" />
                            <div className="sk-rect sk-rect2 bg-primary" />
                            <div className="sk-rect sk-rect3 bg-primary" />
                            <div className="sk-rect sk-rect4 bg-primary" />
                            <div className="sk-rect sk-rect5 bg-primary" />
                          </div>
                        </div>
                      </div>
                    </>
                    :
                    <>
                      <h4 className="tx-dark tx-semibold mg-b-20">Subscription Payment</h4>
                      <p class="px-5">Click on the "Make Payment" button to subscribe to this package for a year.</p>
                      {
                        this.state.selectedPlan === 'bronze' &&
                        <>
                          <div className="pricing-icon">
                            <i className="icon ion-wand" />
                          </div>
                          <h5 className="pricing-title">Bronze</h5>
                          <h2 className="pricing-price mt-5 mb-0"><small>GHS </small> 10</h2>
                          <p className="mb-4 fs-18px text-primary">10 credit</p>
                          
                          <ul className="pricing-list">
                            <li><i className="icon ion-ios-checkmark mr-2 tx-24 text-success" />Search for 10 Schools</li>
                          </ul>
                        </>
                      }
                      
                      {
                        this.state.selectedPlan === 'gold' &&
                        <>
                          <div className="pricing-icon">
                            <i className="icon ion-ribbon-b" />
                          </div>
                          <h5 className="pricing-title">Gold</h5>
                          <h2 className="pricing-price mt-5 mb-0"><small>GHS </small> 50</h2>
                          <p className="mb-4 fs-18px text-primary">60 credit</p>
                          
                          <ul className="pricing-list">
                            <li><i className="icon ion-ios-checkmark mr-2 tx-24 text-success" />Search for 60 Schools</li>
                          </ul>
                        </>
                      }

                      {
                        this.state.selectedPlan === 'platinum' &&
                        <>
                          <div className="pricing-icon">
                            <i className="icon ion-star" />
                          </div>
                          <h5 className="pricing-title">Platinum</h5>
                          <h2 className="pricing-price mt-5 mb-0"><small>GHS </small> 100</h2>
                          <p className="mb-4 fs-18px text-primary">150 credit</p>
                          
                          <ul className="pricing-list">
                            <li><i className="icon ion-ios-checkmark mr-2 tx-24 text-success" />Search for 150 Schools</li>
                          </ul>
                        </>
                      }
                      <p class="text-danger">{this.state.paymentErrorMessage}</p>
                      <div class="nk-modal-action mt-5">
                        <PaystackButton className="btn btn-lg btn-primary" {...paymentConfig} />
                        <button class="btn btn-lg btn-mw btn-light m-1" data-dismiss="modal">Close</button>
                      </div>
                    </>
                  }
                </div>
              </div>
            </div>
          </div>

          <div id="response-modal" className="modal fade">
            <div className="modal-dialog modal-md" role="document">
              <div className="modal-content tx-size-sm">
                <div className="modal-body tx-center pd-y-20 pd-x-20">
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close" style={{position: "fixed", right: "20px"}}
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                  {
                    this.state.isRequestingPayment ?
                    <>
                      <div className="right-panel">
                        <div className="d-flex ht-100 pos-relative align-items-center">
                          <div className="sk-wave">
                            <div className="sk-rect sk-rect1 bg-primary" />
                            <div className="sk-rect sk-rect2 bg-primary" />
                            <div className="sk-rect sk-rect3 bg-primary" />
                            <div className="sk-rect sk-rect4 bg-primary" />
                            <div className="sk-rect sk-rect5 bg-primary" />
                          </div>
                        </div>
                      </div>
                      <h4 className="tx-dark tx-semibold mg-b-20">Updating Credit Balance</h4>
                      <p className="mg-b-20 mg-x-20">Please wait while we update your credit balance.</p>
                      
                      {/* <button type="button" className="btn btn-success pd-x-25" data-dismiss="modal" aria-label="Close">
                        Continue
                      </button> */}
                    </>
                    :
                    <>
                      {
                        this.state.paymentErrorMessage.length > 0 ?
                        <>
                          <i className="icon ion-ios-close-outline tx-100 tx-danger lh-1 mg-t-20 d-inline-block" />
                          <h4 className="tx-danger tx-semibold mg-b-20">Payment Failed!</h4>
                          <p className="mg-b-20 mg-x-20">{this.state.paymentErrorMessage}</p>
                        </>
                        :
                        <>
                          <i className="icon ion-ios-checkmark-outline tx-100 tx-success lh-1 mg-t-20 d-inline-block" />
                          <h4 className="tx-success tx-semibold mg-b-20">Payment Successful!</h4>
                          <p className="mg-b-20 mg-x-20">Your credit balance has been updated successfully.</p>
                        </>
                      }
                      <button
                        type="button"
                        className="btn btn-dark pd-x-25"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        Close
                      </button>
                    </>
                  }
                </div>
              </div>
            </div>
          </div>
          <Footer />
      </Fragment>
    );
  }
}

export default CreditTopUp;