import React, { Fragment } from "react";
import classNames from "classnames";
import AuthService from '../../../misc/AuthService';
import Constants from '../../../misc/Constants';
class Footer extends React.Component {

  constructor(props){
    super(props);
  }

  render(){

    return (
      <div className="slim-footer">
        <div className="container">
          <p>Copyright 2022 © All Rights Reserved. BECE Stats</p>
          {/* <p>
            Powered by: <a href="#">BK Grand Technologies Ltd.</a>
          </p> */}
        </div>
      </div>

    )
  }
}

export default Footer;