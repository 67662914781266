const Production = {
  Constants: {
      "WEB_URL": "https://becestats.com",
      "APP_URL": "https://app.becestats.com",
      "API_BASE_URL": "https://api.becestats.com"
  }
}

const Development = {
  Constants: {
    "WEB_URL": "http://localhost:7700",
    "APP_URL": "http://localhost:3000",
    "API_BASE_URL": "http://localhost:8300"
  }
}

export default process.env.NODE_ENV === 'production' ? Production.Constants : Development.Constants;