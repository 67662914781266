import React, { Fragment } from "react";

class Pagination extends React.Component {

  constructor(props){
    super(props);
    
    this.state = {
      // 
    }
  }

  render(){
    let items = [];

    let delta = 2,
    left = parseInt(this.props.currentPage) - delta,
    right = parseInt(this.props.currentPage) + delta + 1,
    result = [];

    result = Array.from({
        length: parseInt(this.props.pageCount)
      }, (v, k) => k + 1)
      .filter(i => i && i >= left && i < right);

    if (result[0] == this.props.currentPage) {
      items.push(
        <li className="page-item previous disabled">
          <a href="#" className="page-link">
            <i className="fa fa-angle-left"></i>
          </a>
        </li>
      );
    } else {
      items.push(
        <li className="page-item previous">
          <a href={`${this.props.link}p=1${this.props.paramsValue}`} className="page-link">
            <i className="fa fa-angle-left"></i>
          </a>
        </li>
      );
    }

    for (let x = 0; x < result.length; x++) {

      if (result[x] == this.props.currentPage) {
        items.push(
          <li className="page-item active">
            <a href={`${this.props.link}p=${result[x]}${this.props.paramsValue}`} className="page-link">{result[x]}</a>
          </li>
        );
      } else {
        items.push(
          <li className="page-item">
            <a href={`${this.props.link}p=${result[x]}${this.props.paramsValue}`} className="page-link">{result[x]}</a>
          </li>
        );
      }

    }

    if (this.props.pageCount == this.props.currentPage) {
      items.push(
        <li className="page-item next disabled">
          <a href="#" className="page-link">
            <i className="fa fa-angle-right"></i>
          </a>
        </li>
      );
    } else {
      items.push(
        <li className="page-item next">
          <a href={`${this.props.link}p=${this.props.pageCount}${this.props.paramsValue}`} className="page-link">
            <i className="fa fa-angle-right"></i>
          </a>
        </li>
      );
    }

    return (
      <nav>
        <ul className="pagination justify-content-center">
          {items}
        </ul>
      </nav>
    )
  }
}

export default Pagination;