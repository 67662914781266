import React from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Login from './components/client/Login';
import Logout from './components/client/Logout';
import CheckerLogout from './components/checker/Logout';
import Registration from './components/client/Registration';
import ForgotPassword from './components/client/ForgotPassword';
import ResetPassword from './components/client/ResetPassword';
import Dashboard from './components/client/Dashboard';
import NotFound from './components/client/NotFound';
import Onboarding from './components/client/Onboarding';
import Placement from './components/client/Placement';
import Analytics from './components/client/Analytics';
import Subscription from './components/client/Subscription';
import SubjectPerformance from './components/client/Performance';
import CheckerLogin from './components/checker/Login';
import CheckerRegister from './components/checker/Register';
import ChoiceGuide from './components/checker/ChoiceGuide';
import CreditTopUp from './components/checker/Credit';
import ForgotCheckerPassword from './components/checker/ForgotPassword';
import ResetCheckerPassword from './components/checker/ResetPassword';

class Routes extends React.Component {
 
  render(){
    return (
      <Router>
        <Switch>
          
          <Route exact path={`${process.env.PUBLIC_URL + "/"}`} component={Login} />
          <Route exact path={`${process.env.PUBLIC_URL + "/login"}`} component={Login} />
          <Route exact path={`${process.env.PUBLIC_URL + "/register"}`} component={Registration} />
          <Route exact path={`${process.env.PUBLIC_URL + "/forgot-password"}`} component={ForgotPassword} />
          <Route exact path={`${process.env.PUBLIC_URL + "/reset-password"}`} component={ResetPassword} />
          <Route exact path={`${process.env.PUBLIC_URL + "/onboarding"}`} component={Onboarding} />
          <Route exact path={`${process.env.PUBLIC_URL + "/dashboard"}`} component={Dashboard} />
          <Route exact path={`${process.env.PUBLIC_URL + "/placement"}`} component={Placement} />
          <Route exact path={`${process.env.PUBLIC_URL + "/analytics"}`} component={Analytics} />
          <Route exact path={`${process.env.PUBLIC_URL + "/performance"}`} component={SubjectPerformance} />
          <Route exact path={`${process.env.PUBLIC_URL + "/subscription"}`} component={Subscription} />
          
          
          <Route exact path={`${process.env.PUBLIC_URL + "/checker-login"}`} component={CheckerLogin} />
          <Route exact path={`${process.env.PUBLIC_URL + "/forgot-checker-password"}`} component={ForgotCheckerPassword} />
          <Route exact path={`${process.env.PUBLIC_URL + "/reset-checker-password"}`} component={ResetCheckerPassword} />
          <Route exact path={`${process.env.PUBLIC_URL + "/checker-register"}`} component={CheckerRegister} />
          <Route exact path={`${process.env.PUBLIC_URL + "/choice-guide"}`} component={ChoiceGuide} />
          <Route exact path={`${process.env.PUBLIC_URL + "/credit"}`} component={CreditTopUp} />
          
          
          <Route exact path={`${process.env.PUBLIC_URL + "/logout"}`} component={Logout} /> 
          <Route exact path={`${process.env.PUBLIC_URL + "/checker-logout"}`} component={CheckerLogout} /> 
          <Route path={`${process.env.PUBLIC_URL + "/not-found"}`} component={NotFound} />
          <Route exact component={NotFound} />
        </Switch>
      </Router>
    )
  }
}


export default Routes;