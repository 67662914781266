import React, { Fragment } from "react";
import MetaTags from "react-meta-tags";
import AuthService from '../../misc/AuthService';
import CONSTANTS from '../../misc/Constants';
import axios from 'axios';
import cookie from 'react-cookies';
import { reactLocalStorage } from 'reactjs-localstorage';
import { 
  Input,
  Select, 
} from 'antd';
import 'antd/dist/antd.css';
import SideNav from "./include/SideNav";
import Header from "./include/Header";
import Footer from "./include/Footer";
import moment from 'moment';
import Numeral from 'react-numeral';
const { Option } = Select;

class Dashboard extends React.Component {

  constructor(props){
		AuthService.checkProtected();
    super(props);
    this.state = {
      userInfo: AuthService.getAuth().user,
			isLoading: true,
			isRequesting: false,
      year: '',
      dashboard_info: {
        current_year: '',
        stats_years: [],
        stats: {
          total_students: 0,
          passed_students: 0,
          failed_students: 0
        },
      }
    }
	}

  getDashboardInfo () {
    this.setState({
      ...this.state,
      isLoading: true
    });

    axios.post(CONSTANTS.API_BASE_URL + "/user/get-dashboard-info", {
      year: this.state.year
    }, AuthService.getAxiosHeaders())
    .then((response) => {
      let responseInfo = response.data;

      this.setState({
        ...this.state,
        isLoading: false,
        dashboard_info: responseInfo.data,
        year: responseInfo.data.current_year
      });
           
    }).catch((error) => {
      try{

        if(error.response.status === 401){
          window.location = "/logout";
          return;
        }

        let errorResponse = error.response.data;

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("message")){
          errorMessage = errorResponse.message;
        }


        this.setState({
          ...this.state,
          isLoading: false,
          errorMessage: errorMessage
        });
      }catch(e){
        console.log(error)
        // window.location = "/server-offline";
      }
    });
  }

  componentDidMount () {
    this.getDashboardInfo()
    
  }
	

  render(){
    return (
      <Fragment>
          <MetaTags>
          <title>Dashboard - BECE Stats</title>
          </MetaTags>
          <Header activePage={'dashboard'} />
          <div className="slim-mainpanel">
            {
              this.state.isLoading ?
              <div className="container">
                <div className="d-flex ht-300 pos-relative align-items-center">
                  <div className="sk-wave">
                    <div className="sk-rect sk-rect1 bg-primary" />
                    <div className="sk-rect sk-rect2 bg-primary" />
                    <div className="sk-rect sk-rect3 bg-primary" />
                    <div className="sk-rect sk-rect4 bg-primary" />
                    <div className="sk-rect sk-rect5 bg-primary" />
                  </div>
                </div>
              </div>
              :
              <div className="container">
                <div className="slim-pageheader">
                  <ol className="breadcrumb slim-breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="#">Home</a>
                    </li>
                  </ol>
                  <h6 className="slim-pagetitle">Dashboard</h6>
                </div>

                <div className="row">
                  <div className="col-lg-6">
                    <h3 className="tx-inverse mg-b-15">Welcome back, <span className="text-primary">{this.state.userInfo.school_name}</span></h3>
                    <p className="mg-b-40">
                      Below is a quick overview of how your students performed in the <b>{this.state.year} BECE</b>. Use the <b>Quick Links</b> options to get a detail break-down of your students performance.
                    </p>
                  </div>
                </div>
                <div className="card card-dash-chart-one mg-t-20 mg-sm-t-30">
                  <div className="row no-gutters">
                    <div className="col-lg-4 bg-light">
                      <div className="left-panel">
                        <h6 className="slim-card-title">Change Year</h6>
                        <Select className="mb-5 w-100" defaultValue={this.state.year} onChange={(e) => {
                          this.setState({
                            ...this.state,
                            year: e
                          })

                          setTimeout(() => {
                            this.getDashboardInfo();
                          }, 800);
                        }}>
                          {
                            this.state.dashboard_info.stats_years.map((data, index) => {
                              return(
                                <Option value={data} key={index}>{data}</Option>
                              )
                            })
                          }
                        </Select>
                        <div className="card-body p-0">
                          <h6 className="slim-card-title tx-dark">Total Students</h6>
                          <h2 className="tx-lato tx-inverse">
                            <Numeral value={this.state.dashboard_info.stats.total_students.toString()} format={"0,0"}/>
                          </h2>
                        </div>
                        <div className="card-sales p-0">
                          <div className="row">
                            <div className="col tx-18 tx-success border-bottom-0">
                              <label className="tx-12 mb-0">Passed</label>
                              <p><b><Numeral value={this.state.dashboard_info.stats.passed_students.toString()} format={"0,0"}/></b></p>
                            </div>
                            <div className="col tx-18 tx-danger">
                              <label className="tx-12 mb-0">Failed</label>
                              <p><b><Numeral value={this.state.dashboard_info.stats.failed_students.toString()} format={"0,0"}/></b></p>
                            </div>
                          </div>
                        </div>

                        {/* <hr className="mg-t-30 mg-b-40" />
                        <h6 className="visitor-operating-label">School Placement Analytics</h6>
                        <label className="mg-b-5"><b>Category A</b> (30% - 142 Students)</label>
                        <div className="progress mg-b-15">
                          <div
                            className="progress-bar bg-primary progress-bar-xs wd-30p"
                            role="progressbar"
                            aria-valuenow={30}
                            aria-valuemin={0}
                            aria-valuemax={100}
                          />
                        </div>
                        <label className="mg-b-5"><b>Category B</b> (50% - 542 Students)</label>
                        <div className="progress mg-b-15">
                          <div
                            className="progress-bar bg-primary progress-bar-xs wd-50p"
                            role="progressbar"
                            aria-valuenow={50}
                            aria-valuemin={0}
                            aria-valuemax={100}
                          />
                        </div>
                        <label className="mg-b-5"><b>Category C </b>(20% - 162 Students)</label>
                        <div className="progress mg-b-15">
                          <div
                            className="progress-bar bg-primary progress-bar-xs wd-20p"
                            role="progressbar"
                            aria-valuenow={20}
                            aria-valuemin={0}
                            aria-valuemax={100}
                          />
                        </div> */}
                      </div>
                    </div>
                    <div className="col-lg-8">
                      <div className="right-panel">
                        <h6 className="slim-card-title">Quick Links</h6>
                        <a href="/placement"><div className="card-status">
                          <div className="media">
                            <i className="icon ion-ios-list-outline tx-primary tx-50-force" />
                            <div className="media-body">
                              <h2 className="mb-0">Placement</h2>
                              <p className="text-dark">View the list of your students and their placement.</p>
                            </div>
                          </div>
                        </div></a>
                        <hr/>
                        <a href="/analytics"><div className="card-status">
                          <div className="media">
                            <i className="icon ion-ios-pie-outline tx-primary tx-50-force" />
                            <div className="media-body">
                              <h2 className="mb-0">Analytics</h2>
                              <p className="text-dark">View the brake-down of your students placement by school category.</p>
                            </div>
                          </div>
                        </div></a>
                        <hr/>
                        <a href="/performance"><div className="card-status">
                          <div className="media">
                            <i className="icon ion-ios-bookmarks-outline tx-primary tx-50-force" />
                            <div className="media-body">
                              <h2 className="mb-0">Performance</h2>
                              <p className="text-dark">View the break-down of your students performance by subject.</p>
                            </div>
                          </div>
                        </div></a>
                      </div>
                    </div>
                  </div>
                </div>


              </div>
            }
            
          </div>
          <Footer />
      </Fragment>
    );
  }
}

export default Dashboard;