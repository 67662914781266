const SHS = [
  {
     "school_code":"0020301",
     "school_name":"Aburi Girls Senior High",
     "category":"A",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020301",
     "school_name":"Aburi Girls Senior High",
     "category":"A",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020301",
     "school_name":"Aburi Girls Senior High",
     "category":"A",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020301",
     "school_name":"Aburi Girls Senior High",
     "category":"A",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020301",
     "school_name":"Aburi Girls Senior High",
     "category":"A",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0010121",
     "school_name":"Accra Academy",
     "category":"A",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Boys",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0010121",
     "school_name":"Accra Academy",
     "category":"A",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Boys",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0010121",
     "school_name":"Accra Academy",
     "category":"A",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Boys",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0010121",
     "school_name":"Accra Academy",
     "category":"A",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Boys",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0010121",
     "school_name":"Accra Academy",
     "category":"A",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Boys",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0010112",
     "school_name":"Accra Girls Senior High .",
     "category":"A",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0010112",
     "school_name":"Accra Girls Senior High .",
     "category":"A",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0010112",
     "school_name":"Accra Girls Senior High .",
     "category":"A",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0010112",
     "school_name":"Accra Girls Senior High .",
     "category":"A",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0010112",
     "school_name":"Accra Girls Senior High .",
     "category":"A",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9010101",
     "school_name":"Accra Tech. Trg. Centre",
     "category":"A",
     "school_type":"TVET",
     "program":"ARCHITECTURAL DRAFTING",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9010101",
     "school_name":"Accra Tech. Trg. Centre",
     "category":"A",
     "school_type":"TVET",
     "program":"AUTOBODY WORKS",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9010101",
     "school_name":"Accra Tech. Trg. Centre",
     "category":"A",
     "school_type":"TVET",
     "program":"BUILDING CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9010101",
     "school_name":"Accra Tech. Trg. Centre",
     "category":"A",
     "school_type":"TVET",
     "program":"COMPUTER HARDWARE TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9010101",
     "school_name":"Accra Tech. Trg. Centre",
     "category":"A",
     "school_type":"TVET",
     "program":"CREATIVE ART TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9010101",
     "school_name":"Accra Tech. Trg. Centre",
     "category":"A",
     "school_type":"TVET",
     "program":"DATABASE MANAGEMENT(CBT OPTION, FOR GIRLS ONLY)",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9010101",
     "school_name":"Accra Tech. Trg. Centre",
     "category":"A",
     "school_type":"TVET",
     "program":"ELECTRICAL ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9010101",
     "school_name":"Accra Tech. Trg. Centre",
     "category":"A",
     "school_type":"TVET",
     "program":"ELECTRONICS ENGINEERING",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9010101",
     "school_name":"Accra Tech. Trg. Centre",
     "category":"A",
     "school_type":"TVET",
     "program":"FASHION DESIGNING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9010101",
     "school_name":"Accra Tech. Trg. Centre",
     "category":"A",
     "school_type":"TVET",
     "program":"INDUSTRIAL MECHANICS",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9010101",
     "school_name":"Accra Tech. Trg. Centre",
     "category":"A",
     "school_type":"TVET",
     "program":"MECHANICAL. ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9010101",
     "school_name":"Accra Tech. Trg. Centre",
     "category":"A",
     "school_type":"TVET",
     "program":"MOTOR VEHICLE ENGINEERING",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9010101",
     "school_name":"Accra Tech. Trg. Centre",
     "category":"A",
     "school_type":"TVET",
     "program":"PLUMBING & GAS FITTING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9010101",
     "school_name":"Accra Tech. Trg. Centre",
     "category":"A",
     "school_type":"TVET",
     "program":"REFRIGERATION & AIR-CONDITION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9010101",
     "school_name":"Accra Tech. Trg. Centre",
     "category":"A",
     "school_type":"TVET",
     "program":"SMALL ENG. REPAIR",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9010101",
     "school_name":"Accra Tech. Trg. Centre",
     "category":"A",
     "school_type":"TVET",
     "program":"WELDING AND FABRICATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9010101",
     "school_name":"Accra Tech. Trg. Centre",
     "category":"A",
     "school_type":"TVET",
     "program":"WOOD CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0010110",
     "school_name":"Achimota Senior High",
     "category":"A",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0010110",
     "school_name":"Achimota Senior High",
     "category":"A",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0010110",
     "school_name":"Achimota Senior High",
     "category":"A",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0010110",
     "school_name":"Achimota Senior High",
     "category":"A",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0010110",
     "school_name":"Achimota Senior High",
     "category":"A",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9011601",
     "school_name":"Ada Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"BUILDING CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9011601",
     "school_name":"Ada Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"BUSINESS ACCOUNTING",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9011601",
     "school_name":"Ada Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"BUSINESS SECRETARIAL",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9011601",
     "school_name":"Ada Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"ELECTRICAL ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9011601",
     "school_name":"Ada Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"ELECTRONICS ENGINEERING",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9011601",
     "school_name":"Ada Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"FASHION DESIGNING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9011601",
     "school_name":"Ada Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"HOSPITALITY & CATERING MANAGEMENT",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9011601",
     "school_name":"Ada Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"INFORMATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9011601",
     "school_name":"Ada Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"MECHANICAL. ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9011601",
     "school_name":"Ada Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"PLUMBING & GAS FITTING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9011601",
     "school_name":"Ada Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"SOLAR PHOTOVOLTAIC TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9011601",
     "school_name":"Ada Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"WOOD CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030102",
     "school_name":"Adisadel College",
     "category":"A",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Boys",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030102",
     "school_name":"Adisadel College",
     "category":"A",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Boys",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030102",
     "school_name":"Adisadel College",
     "category":"A",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Boys",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030102",
     "school_name":"Adisadel College",
     "category":"A",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Boys",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9021101",
     "school_name":"Akwatia Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"BUILDING CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9021101",
     "school_name":"Akwatia Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"BUSINESS ACCOUNTING",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9021101",
     "school_name":"Akwatia Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"BUSINESS INFORMATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9021101",
     "school_name":"Akwatia Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"COMPUTER HARDWARE TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9021101",
     "school_name":"Akwatia Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"ELECTRICAL ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9021101",
     "school_name":"Akwatia Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"ELECTRONICS ENGINEERING",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9021101",
     "school_name":"Akwatia Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"FASHION DESIGNING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9021101",
     "school_name":"Akwatia Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"HOSPITALITY & CATERING MANAGEMENT",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9021101",
     "school_name":"Akwatia Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"MECHANICAL. ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9021101",
     "school_name":"Akwatia Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"MOTOR VEHICLE ENGINEERING",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9021101",
     "school_name":"Akwatia Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"PLUMBING & GAS FITTING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9021101",
     "school_name":"Akwatia Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"WELDING AND FABRICATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9021101",
     "school_name":"Akwatia Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"WOOD CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9070501",
     "school_name":"Anlo Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"BUILDING CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9070501",
     "school_name":"Anlo Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"BUSINESS ACCOUNTING",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9070501",
     "school_name":"Anlo Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"BUSINESS SECRETARIAL",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9070501",
     "school_name":"Anlo Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"CREATIVE ART TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9070501",
     "school_name":"Anlo Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"ELECTRICAL ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9070501",
     "school_name":"Anlo Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"ELECTRONICS ENGINEERING",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9070501",
     "school_name":"Anlo Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"FASHION DESIGNING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9070501",
     "school_name":"Anlo Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"GENERAL TEXTILES",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9070501",
     "school_name":"Anlo Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"HOSPITALITY & CATERING MANAGEMENT",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9070501",
     "school_name":"Anlo Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"MECHANICAL. ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9070501",
     "school_name":"Anlo Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"MOTOR VEHICLE ENGINEERING",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9070501",
     "school_name":"Anlo Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"PLUMBING & GAS FITTING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9070501",
     "school_name":"Anlo Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"WOOD CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040103",
     "school_name":"Archbishop Porter Girls Snr.High .",
     "category":"A",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040103",
     "school_name":"Archbishop Porter Girls Snr.High .",
     "category":"A",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040103",
     "school_name":"Archbishop Porter Girls Snr.High .",
     "category":"A",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040103",
     "school_name":"Archbishop Porter Girls Snr.High .",
     "category":"A",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040103",
     "school_name":"Archbishop Porter Girls Snr.High .",
     "category":"A",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9030401",
     "school_name":"Asuansi Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"AGRICULTURAL MECHANIZATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9030401",
     "school_name":"Asuansi Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"ARCHITECTURAL DRAFTING",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9030401",
     "school_name":"Asuansi Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"BUILDING CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9030401",
     "school_name":"Asuansi Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"BUSINESS ACCOUNTING",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9030401",
     "school_name":"Asuansi Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"BUSINESS INFORMATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9030401",
     "school_name":"Asuansi Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"BUSINESS SECRETARIAL",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9030401",
     "school_name":"Asuansi Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"COMPUTER HARDWARE TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9030401",
     "school_name":"Asuansi Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"CREATIVE ART TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9030401",
     "school_name":"Asuansi Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"ELECTRICAL ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9030401",
     "school_name":"Asuansi Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"FASHION DESIGNING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9030401",
     "school_name":"Asuansi Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"FURNITURE DESIGN & CONSTRUCTION",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9030401",
     "school_name":"Asuansi Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"HOSPITALITY & CATERING MANAGEMENT",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9030401",
     "school_name":"Asuansi Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"MECHANICAL. ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9030401",
     "school_name":"Asuansi Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"MOTOR VEHICLE ENGINEERING",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9030401",
     "school_name":"Asuansi Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"PLUMBING & GAS FITTING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9030401",
     "school_name":"Asuansi Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"WELDING AND FABRICATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9030401",
     "school_name":"Asuansi Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"WOOD CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9090101",
     "school_name":"Bawku Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"AGRICULTURAL MECHANIZATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9090101",
     "school_name":"Bawku Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"BUILDING CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9090101",
     "school_name":"Bawku Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"BUSINESS ACCOUNTING",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9090101",
     "school_name":"Bawku Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"BUSINESS INFORMATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9090101",
     "school_name":"Bawku Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"BUSINESS SECRETARIAL",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9090101",
     "school_name":"Bawku Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"CREATIVE ART TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9090101",
     "school_name":"Bawku Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"ELECTRICAL ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9090101",
     "school_name":"Bawku Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"ELECTRONICS ENGINEERING",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9090101",
     "school_name":"Bawku Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"FASHION DESIGNING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9090101",
     "school_name":"Bawku Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"HOSPITALITY & CATERING MANAGEMENT",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9090101",
     "school_name":"Bawku Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"MECHANICAL. ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9090101",
     "school_name":"Bawku Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"MOTOR VEHICLE ENGINEERING",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9090101",
     "school_name":"Bawku Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"PLUMBING & GAS FITTING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9090101",
     "school_name":"Bawku Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"WELDING AND FABRICATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9090101",
     "school_name":"Bawku Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"WOOD CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060303",
     "school_name":"Berekum Presby Senior High",
     "category":"A",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060303",
     "school_name":"Berekum Presby Senior High",
     "category":"A",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060303",
     "school_name":"Berekum Presby Senior High",
     "category":"A",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060303",
     "school_name":"Berekum Presby Senior High",
     "category":"A",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060303",
     "school_name":"Berekum Presby Senior High",
     "category":"A",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060303",
     "school_name":"Berekum Presby Senior High",
     "category":"A",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070601",
     "school_name":"Bishop Herman College",
     "category":"A",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Boys",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070601",
     "school_name":"Bishop Herman College",
     "category":"A",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Boys",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070601",
     "school_name":"Bishop Herman College",
     "category":"A",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Boys",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070601",
     "school_name":"Bishop Herman College",
     "category":"A",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Boys",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070601",
     "school_name":"Bishop Herman College",
     "category":"A",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Boys",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0090401",
     "school_name":"Bolga Girls Senior High ",
     "category":"A",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0090401",
     "school_name":"Bolga Girls Senior High ",
     "category":"A",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0090401",
     "school_name":"Bolga Girls Senior High ",
     "category":"A",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0090401",
     "school_name":"Bolga Girls Senior High ",
     "category":"A",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0090401",
     "school_name":"Bolga Girls Senior High ",
     "category":"A",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9090401",
     "school_name":"Bolga Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"ARCHITECTURAL DRAFTING",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9090401",
     "school_name":"Bolga Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"BUILDING CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9090401",
     "school_name":"Bolga Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"BUSINESS ACCOUNTING",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9090401",
     "school_name":"Bolga Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"BUSINESS SECRETARIAL",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9090401",
     "school_name":"Bolga Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"COMPUTER HARDWARE TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9090401",
     "school_name":"Bolga Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"CREATIVE ART TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9090401",
     "school_name":"Bolga Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"ELECTRICAL ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9090401",
     "school_name":"Bolga Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"ELECTRONICS ENGINEERING",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9090401",
     "school_name":"Bolga Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"FASHION DESIGNING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9090401",
     "school_name":"Bolga Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"HOSPITALITY & CATERING MANAGEMENT",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9090401",
     "school_name":"Bolga Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"MECHANICAL. ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9090401",
     "school_name":"Bolga Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"MOTOR VEHICLE ENGINEERING",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9090401",
     "school_name":"Bolga Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"PLUMBING & GAS FITTING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9090401",
     "school_name":"Bolga Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"WELDING AND FABRICATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9090401",
     "school_name":"Bolga Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"WOOD CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0090403",
     "school_name":"Bolgatanga Senior High ",
     "category":"A",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0090403",
     "school_name":"Bolgatanga Senior High ",
     "category":"A",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0090403",
     "school_name":"Bolgatanga Senior High ",
     "category":"A",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0090403",
     "school_name":"Bolgatanga Senior High ",
     "category":"A",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0090403",
     "school_name":"Bolgatanga Senior High ",
     "category":"A",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0090403",
     "school_name":"Bolgatanga Senior High ",
     "category":"A",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9030101",
     "school_name":"Cape Coast Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"ARCHITECTURAL DRAFTING",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9030101",
     "school_name":"Cape Coast Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"BUILDING CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9030101",
     "school_name":"Cape Coast Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"BUSINESS ACCOUNTING",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9030101",
     "school_name":"Cape Coast Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"BUSINESS INFORMATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9030101",
     "school_name":"Cape Coast Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"BUSINESS SECRETARIAL",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9030101",
     "school_name":"Cape Coast Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"CREATIVE ART TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9030101",
     "school_name":"Cape Coast Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"ELECTRICAL ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9030101",
     "school_name":"Cape Coast Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"ELECTRONICS ENGINEERING",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9030101",
     "school_name":"Cape Coast Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"FASHION DESIGNING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9030101",
     "school_name":"Cape Coast Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"FURNITURE DESIGN & CONSTRUCTION",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9030101",
     "school_name":"Cape Coast Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"HOSPITALITY & CATERING MANAGEMENT",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9030101",
     "school_name":"Cape Coast Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"MECHANICAL. ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9030101",
     "school_name":"Cape Coast Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"MOTOR VEHICLE ENGINEERING",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9030101",
     "school_name":"Cape Coast Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"PLUMBING & GAS FITTING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9030101",
     "school_name":"Cape Coast Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"REFRIGERATION & AIR-CONDITION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9030101",
     "school_name":"Cape Coast Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"WELDING AND FABRICATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9030101",
     "school_name":"Cape Coast Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"WOOD CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9080101",
     "school_name":"Dabokpa Voc\/Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"AGRICULTURAL MECHANIZATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9080101",
     "school_name":"Dabokpa Voc\/Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"BIODIGESTER CONSTRUCTION (CBT OPTION)",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9080101",
     "school_name":"Dabokpa Voc\/Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"BUILDING CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9080101",
     "school_name":"Dabokpa Voc\/Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"BUILDING CONSTRUCTION TECHNOLOGY (CBT OPTION)",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9080101",
     "school_name":"Dabokpa Voc\/Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"BUSINESS ACCOUNTING",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9080101",
     "school_name":"Dabokpa Voc\/Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"BUSINESS SECRETARIAL",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9080101",
     "school_name":"Dabokpa Voc\/Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"COMPUTER HARDWARE TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9080101",
     "school_name":"Dabokpa Voc\/Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"CREATIVE ART TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9080101",
     "school_name":"Dabokpa Voc\/Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"ELECTRICAL ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9080101",
     "school_name":"Dabokpa Voc\/Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"ELECTRONICS ENGINEERING",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9080101",
     "school_name":"Dabokpa Voc\/Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"FASHION DESIGNING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9080101",
     "school_name":"Dabokpa Voc\/Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"HOSPITALITY & CATERING MANAGEMENT",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9080101",
     "school_name":"Dabokpa Voc\/Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"MECHANICAL. ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9080101",
     "school_name":"Dabokpa Voc\/Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"MOTOR VEHICLE ENGINEERING",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9080101",
     "school_name":"Dabokpa Voc\/Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"PLUMBING & GAS FITTING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9080101",
     "school_name":"Dabokpa Voc\/Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"SMALL ENG. REPAIR",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9080101",
     "school_name":"Dabokpa Voc\/Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"SOFTWARE DEVELOPMENT(CBT OPTION, FOR GIRLS ONLY)",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9080101",
     "school_name":"Dabokpa Voc\/Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"SOLAR PHOTOVOLTAIC TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9080101",
     "school_name":"Dabokpa Voc\/Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"WELDING AND FABRICATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9080101",
     "school_name":"Dabokpa Voc\/Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"WOOD CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040105",
     "school_name":"Fijai Senior High",
     "category":"A",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040105",
     "school_name":"Fijai Senior High",
     "category":"A",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040105",
     "school_name":"Fijai Senior High",
     "category":"A",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040105",
     "school_name":"Fijai Senior High",
     "category":"A",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040105",
     "school_name":"Fijai Senior High",
     "category":"A",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020104",
     "school_name":"Ghana Senior High, Koforidua ",
     "category":"A",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020104",
     "school_name":"Ghana Senior High, Koforidua ",
     "category":"A",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020104",
     "school_name":"Ghana Senior High, Koforidua ",
     "category":"A",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020104",
     "school_name":"Ghana Senior High, Koforidua ",
     "category":"A",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020104",
     "school_name":"Ghana Senior High, Koforidua ",
     "category":"A",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020104",
     "school_name":"Ghana Senior High, Koforidua ",
     "category":"A",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0080102",
     "school_name":"Ghana Senior High, Tamale",
     "category":"A",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0080102",
     "school_name":"Ghana Senior High, Tamale",
     "category":"A",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0080102",
     "school_name":"Ghana Senior High, Tamale",
     "category":"A",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0080102",
     "school_name":"Ghana Senior High, Tamale",
     "category":"A",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0080102",
     "school_name":"Ghana Senior High, Tamale",
     "category":"A",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040104",
     "school_name":"Ghana Senior High\/Tech",
     "category":"A",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Boys",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040104",
     "school_name":"Ghana Senior High\/Tech",
     "category":"A",
     "school_type":"SHTS",
     "program":"General Science",
     "gender":"Boys",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040104",
     "school_name":"Ghana Senior High\/Tech",
     "category":"A",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Boys",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030103",
     "school_name":"Holy Child School, Cape Coast",
     "category":"A",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030103",
     "school_name":"Holy Child School, Cape Coast",
     "category":"A",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030103",
     "school_name":"Holy Child School, Cape Coast",
     "category":"A",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030103",
     "school_name":"Holy Child School, Cape Coast",
     "category":"A",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030103",
     "school_name":"Holy Child School, Cape Coast",
     "category":"A",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9040401",
     "school_name":"Kikam Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"ARCHITECTURAL DRAFTING",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9040401",
     "school_name":"Kikam Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"BUILDING CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9040401",
     "school_name":"Kikam Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"COMPUTER HARDWARE TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9040401",
     "school_name":"Kikam Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"ELECTRICAL ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9040401",
     "school_name":"Kikam Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"ELECTRONICS ENGINEERING",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9040401",
     "school_name":"Kikam Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"MECHANICAL. ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9040401",
     "school_name":"Kikam Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"MOTOR VEHICLE ENGINEERING",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9040401",
     "school_name":"Kikam Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"PLUMBING & GAS FITTING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9040401",
     "school_name":"Kikam Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"SOLAR PHOTOVOLTAIC TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9040401",
     "school_name":"Kikam Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"WELDING AND FABRICATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9040401",
     "school_name":"Kikam Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"WOOD CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020101",
     "school_name":"Koforidua Senior High\/Tech",
     "category":"A",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020101",
     "school_name":"Koforidua Senior High\/Tech",
     "category":"A",
     "school_type":"SHTS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020101",
     "school_name":"Koforidua Senior High\/Tech",
     "category":"A",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020101",
     "school_name":"Koforidua Senior High\/Tech",
     "category":"A",
     "school_type":"SHTS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9020101",
     "school_name":"Koforidua Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"BIODIGESTER CONSTRUCTION (CBT OPTION)",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9020101",
     "school_name":"Koforidua Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"BUILDING CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9020101",
     "school_name":"Koforidua Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"BUILDING CONSTRUCTION TECHNOLOGY (CBT OPTION)",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9020101",
     "school_name":"Koforidua Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"BUSINESS ACCOUNTING",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9020101",
     "school_name":"Koforidua Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"BUSINESS INFORMATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9020101",
     "school_name":"Koforidua Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"COMPUTER HARDWARE TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9020101",
     "school_name":"Koforidua Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"CREATIVE ART TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9020101",
     "school_name":"Koforidua Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"ELECTRICAL ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9020101",
     "school_name":"Koforidua Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"ELECTRONICS ENGINEERING",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9020101",
     "school_name":"Koforidua Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"FASHION DESIGNING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9020101",
     "school_name":"Koforidua Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"HOSPITALITY & CATERING MANAGEMENT",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9020101",
     "school_name":"Koforidua Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"MECHANICAL. ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9020101",
     "school_name":"Koforidua Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"MOTOR VEHICLE ENGINEERING",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9020101",
     "school_name":"Koforidua Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"PLUMBING & GAS FITTING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9020101",
     "school_name":"Koforidua Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"WELDING AND FABRICATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9020101",
     "school_name":"Koforidua Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"WOOD CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9070601",
     "school_name":"Kpando Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"AGRICULTURAL MECHANIZATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9070601",
     "school_name":"Kpando Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"BIODIGESTER CONSTRUCTION (CBT OPTION)",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9070601",
     "school_name":"Kpando Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"BUILDING CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9070601",
     "school_name":"Kpando Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"BUILDING CONSTRUCTION TECHNOLOGY (CBT OPTION)",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9070601",
     "school_name":"Kpando Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"BUSINESS ACCOUNTING",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9070601",
     "school_name":"Kpando Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"BUSINESS SECRETARIAL",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9070601",
     "school_name":"Kpando Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"COMPUTER HARDWARE TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9070601",
     "school_name":"Kpando Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"CREATIVE ART TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9070601",
     "school_name":"Kpando Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"ELECTRICAL ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9070601",
     "school_name":"Kpando Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"FASHION DESIGNING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9070601",
     "school_name":"Kpando Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"FURNITURE DESIGN & CONSTRUCTION",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9070601",
     "school_name":"Kpando Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"HOSPITALITY & CATERING MANAGEMENT",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9070601",
     "school_name":"Kpando Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"INFORMATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9070601",
     "school_name":"Kpando Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"MECHANICAL. ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9070601",
     "school_name":"Kpando Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"MOTOR VEHICLE ENGINEERING",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9070601",
     "school_name":"Kpando Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"PLUMBING & GAS FITTING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9070601",
     "school_name":"Kpando Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"WELDING AND FABRICATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9070601",
     "school_name":"Kpando Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"WOOD CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021501",
     "school_name":"Krobo Girls Senior High",
     "category":"A",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021501",
     "school_name":"Krobo Girls Senior High",
     "category":"A",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021501",
     "school_name":"Krobo Girls Senior High",
     "category":"A",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021501",
     "school_name":"Krobo Girls Senior High",
     "category":"A",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021501",
     "school_name":"Krobo Girls Senior High",
     "category":"A",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050111",
     "school_name":"Kumasi High School",
     "category":"A",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Boys",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050111",
     "school_name":"Kumasi High School",
     "category":"A",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Boys",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050111",
     "school_name":"Kumasi High School",
     "category":"A",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Boys",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050111",
     "school_name":"Kumasi High School",
     "category":"A",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Boys",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050111",
     "school_name":"Kumasi High School",
     "category":"A",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Boys",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9050101",
     "school_name":"Kumasi Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"AGRICULTURAL MECHANIZATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9050101",
     "school_name":"Kumasi Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"ARCHITECTURAL DRAFTING",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9050101",
     "school_name":"Kumasi Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"AUTOBODY WORKS",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9050101",
     "school_name":"Kumasi Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"BIODIGESTER CONSTRUCTION (CBT OPTION)",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9050101",
     "school_name":"Kumasi Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"BUILDING CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9050101",
     "school_name":"Kumasi Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"BUILDING CONSTRUCTION TECHNOLOGY (CBT OPTION)",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9050101",
     "school_name":"Kumasi Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"COMPUTER HARDWARE TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9050101",
     "school_name":"Kumasi Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"DIESEL MECH\/HEAVY ENGINE, HEAVY DUTY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9050101",
     "school_name":"Kumasi Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"ELECTRICAL ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9050101",
     "school_name":"Kumasi Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"ELECTRICAL MACHINE\/MOTOR REWINDING",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9050101",
     "school_name":"Kumasi Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"ELECTRONICS ENGINEERING",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9050101",
     "school_name":"Kumasi Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"FASHION DESIGNING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9050101",
     "school_name":"Kumasi Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"FURNITURE DESIGN & CONSTRUCTION",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9050101",
     "school_name":"Kumasi Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"INDUSTRIAL MECHANICS",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9050101",
     "school_name":"Kumasi Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"MOTOR VEHICLE ENGINEERING",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9050101",
     "school_name":"Kumasi Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"PLUMBING & GAS FITTING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9050101",
     "school_name":"Kumasi Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"REFRIGERATION & AIR-CONDITION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9050101",
     "school_name":"Kumasi Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"UPHOLSTERY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9050101",
     "school_name":"Kumasi Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"WELDING AND FABRICATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9050101",
     "school_name":"Kumasi Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"WOOD CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0100202",
     "school_name":"Lawra Senior High ",
     "category":"A",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0100202",
     "school_name":"Lawra Senior High ",
     "category":"A",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0100202",
     "school_name":"Lawra Senior High ",
     "category":"A",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0100202",
     "school_name":"Lawra Senior High ",
     "category":"A",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070102",
     "school_name":"Mawuli School, Ho",
     "category":"A",
     "school_type":"SHTS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070102",
     "school_name":"Mawuli School, Ho",
     "category":"A",
     "school_type":"SHTS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070102",
     "school_name":"Mawuli School, Ho",
     "category":"A",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070102",
     "school_name":"Mawuli School, Ho",
     "category":"A",
     "school_type":"SHTS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070102",
     "school_name":"Mawuli School, Ho",
     "category":"A",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070102",
     "school_name":"Mawuli School, Ho",
     "category":"A",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070102",
     "school_name":"Mawuli School, Ho",
     "category":"A",
     "school_type":"SHTS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030301",
     "school_name":"Mfantsiman Girls Senior High",
     "category":"A",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030301",
     "school_name":"Mfantsiman Girls Senior High",
     "category":"A",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030301",
     "school_name":"Mfantsiman Girls Senior High",
     "category":"A",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030301",
     "school_name":"Mfantsiman Girls Senior High",
     "category":"A",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030301",
     "school_name":"Mfantsiman Girls Senior High",
     "category":"A",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030301",
     "school_name":"Mfantsiman Girls Senior High",
     "category":"A",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030104",
     "school_name":"Mfantsipim School",
     "category":"A",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Boys",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030104",
     "school_name":"Mfantsipim School",
     "category":"A",
     "school_type":"SHTS",
     "program":"General Science",
     "gender":"Boys",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030104",
     "school_name":"Mfantsipim School",
     "category":"A",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Boys",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030104",
     "school_name":"Mfantsipim School",
     "category":"A",
     "school_type":"SHTS",
     "program":"Visual Arts",
     "gender":"Boys",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0100201",
     "school_name":"Nandom Senior High",
     "category":"A",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Boys",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0100201",
     "school_name":"Nandom Senior High",
     "category":"A",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Boys",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0100201",
     "school_name":"Nandom Senior High",
     "category":"A",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Boys",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0090501",
     "school_name":"Navrongo Senior High ",
     "category":"A",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0090501",
     "school_name":"Navrongo Senior High ",
     "category":"A",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0090501",
     "school_name":"Navrongo Senior High ",
     "category":"A",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0090501",
     "school_name":"Navrongo Senior High ",
     "category":"A",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0090501",
     "school_name":"Navrongo Senior High ",
     "category":"A",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0090501",
     "school_name":"Navrongo Senior High ",
     "category":"A",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9060901",
     "school_name":"Nkoranza Tech Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"AGRICULTURAL MECHANIZATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9060901",
     "school_name":"Nkoranza Tech Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"ARCHITECTURAL DRAFTING",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9060901",
     "school_name":"Nkoranza Tech Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"BUILDING CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9060901",
     "school_name":"Nkoranza Tech Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"BUSINESS ACCOUNTING",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9060901",
     "school_name":"Nkoranza Tech Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"BUSINESS SECRETARIAL",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9060901",
     "school_name":"Nkoranza Tech Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"COMPUTER HARDWARE TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9060901",
     "school_name":"Nkoranza Tech Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"ELECTRICAL ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9060901",
     "school_name":"Nkoranza Tech Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"FASHION DESIGNING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9060901",
     "school_name":"Nkoranza Tech Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"HOSPITALITY & CATERING MANAGEMENT",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9060901",
     "school_name":"Nkoranza Tech Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"MECHANICAL. ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9060901",
     "school_name":"Nkoranza Tech Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"MOTOR VEHICLE ENGINEERING",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9060901",
     "school_name":"Nkoranza Tech Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"PLUMBING & GAS FITTING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9060901",
     "school_name":"Nkoranza Tech Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"REFRIGERATION & AIR-CONDITION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9060901",
     "school_name":"Nkoranza Tech Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"WELDING AND FABRICATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9060901",
     "school_name":"Nkoranza Tech Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"WOOD CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060107",
     "school_name":"Notre Dame Girls Senior High, Sunyani",
     "category":"A",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060107",
     "school_name":"Notre Dame Girls Senior High, Sunyani",
     "category":"A",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060107",
     "school_name":"Notre Dame Girls Senior High, Sunyani",
     "category":"A",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060107",
     "school_name":"Notre Dame Girls Senior High, Sunyani",
     "category":"A",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060107",
     "school_name":"Notre Dame Girls Senior High, Sunyani",
     "category":"A",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0090505",
     "school_name":"Notre Dame Sem\/ Senior High, Navrongo",
     "category":"A",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Boys",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0090505",
     "school_name":"Notre Dame Sem\/ Senior High, Navrongo",
     "category":"A",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Boys",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021302",
     "school_name":"Ofori Panin Senior High",
     "category":"A",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021302",
     "school_name":"Ofori Panin Senior High",
     "category":"A",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021302",
     "school_name":"Ofori Panin Senior High",
     "category":"A",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021302",
     "school_name":"Ofori Panin Senior High",
     "category":"A",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021302",
     "school_name":"Ofori Panin Senior High",
     "category":"A",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020402",
     "school_name":"Okuapeman Senior High",
     "category":"A",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020402",
     "school_name":"Okuapeman Senior High",
     "category":"A",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020402",
     "school_name":"Okuapeman Senior High",
     "category":"A",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020402",
     "school_name":"Okuapeman Senior High",
     "category":"A",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020402",
     "school_name":"Okuapeman Senior High",
     "category":"A",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020402",
     "school_name":"Okuapeman Senior High",
     "category":"A",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070101",
     "school_name":"OLA Girls Senior High, Ho",
     "category":"A",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070101",
     "school_name":"OLA Girls Senior High, Ho",
     "category":"A",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070101",
     "school_name":"OLA Girls Senior High, Ho",
     "category":"A",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070101",
     "school_name":"OLA Girls Senior High, Ho",
     "category":"A",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070101",
     "school_name":"OLA Girls Senior High, Ho",
     "category":"A",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0061201",
     "school_name":"OLA Girls Senior High, Kenyasi ",
     "category":"A",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0061201",
     "school_name":"OLA Girls Senior High, Kenyasi ",
     "category":"A",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0061201",
     "school_name":"OLA Girls Senior High, Kenyasi ",
     "category":"A",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0061201",
     "school_name":"OLA Girls Senior High, Kenyasi ",
     "category":"A",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050110",
     "school_name":"Opoku Ware School",
     "category":"A",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Boys",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050110",
     "school_name":"Opoku Ware School",
     "category":"A",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Boys",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050110",
     "school_name":"Opoku Ware School",
     "category":"A",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Boys",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050110",
     "school_name":"Opoku Ware School",
     "category":"A",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Boys",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060505",
     "school_name":"Our Lady of Providence Senior High",
     "category":"A",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060505",
     "school_name":"Our Lady of Providence Senior High",
     "category":"A",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060505",
     "school_name":"Our Lady of Providence Senior High",
     "category":"A",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060505",
     "school_name":"Our Lady of Providence Senior High",
     "category":"A",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020102",
     "school_name":"Pope John Snr. High & Min. Sem., Koforidua",
     "category":"A",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Boys",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020102",
     "school_name":"Pope John Snr. High & Min. Sem., Koforidua",
     "category":"A",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Boys",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020102",
     "school_name":"Pope John Snr. High & Min. Sem., Koforidua",
     "category":"A",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Boys",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020102",
     "school_name":"Pope John Snr. High & Min. Sem., Koforidua",
     "category":"A",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Boys",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050108",
     "school_name":"Prempeh College",
     "category":"A",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Boys",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050108",
     "school_name":"Prempeh College",
     "category":"A",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Boys",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050108",
     "school_name":"Prempeh College",
     "category":"A",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Boys",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050108",
     "school_name":"Prempeh College",
     "category":"A",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Boys",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0010111",
     "school_name":"Presby Boys Senior High, Legon",
     "category":"A",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Boys",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0010111",
     "school_name":"Presby Boys Senior High, Legon",
     "category":"A",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Boys",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0010111",
     "school_name":"Presby Boys Senior High, Legon",
     "category":"A",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Boys",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0010111",
     "school_name":"Presby Boys Senior High, Legon",
     "category":"A",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Boys",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0010111",
     "school_name":"Presby Boys Senior High, Legon",
     "category":"A",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Boys",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040102",
     "school_name":"Sekondi College ",
     "category":"A",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040102",
     "school_name":"Sekondi College ",
     "category":"A",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040102",
     "school_name":"Sekondi College ",
     "category":"A",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040102",
     "school_name":"Sekondi College ",
     "category":"A",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040102",
     "school_name":"Sekondi College ",
     "category":"A",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040102",
     "school_name":"Sekondi College ",
     "category":"A",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060203",
     "school_name":"Serwaa Kesse Girls Senior High",
     "category":"A",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060203",
     "school_name":"Serwaa Kesse Girls Senior High",
     "category":"A",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060203",
     "school_name":"Serwaa Kesse Girls Senior High",
     "category":"A",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060203",
     "school_name":"Serwaa Kesse Girls Senior High",
     "category":"A",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060203",
     "school_name":"Serwaa Kesse Girls Senior High",
     "category":"A",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060203",
     "school_name":"Serwaa Kesse Girls Senior High",
     "category":"A",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030101",
     "school_name":"St. Augustine's College, Cape Coast",
     "category":"A",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Boys",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030101",
     "school_name":"St. Augustine's College, Cape Coast",
     "category":"A",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Boys",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030101",
     "school_name":"St. Augustine's College, Cape Coast",
     "category":"A",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Boys",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030101",
     "school_name":"St. Augustine's College, Cape Coast",
     "category":"A",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Boys",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0080107",
     "school_name":"St. Charles Senior High, Tamale",
     "category":"A",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Boys",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0080107",
     "school_name":"St. Charles Senior High, Tamale",
     "category":"A",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Boys",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0080107",
     "school_name":"St. Charles Senior High, Tamale",
     "category":"A",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Boys",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0080107",
     "school_name":"St. Charles Senior High, Tamale",
     "category":"A",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Boys",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0100302",
     "school_name":"St. Francis Girls Senior High, Jirapa ",
     "category":"A",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0100302",
     "school_name":"St. Francis Girls Senior High, Jirapa ",
     "category":"A",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0100302",
     "school_name":"St. Francis Girls Senior High, Jirapa ",
     "category":"A",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0100302",
     "school_name":"St. Francis Girls Senior High, Jirapa ",
     "category":"A",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060106",
     "school_name":"St. James Sem & Senior High, Abesim",
     "category":"A",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Boys",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060106",
     "school_name":"St. James Sem & Senior High, Abesim",
     "category":"A",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Boys",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060106",
     "school_name":"St. James Sem & Senior High, Abesim",
     "category":"A",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Boys",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040107",
     "school_name":"St. John's Senior High, Sekondi",
     "category":"A",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Boys",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040107",
     "school_name":"St. John's Senior High, Sekondi",
     "category":"A",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Boys",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040107",
     "school_name":"St. John's Senior High, Sekondi",
     "category":"A",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Boys",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040107",
     "school_name":"St. John's Senior High, Sekondi",
     "category":"A",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Boys",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050102",
     "school_name":"St. Louis Senior High, Kumasi",
     "category":"A",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050102",
     "school_name":"St. Louis Senior High, Kumasi",
     "category":"A",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050102",
     "school_name":"St. Louis Senior High, Kumasi",
     "category":"A",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050102",
     "school_name":"St. Louis Senior High, Kumasi",
     "category":"A",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050102",
     "school_name":"St. Louis Senior High, Kumasi",
     "category":"A",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0010102",
     "school_name":"St. Mary's Senior High, Korle Gonno ",
     "category":"A",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0010102",
     "school_name":"St. Mary's Senior High, Korle Gonno ",
     "category":"A",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0010102",
     "school_name":"St. Mary's Senior High, Korle Gonno ",
     "category":"A",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0010102",
     "school_name":"St. Mary's Senior High, Korle Gonno ",
     "category":"A",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0010102",
     "school_name":"St. Mary's Senior High, Korle Gonno ",
     "category":"A",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9021301",
     "school_name":"St. Paul's Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"ARCHITECTURAL DRAFTING",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9021301",
     "school_name":"St. Paul's Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"BUILDING CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9021301",
     "school_name":"St. Paul's Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"COMPUTER HARDWARE TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9021301",
     "school_name":"St. Paul's Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"ELECTRICAL ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9021301",
     "school_name":"St. Paul's Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"MECHANICAL. ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9021301",
     "school_name":"St. Paul's Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"MOTOR VEHICLE ENGINEERING",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9021301",
     "school_name":"St. Paul's Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"SOLAR PHOTOVOLTAIC TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9021301",
     "school_name":"St. Paul's Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"WELDING AND FABRICATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9021301",
     "school_name":"St. Paul's Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"WOOD CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021003",
     "school_name":"St. Peter's Senior High, Nkwatia",
     "category":"A",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Boys",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021003",
     "school_name":"St. Peter's Senior High, Nkwatia",
     "category":"A",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Boys",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021003",
     "school_name":"St. Peter's Senior High, Nkwatia",
     "category":"A",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Boys",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021003",
     "school_name":"St. Peter's Senior High, Nkwatia",
     "category":"A",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Boys",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021103",
     "school_name":"St. Rose's Senior High, Akwatia",
     "category":"A",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021103",
     "school_name":"St. Rose's Senior High, Akwatia",
     "category":"A",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021103",
     "school_name":"St. Rose's Senior High, Akwatia",
     "category":"A",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0010105",
     "school_name":"St. Thomas Aquinas Senior High, Cantoments",
     "category":"A",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Boys",
     "residence":"Day"
  },
  {
     "school_code":"0010105",
     "school_name":"St. Thomas Aquinas Senior High, Cantoments",
     "category":"A",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Boys",
     "residence":"Day"
  },
  {
     "school_code":"0010105",
     "school_name":"St. Thomas Aquinas Senior High, Cantoments",
     "category":"A",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Boys",
     "residence":"Day"
  },
  {
     "school_code":"0010105",
     "school_name":"St. Thomas Aquinas Senior High, Cantoments",
     "category":"A",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Boys",
     "residence":"Day"
  },
  {
     "school_code":"0010105",
     "school_name":"St. Thomas Aquinas Senior High, Cantoments",
     "category":"A",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Boys",
     "residence":"Day"
  },
  {
     "school_code":"0060104",
     "school_name":"Sunyani Senior High",
     "category":"A",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060104",
     "school_name":"Sunyani Senior High",
     "category":"A",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060104",
     "school_name":"Sunyani Senior High",
     "category":"A",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060104",
     "school_name":"Sunyani Senior High",
     "category":"A",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060104",
     "school_name":"Sunyani Senior High",
     "category":"A",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060104",
     "school_name":"Sunyani Senior High",
     "category":"A",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050104",
     "school_name":"T. I. Ahmadiyya Senior High , Kumasi",
     "category":"A",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050104",
     "school_name":"T. I. Ahmadiyya Senior High , Kumasi",
     "category":"A",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050104",
     "school_name":"T. I. Ahmadiyya Senior High , Kumasi",
     "category":"A",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050104",
     "school_name":"T. I. Ahmadiyya Senior High , Kumasi",
     "category":"A",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050104",
     "school_name":"T. I. Ahmadiyya Senior High , Kumasi",
     "category":"A",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050104",
     "school_name":"T. I. Ahmadiyya Senior High , Kumasi",
     "category":"A",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9040101",
     "school_name":"Takoradi Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"ARCHITECTURAL DRAFTING",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9040101",
     "school_name":"Takoradi Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"BIODIGESTER CONSTRUCTION (CBT OPTION)",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9040101",
     "school_name":"Takoradi Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"BUILDING CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9040101",
     "school_name":"Takoradi Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"BUILDING CONSTRUCTION TECHNOLOGY (CBT OPTION)",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9040101",
     "school_name":"Takoradi Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"BUSINESS ACCOUNTING",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9040101",
     "school_name":"Takoradi Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"BUSINESS INFORMATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9040101",
     "school_name":"Takoradi Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"BUSINESS SECRETARIAL",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9040101",
     "school_name":"Takoradi Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"COMPUTER HARDWARE TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9040101",
     "school_name":"Takoradi Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"DIGITAL DESIGNING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9040101",
     "school_name":"Takoradi Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"ELECTRICAL ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9040101",
     "school_name":"Takoradi Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"ELECTRONICS ENGINEERING",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9040101",
     "school_name":"Takoradi Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"FASHION DESIGNING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9040101",
     "school_name":"Takoradi Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"HOSPITALITY & CATERING MANAGEMENT",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9040101",
     "school_name":"Takoradi Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"INFORMATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9040101",
     "school_name":"Takoradi Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"MECHANICAL. ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9040101",
     "school_name":"Takoradi Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"MOTOR VEHICLE ENGINEERING",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9040101",
     "school_name":"Takoradi Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"PLUMBING & GAS FITTING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9040101",
     "school_name":"Takoradi Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"REFRIGERATION & AIR-CONDITION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9040101",
     "school_name":"Takoradi Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"SMALL ENG. REPAIR",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9040101",
     "school_name":"Takoradi Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"SOFTWARE DEVELOPMENT(CBT OPTION, FOR GIRLS ONLY)",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9040101",
     "school_name":"Takoradi Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"WELDING AND FABRICATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0080101",
     "school_name":"Tamale Senior High .",
     "category":"A",
     "school_type":"SHTS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0080101",
     "school_name":"Tamale Senior High .",
     "category":"A",
     "school_type":"SHTS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0080101",
     "school_name":"Tamale Senior High .",
     "category":"A",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0080101",
     "school_name":"Tamale Senior High .",
     "category":"A",
     "school_type":"SHTS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0080101",
     "school_name":"Tamale Senior High .",
     "category":"A",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0080101",
     "school_name":"Tamale Senior High .",
     "category":"A",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0080101",
     "school_name":"Tamale Senior High .",
     "category":"A",
     "school_type":"SHTS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0010201",
     "school_name":"Tema Senior High ",
     "category":"A",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0010201",
     "school_name":"Tema Senior High ",
     "category":"A",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0010201",
     "school_name":"Tema Senior High ",
     "category":"A",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0010201",
     "school_name":"Tema Senior High ",
     "category":"A",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0010201",
     "school_name":"Tema Senior High ",
     "category":"A",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9010201",
     "school_name":"Tema Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"ARCHITECTURAL DRAFTING",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9010201",
     "school_name":"Tema Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"BIODIGESTER CONSTRUCTION (CBT OPTION)",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9010201",
     "school_name":"Tema Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"BUILDING CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9010201",
     "school_name":"Tema Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"BUILDING CONSTRUCTION TECHNOLOGY (CBT OPTION)",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9010201",
     "school_name":"Tema Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"BUSINESS ACCOUNTING",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9010201",
     "school_name":"Tema Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"BUSINESS INFORMATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9010201",
     "school_name":"Tema Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"BUSINESS SECRETARIAL",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9010201",
     "school_name":"Tema Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"COMPUTER HARDWARE TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9010201",
     "school_name":"Tema Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"ELECTRICAL ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9010201",
     "school_name":"Tema Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"ELECTRONICS ENGINEERING",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9010201",
     "school_name":"Tema Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"FASHION DESIGNING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9010201",
     "school_name":"Tema Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"GENERAL TEXTILES",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9010201",
     "school_name":"Tema Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"HOSPITALITY & CATERING MANAGEMENT",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9010201",
     "school_name":"Tema Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"INDUSTRIAL MECHANICS",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9010201",
     "school_name":"Tema Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"MECHANICAL. ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9010201",
     "school_name":"Tema Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"MOTOR VEHICLE ENGINEERING",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9010201",
     "school_name":"Tema Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"PLUMBING & GAS FITTING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9010201",
     "school_name":"Tema Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"PRINTING",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9010201",
     "school_name":"Tema Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"SOLAR PHOTOVOLTAIC TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9010201",
     "school_name":"Tema Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"VISUAL COMMUNICATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9010201",
     "school_name":"Tema Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"WELDING AND FABRICATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9010201",
     "school_name":"Tema Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"WOOD CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9100101",
     "school_name":"Wa Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"AGRICULTURAL MECHANIZATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9100101",
     "school_name":"Wa Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"BUILDING CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9100101",
     "school_name":"Wa Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"BUSINESS ACCOUNTING",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9100101",
     "school_name":"Wa Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"BUSINESS INFORMATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9100101",
     "school_name":"Wa Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"COMPUTER HARDWARE TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9100101",
     "school_name":"Wa Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"CREATIVE ART TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9100101",
     "school_name":"Wa Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"ELECTRICAL ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9100101",
     "school_name":"Wa Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"ELECTRONICS ENGINEERING",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9100101",
     "school_name":"Wa Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"FASHION DESIGNING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9100101",
     "school_name":"Wa Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"HOSPITALITY & CATERING MANAGEMENT",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9100101",
     "school_name":"Wa Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"MECHANICAL. ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9100101",
     "school_name":"Wa Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"MOTOR VEHICLE ENGINEERING",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9100101",
     "school_name":"Wa Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"PLUMBING & GAS FITTING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9100101",
     "school_name":"Wa Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"WELDING AND FABRICATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9100101",
     "school_name":"Wa Tech. Inst.",
     "category":"A",
     "school_type":"TVET",
     "program":"WOOD CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030107",
     "school_name":"Wesley Girls Senior High, Cape Coast",
     "category":"A",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030107",
     "school_name":"Wesley Girls Senior High, Cape Coast",
     "category":"A",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030107",
     "school_name":"Wesley Girls Senior High, Cape Coast",
     "category":"A",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030107",
     "school_name":"Wesley Girls Senior High, Cape Coast",
     "category":"A",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030107",
     "school_name":"Wesley Girls Senior High, Cape Coast",
     "category":"A",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050201",
     "school_name":"Yaa Asantewaa Girls Senior High .",
     "category":"A",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050201",
     "school_name":"Yaa Asantewaa Girls Senior High .",
     "category":"A",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050201",
     "school_name":"Yaa Asantewaa Girls Senior High .",
     "category":"A",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050201",
     "school_name":"Yaa Asantewaa Girls Senior High .",
     "category":"A",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050201",
     "school_name":"Yaa Asantewaa Girls Senior High .",
     "category":"A",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050201",
     "school_name":"Yaa Asantewaa Girls Senior High .",
     "category":"A",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021001",
     "school_name":"Abetifi Presby Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021001",
     "school_name":"Abetifi Presby Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021001",
     "school_name":"Abetifi Presby Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021001",
     "school_name":"Abetifi Presby Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021001",
     "school_name":"Abetifi Presby Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9021001",
     "school_name":"Abetifi Tech. Inst.",
     "category":"B",
     "school_type":"TVET",
     "program":"AGRICULTURAL MECHANIZATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9021001",
     "school_name":"Abetifi Tech. Inst.",
     "category":"B",
     "school_type":"TVET",
     "program":"BUILDING CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9021001",
     "school_name":"Abetifi Tech. Inst.",
     "category":"B",
     "school_type":"TVET",
     "program":"BUSINESS ACCOUNTING",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9021001",
     "school_name":"Abetifi Tech. Inst.",
     "category":"B",
     "school_type":"TVET",
     "program":"BUSINESS SECRETARIAL",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9021001",
     "school_name":"Abetifi Tech. Inst.",
     "category":"B",
     "school_type":"TVET",
     "program":"COMPUTER HARDWARE TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9021001",
     "school_name":"Abetifi Tech. Inst.",
     "category":"B",
     "school_type":"TVET",
     "program":"ELECTRICAL ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9021001",
     "school_name":"Abetifi Tech. Inst.",
     "category":"B",
     "school_type":"TVET",
     "program":"ELECTRICAL MACHINE\/MOTOR REWINDING",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9021001",
     "school_name":"Abetifi Tech. Inst.",
     "category":"B",
     "school_type":"TVET",
     "program":"ELECTRONICS ENGINEERING",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9021001",
     "school_name":"Abetifi Tech. Inst.",
     "category":"B",
     "school_type":"TVET",
     "program":"FASHION DESIGNING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9021001",
     "school_name":"Abetifi Tech. Inst.",
     "category":"B",
     "school_type":"TVET",
     "program":"HOSPITALITY & CATERING MANAGEMENT",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9021001",
     "school_name":"Abetifi Tech. Inst.",
     "category":"B",
     "school_type":"TVET",
     "program":"MECHANICAL. ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9021001",
     "school_name":"Abetifi Tech. Inst.",
     "category":"B",
     "school_type":"TVET",
     "program":"MOTOR VEHICLE ENGINEERING",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9021001",
     "school_name":"Abetifi Tech. Inst.",
     "category":"B",
     "school_type":"TVET",
     "program":"WELDING AND FABRICATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9021001",
     "school_name":"Abetifi Tech. Inst.",
     "category":"B",
     "school_type":"TVET",
     "program":"WOOD CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021303",
     "school_name":"Abuakwa State College",
     "category":"B",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021303",
     "school_name":"Abuakwa State College",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021303",
     "school_name":"Abuakwa State College",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021303",
     "school_name":"Abuakwa State College",
     "category":"B",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021303",
     "school_name":"Abuakwa State College",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021303",
     "school_name":"Abuakwa State College",
     "category":"B",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070114",
     "school_name":"Abutia Senior High\/Tchnical",
     "category":"B",
     "school_type":"SHTS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070114",
     "school_name":"Abutia Senior High\/Tchnical",
     "category":"B",
     "school_type":"SHTS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070114",
     "school_name":"Abutia Senior High\/Tchnical",
     "category":"B",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070114",
     "school_name":"Abutia Senior High\/Tchnical",
     "category":"B",
     "school_type":"SHTS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070114",
     "school_name":"Abutia Senior High\/Tchnical",
     "category":"B",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070114",
     "school_name":"Abutia Senior High\/Tchnical",
     "category":"B",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070114",
     "school_name":"Abutia Senior High\/Tchnical",
     "category":"B",
     "school_type":"SHTS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030109",
     "school_name":"Academy of Christ the King, Cape Coast",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030109",
     "school_name":"Academy of Christ the King, Cape Coast",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030109",
     "school_name":"Academy of Christ the King, Cape Coast",
     "category":"B",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030109",
     "school_name":"Academy of Christ the King, Cape Coast",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030109",
     "school_name":"Academy of Christ the King, Cape Coast",
     "category":"B",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0010106",
     "school_name":"Accra Senior High",
     "category":"B",
     "school_type":"SHTS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0010106",
     "school_name":"Accra Senior High",
     "category":"B",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0010106",
     "school_name":"Accra Senior High",
     "category":"B",
     "school_type":"SHTS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0010106",
     "school_name":"Accra Senior High",
     "category":"B",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0010106",
     "school_name":"Accra Senior High",
     "category":"B",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0010106",
     "school_name":"Accra Senior High",
     "category":"B",
     "school_type":"SHTS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0061202",
     "school_name":"Acherensua Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0061202",
     "school_name":"Acherensua Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0061202",
     "school_name":"Acherensua Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0061202",
     "school_name":"Acherensua Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0061202",
     "school_name":"Acherensua Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0061202",
     "school_name":"Acherensua Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020604",
     "school_name":"Achiase Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020604",
     "school_name":"Achiase Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020604",
     "school_name":"Achiase Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020604",
     "school_name":"Achiase Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020604",
     "school_name":"Achiase Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020604",
     "school_name":"Achiase Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0010301",
     "school_name":"Ada Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0010301",
     "school_name":"Ada Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0010301",
     "school_name":"Ada Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0010301",
     "school_name":"Ada Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0010301",
     "school_name":"Ada Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0010301",
     "school_name":"Ada Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0031205",
     "school_name":"Adankwaman Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0031205",
     "school_name":"Adankwaman Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0031205",
     "school_name":"Adankwaman Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0031205",
     "school_name":"Adankwaman Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0031205",
     "school_name":"Adankwaman Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040110",
     "school_name":"Adiembra Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040110",
     "school_name":"Adiembra Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040110",
     "school_name":"Adiembra Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040110",
     "school_name":"Adiembra Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040110",
     "school_name":"Adiembra Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020302",
     "school_name":"Adonten Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020302",
     "school_name":"Adonten Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020302",
     "school_name":"Adonten Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020302",
     "school_name":"Adonten Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020302",
     "school_name":"Adonten Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020302",
     "school_name":"Adonten Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050704",
     "school_name":"Aduman Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050704",
     "school_name":"Aduman Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050704",
     "school_name":"Aduman Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050704",
     "school_name":"Aduman Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050704",
     "school_name":"Aduman Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050704",
     "school_name":"Aduman Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050115",
     "school_name":"Adventist  Senior High, Kumasi",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050115",
     "school_name":"Adventist  Senior High, Kumasi",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050115",
     "school_name":"Adventist  Senior High, Kumasi",
     "category":"B",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050115",
     "school_name":"Adventist  Senior High, Kumasi",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050115",
     "school_name":"Adventist  Senior High, Kumasi",
     "category":"B",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051701",
     "school_name":"Afua Kobi Ampem Girls' Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051701",
     "school_name":"Afua Kobi Ampem Girls' Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051701",
     "school_name":"Afua Kobi Ampem Girls' Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051701",
     "school_name":"Afua Kobi Ampem Girls' Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051701",
     "school_name":"Afua Kobi Ampem Girls' Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051701",
     "school_name":"Afua Kobi Ampem Girls' Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0071003",
     "school_name":"Agate Comm. Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0071003",
     "school_name":"Agate Comm. Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0071003",
     "school_name":"Agate Comm. Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0071003",
     "school_name":"Agate Comm. Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0071003",
     "school_name":"Agate Comm. Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030401",
     "school_name":"Aggrey Mem. A.M.E.Zion Snr. High",
     "category":"B",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030401",
     "school_name":"Aggrey Mem. A.M.E.Zion Snr. High",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030401",
     "school_name":"Aggrey Mem. A.M.E.Zion Snr. High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030401",
     "school_name":"Aggrey Mem. A.M.E.Zion Snr. High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030401",
     "school_name":"Aggrey Mem. A.M.E.Zion Snr. High",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030401",
     "school_name":"Aggrey Mem. A.M.E.Zion Snr. High",
     "category":"B",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050606",
     "school_name":"Agona Senior High\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050606",
     "school_name":"Agona Senior High\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050606",
     "school_name":"Agona Senior High\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050606",
     "school_name":"Agona Senior High\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050606",
     "school_name":"Agona Senior High\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050606",
     "school_name":"Agona Senior High\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050606",
     "school_name":"Agona Senior High\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0061302",
     "school_name":"Ahafoman Senior High\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0061302",
     "school_name":"Ahafoman Senior High\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0061302",
     "school_name":"Ahafoman Senior High\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0061302",
     "school_name":"Ahafoman Senior High\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0061302",
     "school_name":"Ahafoman Senior High\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0061302",
     "school_name":"Ahafoman Senior High\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040106",
     "school_name":"Ahantaman Girls' Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040106",
     "school_name":"Ahantaman Girls' Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040106",
     "school_name":"Ahantaman Girls' Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040106",
     "school_name":"Ahantaman Girls' Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040106",
     "school_name":"Ahantaman Girls' Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040604",
     "school_name":"Akontombra Senior High ",
     "category":"B",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040604",
     "school_name":"Akontombra Senior High ",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040604",
     "school_name":"Akontombra Senior High ",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040604",
     "school_name":"Akontombra Senior High ",
     "category":"B",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040604",
     "school_name":"Akontombra Senior High ",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051205",
     "school_name":"Akrofuom Senior High\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051205",
     "school_name":"Akrofuom Senior High\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051205",
     "school_name":"Akrofuom Senior High\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051205",
     "school_name":"Akrofuom Senior High\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051205",
     "school_name":"Akrofuom Senior High\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051205",
     "school_name":"Akrofuom Senior High\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020803",
     "school_name":"Akwamuman Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020803",
     "school_name":"Akwamuman Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020803",
     "school_name":"Akwamuman Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020803",
     "school_name":"Akwamuman Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020803",
     "school_name":"Akwamuman Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050156",
     "school_name":"Al-Azariya Islamic Snr. High, Kumasi",
     "category":"B",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0050156",
     "school_name":"Al-Azariya Islamic Snr. High, Kumasi",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0050156",
     "school_name":"Al-Azariya Islamic Snr. High, Kumasi",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0050156",
     "school_name":"Al-Azariya Islamic Snr. High, Kumasi",
     "category":"B",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0050156",
     "school_name":"Al-Azariya Islamic Snr. High, Kumasi",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9020901",
     "school_name":"Amankwakrom Fisheries Agric. Tech. Inst.",
     "category":"B",
     "school_type":"TVET",
     "program":"AGRICULTURAL MECHANIZATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9020901",
     "school_name":"Amankwakrom Fisheries Agric. Tech. Inst.",
     "category":"B",
     "school_type":"TVET",
     "program":"BUILDING CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9020901",
     "school_name":"Amankwakrom Fisheries Agric. Tech. Inst.",
     "category":"B",
     "school_type":"TVET",
     "program":"COMPUTER HARDWARE TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9020901",
     "school_name":"Amankwakrom Fisheries Agric. Tech. Inst.",
     "category":"B",
     "school_type":"TVET",
     "program":"CREATIVE ART TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9020901",
     "school_name":"Amankwakrom Fisheries Agric. Tech. Inst.",
     "category":"B",
     "school_type":"TVET",
     "program":"ELECTRICAL ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9020901",
     "school_name":"Amankwakrom Fisheries Agric. Tech. Inst.",
     "category":"B",
     "school_type":"TVET",
     "program":"ELECTRONICS ENGINEERING",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9020901",
     "school_name":"Amankwakrom Fisheries Agric. Tech. Inst.",
     "category":"B",
     "school_type":"TVET",
     "program":"FASHION DESIGNING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9020901",
     "school_name":"Amankwakrom Fisheries Agric. Tech. Inst.",
     "category":"B",
     "school_type":"TVET",
     "program":"HOSPITALITY & CATERING MANAGEMENT",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9020901",
     "school_name":"Amankwakrom Fisheries Agric. Tech. Inst.",
     "category":"B",
     "school_type":"TVET",
     "program":"WELDING AND FABRICATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9020901",
     "school_name":"Amankwakrom Fisheries Agric. Tech. Inst.",
     "category":"B",
     "school_type":"TVET",
     "program":"WOOD CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060404",
     "school_name":"Amanten Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060404",
     "school_name":"Amanten Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060404",
     "school_name":"Amanten Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060404",
     "school_name":"Amanten Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060404",
     "school_name":"Amanten Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040502",
     "school_name":"Amenfiman Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040502",
     "school_name":"Amenfiman Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040502",
     "school_name":"Amenfiman Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040502",
     "school_name":"Amenfiman Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040502",
     "school_name":"Amenfiman Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040502",
     "school_name":"Amenfiman Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060702",
     "school_name":"Ameyaw Akumfi Senior High\/Tech.",
     "category":"B",
     "school_type":"SHTS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060702",
     "school_name":"Ameyaw Akumfi Senior High\/Tech.",
     "category":"B",
     "school_type":"SHTS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060702",
     "school_name":"Ameyaw Akumfi Senior High\/Tech.",
     "category":"B",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060702",
     "school_name":"Ameyaw Akumfi Senior High\/Tech.",
     "category":"B",
     "school_type":"SHTS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060702",
     "school_name":"Ameyaw Akumfi Senior High\/Tech.",
     "category":"B",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060702",
     "school_name":"Ameyaw Akumfi Senior High\/Tech.",
     "category":"B",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060702",
     "school_name":"Ameyaw Akumfi Senior High\/Tech.",
     "category":"B",
     "school_type":"SHTS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050107",
     "school_name":"Anglican Senior High, Kumasi",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050107",
     "school_name":"Anglican Senior High, Kumasi",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050107",
     "school_name":"Anglican Senior High, Kumasi",
     "category":"B",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050107",
     "school_name":"Anglican Senior High, Kumasi",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050107",
     "school_name":"Anglican Senior High, Kumasi",
     "category":"B",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070501",
     "school_name":"Anlo Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070501",
     "school_name":"Anlo Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070501",
     "school_name":"Anlo Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070501",
     "school_name":"Anlo Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070501",
     "school_name":"Anlo Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070501",
     "school_name":"Anlo Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030501",
     "school_name":"Apam Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030501",
     "school_name":"Apam Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030501",
     "school_name":"Apam Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030501",
     "school_name":"Apam Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030501",
     "school_name":"Apam Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050113",
     "school_name":"Armed Forces Senior High\/Tech, Kumasi",
     "category":"B",
     "school_type":"SHTS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050113",
     "school_name":"Armed Forces Senior High\/Tech, Kumasi",
     "category":"B",
     "school_type":"SHTS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050113",
     "school_name":"Armed Forces Senior High\/Tech, Kumasi",
     "category":"B",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050113",
     "school_name":"Armed Forces Senior High\/Tech, Kumasi",
     "category":"B",
     "school_type":"SHTS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050113",
     "school_name":"Armed Forces Senior High\/Tech, Kumasi",
     "category":"B",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050113",
     "school_name":"Armed Forces Senior High\/Tech, Kumasi",
     "category":"B",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050113",
     "school_name":"Armed Forces Senior High\/Tech, Kumasi",
     "category":"B",
     "school_type":"SHTS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020501",
     "school_name":"Asamankese Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020501",
     "school_name":"Asamankese Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020501",
     "school_name":"Asamankese Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020501",
     "school_name":"Asamankese Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020501",
     "school_name":"Asamankese Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020501",
     "school_name":"Asamankese Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050101",
     "school_name":"Asanteman Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050101",
     "school_name":"Asanteman Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050101",
     "school_name":"Asanteman Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050101",
     "school_name":"Asanteman Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050101",
     "school_name":"Asanteman Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051206",
     "school_name":"Asare Bediako Senior High .",
     "category":"B",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051206",
     "school_name":"Asare Bediako Senior High .",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051206",
     "school_name":"Asare Bediako Senior High .",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051206",
     "school_name":"Asare Bediako Senior High .",
     "category":"B",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051206",
     "school_name":"Asare Bediako Senior High .",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040603",
     "school_name":"Asawinso Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040603",
     "school_name":"Asawinso Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040603",
     "school_name":"Asawinso Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040603",
     "school_name":"Asawinso Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040603",
     "school_name":"Asawinso Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020606",
     "school_name":"Attafuah Senior High\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020606",
     "school_name":"Attafuah Senior High\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020606",
     "school_name":"Attafuah Senior High\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020606",
     "school_name":"Attafuah Senior High\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020606",
     "school_name":"Attafuah Senior High\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020606",
     "school_name":"Attafuah Senior High\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020606",
     "school_name":"Attafuah Senior High\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070104",
     "school_name":"Awudome Senior High .",
     "category":"B",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070104",
     "school_name":"Awudome Senior High .",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070104",
     "school_name":"Awudome Senior High .",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070104",
     "school_name":"Awudome Senior High .",
     "category":"B",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070104",
     "school_name":"Awudome Senior High .",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070104",
     "school_name":"Awudome Senior High .",
     "category":"B",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030613",
     "school_name":"Awutu Winton Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0030613",
     "school_name":"Awutu Winton Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0030613",
     "school_name":"Awutu Winton Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0030613",
     "school_name":"Awutu Winton Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0030613",
     "school_name":"Awutu Winton Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0060602",
     "school_name":"Badu Senior High\/Tech.",
     "category":"B",
     "school_type":"SHTS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060602",
     "school_name":"Badu Senior High\/Tech.",
     "category":"B",
     "school_type":"SHTS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060602",
     "school_name":"Badu Senior High\/Tech.",
     "category":"B",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060602",
     "school_name":"Badu Senior High\/Tech.",
     "category":"B",
     "school_type":"SHTS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060602",
     "school_name":"Badu Senior High\/Tech.",
     "category":"B",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060602",
     "school_name":"Badu Senior High\/Tech.",
     "category":"B",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0071105",
     "school_name":"Baglo  Ridge Senior High\/Tech.",
     "category":"B",
     "school_type":"SHTS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0071105",
     "school_name":"Baglo  Ridge Senior High\/Tech.",
     "category":"B",
     "school_type":"SHTS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0071105",
     "school_name":"Baglo  Ridge Senior High\/Tech.",
     "category":"B",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0071105",
     "school_name":"Baglo  Ridge Senior High\/Tech.",
     "category":"B",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0071105",
     "school_name":"Baglo  Ridge Senior High\/Tech.",
     "category":"B",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0071105",
     "school_name":"Baglo  Ridge Senior High\/Tech.",
     "category":"B",
     "school_type":"SHTS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050903",
     "school_name":"Bankoman Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050903",
     "school_name":"Bankoman Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050903",
     "school_name":"Bankoman Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050903",
     "school_name":"Bankoman Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050903",
     "school_name":"Bankoman Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050903",
     "school_name":"Bankoman Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0090101",
     "school_name":"Bawku Senior High ",
     "category":"B",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0090101",
     "school_name":"Bawku Senior High ",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0090101",
     "school_name":"Bawku Senior High ",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0090101",
     "school_name":"Bawku Senior High ",
     "category":"B",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0090101",
     "school_name":"Bawku Senior High ",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0090101",
     "school_name":"Bawku Senior High ",
     "category":"B",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060201",
     "school_name":"Bechem Presby Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060201",
     "school_name":"Bechem Presby Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060201",
     "school_name":"Bechem Presby Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060201",
     "school_name":"Bechem Presby Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060201",
     "school_name":"Bechem Presby Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060201",
     "school_name":"Bechem Presby Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020403",
     "school_name":"Benkum Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020403",
     "school_name":"Benkum Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020403",
     "school_name":"Benkum Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020403",
     "school_name":"Benkum Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020403",
     "school_name":"Benkum Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020403",
     "school_name":"Benkum Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051702",
     "school_name":"Beposo Senior High ",
     "category":"B",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051702",
     "school_name":"Beposo Senior High ",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051702",
     "school_name":"Beposo Senior High ",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051702",
     "school_name":"Beposo Senior High ",
     "category":"B",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051702",
     "school_name":"Beposo Senior High ",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051702",
     "school_name":"Beposo Senior High ",
     "category":"B",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060301",
     "school_name":"Berekum Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060301",
     "school_name":"Berekum Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060301",
     "school_name":"Berekum Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060301",
     "school_name":"Berekum Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060301",
     "school_name":"Berekum Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060301",
     "school_name":"Berekum Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040802",
     "school_name":"Bia Senior High\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040802",
     "school_name":"Bia Senior High\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040802",
     "school_name":"Bia Senior High\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040802",
     "school_name":"Bia Senior High\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040802",
     "school_name":"Bia Senior High\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040802",
     "school_name":"Bia Senior High\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0031001",
     "school_name":"Boa-Amponsem Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0031001",
     "school_name":"Boa-Amponsem Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0031001",
     "school_name":"Boa-Amponsem Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0031001",
     "school_name":"Boa-Amponsem Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0031001",
     "school_name":"Boa-Amponsem Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0031001",
     "school_name":"Boa-Amponsem Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060206",
     "school_name":"Boakye Tromo Senior High\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060206",
     "school_name":"Boakye Tromo Senior High\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060206",
     "school_name":"Boakye Tromo Senior High\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060206",
     "school_name":"Boakye Tromo Senior High\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060206",
     "school_name":"Boakye Tromo Senior High\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060206",
     "school_name":"Boakye Tromo Senior High\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060206",
     "school_name":"Boakye Tromo Senior High\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040803",
     "school_name":"Bodi Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0040803",
     "school_name":"Bodi Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0040803",
     "school_name":"Bodi Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0040803",
     "school_name":"Bodi Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0040803",
     "school_name":"Bodi Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0060205",
     "school_name":"Bomaa Comm. Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060205",
     "school_name":"Bomaa Comm. Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060205",
     "school_name":"Bomaa Comm. Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060205",
     "school_name":"Bomaa Comm. Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060205",
     "school_name":"Bomaa Comm. Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051102",
     "school_name":"Bompata Presby Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051102",
     "school_name":"Bompata Presby Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051102",
     "school_name":"Bompata Presby Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051102",
     "school_name":"Bompata Presby Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051102",
     "school_name":"Bompata Presby Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051102",
     "school_name":"Bompata Presby Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040108",
     "school_name":"Bompeh Senior High.\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0040108",
     "school_name":"Bompeh Senior High.\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0040108",
     "school_name":"Bompeh Senior High.\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0040108",
     "school_name":"Bompeh Senior High.\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0040108",
     "school_name":"Bompeh Senior High.\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0040108",
     "school_name":"Bompeh Senior High.\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0030801",
     "school_name":"Breman Asikuma Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030801",
     "school_name":"Breman Asikuma Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030801",
     "school_name":"Breman Asikuma Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030801",
     "school_name":"Breman Asikuma Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030801",
     "school_name":"Breman Asikuma Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0071101",
     "school_name":"Bueman Senior High .",
     "category":"B",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0071101",
     "school_name":"Bueman Senior High .",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0071101",
     "school_name":"Bueman Senior High .",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0071101",
     "school_name":"Bueman Senior High .",
     "category":"B",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0071101",
     "school_name":"Bueman Senior High .",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0071101",
     "school_name":"Bueman Senior High .",
     "category":"B",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0010202",
     "school_name":"Chemu Senior High\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0010202",
     "school_name":"Chemu Senior High\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0010202",
     "school_name":"Chemu Senior High\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0010202",
     "school_name":"Chemu Senior High\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0010202",
     "school_name":"Chemu Senior High\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0010202",
     "school_name":"Chemu Senior High\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0060102",
     "school_name":"Chiraa Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060102",
     "school_name":"Chiraa Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060102",
     "school_name":"Chiraa Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060102",
     "school_name":"Chiraa Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060102",
     "school_name":"Chiraa Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060102",
     "school_name":"Chiraa Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051204",
     "school_name":"Christ the King Cath., Obuasi",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051204",
     "school_name":"Christ the King Cath., Obuasi",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051204",
     "school_name":"Christ the King Cath., Obuasi",
     "category":"B",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051204",
     "school_name":"Christ the King Cath., Obuasi",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051204",
     "school_name":"Christ the King Cath., Obuasi",
     "category":"B",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051003",
     "school_name":"Collins Senior High\/Commercial, Agogo",
     "category":"B",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051003",
     "school_name":"Collins Senior High\/Commercial, Agogo",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051003",
     "school_name":"Collins Senior High\/Commercial, Agogo",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051003",
     "school_name":"Collins Senior High\/Commercial, Agogo",
     "category":"B",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051003",
     "school_name":"Collins Senior High\/Commercial, Agogo",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051003",
     "school_name":"Collins Senior High\/Commercial, Agogo",
     "category":"B",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9070901",
     "school_name":"Comboni Tech\/Voc Inst.",
     "category":"B",
     "school_type":"TVET",
     "program":"AGRICULTURAL MECHANIZATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9070901",
     "school_name":"Comboni Tech\/Voc Inst.",
     "category":"B",
     "school_type":"TVET",
     "program":"BUILDING CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9070901",
     "school_name":"Comboni Tech\/Voc Inst.",
     "category":"B",
     "school_type":"TVET",
     "program":"BUSINESS ACCOUNTING",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9070901",
     "school_name":"Comboni Tech\/Voc Inst.",
     "category":"B",
     "school_type":"TVET",
     "program":"BUSINESS INFORMATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9070901",
     "school_name":"Comboni Tech\/Voc Inst.",
     "category":"B",
     "school_type":"TVET",
     "program":"BUSINESS SECRETARIAL",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9070901",
     "school_name":"Comboni Tech\/Voc Inst.",
     "category":"B",
     "school_type":"TVET",
     "program":"ELECTRICAL ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9070901",
     "school_name":"Comboni Tech\/Voc Inst.",
     "category":"B",
     "school_type":"TVET",
     "program":"FASHION DESIGNING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9070901",
     "school_name":"Comboni Tech\/Voc Inst.",
     "category":"B",
     "school_type":"TVET",
     "program":"HOSPITALITY & CATERING MANAGEMENT",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9070901",
     "school_name":"Comboni Tech\/Voc Inst.",
     "category":"B",
     "school_type":"TVET",
     "program":"MECHANICAL. ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9070901",
     "school_name":"Comboni Tech\/Voc Inst.",
     "category":"B",
     "school_type":"TVET",
     "program":"MOTOR VEHICLE ENGINEERING",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9070901",
     "school_name":"Comboni Tech\/Voc Inst.",
     "category":"B",
     "school_type":"TVET",
     "program":"PRINTING",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9070901",
     "school_name":"Comboni Tech\/Voc Inst.",
     "category":"B",
     "school_type":"TVET",
     "program":"WELDING AND FABRICATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9070901",
     "school_name":"Comboni Tech\/Voc Inst.",
     "category":"B",
     "school_type":"TVET",
     "program":"WOOD CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0041101",
     "school_name":"Daboase Senior High\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0041101",
     "school_name":"Daboase Senior High\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0041101",
     "school_name":"Daboase Senior High\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0041101",
     "school_name":"Daboase Senior High\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0041101",
     "school_name":"Daboase Senior High\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0041101",
     "school_name":"Daboase Senior High\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0041101",
     "school_name":"Daboase Senior High\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0041002",
     "school_name":"Dadieso Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0041002",
     "school_name":"Dadieso Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0041002",
     "school_name":"Dadieso Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0041002",
     "school_name":"Dadieso Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0041002",
     "school_name":"Dadieso Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060207",
     "school_name":"Derma Comm. Day School",
     "category":"B",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0060207",
     "school_name":"Derma Comm. Day School",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0060207",
     "school_name":"Derma Comm. Day School",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0060207",
     "school_name":"Derma Comm. Day School",
     "category":"B",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0060207",
     "school_name":"Derma Comm. Day School",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0060207",
     "school_name":"Derma Comm. Day School",
     "category":"B",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0060507",
     "school_name":"Diamono Senior High Sch.",
     "category":"B",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060507",
     "school_name":"Diamono Senior High Sch.",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060507",
     "school_name":"Diamono Senior High Sch.",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060507",
     "school_name":"Diamono Senior High Sch.",
     "category":"B",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060507",
     "school_name":"Diamono Senior High Sch.",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0031003",
     "school_name":"Diaso Senior High ",
     "category":"B",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0031003",
     "school_name":"Diaso Senior High ",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0031003",
     "school_name":"Diaso Senior High ",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0031003",
     "school_name":"Diaso Senior High ",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051201",
     "school_name":"Dompoase Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051201",
     "school_name":"Dompoase Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051201",
     "school_name":"Dompoase Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051201",
     "school_name":"Dompoase Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051201",
     "school_name":"Dompoase Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051201",
     "school_name":"Dompoase Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9062601",
     "school_name":"Don Bosco Voc.\/Tech. Inst.",
     "category":"B",
     "school_type":"TVET",
     "program":"AGRICULTURAL MECHANIZATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9062601",
     "school_name":"Don Bosco Voc.\/Tech. Inst.",
     "category":"B",
     "school_type":"TVET",
     "program":"BUILDING CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9062601",
     "school_name":"Don Bosco Voc.\/Tech. Inst.",
     "category":"B",
     "school_type":"TVET",
     "program":"BUSINESS SECRETARIAL",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9062601",
     "school_name":"Don Bosco Voc.\/Tech. Inst.",
     "category":"B",
     "school_type":"TVET",
     "program":"COMPUTER HARDWARE TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9062601",
     "school_name":"Don Bosco Voc.\/Tech. Inst.",
     "category":"B",
     "school_type":"TVET",
     "program":"CREATIVE ART TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9062601",
     "school_name":"Don Bosco Voc.\/Tech. Inst.",
     "category":"B",
     "school_type":"TVET",
     "program":"ELECTRICAL ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9062601",
     "school_name":"Don Bosco Voc.\/Tech. Inst.",
     "category":"B",
     "school_type":"TVET",
     "program":"HOSPITALITY & CATERING MANAGEMENT",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9062601",
     "school_name":"Don Bosco Voc.\/Tech. Inst.",
     "category":"B",
     "school_type":"TVET",
     "program":"WELDING AND FABRICATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9062601",
     "school_name":"Don Bosco Voc.\/Tech. Inst.",
     "category":"B",
     "school_type":"TVET",
     "program":"WOOD CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0061001",
     "school_name":"Dormaa Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0061001",
     "school_name":"Dormaa Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0061001",
     "school_name":"Dormaa Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0061001",
     "school_name":"Dormaa Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0061001",
     "school_name":"Dormaa Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0061001",
     "school_name":"Dormaa Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060501",
     "school_name":"Drobo Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060501",
     "school_name":"Drobo Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060501",
     "school_name":"Drobo Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060501",
     "school_name":"Drobo Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060501",
     "school_name":"Drobo Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060501",
     "school_name":"Drobo Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050801",
     "school_name":"Dwamena Akenten Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050801",
     "school_name":"Dwamena Akenten Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050801",
     "school_name":"Dwamena Akenten Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050801",
     "school_name":"Dwamena Akenten Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050801",
     "school_name":"Dwamena Akenten Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070106",
     "school_name":"E.P.C. Mawuko Girls Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070106",
     "school_name":"E.P.C. Mawuko Girls Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070106",
     "school_name":"E.P.C. Mawuko Girls Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070106",
     "school_name":"E.P.C. Mawuko Girls Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070106",
     "school_name":"E.P.C. Mawuko Girls Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070106",
     "school_name":"E.P.C. Mawuko Girls Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030201",
     "school_name":"Edinaman Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030201",
     "school_name":"Edinaman Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030201",
     "school_name":"Edinaman Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030201",
     "school_name":"Edinaman Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030201",
     "school_name":"Edinaman Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051604",
     "school_name":"Ejisu Senior High\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051604",
     "school_name":"Ejisu Senior High\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051604",
     "school_name":"Ejisu Senior High\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051604",
     "school_name":"Ejisu Senior High\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051604",
     "school_name":"Ejisu Senior High\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051604",
     "school_name":"Ejisu Senior High\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051604",
     "school_name":"Ejisu Senior High\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030302",
     "school_name":"Ekumfi T. I. Ahmadiiyya Snr. High",
     "category":"B",
     "school_type":"SHTS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030302",
     "school_name":"Ekumfi T. I. Ahmadiiyya Snr. High",
     "category":"B",
     "school_type":"SHTS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030302",
     "school_name":"Ekumfi T. I. Ahmadiiyya Snr. High",
     "category":"B",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030302",
     "school_name":"Ekumfi T. I. Ahmadiiyya Snr. High",
     "category":"B",
     "school_type":"SHTS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030302",
     "school_name":"Ekumfi T. I. Ahmadiiyya Snr. High",
     "category":"B",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030302",
     "school_name":"Ekumfi T. I. Ahmadiiyya Snr. High",
     "category":"B",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9030801",
     "school_name":"Enyan-Abaasa Technical Institute",
     "category":"B",
     "school_type":"TVET",
     "program":"BUILDING CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9030801",
     "school_name":"Enyan-Abaasa Technical Institute",
     "category":"B",
     "school_type":"TVET",
     "program":"BUSINESS ACCOUNTING",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9030801",
     "school_name":"Enyan-Abaasa Technical Institute",
     "category":"B",
     "school_type":"TVET",
     "program":"ELECTRICAL ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9030801",
     "school_name":"Enyan-Abaasa Technical Institute",
     "category":"B",
     "school_type":"TVET",
     "program":"FASHION DESIGNING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9030801",
     "school_name":"Enyan-Abaasa Technical Institute",
     "category":"B",
     "school_type":"TVET",
     "program":"HOSPITALITY & CATERING MANAGEMENT",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9030801",
     "school_name":"Enyan-Abaasa Technical Institute",
     "category":"B",
     "school_type":"TVET",
     "program":"MOTOR VEHICLE ENGINEERING",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040303",
     "school_name":"Esiama Senior High\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040303",
     "school_name":"Esiama Senior High\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040303",
     "school_name":"Esiama Senior High\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040303",
     "school_name":"Esiama Senior High\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040303",
     "school_name":"Esiama Senior High\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040303",
     "school_name":"Esiama Senior High\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051203",
     "school_name":"Fomena T.I. Ahmad Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051203",
     "school_name":"Fomena T.I. Ahmad Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051203",
     "school_name":"Fomena T.I. Ahmad Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051203",
     "school_name":"Fomena T.I. Ahmad Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051203",
     "school_name":"Fomena T.I. Ahmad Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051203",
     "school_name":"Fomena T.I. Ahmad Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0010115",
     "school_name":"Forces Senior High\/Tech, Burma Camp",
     "category":"B",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0010115",
     "school_name":"Forces Senior High\/Tech, Burma Camp",
     "category":"B",
     "school_type":"SHTS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0010115",
     "school_name":"Forces Senior High\/Tech, Burma Camp",
     "category":"B",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0010115",
     "school_name":"Forces Senior High\/Tech, Burma Camp",
     "category":"B",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0010115",
     "school_name":"Forces Senior High\/Tech, Burma Camp",
     "category":"B",
     "school_type":"SHTS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050508",
     "school_name":"Ghana Muslim Mission Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050508",
     "school_name":"Ghana Muslim Mission Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050508",
     "school_name":"Ghana Muslim Mission Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050508",
     "school_name":"Ghana Muslim Mission Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050508",
     "school_name":"Ghana Muslim Mission Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030106",
     "school_name":"Ghana National College",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030106",
     "school_name":"Ghana National College",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030106",
     "school_name":"Ghana National College",
     "category":"B",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030106",
     "school_name":"Ghana National College",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030106",
     "school_name":"Ghana National College",
     "category":"B",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0010401",
     "school_name":"Ghanata Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0010401",
     "school_name":"Ghanata Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0010401",
     "school_name":"Ghanata Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0010401",
     "school_name":"Ghanata Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0010401",
     "school_name":"Ghanata Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0010401",
     "school_name":"Ghanata Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060504",
     "school_name":"Goka Senior High\/Tech.",
     "category":"B",
     "school_type":"SHTS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060504",
     "school_name":"Goka Senior High\/Tech.",
     "category":"B",
     "school_type":"SHTS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060504",
     "school_name":"Goka Senior High\/Tech.",
     "category":"B",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060504",
     "school_name":"Goka Senior High\/Tech.",
     "category":"B",
     "school_type":"SHTS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060504",
     "school_name":"Goka Senior High\/Tech.",
     "category":"B",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060504",
     "school_name":"Goka Senior High\/Tech.",
     "category":"B",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060705",
     "school_name":"Guakro Effah Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060705",
     "school_name":"Guakro Effah Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060705",
     "school_name":"Guakro Effah Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060705",
     "school_name":"Guakro Effah Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0061204",
     "school_name":"Gyamfi Kumanini Senior High\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0061204",
     "school_name":"Gyamfi Kumanini Senior High\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0061204",
     "school_name":"Gyamfi Kumanini Senior High\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0061204",
     "school_name":"Gyamfi Kumanini Senior High\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0061204",
     "school_name":"Gyamfi Kumanini Senior High\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0061204",
     "school_name":"Gyamfi Kumanini Senior High\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0061204",
     "school_name":"Gyamfi Kumanini Senior High\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040401",
     "school_name":"Half Assini Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040401",
     "school_name":"Half Assini Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040401",
     "school_name":"Half Assini Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040401",
     "school_name":"Half Assini Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040401",
     "school_name":"Half Assini Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040401",
     "school_name":"Half Assini Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9072100",
     "school_name":"Have Tech. Inst.",
     "category":"B",
     "school_type":"TVET",
     "program":"AGRICULTURAL MECHANIZATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9072100",
     "school_name":"Have Tech. Inst.",
     "category":"B",
     "school_type":"TVET",
     "program":"BUILDING CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9072100",
     "school_name":"Have Tech. Inst.",
     "category":"B",
     "school_type":"TVET",
     "program":"BUSINESS ACCOUNTING",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9072100",
     "school_name":"Have Tech. Inst.",
     "category":"B",
     "school_type":"TVET",
     "program":"COMPUTER HARDWARE TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9072100",
     "school_name":"Have Tech. Inst.",
     "category":"B",
     "school_type":"TVET",
     "program":"ELECTRICAL ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9072100",
     "school_name":"Have Tech. Inst.",
     "category":"B",
     "school_type":"TVET",
     "program":"ELECTRONICS ENGINEERING",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9072100",
     "school_name":"Have Tech. Inst.",
     "category":"B",
     "school_type":"TVET",
     "program":"FASHION DESIGNING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9072100",
     "school_name":"Have Tech. Inst.",
     "category":"B",
     "school_type":"TVET",
     "program":"HOSPITALITY & CATERING MANAGEMENT",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9072100",
     "school_name":"Have Tech. Inst.",
     "category":"B",
     "school_type":"TVET",
     "program":"MECHANICAL. ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9072100",
     "school_name":"Have Tech. Inst.",
     "category":"B",
     "school_type":"TVET",
     "program":"MOTOR VEHICLE ENGINEERING",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9072100",
     "school_name":"Have Tech. Inst.",
     "category":"B",
     "school_type":"TVET",
     "program":"WELDING AND FABRICATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9072100",
     "school_name":"Have Tech. Inst.",
     "category":"B",
     "school_type":"TVET",
     "program":"WOOD CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0061203",
     "school_name":"Hwidiem Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0061203",
     "school_name":"Hwidiem Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0061203",
     "school_name":"Hwidiem Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0061203",
     "school_name":"Hwidiem Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0061203",
     "school_name":"Hwidiem Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0061203",
     "school_name":"Hwidiem Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020204",
     "school_name":"Islamic Girls Senior High,Suhum",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020204",
     "school_name":"Islamic Girls Senior High,Suhum",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020204",
     "school_name":"Islamic Girls Senior High,Suhum",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020204",
     "school_name":"Islamic Girls Senior High,Suhum",
     "category":"B",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050116",
     "school_name":"Islamic Senior High, Ampabame",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050116",
     "school_name":"Islamic Senior High, Ampabame",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050116",
     "school_name":"Islamic Senior High, Ampabame",
     "category":"B",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050116",
     "school_name":"Islamic Senior High, Ampabame",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050116",
     "school_name":"Islamic Senior High, Ampabame",
     "category":"B",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060607",
     "school_name":"Istiquaama Snr. High",
     "category":"B",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060607",
     "school_name":"Istiquaama Snr. High",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060607",
     "school_name":"Istiquaama Snr. High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060607",
     "school_name":"Istiquaama Snr. High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060607",
     "school_name":"Istiquaama Snr. High",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060607",
     "school_name":"Istiquaama Snr. High",
     "category":"B",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051602",
     "school_name":"Jachie Pramso Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051602",
     "school_name":"Jachie Pramso Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051602",
     "school_name":"Jachie Pramso Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051602",
     "school_name":"Jachie Pramso Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051602",
     "school_name":"Jachie Pramso Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051602",
     "school_name":"Jachie Pramso Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050303",
     "school_name":"Jacobu Senior High\/Tech.",
     "category":"B",
     "school_type":"SHTS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050303",
     "school_name":"Jacobu Senior High\/Tech.",
     "category":"B",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050303",
     "school_name":"Jacobu Senior High\/Tech.",
     "category":"B",
     "school_type":"SHTS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050303",
     "school_name":"Jacobu Senior High\/Tech.",
     "category":"B",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050303",
     "school_name":"Jacobu Senior High\/Tech.",
     "category":"B",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050303",
     "school_name":"Jacobu Senior High\/Tech.",
     "category":"B",
     "school_type":"SHTS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060802",
     "school_name":"Jema Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060802",
     "school_name":"Jema Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060802",
     "school_name":"Jema Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060802",
     "school_name":"Jema Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060802",
     "school_name":"Jema Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060802",
     "school_name":"Jema Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060302",
     "school_name":"Jinijini Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060302",
     "school_name":"Jinijini Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060302",
     "school_name":"Jinijini Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060302",
     "school_name":"Jinijini Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060302",
     "school_name":"Jinijini Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051603",
     "school_name":"Juaben Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051603",
     "school_name":"Juaben Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051603",
     "school_name":"Juaben Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051603",
     "school_name":"Juaben Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051603",
     "school_name":"Juaben Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051603",
     "school_name":"Juaben Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040801",
     "school_name":"Juaboso Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040801",
     "school_name":"Juaboso Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040801",
     "school_name":"Juaboso Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040801",
     "school_name":"Juaboso Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040801",
     "school_name":"Juaboso Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040801",
     "school_name":"Juaboso Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021102",
     "school_name":"Kade Senior High\/Tech.",
     "category":"B",
     "school_type":"SHTS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021102",
     "school_name":"Kade Senior High\/Tech.",
     "category":"B",
     "school_type":"SHTS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021102",
     "school_name":"Kade Senior High\/Tech.",
     "category":"B",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021102",
     "school_name":"Kade Senior High\/Tech.",
     "category":"B",
     "school_type":"SHTS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021102",
     "school_name":"Kade Senior High\/Tech.",
     "category":"B",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021102",
     "school_name":"Kade Senior High\/Tech.",
     "category":"B",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0061102",
     "school_name":"Kajaji Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0061102",
     "school_name":"Kajaji Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0061102",
     "school_name":"Kajaji Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0061102",
     "school_name":"Kajaji Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0061102",
     "school_name":"Kajaji Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0080105",
     "school_name":"Kalpohin Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0080105",
     "school_name":"Kalpohin Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0080105",
     "school_name":"Kalpohin Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0080105",
     "school_name":"Kalpohin Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0080105",
     "school_name":"Kalpohin Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0080105",
     "school_name":"Kalpohin Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0100401",
     "school_name":"Kanton Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0100401",
     "school_name":"Kanton Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0100401",
     "school_name":"Kanton Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0100401",
     "school_name":"Kanton Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0100401",
     "school_name":"Kanton Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070502",
     "school_name":"Keta Senior High\/Tech .",
     "category":"B",
     "school_type":"SHTS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070502",
     "school_name":"Keta Senior High\/Tech .",
     "category":"B",
     "school_type":"SHTS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070502",
     "school_name":"Keta Senior High\/Tech .",
     "category":"B",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070502",
     "school_name":"Keta Senior High\/Tech .",
     "category":"B",
     "school_type":"SHTS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070502",
     "school_name":"Keta Senior High\/Tech .",
     "category":"B",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070502",
     "school_name":"Keta Senior High\/Tech .",
     "category":"B",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070502",
     "school_name":"Keta Senior High\/Tech .",
     "category":"B",
     "school_type":"SHTS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021305",
     "school_name":"Kibi Senior High\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021305",
     "school_name":"Kibi Senior High\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021305",
     "school_name":"Kibi Senior High\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021305",
     "school_name":"Kibi Senior High\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021305",
     "school_name":"Kibi Senior High\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021305",
     "school_name":"Kibi Senior High\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050109",
     "school_name":"KNUST Senior High",
     "category":"B",
     "school_type":"SHTS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050109",
     "school_name":"KNUST Senior High",
     "category":"B",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050109",
     "school_name":"KNUST Senior High",
     "category":"B",
     "school_type":"SHTS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050109",
     "school_name":"KNUST Senior High",
     "category":"B",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050109",
     "school_name":"KNUST Senior High",
     "category":"B",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050109",
     "school_name":"KNUST Senior High",
     "category":"B",
     "school_type":"SHTS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050607",
     "school_name":"Kofiase Adventist Senior High\/Tech.",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050607",
     "school_name":"Kofiase Adventist Senior High\/Tech.",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050607",
     "school_name":"Kofiase Adventist Senior High\/Tech.",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050607",
     "school_name":"Kofiase Adventist Senior High\/Tech.",
     "category":"B",
     "school_type":"SHS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050607",
     "school_name":"Kofiase Adventist Senior High\/Tech.",
     "category":"B",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050603",
     "school_name":"Konadu Yiadom Catholic Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050603",
     "school_name":"Konadu Yiadom Catholic Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050603",
     "school_name":"Konadu Yiadom Catholic Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050603",
     "school_name":"Konadu Yiadom Catholic Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050603",
     "school_name":"Konadu Yiadom Catholic Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050603",
     "school_name":"Konadu Yiadom Catholic Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051002",
     "school_name":"Konongo Odumase Senior High .",
     "category":"B",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051002",
     "school_name":"Konongo Odumase Senior High .",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051002",
     "school_name":"Konongo Odumase Senior High .",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051002",
     "school_name":"Konongo Odumase Senior High .",
     "category":"B",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051002",
     "school_name":"Konongo Odumase Senior High .",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051002",
     "school_name":"Konongo Odumase Senior High .",
     "category":"B",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070602",
     "school_name":"Kpando Senior High .",
     "category":"B",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070602",
     "school_name":"Kpando Senior High .",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070602",
     "school_name":"Kpando Senior High .",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070602",
     "school_name":"Kpando Senior High .",
     "category":"B",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070602",
     "school_name":"Kpando Senior High .",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070602",
     "school_name":"Kpando Senior High .",
     "category":"B",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9051801",
     "school_name":"Krobea Asante Tec\/Voc Inst. ",
     "category":"B",
     "school_type":"TVET",
     "program":"BUILDING CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9051801",
     "school_name":"Krobea Asante Tec\/Voc Inst. ",
     "category":"B",
     "school_type":"TVET",
     "program":"ELECTRICAL ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9051801",
     "school_name":"Krobea Asante Tec\/Voc Inst. ",
     "category":"B",
     "school_type":"TVET",
     "program":"FASHION DESIGNING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9051801",
     "school_name":"Krobea Asante Tec\/Voc Inst. ",
     "category":"B",
     "school_type":"TVET",
     "program":"HOSPITALITY & CATERING MANAGEMENT",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9051801",
     "school_name":"Krobea Asante Tec\/Voc Inst. ",
     "category":"B",
     "school_type":"TVET",
     "program":"MOTOR VEHICLE ENGINEERING",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9051801",
     "school_name":"Krobea Asante Tec\/Voc Inst. ",
     "category":"B",
     "school_type":"TVET",
     "program":"PLUMBING & GAS FITTING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9051801",
     "school_name":"Krobea Asante Tec\/Voc Inst. ",
     "category":"B",
     "school_type":"TVET",
     "program":"SOLAR PHOTOVOLTAIC TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9051801",
     "school_name":"Krobea Asante Tec\/Voc Inst. ",
     "category":"B",
     "school_type":"TVET",
     "program":"WELDING AND FABRICATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9051801",
     "school_name":"Krobea Asante Tec\/Voc Inst. ",
     "category":"B",
     "school_type":"TVET",
     "program":"WOOD CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0061303",
     "school_name":"Kukuom Agric Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0061303",
     "school_name":"Kukuom Agric Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0061303",
     "school_name":"Kukuom Agric Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0061303",
     "school_name":"Kukuom Agric Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0061303",
     "school_name":"Kukuom Agric Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0061303",
     "school_name":"Kukuom Agric Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050105",
     "school_name":"Kumasi Academy",
     "category":"B",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050105",
     "school_name":"Kumasi Academy",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050105",
     "school_name":"Kumasi Academy",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050105",
     "school_name":"Kumasi Academy",
     "category":"B",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050105",
     "school_name":"Kumasi Academy",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050105",
     "school_name":"Kumasi Academy",
     "category":"B",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050103",
     "school_name":"Kumasi Girls Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050103",
     "school_name":"Kumasi Girls Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050103",
     "school_name":"Kumasi Girls Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050103",
     "school_name":"Kumasi Girls Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050103",
     "school_name":"Kumasi Girls Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050114",
     "school_name":"Kumasi Wesley Girls High Sch",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050114",
     "school_name":"Kumasi Wesley Girls High Sch",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050114",
     "school_name":"Kumasi Wesley Girls High Sch",
     "category":"B",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050114",
     "school_name":"Kumasi Wesley Girls High Sch",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050114",
     "school_name":"Kumasi Wesley Girls High Sch",
     "category":"B",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021307",
     "school_name":"Kwabeng Anglican Senior High\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021307",
     "school_name":"Kwabeng Anglican Senior High\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021307",
     "school_name":"Kwabeng Anglican Senior High\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021307",
     "school_name":"Kwabeng Anglican Senior High\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021307",
     "school_name":"Kwabeng Anglican Senior High\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021307",
     "school_name":"Kwabeng Anglican Senior High\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021307",
     "school_name":"Kwabeng Anglican Senior High\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060904",
     "school_name":"Kwabre Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060904",
     "school_name":"Kwabre Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060904",
     "school_name":"Kwabre Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060904",
     "school_name":"Kwabre Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060904",
     "school_name":"Kwabre Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021005",
     "school_name":"Kwahu Ridge Senior High",
     "category":"B",
     "school_type":"SHTS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021005",
     "school_name":"Kwahu Ridge Senior High",
     "category":"B",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021005",
     "school_name":"Kwahu Ridge Senior High",
     "category":"B",
     "school_type":"SHTS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021005",
     "school_name":"Kwahu Ridge Senior High",
     "category":"B",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021005",
     "school_name":"Kwahu Ridge Senior High",
     "category":"B",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021005",
     "school_name":"Kwahu Ridge Senior High",
     "category":"B",
     "school_type":"SHTS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021010",
     "school_name":"Kwahu Tafo Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021010",
     "school_name":"Kwahu Tafo Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021010",
     "school_name":"Kwahu Tafo Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021010",
     "school_name":"Kwahu Tafo Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021010",
     "school_name":"Kwahu Tafo Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021010",
     "school_name":"Kwahu Tafo Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0010117",
     "school_name":"Labone Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0010117",
     "school_name":"Labone Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0010117",
     "school_name":"Labone Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0010117",
     "school_name":"Labone Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0010117",
     "school_name":"Labone Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0010117",
     "school_name":"Labone Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0100104",
     "school_name":"Lassie-Tuolu Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0100104",
     "school_name":"Lassie-Tuolu Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0100104",
     "school_name":"Lassie-Tuolu Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051402",
     "school_name":"Maabang Senior High\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051402",
     "school_name":"Maabang Senior High\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051402",
     "school_name":"Maabang Senior High\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051402",
     "school_name":"Maabang Senior High\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051402",
     "school_name":"Maabang Senior High\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051402",
     "school_name":"Maabang Senior High\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051402",
     "school_name":"Maabang Senior High\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070802",
     "school_name":"Mafi-Kumasi Senior High\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070802",
     "school_name":"Mafi-Kumasi Senior High\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070802",
     "school_name":"Mafi-Kumasi Senior High\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070802",
     "school_name":"Mafi-Kumasi Senior High\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070802",
     "school_name":"Mafi-Kumasi Senior High\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070802",
     "school_name":"Mafi-Kumasi Senior High\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070802",
     "school_name":"Mafi-Kumasi Senior High\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0061003",
     "school_name":"Mansen Senior High Sch.",
     "category":"B",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0061003",
     "school_name":"Mansen Senior High Sch.",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0061003",
     "school_name":"Mansen Senior High Sch.",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0061003",
     "school_name":"Mansen Senior High Sch.",
     "category":"B",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021502",
     "school_name":"Manya Krobo Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021502",
     "school_name":"Manya Krobo Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021502",
     "school_name":"Manya Krobo Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021502",
     "school_name":"Manya Krobo Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021502",
     "school_name":"Manya Krobo Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021502",
     "school_name":"Manya Krobo Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060605",
     "school_name":"Menji Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0060605",
     "school_name":"Menji Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0060605",
     "school_name":"Menji Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0060605",
     "school_name":"Menji Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0020410",
     "school_name":"Methodist Girls Senior High, Mamfe",
     "category":"B",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020410",
     "school_name":"Methodist Girls Senior High, Mamfe",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020410",
     "school_name":"Methodist Girls Senior High, Mamfe",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020410",
     "school_name":"Methodist Girls Senior High, Mamfe",
     "category":"B",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020410",
     "school_name":"Methodist Girls Senior High, Mamfe",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020410",
     "school_name":"Methodist Girls Senior High, Mamfe",
     "category":"B",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030304",
     "school_name":"Methodist High School,Saltpond",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030304",
     "school_name":"Methodist High School,Saltpond",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030304",
     "school_name":"Methodist High School,Saltpond",
     "category":"B",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030304",
     "school_name":"Methodist High School,Saltpond",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030304",
     "school_name":"Methodist High School,Saltpond",
     "category":"B",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0061301",
     "school_name":"Mim Senior High ",
     "category":"B",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0061301",
     "school_name":"Mim Senior High ",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0061301",
     "school_name":"Mim Senior High ",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0061301",
     "school_name":"Mim Senior High ",
     "category":"B",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0061301",
     "school_name":"Mim Senior High ",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0061301",
     "school_name":"Mim Senior High ",
     "category":"B",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030504",
     "school_name":"Mozano Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030504",
     "school_name":"Mozano Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030504",
     "school_name":"Mozano Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030504",
     "school_name":"Mozano Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030504",
     "school_name":"Mozano Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030504",
     "school_name":"Mozano Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050205",
     "school_name":"Mpasatia Senior High\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050205",
     "school_name":"Mpasatia Senior High\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050205",
     "school_name":"Mpasatia Senior High\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050205",
     "school_name":"Mpasatia Senior High\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050205",
     "school_name":"Mpasatia Senior High\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050205",
     "school_name":"Mpasatia Senior High\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050205",
     "school_name":"Mpasatia Senior High\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0041102",
     "school_name":"Mpohor Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0041102",
     "school_name":"Mpohor Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0041102",
     "school_name":"Mpohor Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0041102",
     "school_name":"Mpohor Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0041102",
     "school_name":"Mpohor Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0041102",
     "school_name":"Mpohor Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021002",
     "school_name":"Mpraeso Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021002",
     "school_name":"Mpraeso Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021002",
     "school_name":"Mpraeso Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021002",
     "school_name":"Mpraeso Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021002",
     "school_name":"Mpraeso Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060502",
     "school_name":"Nafana Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060502",
     "school_name":"Nafana Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060502",
     "school_name":"Nafana Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060502",
     "school_name":"Nafana Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060502",
     "school_name":"Nafana Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060502",
     "school_name":"Nafana Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051301",
     "school_name":"New Edubiase Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051301",
     "school_name":"New Edubiase Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051301",
     "school_name":"New Edubiase Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051301",
     "school_name":"New Edubiase Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051301",
     "school_name":"New Edubiase Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051301",
     "school_name":"New Edubiase Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020103",
     "school_name":"New Juaben Senior High\/Com",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020103",
     "school_name":"New Juaben Senior High\/Com",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020103",
     "school_name":"New Juaben Senior High\/Com",
     "category":"B",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020103",
     "school_name":"New Juaben Senior High\/Com",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020103",
     "school_name":"New Juaben Senior High\/Com",
     "category":"B",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020401",
     "school_name":"Nifa Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020401",
     "school_name":"Nifa Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020401",
     "school_name":"Nifa Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020401",
     "school_name":"Nifa Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020401",
     "school_name":"Nifa Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020401",
     "school_name":"Nifa Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0010403",
     "school_name":"Ningo Senior High",
     "category":"B",
     "school_type":"SHTS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0010403",
     "school_name":"Ningo Senior High",
     "category":"B",
     "school_type":"SHTS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0010403",
     "school_name":"Ningo Senior High",
     "category":"B",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0010403",
     "school_name":"Ningo Senior High",
     "category":"B",
     "school_type":"SHTS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0010403",
     "school_name":"Ningo Senior High",
     "category":"B",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0010403",
     "school_name":"Ningo Senior High",
     "category":"B",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0010403",
     "school_name":"Ningo Senior High",
     "category":"B",
     "school_type":"SHTS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021004",
     "school_name":"Nkawkaw Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021004",
     "school_name":"Nkawkaw Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021004",
     "school_name":"Nkawkaw Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021004",
     "school_name":"Nkawkaw Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021004",
     "school_name":"Nkawkaw Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021004",
     "school_name":"Nkawkaw Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060604",
     "school_name":"Nkoranman Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060604",
     "school_name":"Nkoranman Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060604",
     "school_name":"Nkoranman Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060604",
     "school_name":"Nkoranman Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060604",
     "school_name":"Nkoranman Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060604",
     "school_name":"Nkoranman Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060901",
     "school_name":"Nkoranza Senior High\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060901",
     "school_name":"Nkoranza Senior High\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060901",
     "school_name":"Nkoranza Senior High\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060901",
     "school_name":"Nkoranza Senior High\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060901",
     "school_name":"Nkoranza Senior High\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060901",
     "school_name":"Nkoranza Senior High\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060901",
     "school_name":"Nkoranza Senior High\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0061004",
     "school_name":"Nkrankwanta Comm Senior High",
     "category":"B",
     "school_type":"SHTS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0061004",
     "school_name":"Nkrankwanta Comm Senior High",
     "category":"B",
     "school_type":"SHTS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0061004",
     "school_name":"Nkrankwanta Comm Senior High",
     "category":"B",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0061004",
     "school_name":"Nkrankwanta Comm Senior High",
     "category":"B",
     "school_type":"SHTS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0061004",
     "school_name":"Nkrankwanta Comm Senior High",
     "category":"B",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021006",
     "school_name":"Nkwatia Presby Senior High\/Com",
     "category":"B",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021006",
     "school_name":"Nkwatia Presby Senior High\/Com",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021006",
     "school_name":"Nkwatia Presby Senior High\/Com",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021006",
     "school_name":"Nkwatia Presby Senior High\/Com",
     "category":"B",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021006",
     "school_name":"Nkwatia Presby Senior High\/Com",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030903",
     "school_name":"Nsaba Presby Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030903",
     "school_name":"Nsaba Presby Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030903",
     "school_name":"Nsaba Presby Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030903",
     "school_name":"Nsaba Presby Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060608",
     "school_name":"Nsawkaw State Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060608",
     "school_name":"Nsawkaw State Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060608",
     "school_name":"Nsawkaw State Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060608",
     "school_name":"Nsawkaw State Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040301",
     "school_name":"Nsein Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040301",
     "school_name":"Nsein Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040301",
     "school_name":"Nsein Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040301",
     "school_name":"Nsein Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040301",
     "school_name":"Nsein Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040301",
     "school_name":"Nsein Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050501",
     "school_name":"Nsutaman Cath. Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050501",
     "school_name":"Nsutaman Cath. Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050501",
     "school_name":"Nsutaman Cath. Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050501",
     "school_name":"Nsutaman Cath. Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050501",
     "school_name":"Nsutaman Cath. Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050501",
     "school_name":"Nsutaman Cath. Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0010114",
     "school_name":"Nungua Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0010114",
     "school_name":"Nungua Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0010114",
     "school_name":"Nungua Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0010114",
     "school_name":"Nungua Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0010114",
     "school_name":"Nungua Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050175",
     "school_name":"Nuru-Ameen Islamic Senior High, Asewase",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0050175",
     "school_name":"Nuru-Ameen Islamic Senior High, Asewase",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0050175",
     "school_name":"Nuru-Ameen Islamic Senior High, Asewase",
     "category":"B",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0050175",
     "school_name":"Nuru-Ameen Islamic Senior High, Asewase",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0050175",
     "school_name":"Nuru-Ameen Islamic Senior High, Asewase",
     "category":"B",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0030901",
     "school_name":"Nyakrom Senior High Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030901",
     "school_name":"Nyakrom Senior High Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030901",
     "school_name":"Nyakrom Senior High Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030901",
     "school_name":"Nyakrom Senior High Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030901",
     "school_name":"Nyakrom Senior High Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030901",
     "school_name":"Nyakrom Senior High Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020601",
     "school_name":"Oda Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020601",
     "school_name":"Oda Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020601",
     "school_name":"Oda Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020601",
     "school_name":"Oda Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020601",
     "school_name":"Oda Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020601",
     "school_name":"Oda Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060103",
     "school_name":"Odomaseman Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060103",
     "school_name":"Odomaseman Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060103",
     "school_name":"Odomaseman Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060103",
     "school_name":"Odomaseman Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060103",
     "school_name":"Odomaseman Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060103",
     "school_name":"Odomaseman Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0010118",
     "school_name":"Odorgonno Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0010118",
     "school_name":"Odorgonno Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0010118",
     "school_name":"Odorgonno Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0010118",
     "school_name":"Odorgonno Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0010118",
     "school_name":"Odorgonno Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0010118",
     "school_name":"Odorgonno Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050172",
     "school_name":"Ofoase Kokoben Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050172",
     "school_name":"Ofoase Kokoben Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050172",
     "school_name":"Ofoase Kokoben Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050172",
     "school_name":"Ofoase Kokoben Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0071104",
     "school_name":"Okadjakrom Senior High\/Tech.",
     "category":"B",
     "school_type":"SHTS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0071104",
     "school_name":"Okadjakrom Senior High\/Tech.",
     "category":"B",
     "school_type":"SHTS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0071104",
     "school_name":"Okadjakrom Senior High\/Tech.",
     "category":"B",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0071104",
     "school_name":"Okadjakrom Senior High\/Tech.",
     "category":"B",
     "school_type":"SHTS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0071104",
     "school_name":"Okadjakrom Senior High\/Tech.",
     "category":"B",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0071104",
     "school_name":"Okadjakrom Senior High\/Tech.",
     "category":"B",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0071104",
     "school_name":"Okadjakrom Senior High\/Tech.",
     "category":"B",
     "school_type":"SHTS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050601",
     "school_name":"Okomfo Anokye Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050601",
     "school_name":"Okomfo Anokye Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050601",
     "school_name":"Okomfo Anokye Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050601",
     "school_name":"Okomfo Anokye Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050601",
     "school_name":"Okomfo Anokye Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050601",
     "school_name":"Okomfo Anokye Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050302",
     "school_name":"Oppong Mem. Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050302",
     "school_name":"Oppong Mem. Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050302",
     "school_name":"Oppong Mem. Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050302",
     "school_name":"Oppong Mem. Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050302",
     "school_name":"Oppong Mem. Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060903",
     "school_name":"Osei Bonsu Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060903",
     "school_name":"Osei Bonsu Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060903",
     "school_name":"Osei Bonsu Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060903",
     "school_name":"Osei Bonsu Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060903",
     "school_name":"Osei Bonsu Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060903",
     "school_name":"Osei Bonsu Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050106",
     "school_name":"Osei Kyeretwie Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050106",
     "school_name":"Osei Kyeretwie Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050106",
     "school_name":"Osei Kyeretwie Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050106",
     "school_name":"Osei Kyeretwie Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050106",
     "school_name":"Osei Kyeretwie Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050203",
     "school_name":"Osei Tutu Senior High, Akropong",
     "category":"B",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Boys",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050203",
     "school_name":"Osei Tutu Senior High, Akropong",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Boys",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050203",
     "school_name":"Osei Tutu Senior High, Akropong",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Boys",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050203",
     "school_name":"Osei Tutu Senior High, Akropong",
     "category":"B",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Boys",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050203",
     "school_name":"Osei Tutu Senior High, Akropong",
     "category":"B",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Boys",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020105",
     "school_name":"Oti Boateng Senior High ",
     "category":"B",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020105",
     "school_name":"Oti Boateng Senior High ",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020105",
     "school_name":"Oti Boateng Senior High ",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020105",
     "school_name":"Oti Boateng Senior High ",
     "category":"B",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020105",
     "school_name":"Oti Boateng Senior High ",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020105",
     "school_name":"Oti Boateng Senior High ",
     "category":"B",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050604",
     "school_name":"Otumfuo Osei Tutu II College",
     "category":"B",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050604",
     "school_name":"Otumfuo Osei Tutu II College",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050604",
     "school_name":"Otumfuo Osei Tutu II College",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050604",
     "school_name":"Otumfuo Osei Tutu II College",
     "category":"B",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050604",
     "school_name":"Otumfuo Osei Tutu II College",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050604",
     "school_name":"Otumfuo Osei Tutu II College",
     "category":"B",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0010213",
     "school_name":"Our Lady of Mercy Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0010213",
     "school_name":"Our Lady of Mercy Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0010213",
     "school_name":"Our Lady of Mercy Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0010213",
     "school_name":"Our Lady of Mercy Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0010213",
     "school_name":"Our Lady of Mercy Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0060707",
     "school_name":"Our Lady of Mount Carmel Girls Senior High, Techiman",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060707",
     "school_name":"Our Lady of Mount Carmel Girls Senior High, Techiman",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060707",
     "school_name":"Our Lady of Mount Carmel Girls Senior High, Techiman",
     "category":"B",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060707",
     "school_name":"Our Lady of Mount Carmel Girls Senior High, Techiman",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020106",
     "school_name":"Oyoko Methodist  Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020106",
     "school_name":"Oyoko Methodist  Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020106",
     "school_name":"Oyoko Methodist  Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020106",
     "school_name":"Oyoko Methodist  Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020106",
     "school_name":"Oyoko Methodist  Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020106",
     "school_name":"Oyoko Methodist  Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070603",
     "school_name":"Peki Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070603",
     "school_name":"Peki Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070603",
     "school_name":"Peki Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070603",
     "school_name":"Peki Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070603",
     "school_name":"Peki Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070603",
     "school_name":"Peki Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020108",
     "school_name":"Pentecost Senior High,Koforidua",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020108",
     "school_name":"Pentecost Senior High,Koforidua",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020108",
     "school_name":"Pentecost Senior High,Koforidua",
     "category":"B",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020108",
     "school_name":"Pentecost Senior High,Koforidua",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020108",
     "school_name":"Pentecost Senior High,Koforidua",
     "category":"B",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0010404",
     "school_name":"Prampram Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0010404",
     "school_name":"Prampram Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0010404",
     "school_name":"Prampram Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0010404",
     "school_name":"Prampram Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0010404",
     "school_name":"Prampram Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060403",
     "school_name":"Prang Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060403",
     "school_name":"Prang Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060403",
     "school_name":"Prang Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060403",
     "school_name":"Prang Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060403",
     "school_name":"Prang Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060403",
     "school_name":"Prang Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021201",
     "school_name":"Presby Senior High, Begoro",
     "category":"B",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021201",
     "school_name":"Presby Senior High, Begoro",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021201",
     "school_name":"Presby Senior High, Begoro",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021201",
     "school_name":"Presby Senior High, Begoro",
     "category":"B",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021201",
     "school_name":"Presby Senior High, Begoro",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021201",
     "school_name":"Presby Senior High, Begoro",
     "category":"B",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0100503",
     "school_name":"Queen of Peace Senior High, Nadowli",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0100503",
     "school_name":"Queen of Peace Senior High, Nadowli",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0100503",
     "school_name":"Queen of Peace Senior High, Nadowli",
     "category":"B",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0100503",
     "school_name":"Queen of Peace Senior High, Nadowli",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020109",
     "school_name":"S.D.A Senior High, Koforidua",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020109",
     "school_name":"S.D.A Senior High, Koforidua",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020109",
     "school_name":"S.D.A Senior High, Koforidua",
     "category":"B",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020109",
     "school_name":"S.D.A Senior High, Koforidua",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020109",
     "school_name":"S.D.A Senior High, Koforidua",
     "category":"B",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060111",
     "school_name":"S.D.A Senior High, Sunyani",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0060111",
     "school_name":"S.D.A Senior High, Sunyani",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0060111",
     "school_name":"S.D.A Senior High, Sunyani",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0060111",
     "school_name":"S.D.A Senior High, Sunyani",
     "category":"B",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0050602",
     "school_name":"S.D.A. Senior High, Agona",
     "category":"B",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050602",
     "school_name":"S.D.A. Senior High, Agona",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050602",
     "school_name":"S.D.A. Senior High, Agona",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050602",
     "school_name":"S.D.A. Senior High, Agona",
     "category":"B",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050602",
     "school_name":"S.D.A. Senior High, Agona",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050602",
     "school_name":"S.D.A. Senior High, Agona",
     "category":"B",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050301",
     "school_name":"S.D.A. Senior High, Bekwai",
     "category":"B",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050301",
     "school_name":"S.D.A. Senior High, Bekwai",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050301",
     "school_name":"S.D.A. Senior High, Bekwai",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050301",
     "school_name":"S.D.A. Senior High, Bekwai",
     "category":"B",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050301",
     "school_name":"S.D.A. Senior High, Bekwai",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050301",
     "school_name":"S.D.A. Senior High, Bekwai",
     "category":"B",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060105",
     "school_name":"Sacred Heart Senior High, Nsoatre",
     "category":"B",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060105",
     "school_name":"Sacred Heart Senior High, Nsoatre",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060105",
     "school_name":"Sacred Heart Senior High, Nsoatre",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060105",
     "school_name":"Sacred Heart Senior High, Nsoatre",
     "category":"B",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060105",
     "school_name":"Sacred Heart Senior High, Nsoatre",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060105",
     "school_name":"Sacred Heart Senior High, Nsoatre",
     "category":"B",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9010102",
     "school_name":"Sacred Heart Tech. Inst.",
     "category":"B",
     "school_type":"TVET",
     "program":"BUSINESS ACCOUNTING",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9010102",
     "school_name":"Sacred Heart Tech. Inst.",
     "category":"B",
     "school_type":"TVET",
     "program":"BUSINESS SECRETARIAL",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9010102",
     "school_name":"Sacred Heart Tech. Inst.",
     "category":"B",
     "school_type":"TVET",
     "program":"COMPUTER HARDWARE TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9010102",
     "school_name":"Sacred Heart Tech. Inst.",
     "category":"B",
     "school_type":"TVET",
     "program":"FASHION DESIGNING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9010102",
     "school_name":"Sacred Heart Tech. Inst.",
     "category":"B",
     "school_type":"TVET",
     "program":"HOSPITALITY & CATERING MANAGEMENT",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9010102",
     "school_name":"Sacred Heart Tech. Inst.",
     "category":"B",
     "school_type":"TVET",
     "program":"WOOD CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0050150",
     "school_name":"Sakafia Islamic Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050150",
     "school_name":"Sakafia Islamic Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050150",
     "school_name":"Sakafia Islamic Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050150",
     "school_name":"Sakafia Islamic Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050150",
     "school_name":"Sakafia Islamic Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0061005",
     "school_name":"Salvation Army Senior High, Aboabo Dormaa",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0061005",
     "school_name":"Salvation Army Senior High, Aboabo Dormaa",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0061005",
     "school_name":"Salvation Army Senior High, Aboabo Dormaa",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060202",
     "school_name":"Samuel Otu Presby Senior High.",
     "category":"B",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060202",
     "school_name":"Samuel Otu Presby Senior High.",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060202",
     "school_name":"Samuel Otu Presby Senior High.",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060202",
     "school_name":"Samuel Otu Presby Senior High.",
     "category":"B",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060202",
     "school_name":"Samuel Otu Presby Senior High.",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0061304",
     "school_name":"Sankore Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0061304",
     "school_name":"Sankore Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0061304",
     "school_name":"Sankore Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021313",
     "school_name":"Saviour Senior High, Osiem ",
     "category":"B",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021313",
     "school_name":"Saviour Senior High, Osiem ",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021313",
     "school_name":"Saviour Senior High, Osiem ",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021313",
     "school_name":"Saviour Senior High, Osiem ",
     "category":"B",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021313",
     "school_name":"Saviour Senior High, Osiem ",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021313",
     "school_name":"Saviour Senior High, Osiem ",
     "category":"B",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040702",
     "school_name":"Sefwi Bekwai Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040702",
     "school_name":"Sefwi Bekwai Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040702",
     "school_name":"Sefwi Bekwai Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040702",
     "school_name":"Sefwi Bekwai Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040702",
     "school_name":"Sefwi Bekwai Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040702",
     "school_name":"Sefwi Bekwai Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040601",
     "school_name":"Sefwi-Wiawso Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040601",
     "school_name":"Sefwi-Wiawso Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040601",
     "school_name":"Sefwi-Wiawso Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040601",
     "school_name":"Sefwi-Wiawso Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040601",
     "school_name":"Sefwi-Wiawso Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040601",
     "school_name":"Sefwi-Wiawso Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040602",
     "school_name":"Sefwi-Wiawso Senior High\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040602",
     "school_name":"Sefwi-Wiawso Senior High\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040602",
     "school_name":"Sefwi-Wiawso Senior High\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040602",
     "school_name":"Sefwi-Wiawso Senior High\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040602",
     "school_name":"Sefwi-Wiawso Senior High\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040602",
     "school_name":"Sefwi-Wiawso Senior High\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040602",
     "school_name":"Sefwi-Wiawso Senior High\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050902",
     "school_name":"Sekyedumase Senior High\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050902",
     "school_name":"Sekyedumase Senior High\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050902",
     "school_name":"Sekyedumase Senior High\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050902",
     "school_name":"Sekyedumase Senior High\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050902",
     "school_name":"Sekyedumase Senior High\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050902",
     "school_name":"Sekyedumase Senior High\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050902",
     "school_name":"Sekyedumase Senior High\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050139",
     "school_name":"Serwaah Nyarko Girls' Snr. High",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050139",
     "school_name":"Serwaah Nyarko Girls' Snr. High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050139",
     "school_name":"Serwaah Nyarko Girls' Snr. High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050139",
     "school_name":"Serwaah Nyarko Girls' Snr. High",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050139",
     "school_name":"Serwaah Nyarko Girls' Snr. High",
     "category":"B",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040111",
     "school_name":"Shama Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040111",
     "school_name":"Shama Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040111",
     "school_name":"Shama Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040111",
     "school_name":"Shama Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040111",
     "school_name":"Shama Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040111",
     "school_name":"Shama Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030906",
     "school_name":"Siddiq Senior High Sch.",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030906",
     "school_name":"Siddiq Senior High Sch.",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030906",
     "school_name":"Siddiq Senior High Sch.",
     "category":"B",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030906",
     "school_name":"Siddiq Senior High Sch.",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030906",
     "school_name":"Siddiq Senior High Sch.",
     "category":"B",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070901",
     "school_name":"Sogakope Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070901",
     "school_name":"Sogakope Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070901",
     "school_name":"Sogakope Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070901",
     "school_name":"Sogakope Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070901",
     "school_name":"Sogakope Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070901",
     "school_name":"Sogakope Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060513",
     "school_name":"St. Ann's Girls Senior High, Sampa",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Girls",
     "residence":"Day"
  },
  {
     "school_code":"0060513",
     "school_name":"St. Ann's Girls Senior High, Sampa",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Girls",
     "residence":"Day"
  },
  {
     "school_code":"9101001",
     "school_name":"St. Basilides Voc.\/Tech. Inst.",
     "category":"B",
     "school_type":"TVET",
     "program":"BUILDING CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9101001",
     "school_name":"St. Basilides Voc.\/Tech. Inst.",
     "category":"B",
     "school_type":"TVET",
     "program":"ELECTRICAL ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9101001",
     "school_name":"St. Basilides Voc.\/Tech. Inst.",
     "category":"B",
     "school_type":"TVET",
     "program":"FASHION DESIGNING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9101001",
     "school_name":"St. Basilides Voc.\/Tech. Inst.",
     "category":"B",
     "school_type":"TVET",
     "program":"SOLAR PHOTOVOLTAIC TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9101001",
     "school_name":"St. Basilides Voc.\/Tech. Inst.",
     "category":"B",
     "school_type":"TVET",
     "program":"WELDING AND FABRICATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9101001",
     "school_name":"St. Basilides Voc.\/Tech. Inst.",
     "category":"B",
     "school_type":"TVET",
     "program":"WOOD CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021008",
     "school_name":"St. Dominic's Senior High\/Tech, Pepease",
     "category":"B",
     "school_type":"SHTS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021008",
     "school_name":"St. Dominic's Senior High\/Tech, Pepease",
     "category":"B",
     "school_type":"SHTS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021008",
     "school_name":"St. Dominic's Senior High\/Tech, Pepease",
     "category":"B",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021008",
     "school_name":"St. Dominic's Senior High\/Tech, Pepease",
     "category":"B",
     "school_type":"SHTS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021008",
     "school_name":"St. Dominic's Senior High\/Tech, Pepease",
     "category":"B",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021008",
     "school_name":"St. Dominic's Senior High\/Tech, Pepease",
     "category":"B",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021008",
     "school_name":"St. Dominic's Senior High\/Tech, Pepease",
     "category":"B",
     "school_type":"SHTS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020603",
     "school_name":"St. Francis Senior High\/Tech, Akim Oda",
     "category":"B",
     "school_type":"SHTS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020603",
     "school_name":"St. Francis Senior High\/Tech, Akim Oda",
     "category":"B",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020603",
     "school_name":"St. Francis Senior High\/Tech, Akim Oda",
     "category":"B",
     "school_type":"SHTS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020603",
     "school_name":"St. Francis Senior High\/Tech, Akim Oda",
     "category":"B",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020603",
     "school_name":"St. Francis Senior High\/Tech, Akim Oda",
     "category":"B",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020603",
     "school_name":"St. Francis Senior High\/Tech, Akim Oda",
     "category":"B",
     "school_type":"SHTS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050119",
     "school_name":"St. Hubert Sem\/Senior High, Kumasi",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Boys",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050119",
     "school_name":"St. Hubert Sem\/Senior High, Kumasi",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Boys",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050119",
     "school_name":"St. Hubert Sem\/Senior High, Kumasi",
     "category":"B",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Boys",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050804",
     "school_name":"St. Jerome Senior High, Abofour",
     "category":"B",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050804",
     "school_name":"St. Jerome Senior High, Abofour",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050804",
     "school_name":"St. Jerome Senior High, Abofour",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050804",
     "school_name":"St. Jerome Senior High, Abofour",
     "category":"B",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050804",
     "school_name":"St. Jerome Senior High, Abofour",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050804",
     "school_name":"St. Jerome Senior High, Abofour",
     "category":"B",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0010501",
     "school_name":"St. John's Grammar Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0010501",
     "school_name":"St. John's Grammar Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0010501",
     "school_name":"St. John's Grammar Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0010501",
     "school_name":"St. John's Grammar Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0010501",
     "school_name":"St. John's Grammar Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0010501",
     "school_name":"St. John's Grammar Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040605",
     "school_name":"St. Joseph Senior High, Sefwi Wiawso",
     "category":"B",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040605",
     "school_name":"St. Joseph Senior High, Sefwi Wiawso",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040605",
     "school_name":"St. Joseph Senior High, Sefwi Wiawso",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040605",
     "school_name":"St. Joseph Senior High, Sefwi Wiawso",
     "category":"B",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040605",
     "school_name":"St. Joseph Senior High, Sefwi Wiawso",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040605",
     "school_name":"St. Joseph Senior High, Sefwi Wiawso",
     "category":"B",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050304",
     "school_name":"St. Joseph Senior High\/Tech, Ahwiren",
     "category":"B",
     "school_type":"SHTS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050304",
     "school_name":"St. Joseph Senior High\/Tech, Ahwiren",
     "category":"B",
     "school_type":"SHTS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050304",
     "school_name":"St. Joseph Senior High\/Tech, Ahwiren",
     "category":"B",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050304",
     "school_name":"St. Joseph Senior High\/Tech, Ahwiren",
     "category":"B",
     "school_type":"SHTS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050304",
     "school_name":"St. Joseph Senior High\/Tech, Ahwiren",
     "category":"B",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050304",
     "school_name":"St. Joseph Senior High\/Tech, Ahwiren",
     "category":"B",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050304",
     "school_name":"St. Joseph Senior High\/Tech, Ahwiren",
     "category":"B",
     "school_type":"SHTS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9080901",
     "school_name":"St. Joseph's Tech. Inst.",
     "category":"B",
     "school_type":"TVET",
     "program":"BUILDING CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9022001",
     "school_name":"St. Joseph's Tech. Inst.",
     "category":"B",
     "school_type":"TVET",
     "program":"BUILDING CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9022001",
     "school_name":"St. Joseph's Tech. Inst.",
     "category":"B",
     "school_type":"TVET",
     "program":"BUSINESS ACCOUNTING",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9080901",
     "school_name":"St. Joseph's Tech. Inst.",
     "category":"B",
     "school_type":"TVET",
     "program":"BUSINESS ACCOUNTING",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9080901",
     "school_name":"St. Joseph's Tech. Inst.",
     "category":"B",
     "school_type":"TVET",
     "program":"BUSINESS INFORMATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9022001",
     "school_name":"St. Joseph's Tech. Inst.",
     "category":"B",
     "school_type":"TVET",
     "program":"BUSINESS INFORMATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9080901",
     "school_name":"St. Joseph's Tech. Inst.",
     "category":"B",
     "school_type":"TVET",
     "program":"BUSINESS SECRETARIAL",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9022001",
     "school_name":"St. Joseph's Tech. Inst.",
     "category":"B",
     "school_type":"TVET",
     "program":"BUSINESS SECRETARIAL",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9022001",
     "school_name":"St. Joseph's Tech. Inst.",
     "category":"B",
     "school_type":"TVET",
     "program":"COMPUTER HARDWARE TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9080901",
     "school_name":"St. Joseph's Tech. Inst.",
     "category":"B",
     "school_type":"TVET",
     "program":"CREATIVE ART TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9080901",
     "school_name":"St. Joseph's Tech. Inst.",
     "category":"B",
     "school_type":"TVET",
     "program":"ELECTRICAL ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9022001",
     "school_name":"St. Joseph's Tech. Inst.",
     "category":"B",
     "school_type":"TVET",
     "program":"ELECTRICAL ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9080901",
     "school_name":"St. Joseph's Tech. Inst.",
     "category":"B",
     "school_type":"TVET",
     "program":"ELECTRONICS ENGINEERING",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9022001",
     "school_name":"St. Joseph's Tech. Inst.",
     "category":"B",
     "school_type":"TVET",
     "program":"ELECTRONICS ENGINEERING",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9022001",
     "school_name":"St. Joseph's Tech. Inst.",
     "category":"B",
     "school_type":"TVET",
     "program":"FASHION DESIGNING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9080901",
     "school_name":"St. Joseph's Tech. Inst.",
     "category":"B",
     "school_type":"TVET",
     "program":"FASHION DESIGNING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9080901",
     "school_name":"St. Joseph's Tech. Inst.",
     "category":"B",
     "school_type":"TVET",
     "program":"HOSPITALITY & CATERING MANAGEMENT",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9022001",
     "school_name":"St. Joseph's Tech. Inst.",
     "category":"B",
     "school_type":"TVET",
     "program":"HOSPITALITY & CATERING MANAGEMENT",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9080901",
     "school_name":"St. Joseph's Tech. Inst.",
     "category":"B",
     "school_type":"TVET",
     "program":"MECHANICAL. ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9022001",
     "school_name":"St. Joseph's Tech. Inst.",
     "category":"B",
     "school_type":"TVET",
     "program":"MECHANICAL. ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9080901",
     "school_name":"St. Joseph's Tech. Inst.",
     "category":"B",
     "school_type":"TVET",
     "program":"MOTOR VEHICLE ENGINEERING",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9022001",
     "school_name":"St. Joseph's Tech. Inst.",
     "category":"B",
     "school_type":"TVET",
     "program":"MOTOR VEHICLE ENGINEERING",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9080901",
     "school_name":"St. Joseph's Tech. Inst.",
     "category":"B",
     "school_type":"TVET",
     "program":"PLUMBING & GAS FITTING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9080901",
     "school_name":"St. Joseph's Tech. Inst.",
     "category":"B",
     "school_type":"TVET",
     "program":"WELDING AND FABRICATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9022001",
     "school_name":"St. Joseph's Tech. Inst.",
     "category":"B",
     "school_type":"TVET",
     "program":"WELDING AND FABRICATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9080901",
     "school_name":"St. Joseph's Tech. Inst.",
     "category":"B",
     "school_type":"TVET",
     "program":"WOOD CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9022001",
     "school_name":"St. Joseph's Tech. Inst.",
     "category":"B",
     "school_type":"TVET",
     "program":"WOOD CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0010142",
     "school_name":"St. Margaret Mary Snr.High\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0010142",
     "school_name":"St. Margaret Mary Snr.High\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0010142",
     "school_name":"St. Margaret Mary Snr.High\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0010142",
     "school_name":"St. Margaret Mary Snr.High\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0010142",
     "school_name":"St. Margaret Mary Snr.High\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0010142",
     "school_name":"St. Margaret Mary Snr.High\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0020303",
     "school_name":"St. Martin's Senior High, Nsawam",
     "category":"B",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020303",
     "school_name":"St. Martin's Senior High, Nsawam",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020303",
     "school_name":"St. Martin's Senior High, Nsawam",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020303",
     "school_name":"St. Martin's Senior High, Nsawam",
     "category":"B",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020303",
     "school_name":"St. Martin's Senior High, Nsawam",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040201",
     "school_name":"St. Mary's Boys' Senior High, Apowa",
     "category":"B",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Boys",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040201",
     "school_name":"St. Mary's Boys' Senior High, Apowa",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Boys",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040201",
     "school_name":"St. Mary's Boys' Senior High, Apowa",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Boys",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040201",
     "school_name":"St. Mary's Boys' Senior High, Apowa",
     "category":"B",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Boys",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040201",
     "school_name":"St. Mary's Boys' Senior High, Apowa",
     "category":"B",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Boys",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051704",
     "school_name":"St. Mary's Girl's Senior High, Konongo",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051704",
     "school_name":"St. Mary's Girl's Senior High, Konongo",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051704",
     "school_name":"St. Mary's Girl's Senior High, Konongo",
     "category":"B",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051704",
     "school_name":"St. Mary's Girl's Senior High, Konongo",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051704",
     "school_name":"St. Mary's Girl's Senior High, Konongo",
     "category":"B",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0071005",
     "school_name":"St. Mary's Sem.& Senior High, Lolobi",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Boys",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0071005",
     "school_name":"St. Mary's Sem.& Senior High, Lolobi",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Boys",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0071005",
     "school_name":"St. Mary's Sem.& Senior High, Lolobi",
     "category":"B",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Boys",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050708",
     "school_name":"St. Michael's Senior High, Ahenkro",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050708",
     "school_name":"St. Michael's Senior High, Ahenkro",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050708",
     "school_name":"St. Michael's Senior High, Ahenkro",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050708",
     "school_name":"St. Michael's Senior High, Ahenkro",
     "category":"B",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050502",
     "school_name":"St. Monica's Senior High, Mampong ",
     "category":"B",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050502",
     "school_name":"St. Monica's Senior High, Mampong ",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050502",
     "school_name":"St. Monica's Senior High, Mampong ",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050502",
     "school_name":"St. Monica's Senior High, Mampong ",
     "category":"B",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050502",
     "school_name":"St. Monica's Senior High, Mampong ",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050502",
     "school_name":"St. Monica's Senior High, Mampong ",
     "category":"B",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020201",
     "school_name":"Suhum Senior High\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020201",
     "school_name":"Suhum Senior High\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020201",
     "school_name":"Suhum Senior High\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020201",
     "school_name":"Suhum Senior High\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020201",
     "school_name":"Suhum Senior High\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060503",
     "school_name":"Sumaman Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060503",
     "school_name":"Sumaman Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060503",
     "school_name":"Sumaman Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060503",
     "school_name":"Sumaman Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9060101",
     "school_name":"Sunyani Methodist Technical Inst.",
     "category":"B",
     "school_type":"TVET",
     "program":"BUILDING CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9060101",
     "school_name":"Sunyani Methodist Technical Inst.",
     "category":"B",
     "school_type":"TVET",
     "program":"BUSINESS ACCOUNTING",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9060101",
     "school_name":"Sunyani Methodist Technical Inst.",
     "category":"B",
     "school_type":"TVET",
     "program":"BUSINESS SECRETARIAL",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9060101",
     "school_name":"Sunyani Methodist Technical Inst.",
     "category":"B",
     "school_type":"TVET",
     "program":"ELECTRICAL ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9060101",
     "school_name":"Sunyani Methodist Technical Inst.",
     "category":"B",
     "school_type":"TVET",
     "program":"ELECTRONICS ENGINEERING",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9060101",
     "school_name":"Sunyani Methodist Technical Inst.",
     "category":"B",
     "school_type":"TVET",
     "program":"FASHION DESIGNING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9060101",
     "school_name":"Sunyani Methodist Technical Inst.",
     "category":"B",
     "school_type":"TVET",
     "program":"HOSPITALITY & CATERING MANAGEMENT",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9060101",
     "school_name":"Sunyani Methodist Technical Inst.",
     "category":"B",
     "school_type":"TVET",
     "program":"MECHANICAL. ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9060101",
     "school_name":"Sunyani Methodist Technical Inst.",
     "category":"B",
     "school_type":"TVET",
     "program":"MOTOR VEHICLE ENGINEERING",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9060101",
     "school_name":"Sunyani Methodist Technical Inst.",
     "category":"B",
     "school_type":"TVET",
     "program":"WELDING AND FABRICATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9060101",
     "school_name":"Sunyani Methodist Technical Inst.",
     "category":"B",
     "school_type":"TVET",
     "program":"WOOD CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030905",
     "school_name":"Swedru Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030905",
     "school_name":"Swedru Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030905",
     "school_name":"Swedru Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030905",
     "school_name":"Swedru Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030905",
     "school_name":"Swedru Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030905",
     "school_name":"Swedru Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051803",
     "school_name":"T. I. Ahmadiyya Girl's Senior High, Asokore",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051803",
     "school_name":"T. I. Ahmadiyya Girl's Senior High, Asokore",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051803",
     "school_name":"T. I. Ahmadiyya Girl's Senior High, Asokore",
     "category":"B",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051803",
     "school_name":"T. I. Ahmadiyya Girl's Senior High, Asokore",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051803",
     "school_name":"T. I. Ahmadiyya Girl's Senior High, Asokore",
     "category":"B",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0100106",
     "school_name":"T. I. Ahmadiyya Senior High, Wa",
     "category":"B",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0100106",
     "school_name":"T. I. Ahmadiyya Senior High, Wa",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0100106",
     "school_name":"T. I. Ahmadiyya Senior High, Wa",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0100106",
     "school_name":"T. I. Ahmadiyya Senior High, Wa",
     "category":"B",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0100106",
     "school_name":"T. I. Ahmadiyya Senior High, Wa",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0080109",
     "school_name":"Tamale Girls Senior High ",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0080109",
     "school_name":"Tamale Girls Senior High ",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0080109",
     "school_name":"Tamale Girls Senior High ",
     "category":"B",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0080109",
     "school_name":"Tamale Girls Senior High ",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040904",
     "school_name":"Tarkwa Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040904",
     "school_name":"Tarkwa Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040904",
     "school_name":"Tarkwa Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040904",
     "school_name":"Tarkwa Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040904",
     "school_name":"Tarkwa Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050140",
     "school_name":"Tawheed Senior High.",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0050140",
     "school_name":"Tawheed Senior High.",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0050140",
     "school_name":"Tawheed Senior High.",
     "category":"B",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0050140",
     "school_name":"Tawheed Senior High.",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0050140",
     "school_name":"Tawheed Senior High.",
     "category":"B",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0060701",
     "school_name":"Techiman Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060701",
     "school_name":"Techiman Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060701",
     "school_name":"Techiman Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060701",
     "school_name":"Techiman Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060701",
     "school_name":"Techiman Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060701",
     "school_name":"Techiman Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051401",
     "school_name":"Tepa Senior High ",
     "category":"B",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051401",
     "school_name":"Tepa Senior High ",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051401",
     "school_name":"Tepa Senior High ",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051401",
     "school_name":"Tepa Senior High ",
     "category":"B",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051401",
     "school_name":"Tepa Senior High ",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051401",
     "school_name":"Tepa Senior High ",
     "category":"B",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050202",
     "school_name":"Toase Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050202",
     "school_name":"Toase Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050202",
     "school_name":"Toase Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050202",
     "school_name":"Toase Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050202",
     "school_name":"Toase Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050202",
     "school_name":"Toase Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070609",
     "school_name":"Tongor Senior High Tech.",
     "category":"B",
     "school_type":"SHTS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070609",
     "school_name":"Tongor Senior High Tech.",
     "category":"B",
     "school_type":"SHTS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070609",
     "school_name":"Tongor Senior High Tech.",
     "category":"B",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070609",
     "school_name":"Tongor Senior High Tech.",
     "category":"B",
     "school_type":"SHTS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070609",
     "school_name":"Tongor Senior High Tech.",
     "category":"B",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070609",
     "school_name":"Tongor Senior High Tech.",
     "category":"B",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070609",
     "school_name":"Tongor Senior High Tech.",
     "category":"B",
     "school_type":"SHTS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060703",
     "school_name":"Tuobodom Senior High\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060703",
     "school_name":"Tuobodom Senior High\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060703",
     "school_name":"Tuobodom Senior High\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060703",
     "school_name":"Tuobodom Senior High\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060703",
     "school_name":"Tuobodom Senior High\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060703",
     "school_name":"Tuobodom Senior High\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060101",
     "school_name":"Twene Amanfo Senior High\/Tech.",
     "category":"B",
     "school_type":"SHTS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0060101",
     "school_name":"Twene Amanfo Senior High\/Tech.",
     "category":"B",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0060101",
     "school_name":"Twene Amanfo Senior High\/Tech.",
     "category":"B",
     "school_type":"SHTS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0060101",
     "school_name":"Twene Amanfo Senior High\/Tech.",
     "category":"B",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0060101",
     "school_name":"Twene Amanfo Senior High\/Tech.",
     "category":"B",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0060101",
     "school_name":"Twene Amanfo Senior High\/Tech.",
     "category":"B",
     "school_type":"SHTS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0051802",
     "school_name":"Tweneboa Kodua Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051802",
     "school_name":"Tweneboa Kodua Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051802",
     "school_name":"Tweneboa Kodua Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051802",
     "school_name":"Tweneboa Kodua Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051802",
     "school_name":"Tweneboa Kodua Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051802",
     "school_name":"Tweneboa Kodua Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0031104",
     "school_name":"Twifo Hemang Senior High\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0031104",
     "school_name":"Twifo Hemang Senior High\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0031104",
     "school_name":"Twifo Hemang Senior High\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0031104",
     "school_name":"Twifo Hemang Senior High\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0031101",
     "school_name":"Twifo Praso Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0031101",
     "school_name":"Twifo Praso Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0031101",
     "school_name":"Twifo Praso Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0031101",
     "school_name":"Twifo Praso Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0031101",
     "school_name":"Twifo Praso Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0031101",
     "school_name":"Twifo Praso Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030105",
     "school_name":"University Practice Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030105",
     "school_name":"University Practice Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030105",
     "school_name":"University Practice Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030105",
     "school_name":"University Practice Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030105",
     "school_name":"University Practice Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030105",
     "school_name":"University Practice Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070605",
     "school_name":"Vakpo Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070605",
     "school_name":"Vakpo Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070605",
     "school_name":"Vakpo Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070605",
     "school_name":"Vakpo Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070605",
     "school_name":"Vakpo Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070605",
     "school_name":"Vakpo Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021301",
     "school_name":"W.B.M. Zion Senior High, Old Tafo",
     "category":"B",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021301",
     "school_name":"W.B.M. Zion Senior High, Old Tafo",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021301",
     "school_name":"W.B.M. Zion Senior High, Old Tafo",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021301",
     "school_name":"W.B.M. Zion Senior High, Old Tafo",
     "category":"B",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021301",
     "school_name":"W.B.M. Zion Senior High, Old Tafo",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021301",
     "school_name":"W.B.M. Zion Senior High, Old Tafo",
     "category":"B",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0100102",
     "school_name":"Wa Senior High .",
     "category":"B",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0100102",
     "school_name":"Wa Senior High .",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0100102",
     "school_name":"Wa Senior High .",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0100102",
     "school_name":"Wa Senior High .",
     "category":"B",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0100102",
     "school_name":"Wa Senior High .",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0100102",
     "school_name":"Wa Senior High .",
     "category":"B",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0061002",
     "school_name":"Wamanafo Senior High\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0061002",
     "school_name":"Wamanafo Senior High\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0061002",
     "school_name":"Wamanafo Senior High\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0061002",
     "school_name":"Wamanafo Senior High\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060601",
     "school_name":"Wenchi Meth. Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060601",
     "school_name":"Wenchi Meth. Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060601",
     "school_name":"Wenchi Meth. Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060601",
     "school_name":"Wenchi Meth. Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060601",
     "school_name":"Wenchi Meth. Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060601",
     "school_name":"Wenchi Meth. Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0010103",
     "school_name":"Wesley Grammar Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0010103",
     "school_name":"Wesley Grammar Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0010103",
     "school_name":"Wesley Grammar Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0010103",
     "school_name":"Wesley Grammar Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0010103",
     "school_name":"Wesley Grammar Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0010103",
     "school_name":"Wesley Grammar Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051703",
     "school_name":"Wesley Senior High, Konongo .",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051703",
     "school_name":"Wesley Senior High, Konongo .",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051703",
     "school_name":"Wesley Senior High, Konongo .",
     "category":"B",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051703",
     "school_name":"Wesley Senior High, Konongo .",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051703",
     "school_name":"Wesley Senior High, Konongo .",
     "category":"B",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0010502",
     "school_name":"West Africa Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0010502",
     "school_name":"West Africa Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0010502",
     "school_name":"West Africa Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0010502",
     "school_name":"West Africa Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0010502",
     "school_name":"West Africa Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0010502",
     "school_name":"West Africa Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0030601",
     "school_name":"Winneba Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030601",
     "school_name":"Winneba Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030601",
     "school_name":"Winneba Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030601",
     "school_name":"Winneba Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030601",
     "school_name":"Winneba Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030601",
     "school_name":"Winneba Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060208",
     "school_name":"Yamfo Anglican Senior High School",
     "category":"B",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060208",
     "school_name":"Yamfo Anglican Senior High School",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060208",
     "school_name":"Yamfo Anglican Senior High School",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060208",
     "school_name":"Yamfo Anglican Senior High School",
     "category":"B",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060208",
     "school_name":"Yamfo Anglican Senior High School",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060208",
     "school_name":"Yamfo Anglican Senior High School",
     "category":"B",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060709",
     "school_name":"Yeboah Asuamah Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060709",
     "school_name":"Yeboah Asuamah Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060709",
     "school_name":"Yeboah Asuamah Senior High",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060401",
     "school_name":"Yeji Senior High\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060401",
     "school_name":"Yeji Senior High\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060401",
     "school_name":"Yeji Senior High\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060401",
     "school_name":"Yeji Senior High\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060401",
     "school_name":"Yeji Senior High\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0080801",
     "school_name":"Yendi Senior High .",
     "category":"B",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0080801",
     "school_name":"Yendi Senior High .",
     "category":"B",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0080801",
     "school_name":"Yendi Senior High .",
     "category":"B",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0080801",
     "school_name":"Yendi Senior High .",
     "category":"B",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0080801",
     "school_name":"Yendi Senior High .",
     "category":"B",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0090201",
     "school_name":"Zebilla Senior High\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0090201",
     "school_name":"Zebilla Senior High\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0090201",
     "school_name":"Zebilla Senior High\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0090201",
     "school_name":"Zebilla Senior High\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0090201",
     "school_name":"Zebilla Senior High\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0090201",
     "school_name":"Zebilla Senior High\/Tech",
     "category":"B",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030403",
     "school_name":"Abakrampa Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030403",
     "school_name":"Abakrampa Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030403",
     "school_name":"Abakrampa Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030403",
     "school_name":"Abakrampa Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030403",
     "school_name":"Abakrampa Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030403",
     "school_name":"Abakrampa Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030403",
     "school_name":"Abakrampa Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030308",
     "school_name":"Abeadze State College",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030308",
     "school_name":"Abeadze State College",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030308",
     "school_name":"Abeadze State College",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030308",
     "school_name":"Abeadze State College",
     "category":"C",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060406",
     "school_name":"Abeaseman Comm. Day Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0060406",
     "school_name":"Abeaseman Comm. Day Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0060406",
     "school_name":"Abeaseman Comm. Day Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0060406",
     "school_name":"Abeaseman Comm. Day Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0060406",
     "school_name":"Abeaseman Comm. Day Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0060406",
     "school_name":"Abeaseman Comm. Day Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9021002",
     "school_name":"Abetifi Vocational Training Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"AGRICULTURAL MECHANIZATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9021002",
     "school_name":"Abetifi Vocational Training Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"ARCHITECTURAL DRAFTING",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9021002",
     "school_name":"Abetifi Vocational Training Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"BUILDING CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9021002",
     "school_name":"Abetifi Vocational Training Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"ELECTRICAL ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9021002",
     "school_name":"Abetifi Vocational Training Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"FASHION DESIGNING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9021002",
     "school_name":"Abetifi Vocational Training Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"HOSPITALITY & CATERING MANAGEMENT",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9021002",
     "school_name":"Abetifi Vocational Training Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"PLUMBING & GAS FITTING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9021002",
     "school_name":"Abetifi Vocational Training Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"WELDING AND FABRICATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9021002",
     "school_name":"Abetifi Vocational Training Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"WOOD CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9021602",
     "school_name":"Abiriw ICCES",
     "category":"C",
     "school_type":"TVET",
     "program":"COSMETOLOGY",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9021602",
     "school_name":"Abiriw ICCES",
     "category":"C",
     "school_type":"TVET",
     "program":"ELECTRICAL ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9021602",
     "school_name":"Abiriw ICCES",
     "category":"C",
     "school_type":"TVET",
     "program":"FASHION DESIGNING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9021602",
     "school_name":"Abiriw ICCES",
     "category":"C",
     "school_type":"TVET",
     "program":"HOSPITALITY & CATERING MANAGEMENT",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0070503",
     "school_name":"Abor Senior High",
     "category":"C",
     "school_type":"SHTS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070503",
     "school_name":"Abor Senior High",
     "category":"C",
     "school_type":"SHTS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070503",
     "school_name":"Abor Senior High",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070503",
     "school_name":"Abor Senior High",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070503",
     "school_name":"Abor Senior High",
     "category":"C",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070503",
     "school_name":"Abor Senior High",
     "category":"C",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070503",
     "school_name":"Abor Senior High",
     "category":"C",
     "school_type":"SHTS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060715",
     "school_name":"Abrafi Senior High ",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0060715",
     "school_name":"Abrafi Senior High ",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0060715",
     "school_name":"Abrafi Senior High ",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9040800",
     "school_name":"Abura YLSTI",
     "category":"C",
     "school_type":"TVET",
     "program":"BUILDING CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9040800",
     "school_name":"Abura YLSTI",
     "category":"C",
     "school_type":"TVET",
     "program":"ELECTRICAL ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9040800",
     "school_name":"Abura YLSTI",
     "category":"C",
     "school_type":"TVET",
     "program":"FASHION DESIGNING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9040800",
     "school_name":"Abura YLSTI",
     "category":"C",
     "school_type":"TVET",
     "program":"HOSPITALITY & CATERING MANAGEMENT",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9040800",
     "school_name":"Abura YLSTI",
     "category":"C",
     "school_type":"TVET",
     "program":"INFORMATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9040800",
     "school_name":"Abura YLSTI",
     "category":"C",
     "school_type":"TVET",
     "program":"WOOD CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030402",
     "school_name":"Aburaman Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030402",
     "school_name":"Aburaman Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030402",
     "school_name":"Aburaman Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030402",
     "school_name":"Aburaman Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030402",
     "school_name":"Aburaman Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030402",
     "school_name":"Aburaman Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0010148",
     "school_name":"Accra Wesley Girls High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Girls",
     "residence":"Day"
  },
  {
     "school_code":"0010148",
     "school_name":"Accra Wesley Girls High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Girls",
     "residence":"Day"
  },
  {
     "school_code":"0010148",
     "school_name":"Accra Wesley Girls High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Girls",
     "residence":"Day"
  },
  {
     "school_code":"0010148",
     "school_name":"Accra Wesley Girls High",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Girls",
     "residence":"Day"
  },
  {
     "school_code":"0010148",
     "school_name":"Accra Wesley Girls High",
     "category":"C",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Girls",
     "residence":"Day"
  },
  {
     "school_code":"0051606",
     "school_name":"Achinakrom Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051606",
     "school_name":"Achinakrom Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051606",
     "school_name":"Achinakrom Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051606",
     "school_name":"Achinakrom Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051606",
     "school_name":"Achinakrom Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051606",
     "school_name":"Achinakrom Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0010302",
     "school_name":"Ada Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0010302",
     "school_name":"Ada Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0010302",
     "school_name":"Ada Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0010302",
     "school_name":"Ada Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0010302",
     "school_name":"Ada Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0010302",
     "school_name":"Ada Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0010302",
     "school_name":"Ada Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070108",
     "school_name":"Adaklu Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070108",
     "school_name":"Adaklu Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070108",
     "school_name":"Adaklu Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070108",
     "school_name":"Adaklu Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070108",
     "school_name":"Adaklu Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070108",
     "school_name":"Adaklu Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050703",
     "school_name":"Adanwomase Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050703",
     "school_name":"Adanwomase Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050703",
     "school_name":"Adanwomase Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050703",
     "school_name":"Adanwomase Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050703",
     "school_name":"Adanwomase Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020503",
     "school_name":"Adeiso Presby Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020503",
     "school_name":"Adeiso Presby Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020503",
     "school_name":"Adeiso Presby Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020503",
     "school_name":"Adeiso Presby Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020503",
     "school_name":"Adeiso Presby Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020503",
     "school_name":"Adeiso Presby Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9072300",
     "school_name":"Adidome Farm Institute ",
     "category":"C",
     "school_type":"TVET",
     "program":"AGRICULTURAL MECHANIZATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0070801",
     "school_name":"Adidome Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070801",
     "school_name":"Adidome Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070801",
     "school_name":"Adidome Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070801",
     "school_name":"Adidome Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070801",
     "school_name":"Adidome Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070801",
     "school_name":"Adidome Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0010186",
     "school_name":"Adjen Kotoku Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0010186",
     "school_name":"Adjen Kotoku Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0010186",
     "school_name":"Adjen Kotoku Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0010186",
     "school_name":"Adjen Kotoku Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0020802",
     "school_name":"Adjena Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020802",
     "school_name":"Adjena Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020802",
     "school_name":"Adjena Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020802",
     "school_name":"Adjena Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020802",
     "school_name":"Adjena Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020802",
     "school_name":"Adjena Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040804",
     "school_name":"Adjoafua Comm. Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0040804",
     "school_name":"Adjoafua Comm. Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0040804",
     "school_name":"Adjoafua Comm. Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0040804",
     "school_name":"Adjoafua Comm. Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0040804",
     "school_name":"Adjoafua Comm. Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0050211",
     "school_name":"Adobewora Comm. Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0050211",
     "school_name":"Adobewora Comm. Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0050211",
     "school_name":"Adobewora Comm. Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0050211",
     "school_name":"Adobewora Comm. Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0050211",
     "school_name":"Adobewora Comm. Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0050211",
     "school_name":"Adobewora Comm. Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0050605",
     "school_name":"Adu Gyamfi Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050605",
     "school_name":"Adu Gyamfi Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050605",
     "school_name":"Adu Gyamfi Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050605",
     "school_name":"Adu Gyamfi Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050605",
     "school_name":"Adu Gyamfi Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051502",
     "school_name":"Adugyama Comm. Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0051502",
     "school_name":"Adugyama Comm. Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0051502",
     "school_name":"Adugyama Comm. Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0051502",
     "school_name":"Adugyama Comm. Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0051502",
     "school_name":"Adugyama Comm. Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0051502",
     "school_name":"Adugyama Comm. Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0050707",
     "school_name":"Adventist Girls Senior High, Ntonso",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050707",
     "school_name":"Adventist Girls Senior High, Ntonso",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050707",
     "school_name":"Adventist Girls Senior High, Ntonso",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050707",
     "school_name":"Adventist Girls Senior High, Ntonso",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050707",
     "school_name":"Adventist Girls Senior High, Ntonso",
     "category":"C",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0071007",
     "school_name":"Afadjato Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0071007",
     "school_name":"Afadjato Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0071007",
     "school_name":"Afadjato Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0071007",
     "school_name":"Afadjato Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0071007",
     "school_name":"Afadjato Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9010602",
     "school_name":"Afienya  YLSTI",
     "category":"C",
     "school_type":"TVET",
     "program":"BUILDING CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9010602",
     "school_name":"Afienya  YLSTI",
     "category":"C",
     "school_type":"TVET",
     "program":"ELECTRICAL ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9010602",
     "school_name":"Afienya  YLSTI",
     "category":"C",
     "school_type":"TVET",
     "program":"FASHION DESIGNING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9010602",
     "school_name":"Afienya  YLSTI",
     "category":"C",
     "school_type":"TVET",
     "program":"HOSPITALITY & CATERING MANAGEMENT",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9010602",
     "school_name":"Afienya  YLSTI",
     "category":"C",
     "school_type":"TVET",
     "program":"PLUMBING & GAS FITTING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9010602",
     "school_name":"Afienya  YLSTI",
     "category":"C",
     "school_type":"TVET",
     "program":"WELDING AND FABRICATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9010602",
     "school_name":"Afienya  YLSTI",
     "category":"C",
     "school_type":"TVET",
     "program":"WOOD CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070709",
     "school_name":"Afife Senior High Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0070709",
     "school_name":"Afife Senior High Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0070709",
     "school_name":"Afife Senior High Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0070709",
     "school_name":"Afife Senior High Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0070709",
     "school_name":"Afife Senior High Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0070709",
     "school_name":"Afife Senior High Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0050609",
     "school_name":"Afigyaman Senior High School",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0050609",
     "school_name":"Afigyaman Senior High School",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0051001",
     "school_name":"Agogo State College",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051001",
     "school_name":"Agogo State College",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051001",
     "school_name":"Agogo State College",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051001",
     "school_name":"Agogo State College",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051001",
     "school_name":"Agogo State College",
     "category":"C",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9010702",
     "school_name":"Agomeda ICCES",
     "category":"C",
     "school_type":"TVET",
     "program":"BUILDING CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9010702",
     "school_name":"Agomeda ICCES",
     "category":"C",
     "school_type":"TVET",
     "program":"COSMETOLOGY",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9010702",
     "school_name":"Agomeda ICCES",
     "category":"C",
     "school_type":"TVET",
     "program":"ELECTRICAL ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9010702",
     "school_name":"Agomeda ICCES",
     "category":"C",
     "school_type":"TVET",
     "program":"ELECTRONICS ENGINEERING",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9010702",
     "school_name":"Agomeda ICCES",
     "category":"C",
     "school_type":"TVET",
     "program":"FASHION DESIGNING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9010702",
     "school_name":"Agomeda ICCES",
     "category":"C",
     "school_type":"TVET",
     "program":"HOSPITALITY & CATERING MANAGEMENT",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9010702",
     "school_name":"Agomeda ICCES",
     "category":"C",
     "school_type":"TVET",
     "program":"WOOD CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9052701",
     "school_name":"Agona CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"BUILDING CONSTRUCTION TECHNOLOGY (CBT OPTION)",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9052701",
     "school_name":"Agona CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"COSMETOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9052701",
     "school_name":"Agona CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"ELECTRICAL ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9052701",
     "school_name":"Agona CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"FASHION DESIGNING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9052701",
     "school_name":"Agona CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"HOSPITALITY & CATERING MANAGEMENT",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9052701",
     "school_name":"Agona CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"MOTOR VEHICLE ENGINEERING",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030915",
     "school_name":"Agona Fankobaa Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0030915",
     "school_name":"Agona Fankobaa Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0030915",
     "school_name":"Agona Fankobaa Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0030915",
     "school_name":"Agona Fankobaa Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0030915",
     "school_name":"Agona Fankobaa Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0030915",
     "school_name":"Agona Fankobaa Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0030909",
     "school_name":"Agona Namonwora Comm.Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0030909",
     "school_name":"Agona Namonwora Comm.Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0030909",
     "school_name":"Agona Namonwora Comm.Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0030909",
     "school_name":"Agona Namonwora Comm.Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0030909",
     "school_name":"Agona Namonwora Comm.Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0070113",
     "school_name":"Agotime Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070113",
     "school_name":"Agotime Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070113",
     "school_name":"Agotime Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070113",
     "school_name":"Agotime Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070113",
     "school_name":"Agotime Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070113",
     "school_name":"Agotime Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050193",
     "school_name":"Agric Nzema Senior High, Kumasi",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0050193",
     "school_name":"Agric Nzema Senior High, Kumasi",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0050193",
     "school_name":"Agric Nzema Senior High, Kumasi",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0050193",
     "school_name":"Agric Nzema Senior High, Kumasi",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0050193",
     "school_name":"Agric Nzema Senior High, Kumasi",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0050193",
     "school_name":"Agric Nzema Senior High, Kumasi",
     "category":"C",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0071203",
     "school_name":"Ahamansu Islamic Senior High School",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0071203",
     "school_name":"Ahamansu Islamic Senior High School",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0071203",
     "school_name":"Ahamansu Islamic Senior High School",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0071203",
     "school_name":"Ahamansu Islamic Senior High School",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0071203",
     "school_name":"Ahamansu Islamic Senior High School",
     "category":"C",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9030802",
     "school_name":"Ajumako Afranse YLSTI",
     "category":"C",
     "school_type":"TVET",
     "program":"AGRICULTURAL MECHANIZATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9030802",
     "school_name":"Ajumako Afranse YLSTI",
     "category":"C",
     "school_type":"TVET",
     "program":"BUILDING CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9030802",
     "school_name":"Ajumako Afranse YLSTI",
     "category":"C",
     "school_type":"TVET",
     "program":"ELECTRICAL ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9030802",
     "school_name":"Ajumako Afranse YLSTI",
     "category":"C",
     "school_type":"TVET",
     "program":"FASHION DESIGNING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9030802",
     "school_name":"Ajumako Afranse YLSTI",
     "category":"C",
     "school_type":"TVET",
     "program":"HOSPITALITY & CATERING MANAGEMENT",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9030802",
     "school_name":"Ajumako Afranse YLSTI",
     "category":"C",
     "school_type":"TVET",
     "program":"WOOD CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070401",
     "school_name":"Akatsi Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070401",
     "school_name":"Akatsi Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070401",
     "school_name":"Akatsi Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070401",
     "school_name":"Akatsi Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070401",
     "school_name":"Akatsi Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070401",
     "school_name":"Akatsi Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070401",
     "school_name":"Akatsi Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021306",
     "school_name":"Akim Asafo Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021306",
     "school_name":"Akim Asafo Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021306",
     "school_name":"Akim Asafo Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021306",
     "school_name":"Akim Asafo Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021306",
     "school_name":"Akim Asafo Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021306",
     "school_name":"Akim Asafo Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020602",
     "school_name":"Akim Swedru Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020602",
     "school_name":"Akim Swedru Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020602",
     "school_name":"Akim Swedru Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020602",
     "school_name":"Akim Swedru Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020602",
     "school_name":"Akim Swedru Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020602",
     "school_name":"Akim Swedru Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020701",
     "school_name":"Akokoaso Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020701",
     "school_name":"Akokoaso Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020701",
     "school_name":"Akokoaso Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020701",
     "school_name":"Akokoaso Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020701",
     "school_name":"Akokoaso Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070111",
     "school_name":"Akome Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070111",
     "school_name":"Akome Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070111",
     "school_name":"Akome Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070111",
     "school_name":"Akome Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070111",
     "school_name":"Akome Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070111",
     "school_name":"Akome Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070111",
     "school_name":"Akome Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0071002",
     "school_name":"Akpafu Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0071002",
     "school_name":"Akpafu Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0071002",
     "school_name":"Akpafu Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0071002",
     "school_name":"Akpafu Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0071002",
     "school_name":"Akpafu Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0071002",
     "school_name":"Akpafu Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0071002",
     "school_name":"Akpafu Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0010195",
     "school_name":"Akramaman Senior High ",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0010195",
     "school_name":"Akramaman Senior High ",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0010195",
     "school_name":"Akramaman Senior High ",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0010195",
     "school_name":"Akramaman Senior High ",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0010195",
     "school_name":"Akramaman Senior High ",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0010195",
     "school_name":"Akramaman Senior High ",
     "category":"C",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0021505",
     "school_name":"Akro Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021505",
     "school_name":"Akro Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021505",
     "school_name":"Akro Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021505",
     "school_name":"Akro Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021505",
     "school_name":"Akro Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021505",
     "school_name":"Akro Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9022200",
     "school_name":"Akrofufu ICCES",
     "category":"C",
     "school_type":"TVET",
     "program":"AGRICULTURAL MECHANIZATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9022200",
     "school_name":"Akrofufu ICCES",
     "category":"C",
     "school_type":"TVET",
     "program":"BUILDING CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9022200",
     "school_name":"Akrofufu ICCES",
     "category":"C",
     "school_type":"TVET",
     "program":"ELECTRICAL ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9022200",
     "school_name":"Akrofufu ICCES",
     "category":"C",
     "school_type":"TVET",
     "program":"FASHION DESIGNING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9022200",
     "school_name":"Akrofufu ICCES",
     "category":"C",
     "school_type":"TVET",
     "program":"HOSPITALITY & CATERING MANAGEMENT",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0020607",
     "school_name":"Akroso Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020607",
     "school_name":"Akroso Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020607",
     "school_name":"Akroso Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020607",
     "school_name":"Akroso Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020607",
     "school_name":"Akroso Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020607",
     "school_name":"Akroso Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020607",
     "school_name":"Akroso Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050802",
     "school_name":"Akumadan Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050802",
     "school_name":"Akumadan Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050802",
     "school_name":"Akumadan Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050802",
     "school_name":"Akumadan Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050802",
     "school_name":"Akumadan Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021504",
     "school_name":"Akuse Methodist Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021504",
     "school_name":"Akuse Methodist Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021504",
     "school_name":"Akuse Methodist Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021504",
     "school_name":"Akuse Methodist Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021504",
     "school_name":"Akuse Methodist Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021504",
     "school_name":"Akuse Methodist Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021504",
     "school_name":"Akuse Methodist Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050907",
     "school_name":"Akwesi Awobaa Senior High",
     "category":"C",
     "school_type":"SHTS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0050907",
     "school_name":"Akwesi Awobaa Senior High",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0050907",
     "school_name":"Akwesi Awobaa Senior High",
     "category":"C",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0030312",
     "school_name":"Akyin Senior High School",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0030312",
     "school_name":"Akyin Senior High School",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0030312",
     "school_name":"Akyin Senior High School",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0030312",
     "school_name":"Akyin Senior High School",
     "category":"C",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0071008",
     "school_name":"Alavanyo Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0071008",
     "school_name":"Alavanyo Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0071008",
     "school_name":"Alavanyo Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0071008",
     "school_name":"Alavanyo Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0071008",
     "school_name":"Alavanyo Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0071008",
     "school_name":"Alavanyo Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050503",
     "school_name":"Amaniampong Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050503",
     "school_name":"Amaniampong Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050503",
     "school_name":"Amaniampong Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050503",
     "school_name":"Amaniampong Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050503",
     "school_name":"Amaniampong Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050503",
     "school_name":"Amaniampong Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9051701",
     "school_name":"Amansie Technical Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"BUILDING CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9051701",
     "school_name":"Amansie Technical Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"ELECTRICAL ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9051701",
     "school_name":"Amansie Technical Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"HOSPITALITY & CATERING MANAGEMENT",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9051701",
     "school_name":"Amansie Technical Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"PLUMBING & GAS FITTING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9051701",
     "school_name":"Amansie Technical Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"WELDING AND FABRICATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9051701",
     "school_name":"Amansie Technical Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"WOOD CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0010504",
     "school_name":"Amasaman Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0010504",
     "school_name":"Amasaman Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0010504",
     "school_name":"Amasaman Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0010504",
     "school_name":"Amasaman Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0010504",
     "school_name":"Amasaman Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0010504",
     "school_name":"Amasaman Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9070101",
     "school_name":"Amedzofe Technical Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"AGRICULTURAL MECHANIZATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9070101",
     "school_name":"Amedzofe Technical Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"BUILDING CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9070101",
     "school_name":"Amedzofe Technical Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"BUSINESS ACCOUNTING",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9070101",
     "school_name":"Amedzofe Technical Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"ELECTRICAL ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9070101",
     "school_name":"Amedzofe Technical Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"FASHION DESIGNING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9070101",
     "school_name":"Amedzofe Technical Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"MOTOR VEHICLE ENGINEERING",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9070101",
     "school_name":"Amedzofe Technical Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"WOOD CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9050403",
     "school_name":"Amoafo  Vocational \/ Technical Institute – Bekwai",
     "category":"C",
     "school_type":"TVET",
     "program":"AGRICULTURAL MECHANIZATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9050403",
     "school_name":"Amoafo  Vocational \/ Technical Institute – Bekwai",
     "category":"C",
     "school_type":"TVET",
     "program":"BUILDING CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9050403",
     "school_name":"Amoafo  Vocational \/ Technical Institute – Bekwai",
     "category":"C",
     "school_type":"TVET",
     "program":"COMPUTER HARDWARE TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9050403",
     "school_name":"Amoafo  Vocational \/ Technical Institute – Bekwai",
     "category":"C",
     "school_type":"TVET",
     "program":"COSMETOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9050403",
     "school_name":"Amoafo  Vocational \/ Technical Institute – Bekwai",
     "category":"C",
     "school_type":"TVET",
     "program":"ELECTRICAL ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9050403",
     "school_name":"Amoafo  Vocational \/ Technical Institute – Bekwai",
     "category":"C",
     "school_type":"TVET",
     "program":"FASHION DESIGNING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9050403",
     "school_name":"Amoafo  Vocational \/ Technical Institute – Bekwai",
     "category":"C",
     "school_type":"TVET",
     "program":"HOSPITALITY & CATERING MANAGEMENT",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9050403",
     "school_name":"Amoafo  Vocational \/ Technical Institute – Bekwai",
     "category":"C",
     "school_type":"TVET",
     "program":"MOTOR VEHICLE ENGINEERING",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9050403",
     "school_name":"Amoafo  Vocational \/ Technical Institute – Bekwai",
     "category":"C",
     "school_type":"TVET",
     "program":"PLUMBING & GAS FITTING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9050403",
     "school_name":"Amoafo  Vocational \/ Technical Institute – Bekwai",
     "category":"C",
     "school_type":"TVET",
     "program":"WELDING AND FABRICATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9050403",
     "school_name":"Amoafo  Vocational \/ Technical Institute – Bekwai",
     "category":"C",
     "school_type":"TVET",
     "program":"WOOD CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020705",
     "school_name":"Amoana Praso Senior High ",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0020705",
     "school_name":"Amoana Praso Senior High ",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0020705",
     "school_name":"Amoana Praso Senior High ",
     "category":"C",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9050600",
     "school_name":"Amoawi ICCES",
     "category":"C",
     "school_type":"TVET",
     "program":"BUILDING CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9050600",
     "school_name":"Amoawi ICCES",
     "category":"C",
     "school_type":"TVET",
     "program":"ELECTRICAL ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9050600",
     "school_name":"Amoawi ICCES",
     "category":"C",
     "school_type":"TVET",
     "program":"FASHION DESIGNING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9050600",
     "school_name":"Amoawi ICCES",
     "category":"C",
     "school_type":"TVET",
     "program":"HOSPITALITY & CATERING MANAGEMENT",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9050600",
     "school_name":"Amoawi ICCES",
     "category":"C",
     "school_type":"TVET",
     "program":"WOOD CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0080117",
     "school_name":"Anbariya Senior High Sch.",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0080117",
     "school_name":"Anbariya Senior High Sch.",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0080117",
     "school_name":"Anbariya Senior High Sch.",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0080117",
     "school_name":"Anbariya Senior High Sch.",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0080117",
     "school_name":"Anbariya Senior High Sch.",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070604",
     "school_name":"Anfoega Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070604",
     "school_name":"Anfoega Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070604",
     "school_name":"Anfoega Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070604",
     "school_name":"Anfoega Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070604",
     "school_name":"Anfoega Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070604",
     "school_name":"Anfoega Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070507",
     "school_name":"Anlo Afiadenyigba Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070507",
     "school_name":"Anlo Afiadenyigba Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070507",
     "school_name":"Anlo Afiadenyigba Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070507",
     "school_name":"Anlo Afiadenyigba Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070507",
     "school_name":"Anlo Afiadenyigba Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070507",
     "school_name":"Anlo Afiadenyigba Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070508",
     "school_name":"Anlo Awomefia Senior High",
     "category":"C",
     "school_type":"SHTS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070508",
     "school_name":"Anlo Awomefia Senior High",
     "category":"C",
     "school_type":"SHTS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070508",
     "school_name":"Anlo Awomefia Senior High",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070508",
     "school_name":"Anlo Awomefia Senior High",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070508",
     "school_name":"Anlo Awomefia Senior High",
     "category":"C",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070508",
     "school_name":"Anlo Awomefia Senior High",
     "category":"C",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070508",
     "school_name":"Anlo Awomefia Senior High",
     "category":"C",
     "school_type":"SHTS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040402",
     "school_name":"Annor Adjaye Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040402",
     "school_name":"Annor Adjaye Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040402",
     "school_name":"Annor Adjaye Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040402",
     "school_name":"Annor Adjaye Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040402",
     "school_name":"Annor Adjaye Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040402",
     "school_name":"Annor Adjaye Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9022101",
     "school_name":"Ansong Technical Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"BUSINESS ACCOUNTING",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9022101",
     "school_name":"Ansong Technical Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"FASHION DESIGNING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9022101",
     "school_name":"Ansong Technical Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"HOSPITALITY & CATERING MANAGEMENT",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9022101",
     "school_name":"Ansong Technical Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"WOOD CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0050701",
     "school_name":"Antoa Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050701",
     "school_name":"Antoa Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050701",
     "school_name":"Antoa Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050701",
     "school_name":"Antoa Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050701",
     "school_name":"Antoa Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050701",
     "school_name":"Antoa Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020808",
     "school_name":"Anum Apapam Comm. Day",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0020808",
     "school_name":"Anum Apapam Comm. Day",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0020808",
     "school_name":"Anum Apapam Comm. Day",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0020808",
     "school_name":"Anum Apapam Comm. Day",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0050213",
     "school_name":"Anum Asamoah Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0050213",
     "school_name":"Anum Asamoah Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0050213",
     "school_name":"Anum Asamoah Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0050213",
     "school_name":"Anum Asamoah Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0020801",
     "school_name":"Anum Presby Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020801",
     "school_name":"Anum Presby Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020801",
     "school_name":"Anum Presby Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020801",
     "school_name":"Anum Presby Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020801",
     "school_name":"Anum Presby Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020801",
     "school_name":"Anum Presby Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9020800",
     "school_name":"Anum Presbyterian Vocational Training Institute ",
     "category":"C",
     "school_type":"TVET",
     "program":"BUILDING CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9020800",
     "school_name":"Anum Presbyterian Vocational Training Institute ",
     "category":"C",
     "school_type":"TVET",
     "program":"BUSINESS SECRETARIAL",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9020800",
     "school_name":"Anum Presbyterian Vocational Training Institute ",
     "category":"C",
     "school_type":"TVET",
     "program":"ELECTRICAL ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9020800",
     "school_name":"Anum Presbyterian Vocational Training Institute ",
     "category":"C",
     "school_type":"TVET",
     "program":"FASHION DESIGNING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9020800",
     "school_name":"Anum Presbyterian Vocational Training Institute ",
     "category":"C",
     "school_type":"TVET",
     "program":"HOSPITALITY & CATERING MANAGEMENT",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021314",
     "school_name":"Apedwa Presby Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0021314",
     "school_name":"Apedwa Presby Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0020805",
     "school_name":"Apeguso Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020805",
     "school_name":"Apeguso Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020805",
     "school_name":"Apeguso Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020805",
     "school_name":"Apeguso Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020805",
     "school_name":"Apeguso Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020805",
     "school_name":"Apeguso Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020611",
     "school_name":"Aperade Senior  High\/Tech.",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0020611",
     "school_name":"Aperade Senior  High\/Tech.",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0020611",
     "school_name":"Aperade Senior  High\/Tech.",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9051300",
     "school_name":"Asankare YLSTI",
     "category":"C",
     "school_type":"TVET",
     "program":"AGRICULTURAL MECHANIZATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9051300",
     "school_name":"Asankare YLSTI",
     "category":"C",
     "school_type":"TVET",
     "program":"BUILDING CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9051300",
     "school_name":"Asankare YLSTI",
     "category":"C",
     "school_type":"TVET",
     "program":"ELECTRONICS ENGINEERING",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9051300",
     "school_name":"Asankare YLSTI",
     "category":"C",
     "school_type":"TVET",
     "program":"FASHION DESIGNING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9051300",
     "school_name":"Asankare YLSTI",
     "category":"C",
     "school_type":"TVET",
     "program":"HOSPITALITY & CATERING MANAGEMENT",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9051300",
     "school_name":"Asankare YLSTI",
     "category":"C",
     "school_type":"TVET",
     "program":"PLUMBING & GAS FITTING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9051300",
     "school_name":"Asankare YLSTI",
     "category":"C",
     "school_type":"TVET",
     "program":"WOOD CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040501",
     "school_name":"Asankrangwa Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040501",
     "school_name":"Asankrangwa Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040501",
     "school_name":"Asankrangwa Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040501",
     "school_name":"Asankrangwa Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040501",
     "school_name":"Asankrangwa Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040501",
     "school_name":"Asankrangwa Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040503",
     "school_name":"Asankrangwa Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040503",
     "school_name":"Asankrangwa Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040503",
     "school_name":"Asankrangwa Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040503",
     "school_name":"Asankrangwa Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040503",
     "school_name":"Asankrangwa Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040503",
     "school_name":"Asankrangwa Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021503",
     "school_name":"Asesewa Senior High School",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021503",
     "school_name":"Asesewa Senior High School",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021503",
     "school_name":"Asesewa Senior High School",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021503",
     "school_name":"Asesewa Senior High School",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021503",
     "school_name":"Asesewa Senior High School",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0010203",
     "school_name":"Ashiaman Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0010203",
     "school_name":"Ashiaman Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0010203",
     "school_name":"Ashiaman Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0010203",
     "school_name":"Ashiaman Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0010203",
     "school_name":"Ashiaman Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0010203",
     "school_name":"Ashiaman Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9011001",
     "school_name":"Ashiaman Tech\/Voc. Inst.",
     "category":"C",
     "school_type":"TVET",
     "program":"BUILDING CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9011001",
     "school_name":"Ashiaman Tech\/Voc. Inst.",
     "category":"C",
     "school_type":"TVET",
     "program":"BUSINESS ACCOUNTING",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9011001",
     "school_name":"Ashiaman Tech\/Voc. Inst.",
     "category":"C",
     "school_type":"TVET",
     "program":"BUSINESS INFORMATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9011001",
     "school_name":"Ashiaman Tech\/Voc. Inst.",
     "category":"C",
     "school_type":"TVET",
     "program":"BUSINESS SECRETARIAL",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9011001",
     "school_name":"Ashiaman Tech\/Voc. Inst.",
     "category":"C",
     "school_type":"TVET",
     "program":"CREATIVE ART TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9011001",
     "school_name":"Ashiaman Tech\/Voc. Inst.",
     "category":"C",
     "school_type":"TVET",
     "program":"ELECTRICAL ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9011001",
     "school_name":"Ashiaman Tech\/Voc. Inst.",
     "category":"C",
     "school_type":"TVET",
     "program":"ELECTRONICS ENGINEERING",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9011001",
     "school_name":"Ashiaman Tech\/Voc. Inst.",
     "category":"C",
     "school_type":"TVET",
     "program":"FASHION DESIGNING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9011001",
     "school_name":"Ashiaman Tech\/Voc. Inst.",
     "category":"C",
     "school_type":"TVET",
     "program":"HOSPITALITY & CATERING MANAGEMENT",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9011001",
     "school_name":"Ashiaman Tech\/Voc. Inst.",
     "category":"C",
     "school_type":"TVET",
     "program":"MOTOR VEHICLE ENGINEERING",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9011001",
     "school_name":"Ashiaman Tech\/Voc. Inst.",
     "category":"C",
     "school_type":"TVET",
     "program":"WELDING AND FABRICATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9030701",
     "school_name":"Assin Foso Vocational Training Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"BUILDING CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9030701",
     "school_name":"Assin Foso Vocational Training Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"BUSINESS INFORMATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9030701",
     "school_name":"Assin Foso Vocational Training Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"COMPUTER HARDWARE TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9030701",
     "school_name":"Assin Foso Vocational Training Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"ELECTRICAL ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9030701",
     "school_name":"Assin Foso Vocational Training Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"FASHION DESIGNING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9030701",
     "school_name":"Assin Foso Vocational Training Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"HOSPITALITY & CATERING MANAGEMENT",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9030701",
     "school_name":"Assin Foso Vocational Training Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"MOTOR VEHICLE ENGINEERING",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9030701",
     "school_name":"Assin Foso Vocational Training Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"WOOD CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0031201",
     "school_name":"Assin Manso Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0031201",
     "school_name":"Assin Manso Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0031201",
     "school_name":"Assin Manso Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0031201",
     "school_name":"Assin Manso Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0031201",
     "school_name":"Assin Manso Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0031201",
     "school_name":"Assin Manso Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0031204",
     "school_name":"Assin North Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0031204",
     "school_name":"Assin North Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0031204",
     "school_name":"Assin North Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0031204",
     "school_name":"Assin North Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0031204",
     "school_name":"Assin North Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0031204",
     "school_name":"Assin North Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0031204",
     "school_name":"Assin North Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0031206",
     "school_name":"Assin Nsuta Agric. Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0031206",
     "school_name":"Assin Nsuta Agric. Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0031206",
     "school_name":"Assin Nsuta Agric. Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0031206",
     "school_name":"Assin Nsuta Agric. Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0031206",
     "school_name":"Assin Nsuta Agric. Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0031207",
     "school_name":"Assin State College",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0031207",
     "school_name":"Assin State College",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0031207",
     "school_name":"Assin State College",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0031207",
     "school_name":"Assin State College",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0031207",
     "school_name":"Assin State College",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0031207",
     "school_name":"Assin State College",
     "category":"C",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070204",
     "school_name":"Asukawkaw Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070204",
     "school_name":"Asukawkaw Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070204",
     "school_name":"Asukawkaw Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070204",
     "school_name":"Asukawkaw Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070204",
     "school_name":"Asukawkaw Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021101",
     "school_name":"Asuom Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021101",
     "school_name":"Asuom Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021101",
     "school_name":"Asuom Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021101",
     "school_name":"Asuom Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021101",
     "school_name":"Asuom Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021101",
     "school_name":"Asuom Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050806",
     "school_name":"Asuoso Comm. Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0050806",
     "school_name":"Asuoso Comm. Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0050806",
     "school_name":"Asuoso Comm. Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0050806",
     "school_name":"Asuoso Comm. Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0060402",
     "school_name":"Atebubu Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060402",
     "school_name":"Atebubu Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060402",
     "school_name":"Atebubu Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060402",
     "school_name":"Atebubu Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060402",
     "school_name":"Atebubu Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060402",
     "school_name":"Atebubu Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070506",
     "school_name":"Atiavi Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0070506",
     "school_name":"Atiavi Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0070506",
     "school_name":"Atiavi Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0070506",
     "school_name":"Atiavi Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0070506",
     "school_name":"Atiavi Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0070506",
     "school_name":"Atiavi Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9070500",
     "school_name":"Atorkor Vocational Training Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"BUILDING CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9070500",
     "school_name":"Atorkor Vocational Training Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"BUSINESS INFORMATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9070500",
     "school_name":"Atorkor Vocational Training Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"ELECTRICAL ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9070500",
     "school_name":"Atorkor Vocational Training Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"FASHION DESIGNING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9070500",
     "school_name":"Atorkor Vocational Training Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"GENERAL TEXTILES",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9070500",
     "school_name":"Atorkor Vocational Training Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"HOSPITALITY & CATERING MANAGEMENT",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9070500",
     "school_name":"Atorkor Vocational Training Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"WELDING AND FABRICATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020608",
     "school_name":"Atweaman Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020608",
     "school_name":"Atweaman Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020608",
     "school_name":"Atweaman Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020608",
     "school_name":"Atweaman Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020608",
     "school_name":"Atweaman Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050208",
     "school_name":"Atwima Kwanwoma Snr High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050208",
     "school_name":"Atwima Kwanwoma Snr High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050208",
     "school_name":"Atwima Kwanwoma Snr High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050208",
     "school_name":"Atwima Kwanwoma Snr High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050208",
     "school_name":"Atwima Kwanwoma Snr High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070103",
     "school_name":"Avatime Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070103",
     "school_name":"Avatime Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070103",
     "school_name":"Avatime Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070103",
     "school_name":"Avatime Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070103",
     "school_name":"Avatime Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070402",
     "school_name":"Ave Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070402",
     "school_name":"Ave Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070402",
     "school_name":"Ave Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070402",
     "school_name":"Ave Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070402",
     "school_name":"Ave Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9072401",
     "school_name":"Ave-Afiadenyingba",
     "category":"C",
     "school_type":"TVET",
     "program":"BUILDING CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9072401",
     "school_name":"Ave-Afiadenyingba",
     "category":"C",
     "school_type":"TVET",
     "program":"COSMETOLOGY",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9072401",
     "school_name":"Ave-Afiadenyingba",
     "category":"C",
     "school_type":"TVET",
     "program":"ELECTRICAL ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9072401",
     "school_name":"Ave-Afiadenyingba",
     "category":"C",
     "school_type":"TVET",
     "program":"FASHION DESIGNING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9072401",
     "school_name":"Ave-Afiadenyingba",
     "category":"C",
     "school_type":"TVET",
     "program":"HOSPITALITY & CATERING MANAGEMENT",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9072401",
     "school_name":"Ave-Afiadenyingba",
     "category":"C",
     "school_type":"TVET",
     "program":"MOTOR VEHICLE ENGINEERING",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0070403",
     "school_name":"Avenor Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0070403",
     "school_name":"Avenor Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0070403",
     "school_name":"Avenor Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0070403",
     "school_name":"Avenor Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9072400",
     "school_name":"Avenorpeme YLSTI",
     "category":"C",
     "school_type":"TVET",
     "program":"AGRICULTURAL MECHANIZATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9072400",
     "school_name":"Avenorpeme YLSTI",
     "category":"C",
     "school_type":"TVET",
     "program":"BUILDING CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9072400",
     "school_name":"Avenorpeme YLSTI",
     "category":"C",
     "school_type":"TVET",
     "program":"ELECTRICAL ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9072400",
     "school_name":"Avenorpeme YLSTI",
     "category":"C",
     "school_type":"TVET",
     "program":"FASHION DESIGNING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9072400",
     "school_name":"Avenorpeme YLSTI",
     "category":"C",
     "school_type":"TVET",
     "program":"HOSPITALITY & CATERING MANAGEMENT",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9072400",
     "school_name":"Avenorpeme YLSTI",
     "category":"C",
     "school_type":"TVET",
     "program":"WOOD CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070803",
     "school_name":"Aveyime Battor Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070803",
     "school_name":"Aveyime Battor Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070803",
     "school_name":"Aveyime Battor Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070803",
     "school_name":"Aveyime Battor Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070803",
     "school_name":"Aveyime Battor Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070803",
     "school_name":"Aveyime Battor Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0090504",
     "school_name":"Awe Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0090504",
     "school_name":"Awe Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0090504",
     "school_name":"Awe Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0090504",
     "school_name":"Awe Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0090504",
     "school_name":"Awe Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0090504",
     "school_name":"Awe Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0090504",
     "school_name":"Awe Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030628",
     "school_name":"Awutu Bawjiase Comm. Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030628",
     "school_name":"Awutu Bawjiase Comm. Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030628",
     "school_name":"Awutu Bawjiase Comm. Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030628",
     "school_name":"Awutu Bawjiase Comm. Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030628",
     "school_name":"Awutu Bawjiase Comm. Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030628",
     "school_name":"Awutu Bawjiase Comm. Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9040301",
     "school_name":"Axim CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"BUILDING CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9040301",
     "school_name":"Axim CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"COSMETOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9040301",
     "school_name":"Axim CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"ELECTRICAL ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9040301",
     "school_name":"Axim CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"FASHION DESIGNING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9040301",
     "school_name":"Axim CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"HOSPITALITY & CATERING MANAGEMENT",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9040301",
     "school_name":"Axim CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"MOTOR VEHICLE ENGINEERING",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9040301",
     "school_name":"Axim CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"PLUMBING & GAS FITTING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9040301",
     "school_name":"Axim CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"WELDING AND FABRICATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040305",
     "school_name":"Axim Girls Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040305",
     "school_name":"Axim Girls Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040305",
     "school_name":"Axim Girls Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040305",
     "school_name":"Axim Girls Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040305",
     "school_name":"Axim Girls Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0031006",
     "school_name":"Ayanfuri Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0031006",
     "school_name":"Ayanfuri Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0031006",
     "school_name":"Ayanfuri Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0020703",
     "school_name":"Ayirebi Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020703",
     "school_name":"Ayirebi Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020703",
     "school_name":"Ayirebi Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020703",
     "school_name":"Ayirebi Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020703",
     "school_name":"Ayirebi Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0090604",
     "school_name":"Azeem-Namoa Senior High\/Tech",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0090604",
     "school_name":"Azeem-Namoa Senior High\/Tech",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0040202",
     "school_name":"Baidoo Bonso Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040202",
     "school_name":"Baidoo Bonso Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040202",
     "school_name":"Baidoo Bonso Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040202",
     "school_name":"Baidoo Bonso Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040202",
     "school_name":"Baidoo Bonso Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040202",
     "school_name":"Baidoo Bonso Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040202",
     "school_name":"Baidoo Bonso Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0080704",
     "school_name":"Bamboi Comm. Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0080704",
     "school_name":"Bamboi Comm. Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0080704",
     "school_name":"Bamboi Comm. Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0080704",
     "school_name":"Bamboi Comm. Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0080704",
     "school_name":"Bamboi Comm. Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0060610",
     "school_name":"Bandaman Senior High",
     "category":"C",
     "school_type":"SHTS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060610",
     "school_name":"Bandaman Senior High",
     "category":"C",
     "school_type":"SHTS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060610",
     "school_name":"Bandaman Senior High",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060610",
     "school_name":"Bandaman Senior High",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060610",
     "school_name":"Bandaman Senior High",
     "category":"C",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060610",
     "school_name":"Bandaman Senior High",
     "category":"C",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060610",
     "school_name":"Bandaman Senior High",
     "category":"C",
     "school_type":"SHTS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051104",
     "school_name":"Banka Comm. Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0051104",
     "school_name":"Banka Comm. Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0051104",
     "school_name":"Banka Comm. Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0051104",
     "school_name":"Banka Comm. Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0050207",
     "school_name":"Barekese Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050207",
     "school_name":"Barekese Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050207",
     "school_name":"Barekese Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050207",
     "school_name":"Barekese Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050207",
     "school_name":"Barekese Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050207",
     "school_name":"Barekese Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0061103",
     "school_name":"Bassa Community Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0061103",
     "school_name":"Bassa Community Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0061103",
     "school_name":"Bassa Community Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0061103",
     "school_name":"Bassa Community Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0070806",
     "school_name":"Battor Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070806",
     "school_name":"Battor Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070806",
     "school_name":"Battor Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070806",
     "school_name":"Battor Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070806",
     "school_name":"Battor Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070806",
     "school_name":"Battor Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0090102",
     "school_name":"Bawku Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0090102",
     "school_name":"Bawku Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0090102",
     "school_name":"Bawku Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0090102",
     "school_name":"Bawku Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0090102",
     "school_name":"Bawku Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0090102",
     "school_name":"Bawku Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9090102",
     "school_name":"Bawku Vocational Training Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"BUSINESS SECRETARIAL",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9090102",
     "school_name":"Bawku Vocational Training Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"INFORMATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9050302",
     "school_name":"Baworo ICCES",
     "category":"C",
     "school_type":"TVET",
     "program":"BUILDING CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9060802",
     "school_name":"Bechem CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"BUILDING CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9060802",
     "school_name":"Bechem CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"COSMETOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9060802",
     "school_name":"Bechem CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"ELECTRICAL ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9060802",
     "school_name":"Bechem CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"FASHION DESIGNING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9060802",
     "school_name":"Bechem CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"HOSPITALITY & CATERING MANAGEMENT",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9060802",
     "school_name":"Bechem CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"MOTOR VEHICLE ENGINEERING",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9060802",
     "school_name":"Bechem CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"PLUMBING & GAS FITTING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9060802",
     "school_name":"Bechem CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"WELDING AND FABRICATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9060802",
     "school_name":"Bechem CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"WOOD CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9050401",
     "school_name":"Bekwai CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"BUILDING CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9050401",
     "school_name":"Bekwai CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"COSMETOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9050401",
     "school_name":"Bekwai CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"ELECTRICAL ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9050401",
     "school_name":"Bekwai CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"FASHION DESIGNING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9050401",
     "school_name":"Bekwai CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"HOSPITALITY & CATERING MANAGEMENT",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9050401",
     "school_name":"Bekwai CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"MOTOR VEHICLE ENGINEERING",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040906",
     "school_name":"Benso Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040906",
     "school_name":"Benso Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040906",
     "school_name":"Benso Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040906",
     "school_name":"Benso Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040906",
     "school_name":"Benso Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021009",
     "school_name":"Bepong Senior High School",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021009",
     "school_name":"Bepong Senior High School",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021009",
     "school_name":"Bepong Senior High School",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021009",
     "school_name":"Bepong Senior High School",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021009",
     "school_name":"Bepong Senior High School",
     "category":"C",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0071109",
     "school_name":"Biakoye Comm. School",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0071109",
     "school_name":"Biakoye Comm. School",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0071109",
     "school_name":"Biakoye Comm. School",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0071109",
     "school_name":"Biakoye Comm. School",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0071109",
     "school_name":"Biakoye Comm. School",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0071109",
     "school_name":"Biakoye Comm. School",
     "category":"C",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040701",
     "school_name":"Bibiani Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040701",
     "school_name":"Bibiani Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040701",
     "school_name":"Bibiani Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040701",
     "school_name":"Bibiani Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040701",
     "school_name":"Bibiani Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040701",
     "school_name":"Bibiani Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0080401",
     "school_name":"Bimbilla Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0080401",
     "school_name":"Bimbilla Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0080401",
     "school_name":"Bimbilla Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0080401",
     "school_name":"Bimbilla Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0080401",
     "school_name":"Bimbilla Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0090701",
     "school_name":"Binduri Comm. Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0090701",
     "school_name":"Binduri Comm. Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0090701",
     "school_name":"Binduri Comm. Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0090701",
     "school_name":"Binduri Comm. Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0100206",
     "school_name":"Birifoh Senior High Sch.",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0100206",
     "school_name":"Birifoh Senior High Sch.",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0030702",
     "school_name":"Bisease Senior High\/Com",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030702",
     "school_name":"Bisease Senior High\/Com",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030702",
     "school_name":"Bisease Senior High\/Com",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030702",
     "school_name":"Bisease Senior High\/Com",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051808",
     "school_name":"Bodomase Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0051808",
     "school_name":"Bodomase Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0051808",
     "school_name":"Bodomase Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0051302",
     "school_name":"Bodwesango Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051302",
     "school_name":"Bodwesango Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051302",
     "school_name":"Bodwesango Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051302",
     "school_name":"Bodwesango Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051302",
     "school_name":"Bodwesango Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051302",
     "school_name":"Bodwesango Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0080701",
     "school_name":"Bole Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0080701",
     "school_name":"Bole Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0080701",
     "school_name":"Bole Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0080701",
     "school_name":"Bole Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0080701",
     "school_name":"Bole Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9090403",
     "school_name":"Bolga CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"BUILDING CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9090403",
     "school_name":"Bolga CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"BUSINESS SECRETARIAL",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9090403",
     "school_name":"Bolga CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"COSMETOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9090403",
     "school_name":"Bolga CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"ELECTRICAL ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9090403",
     "school_name":"Bolga CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"FASHION DESIGNING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9090403",
     "school_name":"Bolga CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"HOSPITALITY & CATERING MANAGEMENT",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9090403",
     "school_name":"Bolga CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"MOTOR VEHICLE ENGINEERING",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9090403",
     "school_name":"Bolga CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"WELDING AND FABRICATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0090413",
     "school_name":"Bolga Sherigu Comm. Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0090413",
     "school_name":"Bolga Sherigu Comm. Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0090413",
     "school_name":"Bolga Sherigu Comm. Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0090413",
     "school_name":"Bolga Sherigu Comm. Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9090701",
     "school_name":"Bongo CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"BUILDING CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9090701",
     "school_name":"Bongo CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"COSMETOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9090701",
     "school_name":"Bongo CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"ELECTRICAL ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9090701",
     "school_name":"Bongo CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"FASHION DESIGNING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9090701",
     "school_name":"Bongo CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"HOSPITALITY & CATERING MANAGEMENT",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9090701",
     "school_name":"Bongo CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"MOTOR VEHICLE ENGINEERING",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0090601",
     "school_name":"Bongo Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0090601",
     "school_name":"Bongo Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0090601",
     "school_name":"Bongo Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0090601",
     "school_name":"Bongo Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0090601",
     "school_name":"Bongo Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0090601",
     "school_name":"Bongo Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030633",
     "school_name":"Bontrase Senior High Tech. Sch",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0030633",
     "school_name":"Bontrase Senior High Tech. Sch",
     "category":"C",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0030633",
     "school_name":"Bontrase Senior High Tech. Sch",
     "category":"C",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0051605",
     "school_name":"Bonwire Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051605",
     "school_name":"Bonwire Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051605",
     "school_name":"Bonwire Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051605",
     "school_name":"Bonwire Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051605",
     "school_name":"Bonwire Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051605",
     "school_name":"Bonwire Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051605",
     "school_name":"Bonwire Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040304",
     "school_name":"Bonzo-Kaku Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040304",
     "school_name":"Bonzo-Kaku Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040304",
     "school_name":"Bonzo-Kaku Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040304",
     "school_name":"Bonzo-Kaku Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040304",
     "school_name":"Bonzo-Kaku Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020804",
     "school_name":"Boso Senior High Technical",
     "category":"C",
     "school_type":"SHTS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020804",
     "school_name":"Boso Senior High Technical",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020804",
     "school_name":"Boso Senior High Technical",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020804",
     "school_name":"Boso Senior High Technical",
     "category":"C",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020804",
     "school_name":"Boso Senior High Technical",
     "category":"C",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020804",
     "school_name":"Boso Senior High Technical",
     "category":"C",
     "school_type":"SHTS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050305",
     "school_name":"Bosome Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050305",
     "school_name":"Bosome Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050305",
     "school_name":"Bosome Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050305",
     "school_name":"Bosome Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050305",
     "school_name":"Bosome Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051616",
     "school_name":"Bosomtwe Girls Stem Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051616",
     "school_name":"Bosomtwe Girls Stem Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051616",
     "school_name":"Bosomtwe Girls Stem Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"STEM",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051613",
     "school_name":"Bosomtwe Oyoko Comm. Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0051613",
     "school_name":"Bosomtwe Oyoko Comm. Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0051613",
     "school_name":"Bosomtwe Oyoko Comm. Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0051613",
     "school_name":"Bosomtwe Oyoko Comm. Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0051613",
     "school_name":"Bosomtwe Oyoko Comm. Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0051617",
     "school_name":"Bosomtwe Stem Academy",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051617",
     "school_name":"Bosomtwe Stem Academy",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051617",
     "school_name":"Bosomtwe Stem Academy",
     "category":"C",
     "school_type":"SHS",
     "program":"STEM",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0071108",
     "school_name":"Bowiri Comm. Day School",
     "category":"C",
     "school_type":"SHTS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0071108",
     "school_name":"Bowiri Comm. Day School",
     "category":"C",
     "school_type":"SHTS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0071108",
     "school_name":"Bowiri Comm. Day School",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0071108",
     "school_name":"Bowiri Comm. Day School",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0071108",
     "school_name":"Bowiri Comm. Day School",
     "category":"C",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0071108",
     "school_name":"Bowiri Comm. Day School",
     "category":"C",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0071108",
     "school_name":"Bowiri Comm. Day School",
     "category":"C",
     "school_type":"SHTS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0030803",
     "school_name":"Brakwa Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030803",
     "school_name":"Brakwa Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030803",
     "school_name":"Brakwa Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030803",
     "school_name":"Brakwa Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030803",
     "school_name":"Brakwa Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0081503",
     "school_name":"Buipe Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0081503",
     "school_name":"Buipe Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0081503",
     "school_name":"Buipe Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0081503",
     "school_name":"Buipe Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9081501",
     "school_name":"Buipe Tech\/Voc Inst.",
     "category":"C",
     "school_type":"TVET",
     "program":"AGRICULTURAL MECHANIZATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9081501",
     "school_name":"Buipe Tech\/Voc Inst.",
     "category":"C",
     "school_type":"TVET",
     "program":"BUILDING CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9081501",
     "school_name":"Buipe Tech\/Voc Inst.",
     "category":"C",
     "school_type":"TVET",
     "program":"BUSINESS ACCOUNTING",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9081501",
     "school_name":"Buipe Tech\/Voc Inst.",
     "category":"C",
     "school_type":"TVET",
     "program":"CREATIVE ART TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9081501",
     "school_name":"Buipe Tech\/Voc Inst.",
     "category":"C",
     "school_type":"TVET",
     "program":"ELECTRICAL ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9081501",
     "school_name":"Buipe Tech\/Voc Inst.",
     "category":"C",
     "school_type":"TVET",
     "program":"FASHION DESIGNING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9081501",
     "school_name":"Buipe Tech\/Voc Inst.",
     "category":"C",
     "school_type":"TVET",
     "program":"GENERAL TEXTILES",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9081501",
     "school_name":"Buipe Tech\/Voc Inst.",
     "category":"C",
     "school_type":"TVET",
     "program":"HOSPITALITY & CATERING MANAGEMENT",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9081501",
     "school_name":"Buipe Tech\/Voc Inst.",
     "category":"C",
     "school_type":"TVET",
     "program":"WOOD CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0080502",
     "school_name":"Bunkpurugu Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0080502",
     "school_name":"Bunkpurugu Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0080502",
     "school_name":"Bunkpurugu Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0080502",
     "school_name":"Bunkpurugu Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0080104",
     "school_name":"Business Senior High, Tamale",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0080104",
     "school_name":"Business Senior High, Tamale",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0080104",
     "school_name":"Business Senior High, Tamale",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0080104",
     "school_name":"Business Senior High, Tamale",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060902",
     "school_name":"Busunya Senior High ",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060902",
     "school_name":"Busunya Senior High ",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060902",
     "school_name":"Busunya Senior High ",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060902",
     "school_name":"Busunya Senior High ",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060902",
     "school_name":"Busunya Senior High ",
     "category":"C",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9070602",
     "school_name":"C.Y.O. Tech\/Voc. Inst.",
     "category":"C",
     "school_type":"TVET",
     "program":"AGRICULTURAL MECHANIZATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9070602",
     "school_name":"C.Y.O. Tech\/Voc. Inst.",
     "category":"C",
     "school_type":"TVET",
     "program":"ARCHITECTURAL DRAFTING",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9070602",
     "school_name":"C.Y.O. Tech\/Voc. Inst.",
     "category":"C",
     "school_type":"TVET",
     "program":"BUILDING CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9070602",
     "school_name":"C.Y.O. Tech\/Voc. Inst.",
     "category":"C",
     "school_type":"TVET",
     "program":"BUSINESS ACCOUNTING",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9070602",
     "school_name":"C.Y.O. Tech\/Voc. Inst.",
     "category":"C",
     "school_type":"TVET",
     "program":"COMPUTER HARDWARE TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9070602",
     "school_name":"C.Y.O. Tech\/Voc. Inst.",
     "category":"C",
     "school_type":"TVET",
     "program":"COMPUTER NETWORKING(CBT OPTION, FOR GIRLS ONLY)",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9070602",
     "school_name":"C.Y.O. Tech\/Voc. Inst.",
     "category":"C",
     "school_type":"TVET",
     "program":"COSMETOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9070602",
     "school_name":"C.Y.O. Tech\/Voc. Inst.",
     "category":"C",
     "school_type":"TVET",
     "program":"ELECTRICAL ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9070602",
     "school_name":"C.Y.O. Tech\/Voc. Inst.",
     "category":"C",
     "school_type":"TVET",
     "program":"ELECTRONICS ENGINEERING",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9070602",
     "school_name":"C.Y.O. Tech\/Voc. Inst.",
     "category":"C",
     "school_type":"TVET",
     "program":"FASHION DESIGNING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9070602",
     "school_name":"C.Y.O. Tech\/Voc. Inst.",
     "category":"C",
     "school_type":"TVET",
     "program":"FURNITURE DESIGN & CONSTRUCTION",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9070602",
     "school_name":"C.Y.O. Tech\/Voc. Inst.",
     "category":"C",
     "school_type":"TVET",
     "program":"GENERAL TEXTILES",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9070602",
     "school_name":"C.Y.O. Tech\/Voc. Inst.",
     "category":"C",
     "school_type":"TVET",
     "program":"HOSPITALITY & CATERING MANAGEMENT",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9070602",
     "school_name":"C.Y.O. Tech\/Voc. Inst.",
     "category":"C",
     "school_type":"TVET",
     "program":"MOTOR VEHICLE ENGINEERING",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9070602",
     "school_name":"C.Y.O. Tech\/Voc. Inst.",
     "category":"C",
     "school_type":"TVET",
     "program":"PLUMBING & GAS FITTING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9070602",
     "school_name":"C.Y.O. Tech\/Voc. Inst.",
     "category":"C",
     "school_type":"TVET",
     "program":"WELDING AND FABRICATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9070602",
     "school_name":"C.Y.O. Tech\/Voc. Inst.",
     "category":"C",
     "school_type":"TVET",
     "program":"WOOD CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9031300",
     "school_name":"Career Training Institute ",
     "category":"C",
     "school_type":"TVET",
     "program":"BUILDING CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9031300",
     "school_name":"Career Training Institute ",
     "category":"C",
     "school_type":"TVET",
     "program":"BUSINESS SECRETARIAL",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9031300",
     "school_name":"Career Training Institute ",
     "category":"C",
     "school_type":"TVET",
     "program":"ELECTRICAL ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9031300",
     "school_name":"Career Training Institute ",
     "category":"C",
     "school_type":"TVET",
     "program":"FASHION DESIGNING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9031300",
     "school_name":"Career Training Institute ",
     "category":"C",
     "school_type":"TVET",
     "program":"HOSPITALITY & CATERING MANAGEMENT",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9070503",
     "school_name":"Caring Sisters Vocational Training Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"FASHION DESIGNING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9070503",
     "school_name":"Caring Sisters Vocational Training Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"HOSPITALITY & CATERING MANAGEMENT",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9040402",
     "school_name":"Charlotte Dolphyne Vocational Training Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"BUILDING CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9040402",
     "school_name":"Charlotte Dolphyne Vocational Training Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"BUSINESS INFORMATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9040402",
     "school_name":"Charlotte Dolphyne Vocational Training Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"ELECTRICAL ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9040402",
     "school_name":"Charlotte Dolphyne Vocational Training Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"FASHION DESIGNING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9040402",
     "school_name":"Charlotte Dolphyne Vocational Training Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"HOSPITALITY & CATERING MANAGEMENT",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9040402",
     "school_name":"Charlotte Dolphyne Vocational Training Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"WELDING AND FABRICATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0080901",
     "school_name":"Chereponi Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0080901",
     "school_name":"Chereponi Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0080901",
     "school_name":"Chereponi Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0080901",
     "school_name":"Chereponi Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0090503",
     "school_name":"Chiana Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0090503",
     "school_name":"Chiana Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0090503",
     "school_name":"Chiana Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0090503",
     "school_name":"Chiana Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0090503",
     "school_name":"Chiana Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0090503",
     "school_name":"Chiana Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040705",
     "school_name":"Chirano Comm. Day School",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0040705",
     "school_name":"Chirano Comm. Day School",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0040705",
     "school_name":"Chirano Comm. Day School",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0040705",
     "school_name":"Chirano Comm. Day School",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0040705",
     "school_name":"Chirano Comm. Day School",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0010119",
     "school_name":"Christian Methodist Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0010119",
     "school_name":"Christian Methodist Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0010119",
     "school_name":"Christian Methodist Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0010119",
     "school_name":"Christian Methodist Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0010119",
     "school_name":"Christian Methodist Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0051607",
     "school_name":"Church Of Christ Senior High ",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051607",
     "school_name":"Church Of Christ Senior High ",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051607",
     "school_name":"Church Of Christ Senior High ",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051607",
     "school_name":"Church Of Christ Senior High ",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051607",
     "school_name":"Church Of Christ Senior High ",
     "category":"C",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030507",
     "school_name":"College of Music Senior, Mozano",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030507",
     "school_name":"College of Music Senior, Mozano",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030507",
     "school_name":"College of Music Senior, Mozano",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9011400",
     "school_name":"Construction Machinery Mechanic Training Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"DIESEL MECH\/HEAVY ENGINE, HEAVY DUTY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9011400",
     "school_name":"Construction Machinery Mechanic Training Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"ELECTRICAL ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9011400",
     "school_name":"Construction Machinery Mechanic Training Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"ELECTRONICS ENGINEERING",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9011400",
     "school_name":"Construction Machinery Mechanic Training Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"MOTOR VEHICLE ENGINEERING",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070902",
     "school_name":"Dabala Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070902",
     "school_name":"Dabala Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070902",
     "school_name":"Dabala Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070902",
     "school_name":"Dabala Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070902",
     "school_name":"Dabala Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070902",
     "school_name":"Dabala Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070902",
     "school_name":"Dabala Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0082201",
     "school_name":"Daboya Comm. Day School",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0082201",
     "school_name":"Daboya Comm. Day School",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0082201",
     "school_name":"Daboya Comm. Day School",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0082201",
     "school_name":"Daboya Comm. Day School",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0082201",
     "school_name":"Daboya Comm. Day School",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0051804",
     "school_name":"Dadease Agric Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051804",
     "school_name":"Dadease Agric Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051804",
     "school_name":"Dadease Agric Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051804",
     "school_name":"Dadease Agric Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051804",
     "school_name":"Dadease Agric Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051804",
     "school_name":"Dadease Agric Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0100502",
     "school_name":"Daffiamah Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0100502",
     "school_name":"Daffiamah Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0100502",
     "school_name":"Daffiamah Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0100502",
     "school_name":"Daffiamah Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0100502",
     "school_name":"Daffiamah Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0080802",
     "school_name":"Dagbon State Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0080802",
     "school_name":"Dagbon State Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0080802",
     "school_name":"Dagbon State Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0080802",
     "school_name":"Dagbon State Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0080802",
     "school_name":"Dagbon State Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0080201",
     "school_name":"Damongo Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0080201",
     "school_name":"Damongo Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0080201",
     "school_name":"Damongo Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0080201",
     "school_name":"Damongo Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0080201",
     "school_name":"Damongo Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050309",
     "school_name":"Denyaseman Cath.Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050309",
     "school_name":"Denyaseman Cath.Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050309",
     "school_name":"Denyaseman Cath.Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050309",
     "school_name":"Denyaseman Cath.Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040109",
     "school_name":"Diabene Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040109",
     "school_name":"Diabene Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040109",
     "school_name":"Diabene Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040109",
     "school_name":"Diabene Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040109",
     "school_name":"Diabene Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040109",
     "school_name":"Diabene Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040109",
     "school_name":"Diabene Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020307",
     "school_name":"Diaspora Girls' Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020307",
     "school_name":"Diaspora Girls' Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020307",
     "school_name":"Diaspora Girls' Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020307",
     "school_name":"Diaspora Girls' Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020307",
     "school_name":"Diaspora Girls' Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0071202",
     "school_name":"Dodi-Papase Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0071202",
     "school_name":"Dodi-Papase Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0071202",
     "school_name":"Dodi-Papase Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0071202",
     "school_name":"Dodi-Papase Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0071202",
     "school_name":"Dodi-Papase Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0071202",
     "school_name":"Dodi-Papase Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0071202",
     "school_name":"Dodi-Papase Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9010700",
     "school_name":"Dodowa ICCES",
     "category":"C",
     "school_type":"TVET",
     "program":"BUILDING CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9010700",
     "school_name":"Dodowa ICCES",
     "category":"C",
     "school_type":"TVET",
     "program":"ELECTRICAL ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9010700",
     "school_name":"Dodowa ICCES",
     "category":"C",
     "school_type":"TVET",
     "program":"FASHION DESIGNING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9010700",
     "school_name":"Dodowa ICCES",
     "category":"C",
     "school_type":"TVET",
     "program":"HOSPITALITY & CATERING MANAGEMENT",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9010700",
     "school_name":"Dodowa ICCES",
     "category":"C",
     "school_type":"TVET",
     "program":"WOOD CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0070805",
     "school_name":"Dofor Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070805",
     "school_name":"Dofor Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070805",
     "school_name":"Dofor Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070805",
     "school_name":"Dofor Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070805",
     "school_name":"Dofor Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070805",
     "school_name":"Dofor Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020901",
     "school_name":"Donkorkrom Agric Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020901",
     "school_name":"Donkorkrom Agric Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020901",
     "school_name":"Donkorkrom Agric Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020901",
     "school_name":"Donkorkrom Agric Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020901",
     "school_name":"Donkorkrom Agric Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020901",
     "school_name":"Donkorkrom Agric Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9061001",
     "school_name":"Dormaa Vocational Training Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"BUILDING CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9061001",
     "school_name":"Dormaa Vocational Training Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"BUSINESS INFORMATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9061001",
     "school_name":"Dormaa Vocational Training Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"BUSINESS SECRETARIAL",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9061001",
     "school_name":"Dormaa Vocational Training Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"ELECTRICAL ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9061001",
     "school_name":"Dormaa Vocational Training Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"HOSPITALITY & CATERING MANAGEMENT",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9061001",
     "school_name":"Dormaa Vocational Training Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"PLUMBING & GAS FITTING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9061001",
     "school_name":"Dormaa Vocational Training Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"WOOD CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0100403",
     "school_name":"Dr. Hila Liman Senior  High School",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0100403",
     "school_name":"Dr. Hila Liman Senior  High School",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0100403",
     "school_name":"Dr. Hila Liman Senior  High School",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060512",
     "school_name":"Duadaso No. 1 Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0060512",
     "school_name":"Duadaso No. 1 Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0060512",
     "school_name":"Duadaso No. 1 Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0031002",
     "school_name":"Dunkwa Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0031002",
     "school_name":"Dunkwa Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0031002",
     "school_name":"Dunkwa Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0031002",
     "school_name":"Dunkwa Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0031002",
     "school_name":"Dunkwa Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0031002",
     "school_name":"Dunkwa Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070703",
     "school_name":"Dzodze Penyi Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070703",
     "school_name":"Dzodze Penyi Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070703",
     "school_name":"Dzodze Penyi Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070703",
     "school_name":"Dzodze Penyi Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070703",
     "school_name":"Dzodze Penyi Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070703",
     "school_name":"Dzodze Penyi Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070109",
     "school_name":"Dzolo Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070109",
     "school_name":"Dzolo Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070109",
     "school_name":"Dzolo Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070109",
     "school_name":"Dzolo Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070109",
     "school_name":"Dzolo Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0081102",
     "school_name":"E. P. Agric Senior High\/Tech.",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0081102",
     "school_name":"E. P. Agric Senior High\/Tech.",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0081102",
     "school_name":"E. P. Agric Senior High\/Tech.",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0071006",
     "school_name":"E. P. Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0071006",
     "school_name":"E. P. Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0071006",
     "school_name":"E. P. Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0071006",
     "school_name":"E. P. Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0071006",
     "school_name":"E. P. Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0071006",
     "school_name":"E. P. Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9071002",
     "school_name":"E.P. Tech\/Voc Inst",
     "category":"C",
     "school_type":"TVET",
     "program":"BUILDING CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9071002",
     "school_name":"E.P. Tech\/Voc Inst",
     "category":"C",
     "school_type":"TVET",
     "program":"BUSINESS ACCOUNTING",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9071002",
     "school_name":"E.P. Tech\/Voc Inst",
     "category":"C",
     "school_type":"TVET",
     "program":"ELECTRICAL ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9071002",
     "school_name":"E.P. Tech\/Voc Inst",
     "category":"C",
     "school_type":"TVET",
     "program":"FASHION DESIGNING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9071002",
     "school_name":"E.P. Tech\/Voc Inst",
     "category":"C",
     "school_type":"TVET",
     "program":"HOSPITALITY & CATERING MANAGEMENT",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9071002",
     "school_name":"E.P. Tech\/Voc Inst",
     "category":"C",
     "school_type":"TVET",
     "program":"MECHANICAL. ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9071002",
     "school_name":"E.P. Tech\/Voc Inst",
     "category":"C",
     "school_type":"TVET",
     "program":"MOTOR VEHICLE ENGINEERING",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9071002",
     "school_name":"E.P. Tech\/Voc Inst",
     "category":"C",
     "school_type":"TVET",
     "program":"WELDING AND FABRICATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9071002",
     "school_name":"E.P. Tech\/Voc Inst",
     "category":"C",
     "school_type":"TVET",
     "program":"WOOD CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0010101",
     "school_name":"Ebenezer Senior High ",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0010101",
     "school_name":"Ebenezer Senior High ",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0010101",
     "school_name":"Ebenezer Senior High ",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0010101",
     "school_name":"Ebenezer Senior High ",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0010101",
     "school_name":"Ebenezer Senior High ",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0010101",
     "school_name":"Ebenezer Senior High ",
     "category":"C",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0051801",
     "school_name":"Effiduase Senior High\/Com ",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051801",
     "school_name":"Effiduase Senior High\/Com ",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051801",
     "school_name":"Effiduase Senior High\/Com ",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051801",
     "school_name":"Effiduase Senior High\/Com ",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051801",
     "school_name":"Effiduase Senior High\/Com ",
     "category":"C",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051806",
     "school_name":"Effiduase Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0051806",
     "school_name":"Effiduase Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0051806",
     "school_name":"Effiduase Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0051806",
     "school_name":"Effiduase Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0030110",
     "school_name":"Effutu Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030110",
     "school_name":"Effutu Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030110",
     "school_name":"Effutu Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030110",
     "school_name":"Effutu Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030110",
     "school_name":"Effutu Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030110",
     "school_name":"Effutu Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030202",
     "school_name":"Eguafo-Abrem Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030202",
     "school_name":"Eguafo-Abrem Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030202",
     "school_name":"Eguafo-Abrem Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030202",
     "school_name":"Eguafo-Abrem Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030202",
     "school_name":"Eguafo-Abrem Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030202",
     "school_name":"Eguafo-Abrem Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051601",
     "school_name":"Ejisuman Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051601",
     "school_name":"Ejisuman Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051601",
     "school_name":"Ejisuman Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051601",
     "school_name":"Ejisuman Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051601",
     "school_name":"Ejisuman Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050901",
     "school_name":"Ejuraman Anglican Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050901",
     "school_name":"Ejuraman Anglican Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050901",
     "school_name":"Ejuraman Anglican Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050901",
     "school_name":"Ejuraman Anglican Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050901",
     "school_name":"Ejuraman Anglican Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050901",
     "school_name":"Ejuraman Anglican Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030703",
     "school_name":"Enyan Denkyira Senior High",
     "category":"C",
     "school_type":"SHTS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030703",
     "school_name":"Enyan Denkyira Senior High",
     "category":"C",
     "school_type":"SHTS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030703",
     "school_name":"Enyan Denkyira Senior High",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030703",
     "school_name":"Enyan Denkyira Senior High",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030703",
     "school_name":"Enyan Denkyira Senior High",
     "category":"C",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030703",
     "school_name":"Enyan Denkyira Senior High",
     "category":"C",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030706",
     "school_name":"Enyan Maim Comm. Day School",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0030706",
     "school_name":"Enyan Maim Comm. Day School",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0030706",
     "school_name":"Enyan Maim Comm. Day School",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0030706",
     "school_name":"Enyan Maim Comm. Day School",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0030706",
     "school_name":"Enyan Maim Comm. Day School",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0030706",
     "school_name":"Enyan Maim Comm. Day School",
     "category":"C",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0100203",
     "school_name":"Eremon Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0100203",
     "school_name":"Eremon Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0100203",
     "school_name":"Eremon Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050402",
     "school_name":"Esaase Bontefufuo Snr. High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050402",
     "school_name":"Esaase Bontefufuo Snr. High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050402",
     "school_name":"Esaase Bontefufuo Snr. High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050402",
     "school_name":"Esaase Bontefufuo Snr. High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050402",
     "school_name":"Esaase Bontefufuo Snr. High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050402",
     "school_name":"Esaase Bontefufuo Snr. High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050402",
     "school_name":"Esaase Bontefufuo Snr. High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9061400",
     "school_name":"Fawohoyeden YLSTI ",
     "category":"C",
     "school_type":"TVET",
     "program":"AGRICULTURAL MECHANIZATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9061400",
     "school_name":"Fawohoyeden YLSTI ",
     "category":"C",
     "school_type":"TVET",
     "program":"BUILDING CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9061400",
     "school_name":"Fawohoyeden YLSTI ",
     "category":"C",
     "school_type":"TVET",
     "program":"ELECTRICAL ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9061400",
     "school_name":"Fawohoyeden YLSTI ",
     "category":"C",
     "school_type":"TVET",
     "program":"FASHION DESIGNING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9061400",
     "school_name":"Fawohoyeden YLSTI ",
     "category":"C",
     "school_type":"TVET",
     "program":"HOSPITALITY & CATERING MANAGEMENT",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030615",
     "school_name":"Fettehman Senior High ",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030615",
     "school_name":"Fettehman Senior High ",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030615",
     "school_name":"Fettehman Senior High ",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030615",
     "school_name":"Fettehman Senior High ",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030615",
     "school_name":"Fettehman Senior High ",
     "category":"C",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040903",
     "school_name":"Fiaseman Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040903",
     "school_name":"Fiaseman Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040903",
     "school_name":"Fiaseman Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040903",
     "school_name":"Fiaseman Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040903",
     "school_name":"Fiaseman Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021018",
     "school_name":"Fodoa Comm. Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0021018",
     "school_name":"Fodoa Comm. Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0021018",
     "school_name":"Fodoa Comm. Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0021018",
     "school_name":"Fodoa Comm. Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0021018",
     "school_name":"Fodoa Comm. Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0021018",
     "school_name":"Fodoa Comm. Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9070401",
     "school_name":"Fr. Dogli Memorial Voc\/Tech. Inst.",
     "category":"C",
     "school_type":"TVET",
     "program":"AGRICULTURAL MECHANIZATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9070401",
     "school_name":"Fr. Dogli Memorial Voc\/Tech. Inst.",
     "category":"C",
     "school_type":"TVET",
     "program":"BUILDING CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9070401",
     "school_name":"Fr. Dogli Memorial Voc\/Tech. Inst.",
     "category":"C",
     "school_type":"TVET",
     "program":"BUSINESS ACCOUNTING",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9070401",
     "school_name":"Fr. Dogli Memorial Voc\/Tech. Inst.",
     "category":"C",
     "school_type":"TVET",
     "program":"BUSINESS SECRETARIAL",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9070401",
     "school_name":"Fr. Dogli Memorial Voc\/Tech. Inst.",
     "category":"C",
     "school_type":"TVET",
     "program":"COMPUTER HARDWARE TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9070401",
     "school_name":"Fr. Dogli Memorial Voc\/Tech. Inst.",
     "category":"C",
     "school_type":"TVET",
     "program":"ELECTRICAL ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9070401",
     "school_name":"Fr. Dogli Memorial Voc\/Tech. Inst.",
     "category":"C",
     "school_type":"TVET",
     "program":"FASHION DESIGNING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9070401",
     "school_name":"Fr. Dogli Memorial Voc\/Tech. Inst.",
     "category":"C",
     "school_type":"TVET",
     "program":"HOSPITALITY & CATERING MANAGEMENT",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9070401",
     "school_name":"Fr. Dogli Memorial Voc\/Tech. Inst.",
     "category":"C",
     "school_type":"TVET",
     "program":"PLUMBING & GAS FITTING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9070401",
     "school_name":"Fr. Dogli Memorial Voc\/Tech. Inst.",
     "category":"C",
     "school_type":"TVET",
     "program":"WOOD CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0010535",
     "school_name":"Frafraha Comm. Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0010535",
     "school_name":"Frafraha Comm. Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0010535",
     "school_name":"Frafraha Comm. Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0010535",
     "school_name":"Frafraha Comm. Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0010535",
     "school_name":"Frafraha Comm. Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0090302",
     "school_name":"Fumbisi Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0090302",
     "school_name":"Fumbisi Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0090302",
     "school_name":"Fumbisi Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0090302",
     "school_name":"Fumbisi Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0090302",
     "school_name":"Fumbisi Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0100108",
     "school_name":"Funsi Senior High School",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0100108",
     "school_name":"Funsi Senior High School",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0080504",
     "school_name":"Gambaga Girls Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0080504",
     "school_name":"Gambaga Girls Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0080504",
     "school_name":"Gambaga Girls Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0090414",
     "school_name":"Gambigo Comm. Day Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0090414",
     "school_name":"Gambigo Comm. Day Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0090414",
     "school_name":"Gambigo Comm. Day Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0090414",
     "school_name":"Gambigo Comm. Day Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0090106",
     "school_name":"Garu Comm. Day Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0090106",
     "school_name":"Garu Comm. Day Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0090106",
     "school_name":"Garu Comm. Day Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0090106",
     "school_name":"Garu Comm. Day Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0070131",
     "school_name":"Gbekor Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0070131",
     "school_name":"Gbekor Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0070131",
     "school_name":"Gbekor Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0070131",
     "school_name":"Gbekor Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9030500",
     "school_name":"Gomoa Adaa Vocational Training Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"BUILDING CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9030500",
     "school_name":"Gomoa Adaa Vocational Training Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"BUSINESS INFORMATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9030500",
     "school_name":"Gomoa Adaa Vocational Training Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"ELECTRICAL ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9030500",
     "school_name":"Gomoa Adaa Vocational Training Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"FASHION DESIGNING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9030500",
     "school_name":"Gomoa Adaa Vocational Training Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"HOSPITALITY & CATERING MANAGEMENT",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9030500",
     "school_name":"Gomoa Adaa Vocational Training Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"WOOD CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030508",
     "school_name":"Gomoa Gyaman Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0030508",
     "school_name":"Gomoa Gyaman Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0030508",
     "school_name":"Gomoa Gyaman Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0030508",
     "school_name":"Gomoa Gyaman Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0030508",
     "school_name":"Gomoa Gyaman Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0030503",
     "school_name":"Gomoa Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030503",
     "school_name":"Gomoa Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030503",
     "school_name":"Gomoa Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030503",
     "school_name":"Gomoa Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030503",
     "school_name":"Gomoa Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030503",
     "school_name":"Gomoa Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030503",
     "school_name":"Gomoa Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9010108",
     "school_name":"Government Technical School, Accra",
     "category":"C",
     "school_type":"TVET",
     "program":"AUTOBODY WORKS",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9010108",
     "school_name":"Government Technical School, Accra",
     "category":"C",
     "school_type":"TVET",
     "program":"ELECTRICAL MACHINE\/MOTOR REWINDING",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9010108",
     "school_name":"Government Technical School, Accra",
     "category":"C",
     "school_type":"TVET",
     "program":"MOTOR VEHICLE ENGINEERING",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9010108",
     "school_name":"Government Technical School, Accra",
     "category":"C",
     "school_type":"TVET",
     "program":"WELDING AND FABRICATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0090602",
     "school_name":"Gowrie Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0090602",
     "school_name":"Gowrie Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0090602",
     "school_name":"Gowrie Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0090602",
     "school_name":"Gowrie Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0090602",
     "school_name":"Gowrie Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0090602",
     "school_name":"Gowrie Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9060105",
     "school_name":"GRATIS Foundation- Bono Region",
     "category":"C",
     "school_type":"TVET",
     "program":"MECHANICAL. ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9060105",
     "school_name":"GRATIS Foundation- Bono Region",
     "category":"C",
     "school_type":"TVET",
     "program":"WELDING AND FABRICATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9030102",
     "school_name":"GRATIS Foundation- Central Region",
     "category":"C",
     "school_type":"TVET",
     "program":"MECHANICAL. ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9030102",
     "school_name":"GRATIS Foundation- Central Region",
     "category":"C",
     "school_type":"TVET",
     "program":"WELDING AND FABRICATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9020104",
     "school_name":"GRATIS Foundation- Eastern Region",
     "category":"C",
     "school_type":"TVET",
     "program":"ELECTRICAL ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9020104",
     "school_name":"GRATIS Foundation- Eastern Region",
     "category":"C",
     "school_type":"TVET",
     "program":"MECHANICAL. ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9020104",
     "school_name":"GRATIS Foundation- Eastern Region",
     "category":"C",
     "school_type":"TVET",
     "program":"MOTOR VEHICLE ENGINEERING",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9020104",
     "school_name":"GRATIS Foundation- Eastern Region",
     "category":"C",
     "school_type":"TVET",
     "program":"WELDING AND FABRICATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9010205",
     "school_name":"GRATIS Foundation- Gt Accra Region",
     "category":"C",
     "school_type":"TVET",
     "program":"FASHION DESIGNING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9010205",
     "school_name":"GRATIS Foundation- Gt Accra Region",
     "category":"C",
     "school_type":"TVET",
     "program":"MECHANICAL. ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9010205",
     "school_name":"GRATIS Foundation- Gt Accra Region",
     "category":"C",
     "school_type":"TVET",
     "program":"WELDING AND FABRICATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9080104",
     "school_name":"GRATIS Foundation- Northern Region",
     "category":"C",
     "school_type":"TVET",
     "program":"MECHANICAL. ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9080104",
     "school_name":"GRATIS Foundation- Northern Region",
     "category":"C",
     "school_type":"TVET",
     "program":"WELDING AND FABRICATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9090402",
     "school_name":"GRATIS Foundation- Upper East Region",
     "category":"C",
     "school_type":"TVET",
     "program":"MECHANICAL. ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9090402",
     "school_name":"GRATIS Foundation- Upper East Region",
     "category":"C",
     "school_type":"TVET",
     "program":"WELDING AND FABRICATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9100103",
     "school_name":"GRATIS Foundation- Upper West Region",
     "category":"C",
     "school_type":"TVET",
     "program":"MECHANICAL. ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9100103",
     "school_name":"GRATIS Foundation- Upper West Region",
     "category":"C",
     "school_type":"TVET",
     "program":"WELDING AND FABRICATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9070701",
     "school_name":"GRATIS Foundation- Volta Region",
     "category":"C",
     "school_type":"TVET",
     "program":"MECHANICAL. ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9070701",
     "school_name":"GRATIS Foundation- Volta Region",
     "category":"C",
     "school_type":"TVET",
     "program":"WELDING AND FABRICATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9040104",
     "school_name":"GRATIS Foundation-Western Region",
     "category":"C",
     "school_type":"TVET",
     "program":"MECHANICAL. ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9040104",
     "school_name":"GRATIS Foundation-Western Region",
     "category":"C",
     "school_type":"TVET",
     "program":"WELDING AND FABRICATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9010500",
     "school_name":"Growth ICCES",
     "category":"C",
     "school_type":"TVET",
     "program":"BUILDING CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9010500",
     "school_name":"Growth ICCES",
     "category":"C",
     "school_type":"TVET",
     "program":"ELECTRICAL ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9010500",
     "school_name":"Growth ICCES",
     "category":"C",
     "school_type":"TVET",
     "program":"FASHION DESIGNING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9010500",
     "school_name":"Growth ICCES",
     "category":"C",
     "school_type":"TVET",
     "program":"HOSPITALITY & CATERING MANAGEMENT",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9010500",
     "school_name":"Growth ICCES",
     "category":"C",
     "school_type":"TVET",
     "program":"WELDING AND FABRICATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9010500",
     "school_name":"Growth ICCES",
     "category":"C",
     "school_type":"TVET",
     "program":"WOOD CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0081001",
     "school_name":"Gushegu Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0081001",
     "school_name":"Gushegu Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0081001",
     "school_name":"Gushegu Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0081001",
     "school_name":"Gushegu Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0081001",
     "school_name":"Gushegu Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040308",
     "school_name":"Gwiraman Comm.Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0040308",
     "school_name":"Gwiraman Comm.Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0040308",
     "school_name":"Gwiraman Comm.Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0040308",
     "school_name":"Gwiraman Comm.Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0040308",
     "school_name":"Gwiraman Comm.Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0040308",
     "school_name":"Gwiraman Comm.Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0050706",
     "school_name":"Gyaama Pensan Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050706",
     "school_name":"Gyaama Pensan Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050706",
     "school_name":"Gyaama Pensan Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050706",
     "school_name":"Gyaama Pensan Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050706",
     "school_name":"Gyaama Pensan Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050706",
     "school_name":"Gyaama Pensan Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0031208",
     "school_name":"Gyaase Community Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0031208",
     "school_name":"Gyaase Community Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0031208",
     "school_name":"Gyaase Community Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0031208",
     "school_name":"Gyaase Community Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0031208",
     "school_name":"Gyaase Community Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0031208",
     "school_name":"Gyaase Community Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060713",
     "school_name":"Gyarko Comm. Day Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0060713",
     "school_name":"Gyarko Comm. Day Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0060713",
     "school_name":"Gyarko Comm. Day Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0060713",
     "school_name":"Gyarko Comm. Day Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0060713",
     "school_name":"Gyarko Comm. Day Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0060713",
     "school_name":"Gyarko Comm. Day Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9062400",
     "school_name":"Gyedu ICCES",
     "category":"C",
     "school_type":"TVET",
     "program":"BUILDING CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9062400",
     "school_name":"Gyedu ICCES",
     "category":"C",
     "school_type":"TVET",
     "program":"ELECTRICAL ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9062400",
     "school_name":"Gyedu ICCES",
     "category":"C",
     "school_type":"TVET",
     "program":"FASHION DESIGNING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9062400",
     "school_name":"Gyedu ICCES",
     "category":"C",
     "school_type":"TVET",
     "program":"HOSPITALITY & CATERING MANAGEMENT",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9062400",
     "school_name":"Gyedu ICCES",
     "category":"C",
     "school_type":"TVET",
     "program":"PLUMBING & GAS FITTING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9062400",
     "school_name":"Gyedu ICCES",
     "category":"C",
     "school_type":"TVET",
     "program":"WELDING AND FABRICATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9062400",
     "school_name":"Gyedu ICCES",
     "category":"C",
     "school_type":"TVET",
     "program":"WOOD CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0020406",
     "school_name":"H'Mount Sinai Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020406",
     "school_name":"H'Mount Sinai Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020406",
     "school_name":"H'Mount Sinai Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020406",
     "school_name":"H'Mount Sinai Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020406",
     "school_name":"H'Mount Sinai Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0100305",
     "school_name":"Han Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0100305",
     "school_name":"Han Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0100305",
     "school_name":"Han Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0100305",
     "school_name":"Han Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9052000",
     "school_name":"Hemang Buoho Vocational Training Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"BUILDING CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9052000",
     "school_name":"Hemang Buoho Vocational Training Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"BUSINESS INFORMATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9052000",
     "school_name":"Hemang Buoho Vocational Training Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"ELECTRICAL ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9052000",
     "school_name":"Hemang Buoho Vocational Training Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"HOSPITALITY & CATERING MANAGEMENT",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9052000",
     "school_name":"Hemang Buoho Vocational Training Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"INFORMATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9052000",
     "school_name":"Hemang Buoho Vocational Training Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"PLUMBING & GAS FITTING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9070704",
     "school_name":"Ho CDTVI",
     "category":"C",
     "school_type":"TVET",
     "program":"COSMETOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9070704",
     "school_name":"Ho CDTVI",
     "category":"C",
     "school_type":"TVET",
     "program":"ELECTRICAL ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9070704",
     "school_name":"Ho CDTVI",
     "category":"C",
     "school_type":"TVET",
     "program":"FASHION DESIGNING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9070704",
     "school_name":"Ho CDTVI",
     "category":"C",
     "school_type":"TVET",
     "program":"HOSPITALITY & CATERING MANAGEMENT",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0100205",
     "school_name":"Holy Family Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0100205",
     "school_name":"Holy Family Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0100205",
     "school_name":"Holy Family Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0010104",
     "school_name":"Holy Trinity Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0010104",
     "school_name":"Holy Trinity Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0010104",
     "school_name":"Holy Trinity Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0010104",
     "school_name":"Holy Trinity Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0040901",
     "school_name":"Huni Valley Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040901",
     "school_name":"Huni Valley Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040901",
     "school_name":"Huni Valley Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040901",
     "school_name":"Huni Valley Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040901",
     "school_name":"Huni Valley Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9050104",
     "school_name":"Institute of Business Studies-Kumasi",
     "category":"C",
     "school_type":"TVET",
     "program":"BUSINESS SECRETARIAL",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0080108",
     "school_name":"Islamic Science Senior High, Tamale",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0080108",
     "school_name":"Islamic Science Senior High, Tamale",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0080108",
     "school_name":"Islamic Science Senior High, Tamale",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0100105",
     "school_name":"Islamic Senior High, Wa",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0100105",
     "school_name":"Islamic Senior High, Wa",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0100105",
     "school_name":"Islamic Senior High, Wa",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0100105",
     "school_name":"Islamic Senior High, Wa",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9100800",
     "school_name":"Issa YLSTI ",
     "category":"C",
     "school_type":"TVET",
     "program":"AGRICULTURAL MECHANIZATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9100800",
     "school_name":"Issa YLSTI ",
     "category":"C",
     "school_type":"TVET",
     "program":"BUILDING CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9100800",
     "school_name":"Issa YLSTI ",
     "category":"C",
     "school_type":"TVET",
     "program":"COMPUTER HARDWARE TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9100800",
     "school_name":"Issa YLSTI ",
     "category":"C",
     "school_type":"TVET",
     "program":"ELECTRICAL ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9100800",
     "school_name":"Issa YLSTI ",
     "category":"C",
     "school_type":"TVET",
     "program":"FASHION DESIGNING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9100800",
     "school_name":"Issa YLSTI ",
     "category":"C",
     "school_type":"TVET",
     "program":"HOSPITALITY & CATERING MANAGEMENT",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9100800",
     "school_name":"Issa YLSTI ",
     "category":"C",
     "school_type":"TVET",
     "program":"WOOD CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050212",
     "school_name":"J. A. Kufuor Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050212",
     "school_name":"J. A. Kufuor Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050212",
     "school_name":"J. A. Kufuor Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030309",
     "school_name":"J.E.A. Mills Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0030309",
     "school_name":"J.E.A. Mills Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0030309",
     "school_name":"J.E.A. Mills Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0030309",
     "school_name":"J.E.A. Mills Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0030309",
     "school_name":"J.E.A. Mills Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0030309",
     "school_name":"J.E.A. Mills Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9021601",
     "school_name":"J.G. Knol Voc. Instititute",
     "category":"C",
     "school_type":"TVET",
     "program":"BUILDING CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9021601",
     "school_name":"J.G. Knol Voc. Instititute",
     "category":"C",
     "school_type":"TVET",
     "program":"ELECTRICAL ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9021601",
     "school_name":"J.G. Knol Voc. Instititute",
     "category":"C",
     "school_type":"TVET",
     "program":"FASHION DESIGNING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9021601",
     "school_name":"J.G. Knol Voc. Instititute",
     "category":"C",
     "school_type":"TVET",
     "program":"HOSPITALITY & CATERING MANAGEMENT",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9021601",
     "school_name":"J.G. Knol Voc. Instititute",
     "category":"C",
     "school_type":"TVET",
     "program":"MOTOR VEHICLE ENGINEERING",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9052803",
     "school_name":"Jachie Special Needs Tech. Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"COMPUTER HARDWARE TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9052803",
     "school_name":"Jachie Special Needs Tech. Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"FASHION DESIGNING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9052803",
     "school_name":"Jachie Special Needs Tech. Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"INFORMATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0100110",
     "school_name":"Jamiat Al-Hidaya Islamic Girls",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0100110",
     "school_name":"Jamiat Al-Hidaya Islamic Girls",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0100110",
     "school_name":"Jamiat Al-Hidaya Islamic Girls",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0080608",
     "school_name":"Janga Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0080608",
     "school_name":"Janga Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0080608",
     "school_name":"Janga Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0071010",
     "school_name":"Jim Bourton Mem Agric. Senior High",
     "category":"C",
     "school_type":"SHTS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0071010",
     "school_name":"Jim Bourton Mem Agric. Senior High",
     "category":"C",
     "school_type":"SHTS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0071010",
     "school_name":"Jim Bourton Mem Agric. Senior High",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0071010",
     "school_name":"Jim Bourton Mem Agric. Senior High",
     "category":"C",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0071010",
     "school_name":"Jim Bourton Mem Agric. Senior High",
     "category":"C",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0071010",
     "school_name":"Jim Bourton Mem Agric. Senior High",
     "category":"C",
     "school_type":"SHTS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0100304",
     "school_name":"Jirapa Senior High ",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0100304",
     "school_name":"Jirapa Senior High ",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0100304",
     "school_name":"Jirapa Senior High ",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0100304",
     "school_name":"Jirapa Senior High ",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0100304",
     "school_name":"Jirapa Senior High ",
     "category":"C",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051101",
     "school_name":"Juaso Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051101",
     "school_name":"Juaso Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051101",
     "school_name":"Juaso Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051101",
     "school_name":"Juaso Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051101",
     "school_name":"Juaso Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051101",
     "school_name":"Juaso Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051007",
     "school_name":"Jubilee Senior High ",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0051007",
     "school_name":"Jubilee Senior High ",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0031102",
     "school_name":"Jukwa Senior High",
     "category":"C",
     "school_type":"SHTS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0031102",
     "school_name":"Jukwa Senior High",
     "category":"C",
     "school_type":"SHTS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0031102",
     "school_name":"Jukwa Senior High",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0031102",
     "school_name":"Jukwa Senior High",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0031102",
     "school_name":"Jukwa Senior High",
     "category":"C",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0031102",
     "school_name":"Jukwa Senior High",
     "category":"C",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0031102",
     "school_name":"Jukwa Senior High",
     "category":"C",
     "school_type":"SHTS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0071201",
     "school_name":"Kadjebi-Asato Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0071201",
     "school_name":"Kadjebi-Asato Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0071201",
     "school_name":"Kadjebi-Asato Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0071201",
     "school_name":"Kadjebi-Asato Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0071201",
     "school_name":"Kadjebi-Asato Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0071201",
     "school_name":"Kadjebi-Asato Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0100501",
     "school_name":"Kaleo Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0100501",
     "school_name":"Kaleo Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0100501",
     "school_name":"Kaleo Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0100501",
     "school_name":"Kaleo Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0100501",
     "school_name":"Kaleo Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9010106",
     "school_name":"Kanda Career Training Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"FASHION DESIGNING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9010106",
     "school_name":"Kanda Career Training Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"HOSPITALITY & CATERING MANAGEMENT",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0010120",
     "school_name":"Kaneshie Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0010120",
     "school_name":"Kaneshie Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0010120",
     "school_name":"Kaneshie Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0010120",
     "school_name":"Kaneshie Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0010120",
     "school_name":"Kaneshie Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0090305",
     "school_name":"Kanjaga Comm. Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0090305",
     "school_name":"Kanjaga Comm. Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0090305",
     "school_name":"Kanjaga Comm. Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0090305",
     "school_name":"Kanjaga Comm. Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0081401",
     "school_name":"Karaga Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0081401",
     "school_name":"Karaga Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0081401",
     "school_name":"Karaga Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0081401",
     "school_name":"Karaga Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0081401",
     "school_name":"Karaga Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0081203",
     "school_name":"Kasuliyili Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0081203",
     "school_name":"Kasuliyili Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0060712",
     "school_name":"Kesse Basahyia Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0060712",
     "school_name":"Kesse Basahyia Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0060712",
     "school_name":"Kesse Basahyia Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0070504",
     "school_name":"Keta Business Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070504",
     "school_name":"Keta Business Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070504",
     "school_name":"Keta Business Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070504",
     "school_name":"Keta Business Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070504",
     "school_name":"Keta Business Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9072001",
     "school_name":"Kete Krachi CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"BUILDING CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9072001",
     "school_name":"Kete Krachi CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"ELECTRICAL ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9072001",
     "school_name":"Kete Krachi CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"FASHION DESIGNING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9072001",
     "school_name":"Kete Krachi CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"HOSPITALITY & CATERING MANAGEMENT",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9072001",
     "school_name":"Kete Krachi CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"MOTOR VEHICLE ENGINEERING",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9072001",
     "school_name":"Kete Krachi CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"PLUMBING & GAS FITTING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9072001",
     "school_name":"Kete Krachi CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"WELDING AND FABRICATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9072001",
     "school_name":"Kete Krachi CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"WOOD CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0070202",
     "school_name":"Kete Krachi Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070202",
     "school_name":"Kete Krachi Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070202",
     "school_name":"Kete Krachi Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070202",
     "school_name":"Kete Krachi Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9021302",
     "school_name":"Kibi CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"BUILDING CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9021302",
     "school_name":"Kibi CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"COMPUTER HARDWARE TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9021302",
     "school_name":"Kibi CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"ELECTRICAL ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9021302",
     "school_name":"Kibi CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"FASHION DESIGNING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9021302",
     "school_name":"Kibi CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"MOTOR VEHICLE ENGINEERING",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9021302",
     "school_name":"Kibi CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"PLUMBING & GAS FITTING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9021302",
     "school_name":"Kibi CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"WELDING AND FABRICATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9021302",
     "school_name":"Kibi CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"WOOD CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0010109",
     "school_name":"Kinbu Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0010109",
     "school_name":"Kinbu Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0010109",
     "school_name":"Kinbu Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0010109",
     "school_name":"Kinbu Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0010109",
     "school_name":"Kinbu Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0010109",
     "school_name":"Kinbu Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9030502",
     "school_name":"Kingstech ICCES",
     "category":"C",
     "school_type":"TVET",
     "program":"BUILDING CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9030502",
     "school_name":"Kingstech ICCES",
     "category":"C",
     "school_type":"TVET",
     "program":"ELECTRICAL ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9030502",
     "school_name":"Kingstech ICCES",
     "category":"C",
     "school_type":"TVET",
     "program":"FASHION DESIGNING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9030502",
     "school_name":"Kingstech ICCES",
     "category":"C",
     "school_type":"TVET",
     "program":"HOSPITALITY & CATERING MANAGEMENT",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9060301",
     "school_name":"Kintampo CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"BUILDING CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9060301",
     "school_name":"Kintampo CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"COSMETOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9060301",
     "school_name":"Kintampo CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"ELECTRICAL ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9060301",
     "school_name":"Kintampo CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"FASHION DESIGNING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9060301",
     "school_name":"Kintampo CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"HOSPITALITY & CATERING MANAGEMENT",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9060301",
     "school_name":"Kintampo CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"MOTOR VEHICLE ENGINEERING",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060801",
     "school_name":"Kintampo Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060801",
     "school_name":"Kintampo Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060801",
     "school_name":"Kintampo Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060801",
     "school_name":"Kintampo Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060801",
     "school_name":"Kintampo Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060801",
     "school_name":"Kintampo Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9020301",
     "school_name":"Kitase Technical Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"FASHION DESIGNING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9020301",
     "school_name":"Kitase Technical Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"HOSPITALITY & CATERING MANAGEMENT",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9070705",
     "school_name":"Klefe Technical Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"ELECTRICAL ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9070705",
     "school_name":"Klefe Technical Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"HOSPITALITY & CATERING MANAGEMENT",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070705",
     "school_name":"Klikor Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070705",
     "school_name":"Klikor Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070705",
     "school_name":"Klikor Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070705",
     "school_name":"Klikor Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070705",
     "school_name":"Klikor Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070705",
     "school_name":"Klikor Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070705",
     "school_name":"Klikor Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021402",
     "school_name":"Klo-Agogo Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021402",
     "school_name":"Klo-Agogo Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021402",
     "school_name":"Klo-Agogo Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021402",
     "school_name":"Klo-Agogo Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021402",
     "school_name":"Klo-Agogo Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0100204",
     "school_name":"Ko Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0100204",
     "school_name":"Ko Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0100204",
     "school_name":"Ko Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060603",
     "school_name":"Koase Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060603",
     "school_name":"Koase Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060603",
     "school_name":"Koase Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060603",
     "school_name":"Koase Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060603",
     "school_name":"Koase Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060603",
     "school_name":"Koase Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060603",
     "school_name":"Koase Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050705",
     "school_name":"Kofi Adjei Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050705",
     "school_name":"Kofi Adjei Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050705",
     "school_name":"Kofi Adjei Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050705",
     "school_name":"Kofi Adjei Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050705",
     "school_name":"Kofi Adjei Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050705",
     "school_name":"Kofi Adjei Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050705",
     "school_name":"Kofi Adjei Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9081601",
     "school_name":"Kofi Annan Vocational Training Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"AGRICULTURAL MECHANIZATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9081601",
     "school_name":"Kofi Annan Vocational Training Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"BUILDING CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9081601",
     "school_name":"Kofi Annan Vocational Training Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"BUSINESS SECRETARIAL",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9081601",
     "school_name":"Kofi Annan Vocational Training Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"ELECTRICAL ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9081601",
     "school_name":"Kofi Annan Vocational Training Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"FASHION DESIGNING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9081601",
     "school_name":"Kofi Annan Vocational Training Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"HOSPITALITY & CATERING MANAGEMENT",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9081601",
     "school_name":"Kofi Annan Vocational Training Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"INFORMATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9081601",
     "school_name":"Kofi Annan Vocational Training Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"WOOD CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030203",
     "school_name":"Komenda Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030203",
     "school_name":"Komenda Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030203",
     "school_name":"Komenda Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030203",
     "school_name":"Komenda Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030203",
     "school_name":"Komenda Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030203",
     "school_name":"Komenda Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030203",
     "school_name":"Komenda Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0090404",
     "school_name":"Kongo Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0090404",
     "school_name":"Kongo Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0090404",
     "school_name":"Kongo Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0090404",
     "school_name":"Kongo Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9052801",
     "school_name":"Konkoma ICCES",
     "category":"C",
     "school_type":"TVET",
     "program":"BUILDING CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9052801",
     "school_name":"Konkoma ICCES",
     "category":"C",
     "school_type":"TVET",
     "program":"ELECTRICAL ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9052801",
     "school_name":"Konkoma ICCES",
     "category":"C",
     "school_type":"TVET",
     "program":"FASHION DESIGNING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0080302",
     "school_name":"Kpandai Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0080302",
     "school_name":"Kpandai Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0080302",
     "school_name":"Kpandai Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0080302",
     "school_name":"Kpandai Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0080302",
     "school_name":"Kpandai Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070303",
     "school_name":"Kpassa Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070303",
     "school_name":"Kpassa Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070303",
     "school_name":"Kpassa Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070303",
     "school_name":"Kpassa Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070303",
     "school_name":"Kpassa Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070303",
     "school_name":"Kpassa Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070303",
     "school_name":"Kpassa Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070105",
     "school_name":"Kpedze Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070105",
     "school_name":"Kpedze Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070105",
     "school_name":"Kpedze Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070105",
     "school_name":"Kpedze Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070105",
     "school_name":"Kpedze Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070105",
     "school_name":"Kpedze Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070608",
     "school_name":"Kpeve Senior High",
     "category":"C",
     "school_type":"SHTS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070608",
     "school_name":"Kpeve Senior High",
     "category":"C",
     "school_type":"SHTS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070608",
     "school_name":"Kpeve Senior High",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070608",
     "school_name":"Kpeve Senior High",
     "category":"C",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070608",
     "school_name":"Kpeve Senior High",
     "category":"C",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070608",
     "school_name":"Kpeve Senior High",
     "category":"C",
     "school_type":"SHTS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0010227",
     "school_name":"Kpone Comm. Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0010227",
     "school_name":"Kpone Comm. Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0010227",
     "school_name":"Kpone Comm. Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0010227",
     "school_name":"Kpone Comm. Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0010227",
     "school_name":"Kpone Comm. Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0010227",
     "school_name":"Kpone Comm. Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9021502",
     "school_name":"Kpong CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"BUILDING CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9021502",
     "school_name":"Kpong CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"COMPUTER HARDWARE TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9021502",
     "school_name":"Kpong CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"COSMETOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9021502",
     "school_name":"Kpong CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"ELECTRICAL ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9021502",
     "school_name":"Kpong CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"FASHION DESIGNING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9021502",
     "school_name":"Kpong CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"HOSPITALITY & CATERING MANAGEMENT",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9021502",
     "school_name":"Kpong CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"MOTOR VEHICLE ENGINEERING",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9021502",
     "school_name":"Kpong CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"PLUMBING & GAS FITTING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9021502",
     "school_name":"Kpong CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"WOOD CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020203",
     "school_name":"Kraboa-Coaltar Presby Snr. High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020203",
     "school_name":"Kraboa-Coaltar Presby Snr. High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020203",
     "school_name":"Kraboa-Coaltar Presby Snr. High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020203",
     "school_name":"Kraboa-Coaltar Presby Snr. High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020203",
     "school_name":"Kraboa-Coaltar Presby Snr. High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020203",
     "school_name":"Kraboa-Coaltar Presby Snr. High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070201",
     "school_name":"Krachi Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070201",
     "school_name":"Krachi Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070201",
     "school_name":"Krachi Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070201",
     "school_name":"Krachi Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070201",
     "school_name":"Krachi Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070201",
     "school_name":"Krachi Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060806",
     "school_name":"Krobo Comm.Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0060806",
     "school_name":"Krobo Comm.Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0060806",
     "school_name":"Krobo Comm.Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0060806",
     "school_name":"Krobo Comm.Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0060806",
     "school_name":"Krobo Comm.Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0050112",
     "school_name":"Kumasi Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050112",
     "school_name":"Kumasi Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050112",
     "school_name":"Kumasi Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050112",
     "school_name":"Kumasi Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050112",
     "school_name":"Kumasi Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050112",
     "school_name":"Kumasi Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9050103",
     "school_name":"Kumasi VocationalTraining Institute-Kumasi",
     "category":"C",
     "school_type":"TVET",
     "program":"AUTOBODY WORKS",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9050103",
     "school_name":"Kumasi VocationalTraining Institute-Kumasi",
     "category":"C",
     "school_type":"TVET",
     "program":"COMPUTER HARDWARE TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9050103",
     "school_name":"Kumasi VocationalTraining Institute-Kumasi",
     "category":"C",
     "school_type":"TVET",
     "program":"COMPUTER NETWORKING(CBT OPTION, FOR GIRLS ONLY)",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9050103",
     "school_name":"Kumasi VocationalTraining Institute-Kumasi",
     "category":"C",
     "school_type":"TVET",
     "program":"DIESEL MECH\/HEAVY ENGINE, HEAVY DUTY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9050103",
     "school_name":"Kumasi VocationalTraining Institute-Kumasi",
     "category":"C",
     "school_type":"TVET",
     "program":"ELECTRICAL ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9050103",
     "school_name":"Kumasi VocationalTraining Institute-Kumasi",
     "category":"C",
     "school_type":"TVET",
     "program":"ELECTRONICS ENGINEERING",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9050103",
     "school_name":"Kumasi VocationalTraining Institute-Kumasi",
     "category":"C",
     "school_type":"TVET",
     "program":"MECHANICAL. ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9050103",
     "school_name":"Kumasi VocationalTraining Institute-Kumasi",
     "category":"C",
     "school_type":"TVET",
     "program":"MOTOR VEHICLE ENGINEERING",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9050103",
     "school_name":"Kumasi VocationalTraining Institute-Kumasi",
     "category":"C",
     "school_type":"TVET",
     "program":"PRINTING",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9050103",
     "school_name":"Kumasi VocationalTraining Institute-Kumasi",
     "category":"C",
     "school_type":"TVET",
     "program":"WELDING AND FABRICATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9052900",
     "school_name":"Kumawuman Inst. Of Skills Training",
     "category":"C",
     "school_type":"TVET",
     "program":"BUILDING CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9052900",
     "school_name":"Kumawuman Inst. Of Skills Training",
     "category":"C",
     "school_type":"TVET",
     "program":"ELECTRICAL ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9052900",
     "school_name":"Kumawuman Inst. Of Skills Training",
     "category":"C",
     "school_type":"TVET",
     "program":"FASHION DESIGNING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0081202",
     "school_name":"Kumbungu Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0081202",
     "school_name":"Kumbungu Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0081202",
     "school_name":"Kumbungu Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0081202",
     "school_name":"Kumbungu Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0081202",
     "school_name":"Kumbungu Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051008",
     "school_name":"Kurofa Methodist Senior High ",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0051008",
     "school_name":"Kurofa Methodist Senior High ",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0090202",
     "school_name":"Kusanaba Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0090202",
     "school_name":"Kusanaba Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0090202",
     "school_name":"Kusanaba Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0090202",
     "school_name":"Kusanaba Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0090202",
     "school_name":"Kusanaba Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9090600",
     "school_name":"Kusanaba Vocational Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"BUILDING CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9090600",
     "school_name":"Kusanaba Vocational Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"ELECTRICAL ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9090600",
     "school_name":"Kusanaba Vocational Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"FASHION DESIGNING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9090600",
     "school_name":"Kusanaba Vocational Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"HOSPITALITY & CATERING MANAGEMENT",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9090600",
     "school_name":"Kusanaba Vocational Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"WOOD CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0010534",
     "school_name":"Kwabenya Comm. Senior High",
     "category":"C",
     "school_type":"SHTS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0010534",
     "school_name":"Kwabenya Comm. Senior High",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0010534",
     "school_name":"Kwabenya Comm. Senior High",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0010534",
     "school_name":"Kwabenya Comm. Senior High",
     "category":"C",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0010534",
     "school_name":"Kwabenya Comm. Senior High",
     "category":"C",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0010534",
     "school_name":"Kwabenya Comm. Senior High",
     "category":"C",
     "school_type":"SHTS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0061101",
     "school_name":"Kwame Danso Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0061101",
     "school_name":"Kwame Danso Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0061101",
     "school_name":"Kwame Danso Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0061101",
     "school_name":"Kwame Danso Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0061101",
     "school_name":"Kwame Danso Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0061101",
     "school_name":"Kwame Danso Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9050301",
     "school_name":"Kwamo CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"BUILDING CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9050301",
     "school_name":"Kwamo CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"ELECTRICAL ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9050301",
     "school_name":"Kwamo CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"FASHION DESIGNING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9050301",
     "school_name":"Kwamo CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"MOTOR VEHICLE ENGINEERING",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9050301",
     "school_name":"Kwamo CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"PLUMBING & GAS FITTING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9050301",
     "school_name":"Kwamo CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"WELDING AND FABRICATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9050301",
     "school_name":"Kwamo CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"WOOD CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030904",
     "school_name":"Kwanyako Senior High ",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030904",
     "school_name":"Kwanyako Senior High ",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030904",
     "school_name":"Kwanyako Senior High ",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030904",
     "school_name":"Kwanyako Senior High ",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030904",
     "school_name":"Kwanyako Senior High ",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030904",
     "school_name":"Kwanyako Senior High ",
     "category":"C",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020507",
     "school_name":"Kwaobaah Nyanoa Comm. Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0020507",
     "school_name":"Kwaobaah Nyanoa Comm. Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0020507",
     "school_name":"Kwaobaah Nyanoa Comm. Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0020507",
     "school_name":"Kwaobaah Nyanoa Comm. Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0020507",
     "school_name":"Kwaobaah Nyanoa Comm. Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0020507",
     "school_name":"Kwaobaah Nyanoa Comm. Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0060714",
     "school_name":"Kwarteng Ankomah Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060714",
     "school_name":"Kwarteng Ankomah Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060714",
     "school_name":"Kwarteng Ankomah Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060714",
     "school_name":"Kwarteng Ankomah Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060714",
     "school_name":"Kwarteng Ankomah Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030305",
     "school_name":"Kwegyir Aggrey Senior High",
     "category":"C",
     "school_type":"SHTS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030305",
     "school_name":"Kwegyir Aggrey Senior High",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030305",
     "school_name":"Kwegyir Aggrey Senior High",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030305",
     "school_name":"Kwegyir Aggrey Senior High",
     "category":"C",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030305",
     "school_name":"Kwegyir Aggrey Senior High",
     "category":"C",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030305",
     "school_name":"Kwegyir Aggrey Senior High",
     "category":"C",
     "school_type":"SHTS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070305",
     "school_name":"Kyabobo Girls' School",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Girls",
     "residence":"Day"
  },
  {
     "school_code":"0070305",
     "school_name":"Kyabobo Girls' School",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Girls",
     "residence":"Day"
  },
  {
     "school_code":"0070305",
     "school_name":"Kyabobo Girls' School",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Girls",
     "residence":"Day"
  },
  {
     "school_code":"0070305",
     "school_name":"Kyabobo Girls' School",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Girls",
     "residence":"Day"
  },
  {
     "school_code":"0031007",
     "school_name":"Kyekyewere Comm. Senior High School",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0031007",
     "school_name":"Kyekyewere Comm. Senior High School",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0031007",
     "school_name":"Kyekyewere Comm. Senior High School",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0031007",
     "school_name":"Kyekyewere Comm. Senior High School",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0031007",
     "school_name":"Kyekyewere Comm. Senior High School",
     "category":"C",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0010128",
     "school_name":"La Presby Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0010128",
     "school_name":"La Presby Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0010128",
     "school_name":"La Presby Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0010128",
     "school_name":"La Presby Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0010128",
     "school_name":"La Presby Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0100601",
     "school_name":"Lambussie Comm. Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0100601",
     "school_name":"Lambussie Comm. Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0100601",
     "school_name":"Lambussie Comm. Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0100601",
     "school_name":"Lambussie Comm. Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0080507",
     "school_name":"Langbinsi Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0080507",
     "school_name":"Langbinsi Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0080507",
     "school_name":"Langbinsi Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0010545",
     "school_name":"Lashibi Comm. Day",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0010545",
     "school_name":"Lashibi Comm. Day",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0010545",
     "school_name":"Lashibi Comm. Day",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0010545",
     "school_name":"Lashibi Comm. Day",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0010545",
     "school_name":"Lashibi Comm. Day",
     "category":"C",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0071004",
     "school_name":"Leklebi Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0071004",
     "school_name":"Leklebi Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0071004",
     "school_name":"Leklebi Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0071004",
     "school_name":"Leklebi Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0071004",
     "school_name":"Leklebi Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0071004",
     "school_name":"Leklebi Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0071001",
     "school_name":"Likpe Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0071001",
     "school_name":"Likpe Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0071001",
     "school_name":"Likpe Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0071001",
     "school_name":"Likpe Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0071001",
     "school_name":"Likpe Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0071001",
     "school_name":"Likpe Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0100111",
     "school_name":"Loggu Comm. Day School",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0100111",
     "school_name":"Loggu Comm. Day School",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0100111",
     "school_name":"Loggu Comm. Day School",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0100111",
     "school_name":"Loggu Comm. Day School",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0020904",
     "school_name":"Maame Krobo Comm. Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0020904",
     "school_name":"Maame Krobo Comm. Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0020904",
     "school_name":"Maame Krobo Comm. Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0020904",
     "school_name":"Maame Krobo Comm. Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0020904",
     "school_name":"Maame Krobo Comm. Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9010901",
     "school_name":"Madina CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"BUILDING CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9010901",
     "school_name":"Madina CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"COSMETOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9010901",
     "school_name":"Madina CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"ELECTRICAL ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9010901",
     "school_name":"Madina CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"FASHION DESIGNING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9010901",
     "school_name":"Madina CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"HOSPITALITY & CATERING MANAGEMENT",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9010901",
     "school_name":"Madina CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"MOTOR VEHICLE ENGINEERING",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020404",
     "school_name":"Mampong\/Akw Snr. High\/Tech for the Deaf",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020404",
     "school_name":"Mampong\/Akw Snr. High\/Tech for the Deaf",
     "category":"C",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020404",
     "school_name":"Mampong\/Akw Snr. High\/Tech for the Deaf",
     "category":"C",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020404",
     "school_name":"Mampong\/Akw Snr. High\/Tech for the Deaf",
     "category":"C",
     "school_type":"SHTS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030701",
     "school_name":"Mando Senior High\/Tech. ",
     "category":"C",
     "school_type":"SHTS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030701",
     "school_name":"Mando Senior High\/Tech. ",
     "category":"C",
     "school_type":"SHTS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030701",
     "school_name":"Mando Senior High\/Tech. ",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030701",
     "school_name":"Mando Senior High\/Tech. ",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030701",
     "school_name":"Mando Senior High\/Tech. ",
     "category":"C",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030701",
     "school_name":"Mando Senior High\/Tech. ",
     "category":"C",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030701",
     "school_name":"Mando Senior High\/Tech. ",
     "category":"C",
     "school_type":"SHTS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020409",
     "school_name":"Mangoase Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020409",
     "school_name":"Mangoase Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020409",
     "school_name":"Mangoase Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020409",
     "school_name":"Mangoase Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020409",
     "school_name":"Mangoase Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020409",
     "school_name":"Mangoase Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030303",
     "school_name":"Mankessim Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030303",
     "school_name":"Mankessim Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030303",
     "school_name":"Mankessim Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030303",
     "school_name":"Mankessim Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030303",
     "school_name":"Mankessim Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030303",
     "school_name":"Mankessim Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030303",
     "school_name":"Mankessim Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9030501",
     "school_name":"Mankoadzi ICCES",
     "category":"C",
     "school_type":"TVET",
     "program":"BUILDING CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9030501",
     "school_name":"Mankoadzi ICCES",
     "category":"C",
     "school_type":"TVET",
     "program":"ELECTRICAL ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9030501",
     "school_name":"Mankoadzi ICCES",
     "category":"C",
     "school_type":"TVET",
     "program":"FASHION DESIGNING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9030501",
     "school_name":"Mankoadzi ICCES",
     "category":"C",
     "school_type":"TVET",
     "program":"HOSPITALITY & CATERING MANAGEMENT",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051501",
     "school_name":"Mankranso Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051501",
     "school_name":"Mankranso Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051501",
     "school_name":"Mankranso Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051501",
     "school_name":"Mankranso Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051501",
     "school_name":"Mankranso Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051501",
     "school_name":"Mankranso Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050403",
     "school_name":"Manso-Adubia Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050403",
     "school_name":"Manso-Adubia Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050403",
     "school_name":"Manso-Adubia Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050403",
     "school_name":"Manso-Adubia Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050403",
     "school_name":"Manso-Adubia Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040504",
     "school_name":"Manso-Amenfi Comm. Day School",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0040504",
     "school_name":"Manso-Amenfi Comm. Day School",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0040504",
     "school_name":"Manso-Amenfi Comm. Day School",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0040504",
     "school_name":"Manso-Amenfi Comm. Day School",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0040504",
     "school_name":"Manso-Amenfi Comm. Day School",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0040504",
     "school_name":"Manso-Amenfi Comm. Day School",
     "category":"C",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9041200",
     "school_name":"Manso-Amenfi Vocational Training Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"BUILDING CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9041200",
     "school_name":"Manso-Amenfi Vocational Training Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"ELECTRICAL ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9041200",
     "school_name":"Manso-Amenfi Vocational Training Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"FASHION DESIGNING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9041200",
     "school_name":"Manso-Amenfi Vocational Training Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"HOSPITALITY & CATERING MANAGEMENT",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9041200",
     "school_name":"Manso-Amenfi Vocational Training Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"MOTOR VEHICLE ENGINEERING",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050401",
     "school_name":"Mansoman Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050401",
     "school_name":"Mansoman Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050401",
     "school_name":"Mansoman Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050401",
     "school_name":"Mansoman Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020903",
     "school_name":"Mem-Chemfre Comm. Senior High",
     "category":"C",
     "school_type":"SHTS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0020903",
     "school_name":"Mem-Chemfre Comm. Senior High",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0020903",
     "school_name":"Mem-Chemfre Comm. Senior High",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0020903",
     "school_name":"Mem-Chemfre Comm. Senior High",
     "category":"C",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0020903",
     "school_name":"Mem-Chemfre Comm. Senior High",
     "category":"C",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0070804",
     "school_name":"Mepe St. Kizito Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070804",
     "school_name":"Mepe St. Kizito Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070804",
     "school_name":"Mepe St. Kizito Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070804",
     "school_name":"Mepe St. Kizito Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070804",
     "school_name":"Mepe St. Kizito Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070804",
     "school_name":"Mepe St. Kizito Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070804",
     "school_name":"Mepe St. Kizito Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040112",
     "school_name":"Methodist Senior High, Sekondi",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0040112",
     "school_name":"Methodist Senior High, Sekondi",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0040112",
     "school_name":"Methodist Senior High, Sekondi",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0040112",
     "school_name":"Methodist Senior High, Sekondi",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0060304",
     "school_name":"Methodist Senior High\/Tech.,Biadan",
     "category":"C",
     "school_type":"SHTS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060304",
     "school_name":"Methodist Senior High\/Tech.,Biadan",
     "category":"C",
     "school_type":"SHTS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060304",
     "school_name":"Methodist Senior High\/Tech.,Biadan",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060304",
     "school_name":"Methodist Senior High\/Tech.,Biadan",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060304",
     "school_name":"Methodist Senior High\/Tech.,Biadan",
     "category":"C",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060304",
     "school_name":"Methodist Senior High\/Tech.,Biadan",
     "category":"C",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9050102",
     "school_name":"Methodist Technincal  Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"BUILDING CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9050102",
     "school_name":"Methodist Technincal  Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"COMPUTER HARDWARE TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9050102",
     "school_name":"Methodist Technincal  Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"ELECTRICAL ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9050102",
     "school_name":"Methodist Technincal  Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"ELECTRONICS ENGINEERING",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9050102",
     "school_name":"Methodist Technincal  Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"FASHION DESIGNING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9050102",
     "school_name":"Methodist Technincal  Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"HOSPITALITY & CATERING MANAGEMENT",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9050102",
     "school_name":"Methodist Technincal  Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"INFORMATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9050102",
     "school_name":"Methodist Technincal  Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"MECHANICAL. ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9050102",
     "school_name":"Methodist Technincal  Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"MOTOR VEHICLE ENGINEERING",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9050102",
     "school_name":"Methodist Technincal  Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"PLUMBING & GAS FITTING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9050102",
     "school_name":"Methodist Technincal  Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"WELDING AND FABRICATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9050102",
     "school_name":"Methodist Technincal  Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"WOOD CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0090510",
     "school_name":"Mirigu Community Day Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0090510",
     "school_name":"Mirigu Community Day Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0090510",
     "school_name":"Mirigu Community Day Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0031107",
     "school_name":"Mokwaa Senior High Sch",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0031107",
     "school_name":"Mokwaa Senior High Sch",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0030406",
     "school_name":"Moree Comm. Senior High",
     "category":"C",
     "school_type":"SHTS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0030406",
     "school_name":"Moree Comm. Senior High",
     "category":"C",
     "school_type":"SHTS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0030406",
     "school_name":"Moree Comm. Senior High",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0030406",
     "school_name":"Moree Comm. Senior High",
     "category":"C",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0030406",
     "school_name":"Moree Comm. Senior High",
     "category":"C",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0030406",
     "school_name":"Moree Comm. Senior High",
     "category":"C",
     "school_type":"SHTS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0081504",
     "school_name":"Mpaha Comm. Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0081504",
     "school_name":"Mpaha Comm. Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0081504",
     "school_name":"Mpaha Comm. Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0081504",
     "school_name":"Mpaha Comm. Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0090301",
     "school_name":"Naab Azantilow Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0090301",
     "school_name":"Naab Azantilow Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0090301",
     "school_name":"Naab Azantilow Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0090301",
     "school_name":"Naab Azantilow Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0090301",
     "school_name":"Naab Azantilow Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0090301",
     "school_name":"Naab Azantilow Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0090511",
     "school_name":"Nabango Senior High School",
     "category":"C",
     "school_type":"SHTS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0090511",
     "school_name":"Nabango Senior High School",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0090511",
     "school_name":"Nabango Senior High School",
     "category":"C",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0080503",
     "school_name":"Nakpanduri Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0080503",
     "school_name":"Nakpanduri Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0080503",
     "school_name":"Nakpanduri Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0080501",
     "school_name":"Nalerigu Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0080501",
     "school_name":"Nalerigu Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0080501",
     "school_name":"Nalerigu Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0080501",
     "school_name":"Nalerigu Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0080501",
     "school_name":"Nalerigu Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9081000",
     "school_name":"Nalerigu YLSTI",
     "category":"C",
     "school_type":"TVET",
     "program":"AGRICULTURAL MECHANIZATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9081000",
     "school_name":"Nalerigu YLSTI",
     "category":"C",
     "school_type":"TVET",
     "program":"BUILDING CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9081000",
     "school_name":"Nalerigu YLSTI",
     "category":"C",
     "school_type":"TVET",
     "program":"ELECTRICAL ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9081000",
     "school_name":"Nalerigu YLSTI",
     "category":"C",
     "school_type":"TVET",
     "program":"FASHION DESIGNING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9081000",
     "school_name":"Nalerigu YLSTI",
     "category":"C",
     "school_type":"TVET",
     "program":"GENERAL TEXTILES",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9081000",
     "school_name":"Nalerigu YLSTI",
     "category":"C",
     "school_type":"TVET",
     "program":"WOOD CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9091200",
     "school_name":"Namalteng Integrated Vocational Training InstituteI",
     "category":"C",
     "school_type":"TVET",
     "program":"BUILDING CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9091200",
     "school_name":"Namalteng Integrated Vocational Training InstituteI",
     "category":"C",
     "school_type":"TVET",
     "program":"ELECTRICAL ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9091200",
     "school_name":"Namalteng Integrated Vocational Training InstituteI",
     "category":"C",
     "school_type":"TVET",
     "program":"FASHION DESIGNING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9091200",
     "school_name":"Namalteng Integrated Vocational Training InstituteI",
     "category":"C",
     "school_type":"TVET",
     "program":"GENERAL TEXTILES",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9091200",
     "school_name":"Namalteng Integrated Vocational Training InstituteI",
     "category":"C",
     "school_type":"TVET",
     "program":"HOSPITALITY & CATERING MANAGEMENT",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9091200",
     "school_name":"Namalteng Integrated Vocational Training InstituteI",
     "category":"C",
     "school_type":"TVET",
     "program":"INFORMATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9091200",
     "school_name":"Namalteng Integrated Vocational Training InstituteI",
     "category":"C",
     "school_type":"TVET",
     "program":"PLUMBING & GAS FITTING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9091200",
     "school_name":"Namalteng Integrated Vocational Training InstituteI",
     "category":"C",
     "school_type":"TVET",
     "program":"WOOD CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050803",
     "school_name":"Namong Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050803",
     "school_name":"Namong Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050803",
     "school_name":"Namong Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050803",
     "school_name":"Namong Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050803",
     "school_name":"Namong Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050803",
     "school_name":"Namong Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050803",
     "school_name":"Namong Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0041001",
     "school_name":"Nana Brentu Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0041001",
     "school_name":"Nana Brentu Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0041001",
     "school_name":"Nana Brentu Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0041001",
     "school_name":"Nana Brentu Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0041001",
     "school_name":"Nana Brentu Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0041001",
     "school_name":"Nana Brentu Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0041001",
     "school_name":"Nana Brentu Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9031101",
     "school_name":"National Vocational Training Centre – Agona - Swedru",
     "category":"C",
     "school_type":"TVET",
     "program":"BUILDING CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9031101",
     "school_name":"National Vocational Training Centre – Agona - Swedru",
     "category":"C",
     "school_type":"TVET",
     "program":"COSMETOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9031101",
     "school_name":"National Vocational Training Centre – Agona - Swedru",
     "category":"C",
     "school_type":"TVET",
     "program":"ELECTRICAL ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9031101",
     "school_name":"National Vocational Training Centre – Agona - Swedru",
     "category":"C",
     "school_type":"TVET",
     "program":"FASHION DESIGNING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9031101",
     "school_name":"National Vocational Training Centre – Agona - Swedru",
     "category":"C",
     "school_type":"TVET",
     "program":"HOSPITALITY & CATERING MANAGEMENT",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9031101",
     "school_name":"National Vocational Training Centre – Agona - Swedru",
     "category":"C",
     "school_type":"TVET",
     "program":"MOTOR VEHICLE ENGINEERING",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9031101",
     "school_name":"National Vocational Training Centre – Agona - Swedru",
     "category":"C",
     "school_type":"TVET",
     "program":"PLUMBING & GAS FITTING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9031101",
     "school_name":"National Vocational Training Centre – Agona - Swedru",
     "category":"C",
     "school_type":"TVET",
     "program":"WOOD CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9090902",
     "school_name":"Navrongo CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"BUILDING CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9090902",
     "school_name":"Navrongo CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"COSMETOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9090902",
     "school_name":"Navrongo CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"ELECTRICAL ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9090902",
     "school_name":"Navrongo CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"FASHION DESIGNING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9090902",
     "school_name":"Navrongo CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"HOSPITALITY & CATERING MANAGEMENT",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9090902",
     "school_name":"Navrongo CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"MOTOR VEHICLE ENGINEERING",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070208",
     "school_name":"Nchumuruman Comm. Day Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0070208",
     "school_name":"Nchumuruman Comm. Day Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0070208",
     "school_name":"Nchumuruman Comm. Day Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0070208",
     "school_name":"Nchumuruman Comm. Day Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0080202",
     "school_name":"Ndewura Jakpa Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0080202",
     "school_name":"Ndewura Jakpa Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0080202",
     "school_name":"Ndewura Jakpa Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0080202",
     "school_name":"Ndewura Jakpa Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0080202",
     "school_name":"Ndewura Jakpa Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9051400",
     "school_name":"Nerebehi ICCES",
     "category":"C",
     "school_type":"TVET",
     "program":"ARCHITECTURAL DRAFTING",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9051400",
     "school_name":"Nerebehi ICCES",
     "category":"C",
     "school_type":"TVET",
     "program":"BUILDING CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9051400",
     "school_name":"Nerebehi ICCES",
     "category":"C",
     "school_type":"TVET",
     "program":"ELECTRICAL ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9051400",
     "school_name":"Nerebehi ICCES",
     "category":"C",
     "school_type":"TVET",
     "program":"FASHION DESIGNING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9051400",
     "school_name":"Nerebehi ICCES",
     "category":"C",
     "school_type":"TVET",
     "program":"HOSPITALITY & CATERING MANAGEMENT",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0020702",
     "school_name":"New Abirem\/Afosu Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020702",
     "school_name":"New Abirem\/Afosu Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020702",
     "school_name":"New Abirem\/Afosu Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020702",
     "school_name":"New Abirem\/Afosu Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020702",
     "school_name":"New Abirem\/Afosu Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9010104",
     "school_name":"New Century Career Training Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"ARCHITECTURAL DRAFTING",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9010104",
     "school_name":"New Century Career Training Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"BUILDING CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9010104",
     "school_name":"New Century Career Training Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"COMPUTER HARDWARE TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9010104",
     "school_name":"New Century Career Training Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"ELECTRICAL ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9010104",
     "school_name":"New Century Career Training Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"ELECTRONICS ENGINEERING",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9010104",
     "school_name":"New Century Career Training Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"FASHION DESIGNING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9010104",
     "school_name":"New Century Career Training Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"HOSPITALITY & CATERING MANAGEMENT",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9010104",
     "school_name":"New Century Career Training Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"PLUMBING & GAS FITTING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9010104",
     "school_name":"New Century Career Training Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"REFRIGERATION & AIR-CONDITION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9010104",
     "school_name":"New Century Career Training Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"WOOD CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060409",
     "school_name":"New Krokompe Comm. Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060409",
     "school_name":"New Krokompe Comm. Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060409",
     "school_name":"New Krokompe Comm. Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060409",
     "school_name":"New Krokompe Comm. Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060612",
     "school_name":"New Longoro Comm.School (Dega)",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0060612",
     "school_name":"New Longoro Comm.School (Dega)",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0060612",
     "school_name":"New Longoro Comm.School (Dega)",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0060612",
     "school_name":"New Longoro Comm.School (Dega)",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0060612",
     "school_name":"New Longoro Comm.School (Dega)",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0060612",
     "school_name":"New Longoro Comm.School (Dega)",
     "category":"C",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0021203",
     "school_name":"New Nsutam Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021203",
     "school_name":"New Nsutam Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021203",
     "school_name":"New Nsutam Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021203",
     "school_name":"New Nsutam Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021203",
     "school_name":"New Nsutam Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021203",
     "school_name":"New Nsutam Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9011302",
     "school_name":"Ngleshie Amanfro ICCES",
     "category":"C",
     "school_type":"TVET",
     "program":"BUILDING CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9011302",
     "school_name":"Ngleshie Amanfro ICCES",
     "category":"C",
     "school_type":"TVET",
     "program":"ELECTRICAL ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9011302",
     "school_name":"Ngleshie Amanfro ICCES",
     "category":"C",
     "school_type":"TVET",
     "program":"FASHION DESIGNING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9011302",
     "school_name":"Ngleshie Amanfro ICCES",
     "category":"C",
     "school_type":"TVET",
     "program":"HOSPITALITY & CATERING MANAGEMENT",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9011302",
     "school_name":"Ngleshie Amanfro ICCES",
     "category":"C",
     "school_type":"TVET",
     "program":"MOTOR VEHICLE ENGINEERING",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0010503",
     "school_name":"Ngleshie Amanfro Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0010503",
     "school_name":"Ngleshie Amanfro Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0010503",
     "school_name":"Ngleshie Amanfro Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0010503",
     "school_name":"Ngleshie Amanfro Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0010503",
     "school_name":"Ngleshie Amanfro Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0010503",
     "school_name":"Ngleshie Amanfro Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050204",
     "school_name":"Nkawie Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050204",
     "school_name":"Nkawie Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050204",
     "school_name":"Nkawie Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050204",
     "school_name":"Nkawie Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050204",
     "school_name":"Nkawie Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050204",
     "school_name":"Nkawie Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050204",
     "school_name":"Nkawie Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050805",
     "school_name":"Nkenkansu Community Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050805",
     "school_name":"Nkenkansu Community Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050805",
     "school_name":"Nkenkansu Community Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050805",
     "school_name":"Nkenkansu Community Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0071103",
     "school_name":"Nkonya Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0071103",
     "school_name":"Nkonya Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0071103",
     "school_name":"Nkonya Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0071103",
     "school_name":"Nkonya Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0071103",
     "school_name":"Nkonya Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0071103",
     "school_name":"Nkonya Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040302",
     "school_name":"Nkroful Agric. Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040302",
     "school_name":"Nkroful Agric. Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040302",
     "school_name":"Nkroful Agric. Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040302",
     "school_name":"Nkroful Agric. Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040302",
     "school_name":"Nkroful Agric. Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040302",
     "school_name":"Nkroful Agric. Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070304",
     "school_name":"Nkwanta Comm.Senior High",
     "category":"C",
     "school_type":"SHTS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0070304",
     "school_name":"Nkwanta Comm.Senior High",
     "category":"C",
     "school_type":"SHTS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0070304",
     "school_name":"Nkwanta Comm.Senior High",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0070304",
     "school_name":"Nkwanta Comm.Senior High",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0070304",
     "school_name":"Nkwanta Comm.Senior High",
     "category":"C",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0070304",
     "school_name":"Nkwanta Comm.Senior High",
     "category":"C",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0070304",
     "school_name":"Nkwanta Comm.Senior High",
     "category":"C",
     "school_type":"SHTS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0070301",
     "school_name":"Nkwanta Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070301",
     "school_name":"Nkwanta Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070301",
     "school_name":"Nkwanta Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070301",
     "school_name":"Nkwanta Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070301",
     "school_name":"Nkwanta Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070301",
     "school_name":"Nkwanta Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060611",
     "school_name":"Nkyeraa Senior High Sch.",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0060611",
     "school_name":"Nkyeraa Senior High Sch.",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0060611",
     "school_name":"Nkyeraa Senior High Sch.",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0060611",
     "school_name":"Nkyeraa Senior High Sch.",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0080103",
     "school_name":"Northern School of Business",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0080103",
     "school_name":"Northern School of Business",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0080103",
     "school_name":"Northern School of Business",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0080103",
     "school_name":"Northern School of Business",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0100109",
     "school_name":"Northern Star Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0100109",
     "school_name":"Northern Star Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0100109",
     "school_name":"Northern Star Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0020304",
     "school_name":"Nsawam Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0020304",
     "school_name":"Nsawam Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0020304",
     "school_name":"Nsawam Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0020304",
     "school_name":"Nsawam Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0020304",
     "school_name":"Nsawam Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0040607",
     "school_name":"Nsawora Edumafa Comm. Senior High School",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040607",
     "school_name":"Nsawora Edumafa Comm. Senior High School",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040607",
     "school_name":"Nsawora Edumafa Comm. Senior High School",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040607",
     "school_name":"Nsawora Edumafa Comm. Senior High School",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040607",
     "school_name":"Nsawora Edumafa Comm. Senior High School",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040607",
     "school_name":"Nsawora Edumafa Comm. Senior High School",
     "category":"C",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9062602",
     "school_name":"Nsoatre CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"BUILDING CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9062602",
     "school_name":"Nsoatre CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"COMPUTER HARDWARE TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9062602",
     "school_name":"Nsoatre CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"COSMETOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9062602",
     "school_name":"Nsoatre CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"ELECTRICAL ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9062602",
     "school_name":"Nsoatre CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"FASHION DESIGNING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9062602",
     "school_name":"Nsoatre CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"HOSPITALITY & CATERING MANAGEMENT",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9062602",
     "school_name":"Nsoatre CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"INFORMATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9062602",
     "school_name":"Nsoatre CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"MOTOR VEHICLE ENGINEERING",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9062602",
     "school_name":"Nsoatre CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"PLUMBING & GAS FITTING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9062602",
     "school_name":"Nsoatre CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"WOOD CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070302",
     "school_name":"Ntruboman Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070302",
     "school_name":"Ntruboman Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070302",
     "school_name":"Ntruboman Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070302",
     "school_name":"Ntruboman Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070302",
     "school_name":"Ntruboman Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0031203",
     "school_name":"Nyankumase Ahenkro Snr. High",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0031203",
     "school_name":"Nyankumase Ahenkro Snr. High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0031203",
     "school_name":"Nyankumase Ahenkro Snr. High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0031203",
     "school_name":"Nyankumase Ahenkro Snr. High",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0031203",
     "school_name":"Nyankumase Ahenkro Snr. High",
     "category":"C",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050206",
     "school_name":"Nyinahin Cath. Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050206",
     "school_name":"Nyinahin Cath. Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050206",
     "school_name":"Nyinahin Cath. Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050206",
     "school_name":"Nyinahin Cath. Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050206",
     "school_name":"Nyinahin Cath. Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050206",
     "school_name":"Nyinahin Cath. Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9040400",
     "school_name":"Nzema Maale YLSTI",
     "category":"C",
     "school_type":"TVET",
     "program":"BUILDING CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"DAY\/Boarding"
  },
  {
     "school_code":"9040400",
     "school_name":"Nzema Maale YLSTI",
     "category":"C",
     "school_type":"TVET",
     "program":"MOTOR VEHICLE ENGINEERING",
     "gender":"Mixed",
     "residence":"DAY\/Boarding"
  },
  {
     "school_code":"9040400",
     "school_name":"Nzema Maale YLSTI",
     "category":"C",
     "school_type":"TVET",
     "program":"WELDING AND FABRICATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"DAY\/Boarding"
  },
  {
     "school_code":"0010108",
     "school_name":"O'Reilly Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0010108",
     "school_name":"O'Reilly Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0010108",
     "school_name":"O'Reilly Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0010108",
     "school_name":"O'Reilly Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0010108",
     "school_name":"O'Reilly Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0010108",
     "school_name":"O'Reilly Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0090502",
     "school_name":"O.L.L. Girls Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0090502",
     "school_name":"O.L.L. Girls Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0090502",
     "school_name":"O.L.L. Girls Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0090502",
     "school_name":"O.L.L. Girls Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0090502",
     "school_name":"O.L.L. Girls Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0031202",
     "school_name":"Obiri Yeboah Senior High\/Technical",
     "category":"C",
     "school_type":"SHTS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0031202",
     "school_name":"Obiri Yeboah Senior High\/Technical",
     "category":"C",
     "school_type":"SHTS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0031202",
     "school_name":"Obiri Yeboah Senior High\/Technical",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0031202",
     "school_name":"Obiri Yeboah Senior High\/Technical",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0031202",
     "school_name":"Obiri Yeboah Senior High\/Technical",
     "category":"C",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0031202",
     "school_name":"Obiri Yeboah Senior High\/Technical",
     "category":"C",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030603",
     "school_name":"Obrachire Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030603",
     "school_name":"Obrachire Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030603",
     "school_name":"Obrachire Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030603",
     "school_name":"Obrachire Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030603",
     "school_name":"Obrachire Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030603",
     "school_name":"Obrachire Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030603",
     "school_name":"Obrachire Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051202",
     "school_name":"Obuasi Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051202",
     "school_name":"Obuasi Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051202",
     "school_name":"Obuasi Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051202",
     "school_name":"Obuasi Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051202",
     "school_name":"Obuasi Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051202",
     "school_name":"Obuasi Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030802",
     "school_name":"Odoben Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030802",
     "school_name":"Odoben Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030802",
     "school_name":"Odoben Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030802",
     "school_name":"Odoben Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030802",
     "school_name":"Odoben Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030802",
     "school_name":"Odoben Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030629",
     "school_name":"Odupong Comm. Day School",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0030629",
     "school_name":"Odupong Comm. Day School",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0030629",
     "school_name":"Odupong Comm. Day School",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0030629",
     "school_name":"Odupong Comm. Day School",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0030629",
     "school_name":"Odupong Comm. Day School",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0030629",
     "school_name":"Odupong Comm. Day School",
     "category":"C",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0051103",
     "school_name":"Ofoase Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051103",
     "school_name":"Ofoase Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051103",
     "school_name":"Ofoase Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051103",
     "school_name":"Ofoase Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051103",
     "school_name":"Ofoase Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030108",
     "school_name":"Oguaa Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030108",
     "school_name":"Oguaa Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030108",
     "school_name":"Oguaa Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030108",
     "school_name":"Oguaa Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030108",
     "school_name":"Oguaa Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030108",
     "school_name":"Oguaa Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030108",
     "school_name":"Oguaa Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030509",
     "school_name":"Ogyeedom Comm.Snr High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0030509",
     "school_name":"Ogyeedom Comm.Snr High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0030509",
     "school_name":"Ogyeedom Comm.Snr High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0030509",
     "school_name":"Ogyeedom Comm.Snr High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9040105",
     "school_name":"OICG \nSek-Takoradi Centre\n",
     "category":"C",
     "school_type":"TVET",
     "program":"ARCHITECTURAL DRAFTING",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9040105",
     "school_name":"OICG \nSek-Takoradi Centre\n",
     "category":"C",
     "school_type":"TVET",
     "program":"BUILDING CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9040105",
     "school_name":"OICG \nSek-Takoradi Centre\n",
     "category":"C",
     "school_type":"TVET",
     "program":"BUSINESS SECRETARIAL",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9040105",
     "school_name":"OICG \nSek-Takoradi Centre\n",
     "category":"C",
     "school_type":"TVET",
     "program":"ELECTRICAL ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9040105",
     "school_name":"OICG \nSek-Takoradi Centre\n",
     "category":"C",
     "school_type":"TVET",
     "program":"FASHION DESIGNING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9040105",
     "school_name":"OICG \nSek-Takoradi Centre\n",
     "category":"C",
     "school_type":"TVET",
     "program":"GENERAL TEXTILES",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9040105",
     "school_name":"OICG \nSek-Takoradi Centre\n",
     "category":"C",
     "school_type":"TVET",
     "program":"HOSPITALITY & CATERING MANAGEMENT",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9040105",
     "school_name":"OICG \nSek-Takoradi Centre\n",
     "category":"C",
     "school_type":"TVET",
     "program":"WELDING AND FABRICATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9010123",
     "school_name":"OICG Accra Centre",
     "category":"C",
     "school_type":"TVET",
     "program":"BUILDING CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9010123",
     "school_name":"OICG Accra Centre",
     "category":"C",
     "school_type":"TVET",
     "program":"BUSINESS SECRETARIAL",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9010123",
     "school_name":"OICG Accra Centre",
     "category":"C",
     "school_type":"TVET",
     "program":"CREATIVE ART TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9010123",
     "school_name":"OICG Accra Centre",
     "category":"C",
     "school_type":"TVET",
     "program":"ELECTRICAL ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9010123",
     "school_name":"OICG Accra Centre",
     "category":"C",
     "school_type":"TVET",
     "program":"FASHION DESIGNING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9010123",
     "school_name":"OICG Accra Centre",
     "category":"C",
     "school_type":"TVET",
     "program":"HOSPITALITY & CATERING MANAGEMENT",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9010123",
     "school_name":"OICG Accra Centre",
     "category":"C",
     "school_type":"TVET",
     "program":"MOTOR VEHICLE ENGINEERING",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9010123",
     "school_name":"OICG Accra Centre",
     "category":"C",
     "school_type":"TVET",
     "program":"PLUMBING & GAS FITTING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9050109",
     "school_name":"OICG Kumasi Centre",
     "category":"C",
     "school_type":"TVET",
     "program":"BUILDING CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9050109",
     "school_name":"OICG Kumasi Centre",
     "category":"C",
     "school_type":"TVET",
     "program":"BUSINESS INFORMATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9050109",
     "school_name":"OICG Kumasi Centre",
     "category":"C",
     "school_type":"TVET",
     "program":"ELECTRICAL ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9050109",
     "school_name":"OICG Kumasi Centre",
     "category":"C",
     "school_type":"TVET",
     "program":"FASHION DESIGNING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9050109",
     "school_name":"OICG Kumasi Centre",
     "category":"C",
     "school_type":"TVET",
     "program":"HOSPITALITY & CATERING MANAGEMENT",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9050109",
     "school_name":"OICG Kumasi Centre",
     "category":"C",
     "school_type":"TVET",
     "program":"PLUMBING & GAS FITTING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9050109",
     "school_name":"OICG Kumasi Centre",
     "category":"C",
     "school_type":"TVET",
     "program":"WELDING AND FABRICATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0051615",
     "school_name":"Onwe senior High Senior ",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0051615",
     "school_name":"Onwe senior High Senior ",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0051615",
     "school_name":"Onwe senior High Senior ",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0050608",
     "school_name":"Opoku Agyeman Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0050608",
     "school_name":"Opoku Agyeman Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0050608",
     "school_name":"Opoku Agyeman Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0051614",
     "school_name":"Osei Adutwum Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051614",
     "school_name":"Osei Adutwum Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051614",
     "school_name":"Osei Adutwum Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051614",
     "school_name":"Osei Adutwum Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021202",
     "school_name":"Osino Presby Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021202",
     "school_name":"Osino Presby Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021202",
     "school_name":"Osino Presby Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021202",
     "school_name":"Osino Presby Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021202",
     "school_name":"Osino Presby Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021202",
     "school_name":"Osino Presby Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021202",
     "school_name":"Osino Presby Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0010402",
     "school_name":"Osudoku Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0010402",
     "school_name":"Osudoku Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0010402",
     "school_name":"Osudoku Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0010402",
     "school_name":"Osudoku Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0010402",
     "school_name":"Osudoku Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0010402",
     "school_name":"Osudoku Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070203",
     "school_name":"Oti Senior High\/Tech Sch",
     "category":"C",
     "school_type":"SHTS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070203",
     "school_name":"Oti Senior High\/Tech Sch",
     "category":"C",
     "school_type":"SHTS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070203",
     "school_name":"Oti Senior High\/Tech Sch",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070203",
     "school_name":"Oti Senior High\/Tech Sch",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070203",
     "school_name":"Oti Senior High\/Tech Sch",
     "category":"C",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070203",
     "school_name":"Oti Senior High\/Tech Sch",
     "category":"C",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9061100",
     "school_name":"Our Lady Fatima Vocational Training Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"BUILDING CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9061100",
     "school_name":"Our Lady Fatima Vocational Training Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"BUSINESS SECRETARIAL",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9061100",
     "school_name":"Our Lady Fatima Vocational Training Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"ELECTRICAL ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9061100",
     "school_name":"Our Lady Fatima Vocational Training Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"FASHION DESIGNING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9061100",
     "school_name":"Our Lady Fatima Vocational Training Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"WOOD CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051004",
     "school_name":"Owerriman Senior High",
     "category":"C",
     "school_type":"SHTS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051004",
     "school_name":"Owerriman Senior High",
     "category":"C",
     "school_type":"SHTS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051004",
     "school_name":"Owerriman Senior High",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051004",
     "school_name":"Owerriman Senior High",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051004",
     "school_name":"Owerriman Senior High",
     "category":"C",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051004",
     "school_name":"Owerriman Senior High",
     "category":"C",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051004",
     "school_name":"Owerriman Senior High",
     "category":"C",
     "school_type":"SHTS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0090509",
     "school_name":"Paga Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0090509",
     "school_name":"Paga Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0090509",
     "school_name":"Paga Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0090509",
     "school_name":"Paga Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9030601",
     "school_name":"Panfokrom CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"BUILDING CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9030601",
     "school_name":"Panfokrom CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"COSMETOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9030601",
     "school_name":"Panfokrom CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"ELECTRICAL ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9030601",
     "school_name":"Panfokrom CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"FASHION DESIGNING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9030601",
     "school_name":"Panfokrom CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"HOSPITALITY & CATERING MANAGEMENT",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9030601",
     "school_name":"Panfokrom CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"INFORMATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9030601",
     "school_name":"Panfokrom CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"MOTOR VEHICLE ENGINEERING",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9030601",
     "school_name":"Panfokrom CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"PLUMBING & GAS FITTING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9030601",
     "school_name":"Panfokrom CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"PRINTING",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050194",
     "school_name":"Parkoso Comm. Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0050194",
     "school_name":"Parkoso Comm. Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0050194",
     "school_name":"Parkoso Comm. Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0050194",
     "school_name":"Parkoso Comm. Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0050194",
     "school_name":"Parkoso Comm. Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0050194",
     "school_name":"Parkoso Comm. Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0070607",
     "school_name":"Peki Senior High\/Technical",
     "category":"C",
     "school_type":"SHTS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070607",
     "school_name":"Peki Senior High\/Technical",
     "category":"C",
     "school_type":"SHTS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070607",
     "school_name":"Peki Senior High\/Technical",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070607",
     "school_name":"Peki Senior High\/Technical",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070607",
     "school_name":"Peki Senior High\/Technical",
     "category":"C",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070607",
     "school_name":"Peki Senior High\/Technical",
     "category":"C",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070607",
     "school_name":"Peki Senior High\/Technical",
     "category":"C",
     "school_type":"SHTS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050154",
     "school_name":"Pentecost Senior High, Kumasi",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050154",
     "school_name":"Pentecost Senior High, Kumasi",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050154",
     "school_name":"Pentecost Senior High, Kumasi",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050154",
     "school_name":"Pentecost Senior High, Kumasi",
     "category":"C",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0100303",
     "school_name":"Piina Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0100303",
     "school_name":"Piina Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0100303",
     "school_name":"Piina Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0100303",
     "school_name":"Piina Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9010105",
     "school_name":"Pilot Training Institute-Kokomlemle",
     "category":"C",
     "school_type":"TVET",
     "program":"ARCHITECTURAL DRAFTING",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9010105",
     "school_name":"Pilot Training Institute-Kokomlemle",
     "category":"C",
     "school_type":"TVET",
     "program":"BUILDING CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9010105",
     "school_name":"Pilot Training Institute-Kokomlemle",
     "category":"C",
     "school_type":"TVET",
     "program":"COMPUTER HARDWARE TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9010105",
     "school_name":"Pilot Training Institute-Kokomlemle",
     "category":"C",
     "school_type":"TVET",
     "program":"DATABASE MANAGEMENT(CBT OPTION, FOR GIRLS ONLY)",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9010105",
     "school_name":"Pilot Training Institute-Kokomlemle",
     "category":"C",
     "school_type":"TVET",
     "program":"ELECTRICAL ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9010105",
     "school_name":"Pilot Training Institute-Kokomlemle",
     "category":"C",
     "school_type":"TVET",
     "program":"ELECTRONICS ENGINEERING",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9010105",
     "school_name":"Pilot Training Institute-Kokomlemle",
     "category":"C",
     "school_type":"TVET",
     "program":"MECHANICAL. ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9010105",
     "school_name":"Pilot Training Institute-Kokomlemle",
     "category":"C",
     "school_type":"TVET",
     "program":"REFRIGERATION & AIR-CONDITION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9010105",
     "school_name":"Pilot Training Institute-Kokomlemle",
     "category":"C",
     "school_type":"TVET",
     "program":"WELDING AND FABRICATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9010105",
     "school_name":"Pilot Training Institute-Kokomlemle",
     "category":"C",
     "school_type":"TVET",
     "program":"WOOD CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0081302",
     "school_name":"Pong-Tamale Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0081302",
     "school_name":"Pong-Tamale Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0081302",
     "school_name":"Pong-Tamale Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0081302",
     "school_name":"Pong-Tamale Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0081302",
     "school_name":"Pong-Tamale Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0081302",
     "school_name":"Pong-Tamale Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030502",
     "school_name":"Potsin T.I. Ahm. Senior High",
     "category":"C",
     "school_type":"SHTS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030502",
     "school_name":"Potsin T.I. Ahm. Senior High",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030502",
     "school_name":"Potsin T.I. Ahm. Senior High",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030502",
     "school_name":"Potsin T.I. Ahm. Senior High",
     "category":"C",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030502",
     "school_name":"Potsin T.I. Ahm. Senior High",
     "category":"C",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030502",
     "school_name":"Potsin T.I. Ahm. Senior High",
     "category":"C",
     "school_type":"SHTS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9010601",
     "school_name":"Prampram CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"COSMETOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9010601",
     "school_name":"Prampram CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"ELECTRICAL ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9010601",
     "school_name":"Prampram CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"FASHION DESIGNING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9010601",
     "school_name":"Prampram CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"HOSPITALITY & CATERING MANAGEMENT",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9010601",
     "school_name":"Prampram CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"MOTOR VEHICLE ENGINEERING",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020405",
     "school_name":"Presby  Senior High\/Tech, Larteh",
     "category":"C",
     "school_type":"SHTS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020405",
     "school_name":"Presby  Senior High\/Tech, Larteh",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020405",
     "school_name":"Presby  Senior High\/Tech, Larteh",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020405",
     "school_name":"Presby  Senior High\/Tech, Larteh",
     "category":"C",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020405",
     "school_name":"Presby  Senior High\/Tech, Larteh",
     "category":"C",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020405",
     "school_name":"Presby  Senior High\/Tech, Larteh",
     "category":"C",
     "school_type":"SHTS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020408",
     "school_name":"Presby Senior High, Mampong Akwapim",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020408",
     "school_name":"Presby Senior High, Mampong Akwapim",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020408",
     "school_name":"Presby Senior High, Mampong Akwapim",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020408",
     "school_name":"Presby Senior High, Mampong Akwapim",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020408",
     "school_name":"Presby Senior High, Mampong Akwapim",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020408",
     "school_name":"Presby Senior High, Mampong Akwapim",
     "category":"C",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0010107",
     "school_name":"Presby Senior High, Osu",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0010107",
     "school_name":"Presby Senior High, Osu",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0010107",
     "school_name":"Presby Senior High, Osu",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0010107",
     "school_name":"Presby Senior High, Osu",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0010107",
     "school_name":"Presby Senior High, Osu",
     "category":"C",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0020202",
     "school_name":"Presby Senior High, Suhum",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020202",
     "school_name":"Presby Senior High, Suhum",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020202",
     "school_name":"Presby Senior High, Suhum",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020202",
     "school_name":"Presby Senior High, Suhum",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020202",
     "school_name":"Presby Senior High, Suhum",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020202",
     "school_name":"Presby Senior High, Suhum",
     "category":"C",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0080111",
     "school_name":"Presby Senior High, Tamale",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0080111",
     "school_name":"Presby Senior High, Tamale",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0080111",
     "school_name":"Presby Senior High, Tamale",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0010210",
     "school_name":"Presby Senior High, Tema",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0010210",
     "school_name":"Presby Senior High, Tema",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0010210",
     "school_name":"Presby Senior High, Tema",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0010210",
     "school_name":"Presby Senior High, Tema",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0010210",
     "school_name":"Presby Senior High, Tema",
     "category":"C",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0010116",
     "school_name":"Presby Senior High, Teshie ",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0010116",
     "school_name":"Presby Senior High, Teshie ",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0010116",
     "school_name":"Presby Senior High, Teshie ",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0010116",
     "school_name":"Presby Senior High, Teshie ",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0010116",
     "school_name":"Presby Senior High, Teshie ",
     "category":"C",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0020305",
     "school_name":"Presby Senior High\/Tech, Aburi",
     "category":"C",
     "school_type":"SHTS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020305",
     "school_name":"Presby Senior High\/Tech, Aburi",
     "category":"C",
     "school_type":"SHTS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020305",
     "school_name":"Presby Senior High\/Tech, Aburi",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020305",
     "school_name":"Presby Senior High\/Tech, Aburi",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020305",
     "school_name":"Presby Senior High\/Tech, Aburi",
     "category":"C",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020305",
     "school_name":"Presby Senior High\/Tech, Aburi",
     "category":"C",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020305",
     "school_name":"Presby Senior High\/Tech, Aburi",
     "category":"C",
     "school_type":"SHTS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020407",
     "school_name":"Presby Senior High\/Tech, Adukrom",
     "category":"C",
     "school_type":"SHTS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020407",
     "school_name":"Presby Senior High\/Tech, Adukrom",
     "category":"C",
     "school_type":"SHTS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020407",
     "school_name":"Presby Senior High\/Tech, Adukrom",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020407",
     "school_name":"Presby Senior High\/Tech, Adukrom",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020407",
     "school_name":"Presby Senior High\/Tech, Adukrom",
     "category":"C",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020407",
     "school_name":"Presby Senior High\/Tech, Adukrom",
     "category":"C",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020407",
     "school_name":"Presby Senior High\/Tech, Adukrom",
     "category":"C",
     "school_type":"SHTS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050505",
     "school_name":"Presby Senior High\/Tech, Kwamang",
     "category":"C",
     "school_type":"SHTS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050505",
     "school_name":"Presby Senior High\/Tech, Kwamang",
     "category":"C",
     "school_type":"SHTS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050505",
     "school_name":"Presby Senior High\/Tech, Kwamang",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050505",
     "school_name":"Presby Senior High\/Tech, Kwamang",
     "category":"C",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050505",
     "school_name":"Presby Senior High\/Tech, Kwamang",
     "category":"C",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050505",
     "school_name":"Presby Senior High\/Tech, Kwamang",
     "category":"C",
     "school_type":"SHTS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0080509",
     "school_name":"Presby Senior High\/Tech., Nakpanduri",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0080509",
     "school_name":"Presby Senior High\/Tech., Nakpanduri",
     "category":"C",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0080509",
     "school_name":"Presby Senior High\/Tech., Nakpanduri",
     "category":"C",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0040905",
     "school_name":"Prestea Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040905",
     "school_name":"Prestea Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040905",
     "school_name":"Prestea Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040905",
     "school_name":"Prestea Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040905",
     "school_name":"Prestea Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040905",
     "school_name":"Prestea Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040905",
     "school_name":"Prestea Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050117",
     "school_name":"Prince Of Peace Girls",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Girls",
     "residence":"Day"
  },
  {
     "school_code":"0050117",
     "school_name":"Prince Of Peace Girls",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Girls",
     "residence":"Day"
  },
  {
     "school_code":"0050117",
     "school_name":"Prince Of Peace Girls",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Girls",
     "residence":"Day"
  },
  {
     "school_code":"0090107",
     "school_name":"Pusiga Comm. Day Senior High Sch.",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0090107",
     "school_name":"Pusiga Comm. Day Senior High Sch.",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0090107",
     "school_name":"Pusiga Comm. Day Senior High Sch.",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0090107",
     "school_name":"Pusiga Comm. Day Senior High Sch.",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0090107",
     "school_name":"Pusiga Comm. Day Senior High Sch.",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0040704",
     "school_name":"Queens Girls' Senior High, Sefwi Awhiaso",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040704",
     "school_name":"Queens Girls' Senior High, Sefwi Awhiaso",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040704",
     "school_name":"Queens Girls' Senior High, Sefwi Awhiaso",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040704",
     "school_name":"Queens Girls' Senior High, Sefwi Awhiaso",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040704",
     "school_name":"Queens Girls' Senior High, Sefwi Awhiaso",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040704",
     "school_name":"Queens Girls' Senior High, Sefwi Awhiaso",
     "category":"C",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021601",
     "school_name":"S.D.A. Senior High. Akim Sekyere",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021601",
     "school_name":"S.D.A. Senior High. Akim Sekyere",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021601",
     "school_name":"S.D.A. Senior High. Akim Sekyere",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021601",
     "school_name":"S.D.A. Senior High. Akim Sekyere",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021601",
     "school_name":"S.D.A. Senior High. Akim Sekyere",
     "category":"C",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0080902",
     "school_name":"Saboba E.P. Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0080902",
     "school_name":"Saboba E.P. Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0080902",
     "school_name":"Saboba E.P. Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0080902",
     "school_name":"Saboba E.P. Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051503",
     "school_name":"Sabronum Methodist Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0051503",
     "school_name":"Sabronum Methodist Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0051503",
     "school_name":"Sabronum Methodist Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0051503",
     "school_name":"Sabronum Methodist Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0080506",
     "school_name":"Sakogu Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0080506",
     "school_name":"Sakogu Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0080506",
     "school_name":"Sakogu Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0080301",
     "school_name":"Salaga Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0080301",
     "school_name":"Salaga Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0080301",
     "school_name":"Salaga Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0080301",
     "school_name":"Salaga Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0080301",
     "school_name":"Salaga Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0080301",
     "school_name":"Salaga Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0080303",
     "school_name":"Salaga T.I. Ahmad Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0080303",
     "school_name":"Salaga T.I. Ahmad Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0080303",
     "school_name":"Salaga T.I. Ahmad Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0080303",
     "school_name":"Salaga T.I. Ahmad Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0080303",
     "school_name":"Salaga T.I. Ahmad Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021104",
     "school_name":"Salvation Army Senior High, Akim Wenchi",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021104",
     "school_name":"Salvation Army Senior High, Akim Wenchi",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021104",
     "school_name":"Salvation Army Senior High, Akim Wenchi",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021104",
     "school_name":"Salvation Army Senior High, Akim Wenchi",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021104",
     "school_name":"Salvation Army Senior High, Akim Wenchi",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0090303",
     "school_name":"Sandema Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0090303",
     "school_name":"Sandema Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0090303",
     "school_name":"Sandema Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0090303",
     "school_name":"Sandema Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0090303",
     "school_name":"Sandema Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0090303",
     "school_name":"Sandema Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9091000",
     "school_name":"Sandema YLSTI",
     "category":"C",
     "school_type":"TVET",
     "program":"AGRICULTURAL MECHANIZATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9091000",
     "school_name":"Sandema YLSTI",
     "category":"C",
     "school_type":"TVET",
     "program":"BUILDING CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9091000",
     "school_name":"Sandema YLSTI",
     "category":"C",
     "school_type":"TVET",
     "program":"ELECTRICAL ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9091000",
     "school_name":"Sandema YLSTI",
     "category":"C",
     "school_type":"TVET",
     "program":"FASHION DESIGNING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9091000",
     "school_name":"Sandema YLSTI",
     "category":"C",
     "school_type":"TVET",
     "program":"HOSPITALITY & CATERING MANAGEMENT",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0080805",
     "school_name":"Sang Comm. Day School",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0080805",
     "school_name":"Sang Comm. Day School",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0080805",
     "school_name":"Sang Comm. Day School",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0080805",
     "school_name":"Sang Comm. Day School",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0080805",
     "school_name":"Sang Comm. Day School",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0040203",
     "school_name":"Sankor Comm. Senior High School",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040203",
     "school_name":"Sankor Comm. Senior High School",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040203",
     "school_name":"Sankor Comm. Senior High School",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040203",
     "school_name":"Sankor Comm. Senior High School",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0081301",
     "school_name":"Savelugu Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0081301",
     "school_name":"Savelugu Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0081301",
     "school_name":"Savelugu Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0081301",
     "school_name":"Savelugu Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0081301",
     "school_name":"Savelugu Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9081903",
     "school_name":"Savelugu Technical Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"BUILDING CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9081903",
     "school_name":"Savelugu Technical Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"BUSINESS INFORMATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9081903",
     "school_name":"Savelugu Technical Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"CREATIVE ART TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9081903",
     "school_name":"Savelugu Technical Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"ELECTRICAL ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9081903",
     "school_name":"Savelugu Technical Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"FASHION DESIGNING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9081903",
     "school_name":"Savelugu Technical Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"HOSPITALITY & CATERING MANAGEMENT",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0080205",
     "school_name":"Sawla Senior High Sch.",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0080205",
     "school_name":"Sawla Senior High Sch.",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0080205",
     "school_name":"Sawla Senior High Sch.",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030602",
     "school_name":"Senya Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030602",
     "school_name":"Senya Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030602",
     "school_name":"Senya Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030602",
     "school_name":"Senya Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030602",
     "school_name":"Senya Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030602",
     "school_name":"Senya Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9041100",
     "school_name":"Shama ICCES",
     "category":"C",
     "school_type":"TVET",
     "program":"ARCHITECTURAL DRAFTING",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9041100",
     "school_name":"Shama ICCES",
     "category":"C",
     "school_type":"TVET",
     "program":"BUILDING CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9041100",
     "school_name":"Shama ICCES",
     "category":"C",
     "school_type":"TVET",
     "program":"COSMETOLOGY",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9041100",
     "school_name":"Shama ICCES",
     "category":"C",
     "school_type":"TVET",
     "program":"ELECTRICAL ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9041100",
     "school_name":"Shama ICCES",
     "category":"C",
     "school_type":"TVET",
     "program":"FASHION DESIGNING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9041100",
     "school_name":"Shama ICCES",
     "category":"C",
     "school_type":"TVET",
     "program":"HOSPITALITY & CATERING MANAGEMENT",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0070122",
     "school_name":"Shia Senior HighTechnical",
     "category":"C",
     "school_type":"SHTS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0070122",
     "school_name":"Shia Senior HighTechnical",
     "category":"C",
     "school_type":"SHTS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0070122",
     "school_name":"Shia Senior HighTechnical",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0070122",
     "school_name":"Shia Senior HighTechnical",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0070122",
     "school_name":"Shia Senior HighTechnical",
     "category":"C",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0070122",
     "school_name":"Shia Senior HighTechnical",
     "category":"C",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0070122",
     "school_name":"Shia Senior HighTechnical",
     "category":"C",
     "school_type":"SHTS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0050702",
     "school_name":"Simms Senior High\/Com.",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050702",
     "school_name":"Simms Senior High\/Com.",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050702",
     "school_name":"Simms Senior High\/Com.",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050702",
     "school_name":"Simms Senior High\/Com.",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050702",
     "school_name":"Simms Senior High\/Com.",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050702",
     "school_name":"Simms Senior High\/Com.",
     "category":"C",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0090506",
     "school_name":"Sirigu Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0090506",
     "school_name":"Sirigu Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0090506",
     "school_name":"Sirigu Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0090506",
     "school_name":"Sirigu Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9030103",
     "school_name":"Social Welfare Girls Vocational Training Centre ",
     "category":"C",
     "school_type":"TVET",
     "program":"COSMETOLOGY",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9030103",
     "school_name":"Social Welfare Girls Vocational Training Centre ",
     "category":"C",
     "school_type":"TVET",
     "program":"FASHION DESIGNING TECHNOLOGY",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9030103",
     "school_name":"Social Welfare Girls Vocational Training Centre ",
     "category":"C",
     "school_type":"TVET",
     "program":"GENERAL TEXTILES",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9030103",
     "school_name":"Social Welfare Girls Vocational Training Centre ",
     "category":"C",
     "school_type":"TVET",
     "program":"HOSPITALITY & CATERING MANAGEMENT",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9060107",
     "school_name":"Social Welfare Vocational \/ Rehabilitation Training Centre",
     "category":"C",
     "school_type":"TVET",
     "program":"BUILDING CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9060107",
     "school_name":"Social Welfare Vocational \/ Rehabilitation Training Centre",
     "category":"C",
     "school_type":"TVET",
     "program":"ELECTRICAL ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9060107",
     "school_name":"Social Welfare Vocational \/ Rehabilitation Training Centre",
     "category":"C",
     "school_type":"TVET",
     "program":"FASHION DESIGNING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9060107",
     "school_name":"Social Welfare Vocational \/ Rehabilitation Training Centre",
     "category":"C",
     "school_type":"TVET",
     "program":"GENERAL TEXTILES",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9060107",
     "school_name":"Social Welfare Vocational \/ Rehabilitation Training Centre",
     "category":"C",
     "school_type":"TVET",
     "program":"HOSPITALITY & CATERING MANAGEMENT",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9060107",
     "school_name":"Social Welfare Vocational \/ Rehabilitation Training Centre",
     "category":"C",
     "school_type":"TVET",
     "program":"MOTOR VEHICLE ENGINEERING",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9060107",
     "school_name":"Social Welfare Vocational \/ Rehabilitation Training Centre",
     "category":"C",
     "school_type":"TVET",
     "program":"WOOD CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9040106",
     "school_name":"Social Welfare Vocational \/Rehabilitation Training Centre",
     "category":"C",
     "school_type":"TVET",
     "program":"BUILDING CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9040106",
     "school_name":"Social Welfare Vocational \/Rehabilitation Training Centre",
     "category":"C",
     "school_type":"TVET",
     "program":"COSMETOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9040106",
     "school_name":"Social Welfare Vocational \/Rehabilitation Training Centre",
     "category":"C",
     "school_type":"TVET",
     "program":"ELECTRICAL ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9040106",
     "school_name":"Social Welfare Vocational \/Rehabilitation Training Centre",
     "category":"C",
     "school_type":"TVET",
     "program":"FASHION DESIGNING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9040106",
     "school_name":"Social Welfare Vocational \/Rehabilitation Training Centre",
     "category":"C",
     "school_type":"TVET",
     "program":"HOSPITALITY & CATERING MANAGEMENT",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9040106",
     "school_name":"Social Welfare Vocational \/Rehabilitation Training Centre",
     "category":"C",
     "school_type":"TVET",
     "program":"WOOD CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9021401",
     "school_name":"Social Welfare Vocational Training Centre",
     "category":"C",
     "school_type":"TVET",
     "program":"BUILDING CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9070703",
     "school_name":"Social Welfare Vocational Training Centre",
     "category":"C",
     "school_type":"TVET",
     "program":"BUILDING CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9021401",
     "school_name":"Social Welfare Vocational Training Centre",
     "category":"C",
     "school_type":"TVET",
     "program":"COSMETOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9070703",
     "school_name":"Social Welfare Vocational Training Centre",
     "category":"C",
     "school_type":"TVET",
     "program":"ELECTRICAL ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9021401",
     "school_name":"Social Welfare Vocational Training Centre",
     "category":"C",
     "school_type":"TVET",
     "program":"ELECTRICAL ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9021401",
     "school_name":"Social Welfare Vocational Training Centre",
     "category":"C",
     "school_type":"TVET",
     "program":"FASHION DESIGNING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9070703",
     "school_name":"Social Welfare Vocational Training Centre",
     "category":"C",
     "school_type":"TVET",
     "program":"FASHION DESIGNING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9070703",
     "school_name":"Social Welfare Vocational Training Centre",
     "category":"C",
     "school_type":"TVET",
     "program":"HOSPITALITY & CATERING MANAGEMENT",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9021401",
     "school_name":"Social Welfare Vocational Training Centre",
     "category":"C",
     "school_type":"TVET",
     "program":"HOSPITALITY & CATERING MANAGEMENT",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9021401",
     "school_name":"Social Welfare Vocational Training Centre",
     "category":"C",
     "school_type":"TVET",
     "program":"MOTOR VEHICLE ENGINEERING",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9021401",
     "school_name":"Social Welfare Vocational Training Centre",
     "category":"C",
     "school_type":"TVET",
     "program":"PLUMBING & GAS FITTING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9070703",
     "school_name":"Social Welfare Vocational Training Centre",
     "category":"C",
     "school_type":"TVET",
     "program":"WOOD CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9020102",
     "school_name":"Social Welfare Vocational Training Centre - Koforidua",
     "category":"C",
     "school_type":"TVET",
     "program":"BUILDING CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9020102",
     "school_name":"Social Welfare Vocational Training Centre - Koforidua",
     "category":"C",
     "school_type":"TVET",
     "program":"COSMETOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9020102",
     "school_name":"Social Welfare Vocational Training Centre - Koforidua",
     "category":"C",
     "school_type":"TVET",
     "program":"ELECTRONICS ENGINEERING",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9020102",
     "school_name":"Social Welfare Vocational Training Centre - Koforidua",
     "category":"C",
     "school_type":"TVET",
     "program":"FASHION DESIGNING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9020102",
     "school_name":"Social Welfare Vocational Training Centre - Koforidua",
     "category":"C",
     "school_type":"TVET",
     "program":"HOSPITALITY & CATERING MANAGEMENT",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9020102",
     "school_name":"Social Welfare Vocational Training Centre - Koforidua",
     "category":"C",
     "school_type":"TVET",
     "program":"INFORMATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9020102",
     "school_name":"Social Welfare Vocational Training Centre - Koforidua",
     "category":"C",
     "school_type":"TVET",
     "program":"MOTOR VEHICLE ENGINEERING",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9020102",
     "school_name":"Social Welfare Vocational Training Centre - Koforidua",
     "category":"C",
     "school_type":"TVET",
     "program":"WOOD CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070119",
     "school_name":"Sokode Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070119",
     "school_name":"Sokode Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070119",
     "school_name":"Sokode Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070119",
     "school_name":"Sokode Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070119",
     "school_name":"Sokode Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070119",
     "school_name":"Sokode Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070119",
     "school_name":"Sokode Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0100506",
     "school_name":"Sombo Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0100506",
     "school_name":"Sombo Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0070702",
     "school_name":"Some Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070702",
     "school_name":"Some Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070702",
     "school_name":"Some Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070702",
     "school_name":"Some Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070702",
     "school_name":"Some Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070702",
     "school_name":"Some Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9010107",
     "school_name":"South Labone Girls Vocational Training Centre – Osu - Accra",
     "category":"C",
     "school_type":"TVET",
     "program":"COSMETOLOGY",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9010107",
     "school_name":"South Labone Girls Vocational Training Centre – Osu - Accra",
     "category":"C",
     "school_type":"TVET",
     "program":"FASHION DESIGNING TECHNOLOGY",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9010107",
     "school_name":"South Labone Girls Vocational Training Centre – Osu - Accra",
     "category":"C",
     "school_type":"TVET",
     "program":"HOSPITALITY & CATERING MANAGEMENT",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050214",
     "school_name":"Spiritan Senior High ",
     "category":"C",
     "school_type":"SHTS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0050214",
     "school_name":"Spiritan Senior High ",
     "category":"C",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0050214",
     "school_name":"Spiritan Senior High ",
     "category":"C",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9100902",
     "school_name":"St Anne's Vocational Training Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"COSMETOLOGY",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9100902",
     "school_name":"St Anne's Vocational Training Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"FASHION DESIGNING TECHNOLOGY",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9100902",
     "school_name":"St Anne's Vocational Training Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"GENERAL TEXTILES",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9100902",
     "school_name":"St Anne's Vocational Training Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"HOSPITALITY & CATERING MANAGEMENT",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9100902",
     "school_name":"St Anne's Vocational Training Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"INFORMATION TECHNOLOGY",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9070800",
     "school_name":"St Theresa's Vocational Training Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"AGRICULTURAL MECHANIZATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9070800",
     "school_name":"St Theresa's Vocational Training Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"BUILDING CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9070800",
     "school_name":"St Theresa's Vocational Training Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"BUSINESS SECRETARIAL",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9070800",
     "school_name":"St Theresa's Vocational Training Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"ELECTRICAL ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9070800",
     "school_name":"St Theresa's Vocational Training Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"FASHION DESIGNING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9070800",
     "school_name":"St Theresa's Vocational Training Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"WOOD CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0080703",
     "school_name":"St. Anthony of Padua Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0080703",
     "school_name":"St. Anthony of Padua Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0080703",
     "school_name":"St. Anthony of Padua Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0080703",
     "school_name":"St. Anthony of Padua Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060308",
     "school_name":"St. Augustine Senior High, Nsapor-Berekum",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0060308",
     "school_name":"St. Augustine Senior High, Nsapor-Berekum",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0100505",
     "school_name":"St. Augustine Senior High\/Tech, Saan Charikpong",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0100505",
     "school_name":"St. Augustine Senior High\/Tech, Saan Charikpong",
     "category":"C",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0100505",
     "school_name":"St. Augustine Senior High\/Tech, Saan Charikpong",
     "category":"C",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040902",
     "school_name":"St. Augustine's Senior High, Bogoso",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040902",
     "school_name":"St. Augustine's Senior High, Bogoso",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040902",
     "school_name":"St. Augustine's Senior High, Bogoso",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040902",
     "school_name":"St. Augustine's Senior High, Bogoso",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040902",
     "school_name":"St. Augustine's Senior High, Bogoso",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9090901",
     "school_name":"St. Bernadettes Tech\/Voc.",
     "category":"C",
     "school_type":"TVET",
     "program":"BUILDING CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9090901",
     "school_name":"St. Bernadettes Tech\/Voc.",
     "category":"C",
     "school_type":"TVET",
     "program":"BUSINESS ACCOUNTING",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9090901",
     "school_name":"St. Bernadettes Tech\/Voc.",
     "category":"C",
     "school_type":"TVET",
     "program":"BUSINESS SECRETARIAL",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9090901",
     "school_name":"St. Bernadettes Tech\/Voc.",
     "category":"C",
     "school_type":"TVET",
     "program":"CREATIVE ART TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9090901",
     "school_name":"St. Bernadettes Tech\/Voc.",
     "category":"C",
     "school_type":"TVET",
     "program":"ELECTRICAL ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9090901",
     "school_name":"St. Bernadettes Tech\/Voc.",
     "category":"C",
     "school_type":"TVET",
     "program":"FASHION DESIGNING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9090901",
     "school_name":"St. Bernadettes Tech\/Voc.",
     "category":"C",
     "school_type":"TVET",
     "program":"HOSPITALITY & CATERING MANAGEMENT",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9090901",
     "school_name":"St. Bernadettes Tech\/Voc.",
     "category":"C",
     "school_type":"TVET",
     "program":"PLUMBING & GAS FITTING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9090901",
     "school_name":"St. Bernadettes Tech\/Voc.",
     "category":"C",
     "school_type":"TVET",
     "program":"WELDING AND FABRICATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9090901",
     "school_name":"St. Bernadettes Tech\/Voc.",
     "category":"C",
     "school_type":"TVET",
     "program":"WOOD CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070510",
     "school_name":"St. Catherine Girls Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070510",
     "school_name":"St. Catherine Girls Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070510",
     "school_name":"St. Catherine Girls Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070510",
     "school_name":"St. Catherine Girls Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070510",
     "school_name":"St. Catherine Girls Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9100400",
     "school_name":"St. Clare's Vocational Training Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"BUSINESS INFORMATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9100400",
     "school_name":"St. Clare's Vocational Training Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"ELECTRICAL ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9100400",
     "school_name":"St. Clare's Vocational Training Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"FASHION DESIGNING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9100400",
     "school_name":"St. Clare's Vocational Training Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"GENERAL TEXTILES",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9100400",
     "school_name":"St. Clare's Vocational Training Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"HOSPITALITY & CATERING MANAGEMENT",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9072101",
     "school_name":"St. Daniel Comboni Tech\/Voc Inst.",
     "category":"C",
     "school_type":"TVET",
     "program":"AGRICULTURAL MECHANIZATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9072101",
     "school_name":"St. Daniel Comboni Tech\/Voc Inst.",
     "category":"C",
     "school_type":"TVET",
     "program":"ARCHITECTURAL DRAFTING",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9072101",
     "school_name":"St. Daniel Comboni Tech\/Voc Inst.",
     "category":"C",
     "school_type":"TVET",
     "program":"BUILDING CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9072101",
     "school_name":"St. Daniel Comboni Tech\/Voc Inst.",
     "category":"C",
     "school_type":"TVET",
     "program":"BUSINESS ACCOUNTING",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9072101",
     "school_name":"St. Daniel Comboni Tech\/Voc Inst.",
     "category":"C",
     "school_type":"TVET",
     "program":"ELECTRICAL ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9072101",
     "school_name":"St. Daniel Comboni Tech\/Voc Inst.",
     "category":"C",
     "school_type":"TVET",
     "program":"ELECTRONICS ENGINEERING",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9072101",
     "school_name":"St. Daniel Comboni Tech\/Voc Inst.",
     "category":"C",
     "school_type":"TVET",
     "program":"FASHION DESIGNING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9072101",
     "school_name":"St. Daniel Comboni Tech\/Voc Inst.",
     "category":"C",
     "school_type":"TVET",
     "program":"HOSPITALITY & CATERING MANAGEMENT",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9072101",
     "school_name":"St. Daniel Comboni Tech\/Voc Inst.",
     "category":"C",
     "school_type":"TVET",
     "program":"MOTOR VEHICLE ENGINEERING",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9072101",
     "school_name":"St. Daniel Comboni Tech\/Voc Inst.",
     "category":"C",
     "school_type":"TVET",
     "program":"WELDING AND FABRICATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9072101",
     "school_name":"St. Daniel Comboni Tech\/Voc Inst.",
     "category":"C",
     "school_type":"TVET",
     "program":"WOOD CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020902",
     "school_name":"St. Fidelis Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020902",
     "school_name":"St. Fidelis Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020902",
     "school_name":"St. Fidelis Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020902",
     "school_name":"St. Fidelis Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020902",
     "school_name":"St. Fidelis Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020902",
     "school_name":"St. Fidelis Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020902",
     "school_name":"St. Fidelis Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060704",
     "school_name":"St. Francis Seminary\/Senior High, Buoyem",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Boys",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060704",
     "school_name":"St. Francis Seminary\/Senior High, Buoyem",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Boys",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060704",
     "school_name":"St. Francis Seminary\/Senior High, Buoyem",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Boys",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060704",
     "school_name":"St. Francis Seminary\/Senior High, Buoyem",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Boys",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060704",
     "school_name":"St. Francis Seminary\/Senior High, Buoyem",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Boys",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060704",
     "school_name":"St. Francis Seminary\/Senior High, Buoyem",
     "category":"C",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Boys",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051608",
     "school_name":"St. George's Senior High Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051608",
     "school_name":"St. George's Senior High Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051608",
     "school_name":"St. George's Senior High Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051608",
     "school_name":"St. George's Senior High Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051608",
     "school_name":"St. George's Senior High Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051608",
     "school_name":"St. George's Senior High Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030624",
     "school_name":"St. Gregory Catholic Senior High School",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0030624",
     "school_name":"St. Gregory Catholic Senior High School",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0030624",
     "school_name":"St. Gregory Catholic Senior High School",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0090507",
     "school_name":"St. John's Integrated Snr. High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0090507",
     "school_name":"St. John's Integrated Snr. High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0090507",
     "school_name":"St. John's Integrated Snr. High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0090507",
     "school_name":"St. John's Integrated Snr. High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0090507",
     "school_name":"St. John's Integrated Snr. High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050504",
     "school_name":"St. Joseph Sem\/Senior High, Mampong",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050504",
     "school_name":"St. Joseph Sem\/Senior High, Mampong",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050504",
     "school_name":"St. Joseph Sem\/Senior High, Mampong",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050504",
     "school_name":"St. Joseph Sem\/Senior High, Mampong",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050504",
     "school_name":"St. Joseph Sem\/Senior High, Mampong",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050504",
     "school_name":"St. Joseph Sem\/Senior High, Mampong",
     "category":"C",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9020902",
     "school_name":"St. Mary's Voc.\/Tech. Inst.",
     "category":"C",
     "school_type":"TVET",
     "program":"BUSINESS ACCOUNTING",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9020902",
     "school_name":"St. Mary's Voc.\/Tech. Inst.",
     "category":"C",
     "school_type":"TVET",
     "program":"BUSINESS INFORMATION TECHNOLOGY",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9020902",
     "school_name":"St. Mary's Voc.\/Tech. Inst.",
     "category":"C",
     "school_type":"TVET",
     "program":"BUSINESS SECRETARIAL",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9020902",
     "school_name":"St. Mary's Voc.\/Tech. Inst.",
     "category":"C",
     "school_type":"TVET",
     "program":"CREATIVE ART TECHNOLOGY",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9020902",
     "school_name":"St. Mary's Voc.\/Tech. Inst.",
     "category":"C",
     "school_type":"TVET",
     "program":"FASHION DESIGNING TECHNOLOGY",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9020902",
     "school_name":"St. Mary's Voc.\/Tech. Inst.",
     "category":"C",
     "school_type":"TVET",
     "program":"GENERAL TEXTILES",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9020902",
     "school_name":"St. Mary's Voc.\/Tech. Inst.",
     "category":"C",
     "school_type":"TVET",
     "program":"HOSPITALITY & CATERING MANAGEMENT",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9020902",
     "school_name":"St. Mary's Voc.\/Tech. Inst.",
     "category":"C",
     "school_type":"TVET",
     "program":"WELDING AND FABRICATION TECHNOLOGY",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9020902",
     "school_name":"St. Mary's Voc.\/Tech. Inst.",
     "category":"C",
     "school_type":"TVET",
     "program":"WOOD CONSTRUCTION TECHNOLOGY",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9080102",
     "school_name":"St. Mary's Vocational Training Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"BUSINESS SECRETARIAL",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9080102",
     "school_name":"St. Mary's Vocational Training Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"COMPUTER HARDWARE TECHNOLOGY",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9080102",
     "school_name":"St. Mary's Vocational Training Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"COSMETOLOGY",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9080102",
     "school_name":"St. Mary's Vocational Training Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"ELECTRICAL ENGINEERING TECHNOLOGY",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9080102",
     "school_name":"St. Mary's Vocational Training Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"FASHION DESIGNING TECHNOLOGY",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9080102",
     "school_name":"St. Mary's Vocational Training Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"GENERAL TEXTILES",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9080102",
     "school_name":"St. Mary's Vocational Training Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"HOSPITALITY & CATERING MANAGEMENT",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9080102",
     "school_name":"St. Mary's Vocational Training Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"SMALL ENG. REPAIR",
     "gender":"Girls",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9020500",
     "school_name":"St. Mary's Vocational Training Institute - Eastern",
     "category":"C",
     "school_type":"TVET",
     "program":"BUSINESS INFORMATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9020500",
     "school_name":"St. Mary's Vocational Training Institute - Eastern",
     "category":"C",
     "school_type":"TVET",
     "program":"COMPUTER HARDWARE TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9020500",
     "school_name":"St. Mary's Vocational Training Institute - Eastern",
     "category":"C",
     "school_type":"TVET",
     "program":"COSMETOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9020500",
     "school_name":"St. Mary's Vocational Training Institute - Eastern",
     "category":"C",
     "school_type":"TVET",
     "program":"ELECTRICAL ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9020500",
     "school_name":"St. Mary's Vocational Training Institute - Eastern",
     "category":"C",
     "school_type":"TVET",
     "program":"FASHION DESIGNING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9020500",
     "school_name":"St. Mary's Vocational Training Institute - Eastern",
     "category":"C",
     "school_type":"TVET",
     "program":"HOSPITALITY & CATERING MANAGEMENT",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9052800",
     "school_name":"St. Michael Tech\/Voc Inst",
     "category":"C",
     "school_type":"TVET",
     "program":"ARCHITECTURAL DRAFTING",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9052800",
     "school_name":"St. Michael Tech\/Voc Inst",
     "category":"C",
     "school_type":"TVET",
     "program":"BUILDING CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9052800",
     "school_name":"St. Michael Tech\/Voc Inst",
     "category":"C",
     "school_type":"TVET",
     "program":"CREATIVE ART TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9052800",
     "school_name":"St. Michael Tech\/Voc Inst",
     "category":"C",
     "school_type":"TVET",
     "program":"ELECTRICAL ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9052800",
     "school_name":"St. Michael Tech\/Voc Inst",
     "category":"C",
     "school_type":"TVET",
     "program":"FASHION DESIGNING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9052800",
     "school_name":"St. Michael Tech\/Voc Inst",
     "category":"C",
     "school_type":"TVET",
     "program":"GENERAL TEXTILES",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9052800",
     "school_name":"St. Michael Tech\/Voc Inst",
     "category":"C",
     "school_type":"TVET",
     "program":"HOSPITALITY & CATERING MANAGEMENT",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020704",
     "school_name":"St. Michael's Senior High, Akoase (Nkawkaw)",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0020704",
     "school_name":"St. Michael's Senior High, Akoase (Nkawkaw)",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0020704",
     "school_name":"St. Michael's Senior High, Akoase (Nkawkaw)",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0020704",
     "school_name":"St. Michael's Senior High, Akoase (Nkawkaw)",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0020704",
     "school_name":"St. Michael's Senior High, Akoase (Nkawkaw)",
     "category":"C",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0021007",
     "school_name":"St. Paul's Senior High, Asakraka Kwahu",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021007",
     "school_name":"St. Paul's Senior High, Asakraka Kwahu",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021007",
     "school_name":"St. Paul's Senior High, Asakraka Kwahu",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021007",
     "school_name":"St. Paul's Senior High, Asakraka Kwahu",
     "category":"C",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070701",
     "school_name":"St. Paul's Senior High, Denu",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Boys",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070701",
     "school_name":"St. Paul's Senior High, Denu",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Boys",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070701",
     "school_name":"St. Paul's Senior High, Denu",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Boys",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070701",
     "school_name":"St. Paul's Senior High, Denu",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Boys",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070701",
     "school_name":"St. Paul's Senior High, Denu",
     "category":"C",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Boys",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051612",
     "school_name":"St. Sebastian Cath. Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0051612",
     "school_name":"St. Sebastian Cath. Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0051612",
     "school_name":"St. Sebastian Cath. Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0051612",
     "school_name":"St. Sebastian Cath. Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0021304",
     "school_name":"St. Stephen's Presby Snr. High\/Tech, Asiakwa",
     "category":"C",
     "school_type":"SHTS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021304",
     "school_name":"St. Stephen's Presby Snr. High\/Tech, Asiakwa",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021304",
     "school_name":"St. Stephen's Presby Snr. High\/Tech, Asiakwa",
     "category":"C",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021304",
     "school_name":"St. Stephen's Presby Snr. High\/Tech, Asiakwa",
     "category":"C",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021304",
     "school_name":"St. Stephen's Presby Snr. High\/Tech, Asiakwa",
     "category":"C",
     "school_type":"SHTS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020502",
     "school_name":"St. Thomas Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020502",
     "school_name":"St. Thomas Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020502",
     "school_name":"St. Thomas Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020502",
     "school_name":"St. Thomas Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020502",
     "school_name":"St. Thomas Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0020502",
     "school_name":"St. Thomas Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9100901",
     "school_name":"St.John's Voc. Tech. Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"BUILDING CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9100901",
     "school_name":"St.John's Voc. Tech. Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"ELECTRICAL ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9100901",
     "school_name":"St.John's Voc. Tech. Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"MOTOR VEHICLE ENGINEERING",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9100901",
     "school_name":"St.John's Voc. Tech. Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"PLUMBING & GAS FITTING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9100901",
     "school_name":"St.John's Voc. Tech. Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"WELDING AND FABRICATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9100901",
     "school_name":"St.John's Voc. Tech. Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"WOOD CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040907",
     "school_name":"Stem Academy, Awaso",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040907",
     "school_name":"Stem Academy, Awaso",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040907",
     "school_name":"Stem Academy, Awaso",
     "category":"C",
     "school_type":"SHS",
     "program":"STEM",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060609",
     "school_name":"Stem Academy, Koase",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060609",
     "school_name":"Stem Academy, Koase",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060609",
     "school_name":"Stem Academy, Koase",
     "category":"C",
     "school_type":"SHS",
     "program":"STEM",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0080610",
     "school_name":"Stem Academy, Kpasenkpe",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0080610",
     "school_name":"Stem Academy, Kpasenkpe",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0080610",
     "school_name":"Stem Academy, Kpasenkpe",
     "category":"C",
     "school_type":"SHS",
     "program":"STEM",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9020701",
     "school_name":"Suhum CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"BUILDING CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9020701",
     "school_name":"Suhum CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"BUSINESS SECRETARIAL",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9020701",
     "school_name":"Suhum CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"COSMETOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9020701",
     "school_name":"Suhum CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"ELECTRICAL ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9020701",
     "school_name":"Suhum CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"FASHION DESIGNING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9020701",
     "school_name":"Suhum CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"HOSPITALITY & CATERING MANAGEMENT",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9020701",
     "school_name":"Suhum CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"MOTOR VEHICLE ENGINEERING",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9020701",
     "school_name":"Suhum CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"PLUMBING & GAS FITTING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9060106",
     "school_name":"Sunyani CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"BUILDING CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9060106",
     "school_name":"Sunyani CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"COMPUTER HARDWARE TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9060106",
     "school_name":"Sunyani CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"COMPUTER NETWORKING(CBT OPTION, FOR GIRLS ONLY)",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9060106",
     "school_name":"Sunyani CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"COSMETOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9060106",
     "school_name":"Sunyani CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"ELECTRICAL ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9060106",
     "school_name":"Sunyani CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"FASHION DESIGNING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9060106",
     "school_name":"Sunyani CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"HOSPITALITY & CATERING MANAGEMENT",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9060106",
     "school_name":"Sunyani CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"MOTOR VEHICLE ENGINEERING",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9060106",
     "school_name":"Sunyani CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"PLUMBING & GAS FITTING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9060106",
     "school_name":"Sunyani CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"WELDING AND FABRICATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9060106",
     "school_name":"Sunyani CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"WOOD CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030902",
     "school_name":"Swedru Sch. Of Business",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030902",
     "school_name":"Swedru Sch. Of Business",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030902",
     "school_name":"Swedru Sch. Of Business",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030902",
     "school_name":"Swedru Sch. Of Business",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030902",
     "school_name":"Swedru Sch. Of Business",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0030902",
     "school_name":"Swedru Sch. Of Business",
     "category":"C",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9040107",
     "school_name":"Takoradi CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"BUILDING CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9040107",
     "school_name":"Takoradi CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"BUILDING CONSTRUCTION TECHNOLOGY (CBT OPTION)",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9040107",
     "school_name":"Takoradi CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"COSMETOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9040107",
     "school_name":"Takoradi CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"ELECTRICAL ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9040107",
     "school_name":"Takoradi CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"FASHION DESIGNING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9040107",
     "school_name":"Takoradi CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"HOSPITALITY & CATERING MANAGEMENT",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9040107",
     "school_name":"Takoradi CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"MOTOR VEHICLE ENGINEERING",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040101",
     "school_name":"Takoradi Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040101",
     "school_name":"Takoradi Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040101",
     "school_name":"Takoradi Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040101",
     "school_name":"Takoradi Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040101",
     "school_name":"Takoradi Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040101",
     "school_name":"Takoradi Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9040102",
     "school_name":"Takoradi Vocational Training Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"BUSINESS INFORMATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9040102",
     "school_name":"Takoradi Vocational Training Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"ELECTRICAL ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9040102",
     "school_name":"Takoradi Vocational Training Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"MECHANICAL. ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9040102",
     "school_name":"Takoradi Vocational Training Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"MOTOR VEHICLE ENGINEERING",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9040102",
     "school_name":"Takoradi Vocational Training Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"WELDING AND FABRICATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0100504",
     "school_name":"Takpo Senior High Sch.",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0100504",
     "school_name":"Takpo Senior High Sch.",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9022600",
     "school_name":"Takrowase YLSTI",
     "category":"C",
     "school_type":"TVET",
     "program":"AGRICULTURAL MECHANIZATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9022600",
     "school_name":"Takrowase YLSTI",
     "category":"C",
     "school_type":"TVET",
     "program":"BUILDING CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9022600",
     "school_name":"Takrowase YLSTI",
     "category":"C",
     "school_type":"TVET",
     "program":"ELECTRICAL ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9022600",
     "school_name":"Takrowase YLSTI",
     "category":"C",
     "school_type":"TVET",
     "program":"FASHION DESIGNING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9022600",
     "school_name":"Takrowase YLSTI",
     "category":"C",
     "school_type":"TVET",
     "program":"HOSPITALITY & CATERING MANAGEMENT",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9080105",
     "school_name":"Tamale CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"BUILDING CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9080105",
     "school_name":"Tamale CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"COSMETOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9080105",
     "school_name":"Tamale CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"ELECTRICAL ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9080105",
     "school_name":"Tamale CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"FASHION DESIGNING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9080105",
     "school_name":"Tamale CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"HOSPITALITY & CATERING MANAGEMENT",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9080105",
     "school_name":"Tamale CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"MOTOR VEHICLE ENGINEERING",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9080105",
     "school_name":"Tamale CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"WOOD CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9081901",
     "school_name":"Tamale Technical Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"AGRICULTURAL MECHANIZATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9081901",
     "school_name":"Tamale Technical Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"BUILDING CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9081901",
     "school_name":"Tamale Technical Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"CREATIVE ART TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9081901",
     "school_name":"Tamale Technical Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"ELECTRICAL ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9081901",
     "school_name":"Tamale Technical Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"ELECTRONICS ENGINEERING",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9081901",
     "school_name":"Tamale Technical Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"FASHION DESIGNING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9081901",
     "school_name":"Tamale Technical Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"HOSPITALITY & CATERING MANAGEMENT",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9081901",
     "school_name":"Tamale Technical Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"MECHANICAL. ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9081901",
     "school_name":"Tamale Technical Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"MOTOR VEHICLE ENGINEERING",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9081901",
     "school_name":"Tamale Technical Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"WELDING AND FABRICATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9081901",
     "school_name":"Tamale Technical Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"WOOD CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9080103",
     "school_name":"Tamale Vocational Training Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"BUILDING CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9080103",
     "school_name":"Tamale Vocational Training Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"BUSINESS INFORMATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9080103",
     "school_name":"Tamale Vocational Training Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"BUSINESS SECRETARIAL",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9080103",
     "school_name":"Tamale Vocational Training Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"ELECTRICAL ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9080103",
     "school_name":"Tamale Vocational Training Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"FASHION DESIGNING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9080103",
     "school_name":"Tamale Vocational Training Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"HOSPITALITY & CATERING MANAGEMENT",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9080103",
     "school_name":"Tamale Vocational Training Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"PLUMBING & GAS FITTING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9080103",
     "school_name":"Tamale Vocational Training Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"WOOD CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070112",
     "school_name":"Tanyigbe Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070112",
     "school_name":"Tanyigbe Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070112",
     "school_name":"Tanyigbe Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070112",
     "school_name":"Tanyigbe Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070112",
     "school_name":"Tanyigbe Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0071106",
     "school_name":"Tapaman Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0071106",
     "school_name":"Tapaman Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0071106",
     "school_name":"Tapaman Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0071106",
     "school_name":"Tapaman Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0071106",
     "school_name":"Tapaman Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021105",
     "school_name":"Tarkrosi Comm. Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0021105",
     "school_name":"Tarkrosi Comm. Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0021105",
     "school_name":"Tarkrosi Comm. Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0021105",
     "school_name":"Tarkrosi Comm. Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9040201",
     "school_name":"Tarkwa CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"BUILDING CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9040201",
     "school_name":"Tarkwa CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"COSMETOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9040201",
     "school_name":"Tarkwa CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"ELECTRICAL ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9040201",
     "school_name":"Tarkwa CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"FASHION DESIGNING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9040201",
     "school_name":"Tarkwa CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"HOSPITALITY & CATERING MANAGEMENT",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9040201",
     "school_name":"Tarkwa CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"MOTOR VEHICLE ENGINEERING",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9040201",
     "school_name":"Tarkwa CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"WELDING AND FABRICATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070107",
     "school_name":"Taviefe Comm. Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070107",
     "school_name":"Taviefe Comm. Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070107",
     "school_name":"Taviefe Comm. Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070107",
     "school_name":"Taviefe Comm. Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9071900",
     "school_name":"Technology Solution Center, Adidome",
     "category":"C",
     "school_type":"TVET",
     "program":"MECHANICAL. ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9071900",
     "school_name":"Technology Solution Center, Adidome",
     "category":"C",
     "school_type":"TVET",
     "program":"WELDING AND FABRICATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9020501",
     "school_name":"Technology Solution Center, Asamankese",
     "category":"C",
     "school_type":"TVET",
     "program":"MECHANICAL. ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9020501",
     "school_name":"Technology Solution Center, Asamankese",
     "category":"C",
     "school_type":"TVET",
     "program":"WELDING AND FABRICATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9041300",
     "school_name":"Technology Solution Center, Asankragwa",
     "category":"C",
     "school_type":"TVET",
     "program":"MECHANICAL. ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9041300",
     "school_name":"Technology Solution Center, Asankragwa",
     "category":"C",
     "school_type":"TVET",
     "program":"WELDING AND FABRICATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9030700",
     "school_name":"Technology Solution Center, Assin Edubiase",
     "category":"C",
     "school_type":"TVET",
     "program":"MECHANICAL. ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9030700",
     "school_name":"Technology Solution Center, Assin Edubiase",
     "category":"C",
     "school_type":"TVET",
     "program":"WELDING AND FABRICATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9031200",
     "school_name":"Technology Solution Center, Awutu Senya",
     "category":"C",
     "school_type":"TVET",
     "program":"MECHANICAL. ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9031200",
     "school_name":"Technology Solution Center, Awutu Senya",
     "category":"C",
     "school_type":"TVET",
     "program":"WELDING AND FABRICATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9060800",
     "school_name":"Technology Solution Center, Bechem",
     "category":"C",
     "school_type":"TVET",
     "program":"MECHANICAL. ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9060800",
     "school_name":"Technology Solution Center, Bechem",
     "category":"C",
     "school_type":"TVET",
     "program":"WELDING AND FABRICATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9050402",
     "school_name":"Technology Solution Center, Bekwai",
     "category":"C",
     "school_type":"TVET",
     "program":"MECHANICAL. ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9050402",
     "school_name":"Technology Solution Center, Bekwai",
     "category":"C",
     "school_type":"TVET",
     "program":"WELDING AND FABRICATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9040600",
     "school_name":"Technology Solution Center, Bibiani",
     "category":"C",
     "school_type":"TVET",
     "program":"MECHANICAL. ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9040600",
     "school_name":"Technology Solution Center, Bibiani",
     "category":"C",
     "school_type":"TVET",
     "program":"WELDING AND FABRICATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9080700",
     "school_name":"Technology Solution Center, Bole",
     "category":"C",
     "school_type":"TVET",
     "program":"MECHANICAL. ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9080700",
     "school_name":"Technology Solution Center, Bole",
     "category":"C",
     "school_type":"TVET",
     "program":"WELDING AND FABRICATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9082100",
     "school_name":"Technology Solution Center, Damango",
     "category":"C",
     "school_type":"TVET",
     "program":"MECHANICAL. ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"DAY\/Boarding"
  },
  {
     "school_code":"9082100",
     "school_name":"Technology Solution Center, Damango",
     "category":"C",
     "school_type":"TVET",
     "program":"WELDING AND FABRICATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"DAY\/Boarding"
  },
  {
     "school_code":"9020903",
     "school_name":"Technology Solution Center, Donkorkrom",
     "category":"C",
     "school_type":"TVET",
     "program":"MECHANICAL. ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9020903",
     "school_name":"Technology Solution Center, Donkorkrom",
     "category":"C",
     "school_type":"TVET",
     "program":"WELDING AND FABRICATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9061000",
     "school_name":"Technology Solution Center, Dormaa Ahenkro",
     "category":"C",
     "school_type":"TVET",
     "program":"FASHION DESIGNING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9061000",
     "school_name":"Technology Solution Center, Dormaa Ahenkro",
     "category":"C",
     "school_type":"TVET",
     "program":"MECHANICAL. ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9061000",
     "school_name":"Technology Solution Center, Dormaa Ahenkro",
     "category":"C",
     "school_type":"TVET",
     "program":"WELDING AND FABRICATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9060500",
     "school_name":"Technology Solution Center, Drobo",
     "category":"C",
     "school_type":"TVET",
     "program":"MECHANICAL. ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9060500",
     "school_name":"Technology Solution Center, Drobo",
     "category":"C",
     "school_type":"TVET",
     "program":"WELDING AND FABRICATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9030200",
     "school_name":"Technology Solution Center, Elmina",
     "category":"C",
     "school_type":"TVET",
     "program":"FASHION DESIGNING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"DAY\/Boarding"
  },
  {
     "school_code":"9030200",
     "school_name":"Technology Solution Center, Elmina",
     "category":"C",
     "school_type":"TVET",
     "program":"MECHANICAL. ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"DAY\/Boarding"
  },
  {
     "school_code":"9030200",
     "school_name":"Technology Solution Center, Elmina",
     "category":"C",
     "school_type":"TVET",
     "program":"WELDING AND FABRICATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"DAY\/Boarding"
  },
  {
     "school_code":"9090800",
     "school_name":"Technology Solution Center, Garu",
     "category":"C",
     "school_type":"TVET",
     "program":"MECHANICAL. ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9090800",
     "school_name":"Technology Solution Center, Garu",
     "category":"C",
     "school_type":"TVET",
     "program":"WELDING AND FABRICATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9060400",
     "school_name":"Technology Solution Center, Goaso",
     "category":"C",
     "school_type":"TVET",
     "program":"BUILDING CONSTRUCTION TECHNOLOGY (CBT OPTION)",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9060400",
     "school_name":"Technology Solution Center, Goaso",
     "category":"C",
     "school_type":"TVET",
     "program":"ELECTRICAL ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9060400",
     "school_name":"Technology Solution Center, Goaso",
     "category":"C",
     "school_type":"TVET",
     "program":"FASHION DESIGNING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9060400",
     "school_name":"Technology Solution Center, Goaso",
     "category":"C",
     "school_type":"TVET",
     "program":"MECHANICAL. ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9060400",
     "school_name":"Technology Solution Center, Goaso",
     "category":"C",
     "school_type":"TVET",
     "program":"WELDING AND FABRICATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9040700",
     "school_name":"Technology Solution Center, Half Assini",
     "category":"C",
     "school_type":"TVET",
     "program":"MECHANICAL. ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9040700",
     "school_name":"Technology Solution Center, Half Assini",
     "category":"C",
     "school_type":"TVET",
     "program":"WELDING AND FABRICATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9071000",
     "school_name":"Technology Solution Center, Hohoe",
     "category":"C",
     "school_type":"TVET",
     "program":"FASHION DESIGNING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9071000",
     "school_name":"Technology Solution Center, Hohoe",
     "category":"C",
     "school_type":"TVET",
     "program":"MECHANICAL. ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9071000",
     "school_name":"Technology Solution Center, Hohoe",
     "category":"C",
     "school_type":"TVET",
     "program":"WELDING AND FABRICATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9050900",
     "school_name":"Technology Solution Center, Konongo",
     "category":"C",
     "school_type":"TVET",
     "program":"MECHANICAL. ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9050900",
     "school_name":"Technology Solution Center, Konongo",
     "category":"C",
     "school_type":"TVET",
     "program":"WELDING AND FABRICATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9072000",
     "school_name":"Technology Solution Center, Krachi",
     "category":"C",
     "school_type":"TVET",
     "program":"MECHANICAL. ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9072000",
     "school_name":"Technology Solution Center, Krachi",
     "category":"C",
     "school_type":"TVET",
     "program":"WELDING AND FABRICATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9050500",
     "school_name":"Technology Solution Center, Mampong",
     "category":"C",
     "school_type":"TVET",
     "program":"MECHANICAL. ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9050500",
     "school_name":"Technology Solution Center, Mampong",
     "category":"C",
     "school_type":"TVET",
     "program":"WELDING AND FABRICATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9030300",
     "school_name":"Technology Solution Center, Mankesim",
     "category":"C",
     "school_type":"TVET",
     "program":"MECHANICAL. ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9030300",
     "school_name":"Technology Solution Center, Mankesim",
     "category":"C",
     "school_type":"TVET",
     "program":"WELDING AND FABRICATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9101002",
     "school_name":"Technology Solution Center, Nadowli",
     "category":"C",
     "school_type":"TVET",
     "program":"MECHANICAL. ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9101002",
     "school_name":"Technology Solution Center, Nadowli",
     "category":"C",
     "school_type":"TVET",
     "program":"WELDING AND FABRICATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9090903",
     "school_name":"Technology Solution Center, Navrongo",
     "category":"C",
     "school_type":"TVET",
     "program":"MECHANICAL. ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9090903",
     "school_name":"Technology Solution Center, Navrongo",
     "category":"C",
     "school_type":"TVET",
     "program":"MOTOR VEHICLE ENGINEERING",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9090903",
     "school_name":"Technology Solution Center, Navrongo",
     "category":"C",
     "school_type":"TVET",
     "program":"WELDING AND FABRICATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9081500",
     "school_name":"Technology Solution Center, Salaga",
     "category":"C",
     "school_type":"TVET",
     "program":"FASHION DESIGNING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9081500",
     "school_name":"Technology Solution Center, Salaga",
     "category":"C",
     "school_type":"TVET",
     "program":"MECHANICAL. ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9081500",
     "school_name":"Technology Solution Center, Salaga",
     "category":"C",
     "school_type":"TVET",
     "program":"WELDING AND FABRICATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9081902",
     "school_name":"Technology Solution Center, Savelugu",
     "category":"C",
     "school_type":"TVET",
     "program":"MECHANICAL. ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9081902",
     "school_name":"Technology Solution Center, Savelugu",
     "category":"C",
     "school_type":"TVET",
     "program":"WELDING AND FABRICATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9053000",
     "school_name":"Technology Solution Center, Sekyere Kumawu",
     "category":"C",
     "school_type":"TVET",
     "program":"FASHION DESIGNING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9053000",
     "school_name":"Technology Solution Center, Sekyere Kumawu",
     "category":"C",
     "school_type":"TVET",
     "program":"MECHANICAL. ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9053000",
     "school_name":"Technology Solution Center, Sekyere Kumawu",
     "category":"C",
     "school_type":"TVET",
     "program":"WELDING AND FABRICATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9021400",
     "school_name":"Technology Solution Center, Somanya",
     "category":"C",
     "school_type":"TVET",
     "program":"MECHANICAL. ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9021400",
     "school_name":"Technology Solution Center, Somanya",
     "category":"C",
     "school_type":"TVET",
     "program":"WELDING AND FABRICATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9020700",
     "school_name":"Technology Solution Center, Suhum",
     "category":"C",
     "school_type":"TVET",
     "program":"FASHION DESIGNING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"DAY\/Boarding"
  },
  {
     "school_code":"9020700",
     "school_name":"Technology Solution Center, Suhum",
     "category":"C",
     "school_type":"TVET",
     "program":"MECHANICAL. ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"DAY\/Boarding"
  },
  {
     "school_code":"9020700",
     "school_name":"Technology Solution Center, Suhum",
     "category":"C",
     "school_type":"TVET",
     "program":"WELDING AND FABRICATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"DAY\/Boarding"
  },
  {
     "school_code":"9061200",
     "school_name":"Technology Solution Center, Techiman",
     "category":"C",
     "school_type":"TVET",
     "program":"MECHANICAL. ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9061200",
     "school_name":"Technology Solution Center, Techiman",
     "category":"C",
     "school_type":"TVET",
     "program":"WELDING AND FABRICATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9010206",
     "school_name":"Tema Industrial Mission",
     "category":"C",
     "school_type":"TVET",
     "program":"BUILDING CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9010206",
     "school_name":"Tema Industrial Mission",
     "category":"C",
     "school_type":"TVET",
     "program":"ELECTRICAL ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9010206",
     "school_name":"Tema Industrial Mission",
     "category":"C",
     "school_type":"TVET",
     "program":"ELECTRONICS ENGINEERING",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9010206",
     "school_name":"Tema Industrial Mission",
     "category":"C",
     "school_type":"TVET",
     "program":"FASHION DESIGNING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9010206",
     "school_name":"Tema Industrial Mission",
     "category":"C",
     "school_type":"TVET",
     "program":"HOSPITALITY & CATERING MANAGEMENT",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9010206",
     "school_name":"Tema Industrial Mission",
     "category":"C",
     "school_type":"TVET",
     "program":"WOOD CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0010204",
     "school_name":"Tema Manhean Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0010204",
     "school_name":"Tema Manhean Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0010204",
     "school_name":"Tema Manhean Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0010204",
     "school_name":"Tema Manhean Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0010204",
     "school_name":"Tema Manhean Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0010204",
     "school_name":"Tema Manhean Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0010204",
     "school_name":"Tema Manhean Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0010205",
     "school_name":"Tema Meth. Day Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0010205",
     "school_name":"Tema Meth. Day Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0010205",
     "school_name":"Tema Meth. Day Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0010205",
     "school_name":"Tema Meth. Day Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0010205",
     "school_name":"Tema Meth. Day Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0090103",
     "school_name":"Tempane Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0090103",
     "school_name":"Tempane Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0090103",
     "school_name":"Tempane Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0090103",
     "school_name":"Tempane Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0060209",
     "school_name":"Terchire Senior High ",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0060209",
     "school_name":"Terchire Senior High ",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0060209",
     "school_name":"Terchire Senior High ",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9011701",
     "school_name":"Teshie Tech. Instittute",
     "category":"C",
     "school_type":"TVET",
     "program":"BUILDING CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9011701",
     "school_name":"Teshie Tech. Instittute",
     "category":"C",
     "school_type":"TVET",
     "program":"BUSINESS ACCOUNTING",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9011701",
     "school_name":"Teshie Tech. Instittute",
     "category":"C",
     "school_type":"TVET",
     "program":"BUSINESS INFORMATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9011701",
     "school_name":"Teshie Tech. Instittute",
     "category":"C",
     "school_type":"TVET",
     "program":"ELECTRICAL ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9011701",
     "school_name":"Teshie Tech. Instittute",
     "category":"C",
     "school_type":"TVET",
     "program":"FASHION DESIGNING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9011701",
     "school_name":"Teshie Tech. Instittute",
     "category":"C",
     "school_type":"TVET",
     "program":"HOSPITALITY & CATERING MANAGEMENT",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9011701",
     "school_name":"Teshie Tech. Instittute",
     "category":"C",
     "school_type":"TVET",
     "program":"WOOD CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9052802",
     "school_name":"Tetrefu ICCES",
     "category":"C",
     "school_type":"TVET",
     "program":"ARCHITECTURAL DRAFTING",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9052802",
     "school_name":"Tetrefu ICCES",
     "category":"C",
     "school_type":"TVET",
     "program":"BUSINESS INFORMATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9052802",
     "school_name":"Tetrefu ICCES",
     "category":"C",
     "school_type":"TVET",
     "program":"HOSPITALITY & CATERING MANAGEMENT",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9052802",
     "school_name":"Tetrefu ICCES",
     "category":"C",
     "school_type":"TVET",
     "program":"WOOD CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0070704",
     "school_name":"Three Town Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070704",
     "school_name":"Three Town Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070704",
     "school_name":"Three Town Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070704",
     "school_name":"Three Town Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070704",
     "school_name":"Three Town Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070704",
     "school_name":"Three Town Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051805",
     "school_name":"Tijjaniya Senior High ",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051805",
     "school_name":"Tijjaniya Senior High ",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051805",
     "school_name":"Tijjaniya Senior High ",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0051805",
     "school_name":"Tijjaniya Senior High ",
     "category":"C",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9071200",
     "school_name":"Toh-Kpalime Vocational Training Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"BUILDING CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9071200",
     "school_name":"Toh-Kpalime Vocational Training Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"BUSINESS INFORMATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9071200",
     "school_name":"Toh-Kpalime Vocational Training Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"ELECTRICAL ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9071200",
     "school_name":"Toh-Kpalime Vocational Training Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"FASHION DESIGNING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9071200",
     "school_name":"Toh-Kpalime Vocational Training Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"HOSPITALITY & CATERING MANAGEMENT",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9071200",
     "school_name":"Toh-Kpalime Vocational Training Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"WOOD CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0081201",
     "school_name":"Tolon Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0081201",
     "school_name":"Tolon Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0081201",
     "school_name":"Tolon Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0081201",
     "school_name":"Tolon Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0081201",
     "school_name":"Tolon Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0090412",
     "school_name":"Tongo Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0090412",
     "school_name":"Tongo Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0090412",
     "school_name":"Tongo Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0090412",
     "school_name":"Tongo Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0070509",
     "school_name":"Tsiame Senior High",
     "category":"C",
     "school_type":"SHTS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0070509",
     "school_name":"Tsiame Senior High",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0070509",
     "school_name":"Tsiame Senior High",
     "category":"C",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0070509",
     "school_name":"Tsiame Senior High",
     "category":"C",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0070110",
     "school_name":"Tsito Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070110",
     "school_name":"Tsito Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070110",
     "school_name":"Tsito Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070110",
     "school_name":"Tsito Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070110",
     "school_name":"Tsito Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070110",
     "school_name":"Tsito Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070110",
     "school_name":"Tsito Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0100402",
     "school_name":"Tumu Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0100402",
     "school_name":"Tumu Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0100402",
     "school_name":"Tumu Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0100402",
     "school_name":"Tumu Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0100402",
     "school_name":"Tumu Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0100402",
     "school_name":"Tumu Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0080702",
     "school_name":"Tuna Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0080702",
     "school_name":"Tuna Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0080702",
     "school_name":"Tuna Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0080702",
     "school_name":"Tuna Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0080702",
     "school_name":"Tuna Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050312",
     "school_name":"Tweapease Senior High School",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050312",
     "school_name":"Tweapease Senior High School",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9052300",
     "school_name":"Twedie ICCES",
     "category":"C",
     "school_type":"TVET",
     "program":"BUILDING CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9052300",
     "school_name":"Twedie ICCES",
     "category":"C",
     "school_type":"TVET",
     "program":"ELECTRICAL ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9052300",
     "school_name":"Twedie ICCES",
     "category":"C",
     "school_type":"TVET",
     "program":"FASHION DESIGNING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9052300",
     "school_name":"Twedie ICCES",
     "category":"C",
     "school_type":"TVET",
     "program":"HOSPITALITY & CATERING MANAGEMENT",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9052300",
     "school_name":"Twedie ICCES",
     "category":"C",
     "school_type":"TVET",
     "program":"WOOD CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0100301",
     "school_name":"Ullo Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0100301",
     "school_name":"Ullo Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0100301",
     "school_name":"Ullo Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0100301",
     "school_name":"Ullo Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040306",
     "school_name":"Uthman Bin Afam SHS",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040306",
     "school_name":"Uthman Bin Afam SHS",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040306",
     "school_name":"Uthman Bin Afam SHS",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040306",
     "school_name":"Uthman Bin Afam SHS",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0040306",
     "school_name":"Uthman Bin Afam SHS",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050158",
     "school_name":"Uthmaniya Senior High, Tafo",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0050158",
     "school_name":"Uthmaniya Senior High, Tafo",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0050158",
     "school_name":"Uthmaniya Senior High, Tafo",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0050158",
     "school_name":"Uthmaniya Senior High, Tafo",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0070606",
     "school_name":"Vakpo Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070606",
     "school_name":"Vakpo Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070606",
     "school_name":"Vakpo Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070606",
     "school_name":"Vakpo Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070606",
     "school_name":"Vakpo Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070606",
     "school_name":"Vakpo Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0071009",
     "school_name":"Ve Comm. Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0071009",
     "school_name":"Ve Comm. Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0071009",
     "school_name":"Ve Comm. Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0071009",
     "school_name":"Ve Comm. Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0071009",
     "school_name":"Ve Comm. Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0071009",
     "school_name":"Ve Comm. Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0080106",
     "school_name":"Vitting Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0080106",
     "school_name":"Vitting Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0080106",
     "school_name":"Vitting Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0080106",
     "school_name":"Vitting Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0080106",
     "school_name":"Vitting Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0080106",
     "school_name":"Vitting Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9030301",
     "school_name":"Vocational Training and Rehabilitation Institute, Biriwa",
     "category":"C",
     "school_type":"TVET",
     "program":"BUILDING CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9030301",
     "school_name":"Vocational Training and Rehabilitation Institute, Biriwa",
     "category":"C",
     "school_type":"TVET",
     "program":"COMPUTER HARDWARE TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9030301",
     "school_name":"Vocational Training and Rehabilitation Institute, Biriwa",
     "category":"C",
     "school_type":"TVET",
     "program":"ELECTRICAL ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9030301",
     "school_name":"Vocational Training and Rehabilitation Institute, Biriwa",
     "category":"C",
     "school_type":"TVET",
     "program":"FASHION DESIGNING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9030301",
     "school_name":"Vocational Training and Rehabilitation Institute, Biriwa",
     "category":"C",
     "school_type":"TVET",
     "program":"HOSPITALITY & CATERING MANAGEMENT",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9030301",
     "school_name":"Vocational Training and Rehabilitation Institute, Biriwa",
     "category":"C",
     "school_type":"TVET",
     "program":"MOTOR VEHICLE ENGINEERING",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9030301",
     "school_name":"Vocational Training and Rehabilitation Institute, Biriwa",
     "category":"C",
     "school_type":"TVET",
     "program":"REFRIGERATION & AIR-CONDITION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9030301",
     "school_name":"Vocational Training and Rehabilitation Institute, Biriwa",
     "category":"C",
     "school_type":"TVET",
     "program":"WELDING AND FABRICATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9030301",
     "school_name":"Vocational Training and Rehabilitation Institute, Biriwa",
     "category":"C",
     "school_type":"TVET",
     "program":"WOOD CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070808",
     "school_name":"Volo Comm. Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0070808",
     "school_name":"Volo Comm. Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0070808",
     "school_name":"Volo Comm. Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0070808",
     "school_name":"Volo Comm. Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0070808",
     "school_name":"Volo Comm. Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0070808",
     "school_name":"Volo Comm. Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0070511",
     "school_name":"Volta Senior High School",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0070511",
     "school_name":"Volta Senior High School",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0070511",
     "school_name":"Volta Senior High School",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0070511",
     "school_name":"Volta Senior High School",
     "category":"C",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9070702",
     "school_name":"Volta Tech Inst",
     "category":"C",
     "school_type":"TVET",
     "program":"BUILDING CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9070702",
     "school_name":"Volta Tech Inst",
     "category":"C",
     "school_type":"TVET",
     "program":"BUSINESS ACCOUNTING",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9070702",
     "school_name":"Volta Tech Inst",
     "category":"C",
     "school_type":"TVET",
     "program":"BUSINESS INFORMATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9070702",
     "school_name":"Volta Tech Inst",
     "category":"C",
     "school_type":"TVET",
     "program":"COMPUTER HARDWARE TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9070702",
     "school_name":"Volta Tech Inst",
     "category":"C",
     "school_type":"TVET",
     "program":"ELECTRICAL ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9070702",
     "school_name":"Volta Tech Inst",
     "category":"C",
     "school_type":"TVET",
     "program":"FASHION DESIGNING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9070702",
     "school_name":"Volta Tech Inst",
     "category":"C",
     "school_type":"TVET",
     "program":"HOSPITALITY & CATERING MANAGEMENT",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9070702",
     "school_name":"Volta Tech Inst",
     "category":"C",
     "school_type":"TVET",
     "program":"MOTOR VEHICLE ENGINEERING",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9100104",
     "school_name":"Wa CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"BUILDING CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9100104",
     "school_name":"Wa CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"COSMETOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9100104",
     "school_name":"Wa CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"ELECTRICAL ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9100104",
     "school_name":"Wa CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"FASHION DESIGNING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9100104",
     "school_name":"Wa CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"GENERAL TEXTILES",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9100104",
     "school_name":"Wa CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"HOSPITALITY & CATERING MANAGEMENT",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9100104",
     "school_name":"Wa CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"INFORMATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9100104",
     "school_name":"Wa CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"MOTOR VEHICLE ENGINEERING",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9100104",
     "school_name":"Wa CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"WOOD CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0100101",
     "school_name":"Wa Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0100101",
     "school_name":"Wa Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0100101",
     "school_name":"Wa Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0100101",
     "school_name":"Wa Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0080601",
     "school_name":"Walewale Senior High",
     "category":"C",
     "school_type":"SHTS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0080601",
     "school_name":"Walewale Senior High",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0080601",
     "school_name":"Walewale Senior High",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0080601",
     "school_name":"Walewale Senior High",
     "category":"C",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0080601",
     "school_name":"Walewale Senior High",
     "category":"C",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0080601",
     "school_name":"Walewale Senior High",
     "category":"C",
     "school_type":"SHTS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9080601",
     "school_name":"Walewale Tech\/ Voc Inst.",
     "category":"C",
     "school_type":"TVET",
     "program":"AGRICULTURAL MECHANIZATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9080601",
     "school_name":"Walewale Tech\/ Voc Inst.",
     "category":"C",
     "school_type":"TVET",
     "program":"BUILDING CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9080601",
     "school_name":"Walewale Tech\/ Voc Inst.",
     "category":"C",
     "school_type":"TVET",
     "program":"BUSINESS ACCOUNTING",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9080601",
     "school_name":"Walewale Tech\/ Voc Inst.",
     "category":"C",
     "school_type":"TVET",
     "program":"BUSINESS SECRETARIAL",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9080601",
     "school_name":"Walewale Tech\/ Voc Inst.",
     "category":"C",
     "school_type":"TVET",
     "program":"COMPUTER HARDWARE TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9080601",
     "school_name":"Walewale Tech\/ Voc Inst.",
     "category":"C",
     "school_type":"TVET",
     "program":"ELECTRICAL ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9080601",
     "school_name":"Walewale Tech\/ Voc Inst.",
     "category":"C",
     "school_type":"TVET",
     "program":"FASHION DESIGNING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9080601",
     "school_name":"Walewale Tech\/ Voc Inst.",
     "category":"C",
     "school_type":"TVET",
     "program":"HOSPITALITY & CATERING MANAGEMENT",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9080601",
     "school_name":"Walewale Tech\/ Voc Inst.",
     "category":"C",
     "school_type":"TVET",
     "program":"PLUMBING & GAS FITTING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0080903",
     "school_name":"Wapuli Comm. Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0080903",
     "school_name":"Wapuli Comm. Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0080903",
     "school_name":"Wapuli Comm. Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0050307",
     "school_name":"Wesley High School, Bekwai",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050307",
     "school_name":"Wesley High School, Bekwai",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050307",
     "school_name":"Wesley High School, Bekwai",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050307",
     "school_name":"Wesley High School, Bekwai",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050307",
     "school_name":"Wesley High School, Bekwai",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050307",
     "school_name":"Wesley High School, Bekwai",
     "category":"C",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070708",
     "school_name":"Weta Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070708",
     "school_name":"Weta Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070708",
     "school_name":"Weta Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070708",
     "school_name":"Weta Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070708",
     "school_name":"Weta Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070708",
     "school_name":"Weta Senior High\/Tech.",
     "category":"C",
     "school_type":"SHTS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0050808",
     "school_name":"Wiafe Akenten Presby Senior High ",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0050808",
     "school_name":"Wiafe Akenten Presby Senior High ",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0050808",
     "school_name":"Wiafe Akenten Presby Senior High ",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0090304",
     "school_name":"Wiaga Comm. Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0090304",
     "school_name":"Wiaga Comm. Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"9030900",
     "school_name":"Winneba Vocational Training Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"BUILDING CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9030900",
     "school_name":"Winneba Vocational Training Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"BUSINESS INFORMATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9030900",
     "school_name":"Winneba Vocational Training Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"ELECTRICAL ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9030900",
     "school_name":"Winneba Vocational Training Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"FASHION DESIGNING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9030900",
     "school_name":"Winneba Vocational Training Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"HOSPITALITY & CATERING MANAGEMENT",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9030900",
     "school_name":"Winneba Vocational Training Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"INFORMATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9030900",
     "school_name":"Winneba Vocational Training Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"MECHANICAL. ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9030900",
     "school_name":"Winneba Vocational Training Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"MOTOR VEHICLE ENGINEERING",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9030900",
     "school_name":"Winneba Vocational Training Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"PLUMBING & GAS FITTING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9030900",
     "school_name":"Winneba Vocational Training Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"WELDING AND FABRICATION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0071102",
     "school_name":"Worawora Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0071102",
     "school_name":"Worawora Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0071102",
     "school_name":"Worawora Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0071102",
     "school_name":"Worawora Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0071102",
     "school_name":"Worawora Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0071102",
     "school_name":"Worawora Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070706",
     "school_name":"Wovenu Senior High Technical",
     "category":"C",
     "school_type":"SHTS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070706",
     "school_name":"Wovenu Senior High Technical",
     "category":"C",
     "school_type":"SHTS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070706",
     "school_name":"Wovenu Senior High Technical",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070706",
     "school_name":"Wovenu Senior High Technical",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070706",
     "school_name":"Wovenu Senior High Technical",
     "category":"C",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070706",
     "school_name":"Wovenu Senior High Technical",
     "category":"C",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070706",
     "school_name":"Wovenu Senior High Technical",
     "category":"C",
     "school_type":"SHTS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0080402",
     "school_name":"Wulensi Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0080402",
     "school_name":"Wulensi Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0080402",
     "school_name":"Wulensi Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0080402",
     "school_name":"Wulensi Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0080602",
     "school_name":"Wulugu Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0080602",
     "school_name":"Wulugu Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0080602",
     "school_name":"Wulugu Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070211",
     "school_name":"Yabram Comm. Day School",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0070211",
     "school_name":"Yabram Comm. Day School",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0070211",
     "school_name":"Yabram Comm. Day School",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0070211",
     "school_name":"Yabram Comm. Day School",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0070211",
     "school_name":"Yabram Comm. Day School",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0070211",
     "school_name":"Yabram Comm. Day School",
     "category":"C",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0080604",
     "school_name":"Yagaba Senior High School",
     "category":"C",
     "school_type":"SHTS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0080604",
     "school_name":"Yagaba Senior High School",
     "category":"C",
     "school_type":"SHTS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0080604",
     "school_name":"Yagaba Senior High School",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0080604",
     "school_name":"Yagaba Senior High School",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0080604",
     "school_name":"Yagaba Senior High School",
     "category":"C",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0080604",
     "school_name":"Yagaba Senior High School",
     "category":"C",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9060200",
     "school_name":"Yamfo Vocational Training Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"BUILDING CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9060200",
     "school_name":"Yamfo Vocational Training Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"ELECTRICAL ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9060200",
     "school_name":"Yamfo Vocational Training Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"HOSPITALITY & CATERING MANAGEMENT",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9060200",
     "school_name":"Yamfo Vocational Training Institute",
     "category":"C",
     "school_type":"TVET",
     "program":"WOOD CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021401",
     "school_name":"Yilo Krobo Senior High\/Com",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021401",
     "school_name":"Yilo Krobo Senior High\/Com",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021401",
     "school_name":"Yilo Krobo Senior High\/Com",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021401",
     "school_name":"Yilo Krobo Senior High\/Com",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021401",
     "school_name":"Yilo Krobo Senior High\/Com",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0021401",
     "school_name":"Yilo Krobo Senior High\/Com",
     "category":"C",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0081101",
     "school_name":"Zabzugu Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0081101",
     "school_name":"Zabzugu Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0081101",
     "school_name":"Zabzugu Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0081101",
     "school_name":"Zabzugu Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0081101",
     "school_name":"Zabzugu Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0090402",
     "school_name":"Zamse Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0090402",
     "school_name":"Zamse Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0090402",
     "school_name":"Zamse Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0090402",
     "school_name":"Zamse Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0090402",
     "school_name":"Zamse Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070130",
     "school_name":"Ziavi Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0070130",
     "school_name":"Ziavi Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0070130",
     "school_name":"Ziavi Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0070130",
     "school_name":"Ziavi Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0070130",
     "school_name":"Ziavi Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0070130",
     "school_name":"Ziavi Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Technical",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0070130",
     "school_name":"Ziavi Senior High\/Tech",
     "category":"C",
     "school_type":"SHTS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0070505",
     "school_name":"Zion Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070505",
     "school_name":"Zion Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070505",
     "school_name":"Zion Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070505",
     "school_name":"Zion Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070505",
     "school_name":"Zion Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070505",
     "school_name":"Zion Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0070125",
     "school_name":"Ziope Senior High Sch.",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0070125",
     "school_name":"Ziope Senior High Sch.",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0070125",
     "school_name":"Ziope Senior High Sch.",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0070125",
     "school_name":"Ziope Senior High Sch.",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0070125",
     "school_name":"Ziope Senior High Sch.",
     "category":"C",
     "school_type":"SHS",
     "program":"Visual Arts",
     "gender":"Mixed",
     "residence":"Day"
  },
  {
     "school_code":"0090603",
     "school_name":"Zorkor Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0090603",
     "school_name":"Zorkor Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0090603",
     "school_name":"Zorkor Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0090603",
     "school_name":"Zorkor Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0090405",
     "school_name":"Zuarungu Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Agriculture",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0090405",
     "school_name":"Zuarungu Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Business",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0090405",
     "school_name":"Zuarungu Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Arts",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0090405",
     "school_name":"Zuarungu Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"General Science",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"0090405",
     "school_name":"Zuarungu Senior High",
     "category":"C",
     "school_type":"SHS",
     "program":"Home Economics",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9081600",
     "school_name":"Zugu Dabogni CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"BUILDING CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9081600",
     "school_name":"Zugu Dabogni CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"COSMETOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9081600",
     "school_name":"Zugu Dabogni CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"ELECTRICAL ENGINEERING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9081600",
     "school_name":"Zugu Dabogni CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"FASHION DESIGNING TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9081600",
     "school_name":"Zugu Dabogni CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"HOSPITALITY & CATERING MANAGEMENT",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9081600",
     "school_name":"Zugu Dabogni CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"MOTOR VEHICLE ENGINEERING",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  },
  {
     "school_code":"9081600",
     "school_name":"Zugu Dabogni CDVTI",
     "category":"C",
     "school_type":"TVET",
     "program":"WOOD CONSTRUCTION TECHNOLOGY",
     "gender":"Mixed",
     "residence":"Day\/Boarding"
  }
]

export default SHS;
