import React, { Fragment } from "react";
import classNames from "classnames";
import AuthService from '../../../misc/AuthService';
class SideNav extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      userInfo: AuthService.getChecker().checker
    }
  }

  render(){

    return (
      <div id="kt_aside" class="aside" data-kt-drawer="true" data-kt-drawer-name="aside" data-kt-drawer-activate="{default: true, lg: false}" data-kt-drawer-overlay="true" data-kt-drawer-width="auto" data-kt-drawer-direction="start" data-kt-drawer-toggle="#kt_aside_toggle">
                  
        <div class="aside-logo flex-column-auto pt-10 pt-lg-20" id="kt_aside_logo">
          <a href="/dashboard">
            <img alt="Logo" src="/assets/media/logos/hodlpay_icon.svg" class="h-60px" />
          </a>
        </div>
        
        
        <div class="aside-menu flex-column-fluid pt-0 pb-7 py-lg-10" id="kt_aside_menu">
          
          <div id="kt_aside_menu_wrapper" class="w-100 hover-scroll-overlay-y scroll-ps d-flex" data-kt-scroll="true" data-kt-scroll-height="auto" data-kt-scroll-dependencies="#kt_aside_logo, #kt_aside_footer" data-kt-scroll-wrappers="#kt_aside, #kt_aside_menu" data-kt-scroll-offset="0">
            <div id="kt_aside_menu" class="menu menu-column menu-title-gray-600 menu-state-primary menu-state-icon-primary menu-state-bullet-primary menu-icon-gray-400 menu-arrow-gray-400 fw-bold fs-6 my-auto" data-kt-menu="true">
              <div className={classNames("menu-item py-3", {"here show": this.props.active === "dashboard"})}>
                <span class="menu-link" title="Dashboard" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-dismiss="click" data-bs-placement="right">
                  <a href="/dashboard" class="menu-icon">
                    
                    <span class="svg-icon svg-icon-2x">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <rect x="2" y="2" width="9" height="9" rx="2" fill="black" />
                        <rect opacity="0.3" x="13" y="2" width="9" height="9" rx="2" fill="black" />
                        <rect opacity="0.3" x="13" y="13" width="9" height="9" rx="2" fill="black" />
                        <rect opacity="0.3" x="2" y="13" width="9" height="9" rx="2" fill="black" />
                      </svg>
                    </span>
                    
                  </a>
                </span>
              </div>
              <div className={classNames("menu-item py-3", {"here show": this.props.active === "orders"})}>
                <span class="menu-link" title="Orders" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-dismiss="click" data-bs-placement="right">
                  <a href="/orders" class="menu-icon">
                    
                    <span class="svg-icon svg-icon-2x">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                          <rect x="0" y="0" width="24" height="24"/>
                          <rect fill="#000000" opacity="0.3" x="2" y="2" width="10" height="12" rx="2"/>
                          <path d="M4,6 L20,6 C21.1045695,6 22,6.8954305 22,8 L22,20 C22,21.1045695 21.1045695,22 20,22 L4,22 C2.8954305,22 2,21.1045695 2,20 L2,8 C2,6.8954305 2.8954305,6 4,6 Z M18,16 C19.1045695,16 20,15.1045695 20,14 C20,12.8954305 19.1045695,12 18,12 C16.8954305,12 16,12.8954305 16,14 C16,15.1045695 16.8954305,16 18,16 Z" fill="#000000"/>
                        </g>
                      </svg>
                    </span>
                    
                  </a>
                </span>
              </div>
              <div className={classNames("menu-item py-3", {"here show": this.props.active === "faq"})}>
                <span class="menu-link" title="FAQ" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-dismiss="click" data-bs-placement="right">
                  <a href="/faq" class="menu-icon">
                    
                    <span class="svg-icon svg-icon-2x">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <rect x="0" y="0" width="24" height="24"/>
                            <polygon fill="#000000" opacity="0.3" points="5 15 3 21.5 9.5 19.5"/>
                            <path d="M13.5,21 C8.25329488,21 4,16.7467051 4,11.5 C4,6.25329488 8.25329488,2 13.5,2 C18.7467051,2 23,6.25329488 23,11.5 C23,16.7467051 18.7467051,21 13.5,21 Z M9,8 C8.44771525,8 8,8.44771525 8,9 C8,9.55228475 8.44771525,10 9,10 L18,10 C18.5522847,10 19,9.55228475 19,9 C19,8.44771525 18.5522847,8 18,8 L9,8 Z M9,12 C8.44771525,12 8,12.4477153 8,13 C8,13.5522847 8.44771525,14 9,14 L14,14 C14.5522847,14 15,13.5522847 15,13 C15,12.4477153 14.5522847,12 14,12 L9,12 Z" fill="#000000"/>
                        </g>
                      </svg>
                    </span>
                    
                  </a>
                </span>
              </div>
            </div>
          </div>
          
        </div>
        
        
        <div class="aside-footer flex-column-auto pb-5 pb-lg-10" id="kt_aside_footer">
          
          <div class="d-flex flex-center w-100 scroll-px" data-bs-toggle="tooltip" data-bs-placement="right" data-bs-dismiss="click" title="Account">
            <button type="button" class="btn btn-custom" data-kt-menu-trigger="click" data-kt-menu-overflow="true" data-kt-menu-placement="top-start">
              
              <span class="svg-icon svg-icon-2x m-0">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path d="M6.28548 15.0861C7.34369 13.1814 9.35142 12 11.5304 12H12.4696C14.6486 12 16.6563 13.1814 17.7145 15.0861L19.3493 18.0287C20.0899 19.3618 19.1259 21 17.601 21H6.39903C4.87406 21 3.91012 19.3618 4.65071 18.0287L6.28548 15.0861Z" fill="black" />
                  <rect opacity="0.3" x="8" y="3" width="8" height="8" rx="4" fill="black" />
                </svg>
              </span>
              
            </button>
            
            <div class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px" data-kt-menu="true">
                    
                    <div class="menu-item px-3">
                      <div class="menu-content d-flex align-items-center px-3">
                        
                        <div class="symbol symbol-50px me-5">
                          <span class="svg-icon svg-icon-primary svg-icon-3x ms-n1">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                              <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                  <polygon points="0 0 24 0 24 24 0 24"/>
                                  <path d="M12,11 C9.790861,11 8,9.209139 8,7 C8,4.790861 9.790861,3 12,3 C14.209139,3 16,4.790861 16,7 C16,9.209139 14.209139,11 12,11 Z" fill="#000000" fill-rule="nonzero" opacity="0.3"/>
                                  <path d="M3.00065168,20.1992055 C3.38825852,15.4265159 7.26191235,13 11.9833413,13 C16.7712164,13 20.7048837,15.2931929 20.9979143,20.2 C21.0095879,20.3954741 20.9979143,21 20.2466999,21 C16.541124,21 11.0347247,21 3.72750223,21 C3.47671215,21 2.97953825,20.45918 3.00065168,20.1992055 Z" fill="#000000" fill-rule="nonzero"/>
                              </g>
                            </svg>
                          </span>
                        </div>
                        
                        
                        <div class="d-flex flex-column">
                          <div class="fw-bolder d-flex align-items-center fs-5">
                            {this.state.userInfo.first_name} {this.state.userInfo.last_name} 
                            {/* <span class="badge badge-light-success fw-bolder fs-8 px-2 py-1 ms-2">Pro</span> */}
                          </div>
                          <a href="#" class="fw-bold text-muted text-hover-primary fs-7">{this.state.userInfo.email}</a>
                        </div>
                        
                      </div>
                    </div>
                    
                    
                    <div class="separator my-2"></div>
                    
                    
                    {/* <div class="menu-item px-5">
                      <a href="account/overview.html" class="menu-link px-5">My Profile</a>
                    </div> */}
                    
                    {/* <div class="menu-item px-5 my-1">
                      <a href="/account-settings" class="menu-link px-5">Account Settings</a>
                    </div> */}
                    
                    
                    <div class="menu-item px-5">
                      <a href="/checker-logout" class="menu-link px-5">Sign Out</a>
                    </div>
                    
                    
                    {/* <div class="separator my-2"></div>
                    
                    
                    <div class="menu-item px-5">
                      <div class="menu-content px-5">
                        <label class="form-check form-switch form-check-custom form-check-solid pulse pulse-success" for="kt_user_menu_dark_mode_toggle">
                          <input class="form-check-input w-30px h-20px" type="checkbox" value="1" name="mode" id="kt_user_menu_dark_mode_toggle" data-kt-url="dark/index.html" />
                          <span class="pulse-ring ms-n1"></span>
                          <span class="form-check-label text-gray-600 fs-7">Dark Mode</span>
                        </label>
                      </div>
                    </div> */}
                    
                  </div>
            
          </div>
          
        </div>
        
      </div>
    )
  }
}

export default SideNav;