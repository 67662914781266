import React, { Fragment } from "react";
import MetaTags from "react-meta-tags";
import AuthService from '../../misc/AuthService';
import CONSTANTS from '../../misc/Constants';
import axios from 'axios';
import cookie from 'react-cookies';
import { reactLocalStorage } from 'reactjs-localstorage';
import { 
  Input,
  Select, 
} from 'antd';
import 'antd/dist/antd.css';
import SideNav from "./include/SideNav";
import Header from "./include/Header";
import Footer from "./include/Footer";
import moment from 'moment';
import Numeral from 'react-numeral';
const { Option } = Select;

class SubjectPerformance extends React.Component {

  constructor(props){
		AuthService.checkProtected();
    super(props);
    this.state = {
      userInfo: AuthService.getAuth().user,
			isLoading: true,
			isRequesting: false,
      upgrade: false,
      year: '',
      performance_info: {
        current_year: '',
        stats_years: [],
        stats: {
          total_students: 0,
          passed_students: 0,
          failed_students: 0
        },
        performance: {}
      }
    }
	}

  getPerformanceInfo () {
    this.setState({
      ...this.state,
      isRequesting: true
    });

    axios.post(CONSTANTS.API_BASE_URL + "/user/get-performance", {
      year: this.state.year
    }, AuthService.getAxiosHeaders())
    .then((response) => {
      let responseInfo = response.data;

      this.setState({
        ...this.state,
        isLoading: false,
        isRequesting: false,
        upgrade: false,
        performance_info: responseInfo.data,
        year: responseInfo.data.current_year
      });
           
    }).catch((error) => {
      try{

        if(error.response.status === 401){
          window.location = "/logout";
          return;
        }

        let errorResponse = error.response.data;
        let upgrade = false;

        if(error.response.status === 402){
          upgrade = true;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("message")){
          errorMessage = errorResponse.message;
        }


        this.setState({
          ...this.state,
          isLoading: false,
          isRequesting: false,
          upgrade: upgrade,
          errorMessage: errorMessage
        });
      }catch(e){
        console.log(error)
        // window.location = "/server-offline";
      }
    });
  }

  componentDidMount () {
    this.getPerformanceInfo()
    
  }
	

  render(){
    return (
      <Fragment>
          <MetaTags>
          <title>Performance - BECE Stats</title>
          </MetaTags>
          <Header activePage={'performance'} />
          <div className="slim-mainpanel">
            {
              this.state.isLoading ?
              <div className="container">
                <div className="d-flex ht-300 pos-relative align-items-center">
                  <div className="sk-wave">
                    <div className="sk-rect sk-rect1 bg-primary" />
                    <div className="sk-rect sk-rect2 bg-primary" />
                    <div className="sk-rect sk-rect3 bg-primary" />
                    <div className="sk-rect sk-rect4 bg-primary" />
                    <div className="sk-rect sk-rect5 bg-primary" />
                  </div>
                </div>
              </div>
              :
              <div className="container">
                <div className="slim-pageheader">
                  <ol className="breadcrumb slim-breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="/dashboard">Home</a>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">Subjects Performance</li>
                  </ol>
                  <h6 className="slim-pagetitle">Performance</h6>
                </div>

                <div className="card card-dash-chart-one mg-t-20 mg-sm-t-30">
                  <div className="row no-gutters">
                    <div className="col-lg-4 bg-light">
                      <div className="left-panel">
                      <h6 className="slim-card-title mb-4">Filter</h6>
                        <div className="form-group">
                          <div className="form-label-group">
                              <label className="form-label" htmlFor="default-01">Year</label>
                          </div>
                          <Select className="mb-2 w-100" defaultValue={this.state.year} onChange={(e) => {
                            this.setState({
                              ...this.state,
                              year: e
                            })

                            setTimeout(() => {
                              this.getPerformanceInfo();
                            }, 800);
                          }}>
                            {
                              this.state.performance_info.stats_years.map((data, index) => {
                                return(
                                  <Option value={data} key={index}>{data}</Option>
                                )
                              })
                            }
                          </Select>
                        </div>
                        {
                          this.state.isRequesting ?
                          <button type="button" disabled className="btn btn-block btn-primary mg-b-10 mt-2">
                            <span>Please Wait...</span> 
                          </button>
                          :
                          <button onClick={() => {this.getPerformanceInfo()}} className="btn btn-primary btn-block mg-b-10 mt-2">Submit</button>
                        }
                      </div>
                    </div>
                    <div className="col-lg-8">
                      {
                        this.state.isRequesting ?
                        <div className="right-panel">
                          <div className="d-flex ht-300 pos-relative align-items-center">
                            <div className="sk-wave">
                              <div className="sk-rect sk-rect1 bg-primary" />
                              <div className="sk-rect sk-rect2 bg-primary" />
                              <div className="sk-rect sk-rect3 bg-primary" />
                              <div className="sk-rect sk-rect4 bg-primary" />
                              <div className="sk-rect sk-rect5 bg-primary" />
                            </div>
                          </div>
                        </div>
                        :
                        <>
                          {
                            this.state.upgrade ?
                            <div className="right-panel">
                              <div className="row">
                                <div className="col-md-6 offset-3 mb-5" style={{border: "none"}}>
                                  <div className="pd-25 tx-center">
                                    <div className="card-icon-wrapper primary">
                                      <i className="icon ion-ios-color-wand-outline" />
                                    </div>
                                    <h4 className="tx-gray-800 mg-b-5">Upgrade Plan</h4>
                                    <p className="mg-b-50">
                                      Upgrade your plan to get access to this feature.    
                                    </p>
                                    <a href="/subscription" className="btn btn-dark btn-block text-uppercase tx-bold">
                                      Upgrade plan
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                            :
                            <div className="right-panel">
                              <div className="card-body p-0">
                                <h6 className="slim-card-title tx-dark mb-2">Total Students</h6>
                                <h2 className="tx-lato tx-inverse"><Numeral value={this.state.performance_info.stats.total_students.toString()} format={"0,0"}/></h2>
                              </div>
                              <div className="card-sales p-0">
                                <div className="row">
                                  <div className="col tx-18 tx-success border-bottom-0">
                                    <label className="tx-12 mb-0">Passed</label>
                                    <p><b><Numeral value={this.state.performance_info.stats.passed_students.toString()} format={"0,0"}/></b></p>
                                  </div>
                                  <div className="col tx-18 tx-danger">
                                    <label className="tx-12 mb-0">Failed</label>
                                    <p><b><Numeral value={this.state.performance_info.stats.failed_students.toString()} format={"0,0"}/></b></p>
                                  </div>
                                </div>
                              </div>
                              <hr className="mg-t-30 mg-b-40" />
                              {
                                this.state.performance_info.performance.map((data, index) => {
                                  return (
                                    <>
                                      <div className="card-status" key={index}>
                                        <div className="media">
                                          <div className="media-body">
                                            <h4 className="mb-2 text-uppercase">{data.subject}</h4>
                                            {/* <p className="text-dark tx-18 mb-2">Average Grade: <b>3</b></p> */}
                                            {
                                              data.stats.map((sData, sIndex) => {
                                                return (
                                                  <div className="my-3" key={sIndex}>
                                                    <span className="tx-12 d-block mg-b-5">
                                                      <span className="fs-16px">Grade {sData.grade}</span> - <b>{sData.total_students} {sData.total_students > 1 ? `Students` : `Student`} ({sData.percentage}%)</b>
                                                    </span>
                                                    <div className="progress mg-b-10">
                                                      <div style={{width: sData.percentage+"%"}}
                                                        className="progress-bar ht-2 bg-primary progress-bar-xs"
                                                        role="progressbar"
                                                        aria-valuenow={sData.percentage}
                                                        aria-valuemin={0}
                                                        aria-valuemax={100}
                                                      />
                                                    </div>
                                                  </div>
                                                )
                                              })
                                            }
                                            
                                            {/* <div className="my-3">
                                              <span className="tx-12 d-block mg-b-5">
                                                Grade 2 - <b>83 Students (15%)</b>
                                              </span>
                                              <div className="progress mg-b-10">
                                                <div
                                                  className="progress-bar ht-2 wd-15p"
                                                  role="progressbar"
                                                  aria-valuenow={25}
                                                  aria-valuemin={0}
                                                  aria-valuemax={100}
                                                />
                                              </div>
                                            </div> */}
                                          </div>
                                        </div>
                                      </div>
                                      <hr/>
                                    </>
                                  )
                                })
                              }
                              
                            </div>
                          }
                        </>
                        
                      }
                    </div>
                  </div>
                </div>


              </div>
            }
          </div>
          <Footer />
      </Fragment>
    );
  }
}

export default SubjectPerformance;