import React, { Fragment } from "react";
import MetaTags from "react-meta-tags";
import AuthService from '../../misc/AuthService';
import CONSTANTS from '../../misc/Constants';
import axios from 'axios';
import cookie from 'react-cookies';
import { reactLocalStorage } from 'reactjs-localstorage';
import { 
  Input,
  Select, 
} from 'antd';
import 'antd/dist/antd.css';
import SideNav from "./include/SideNav";
import Header from "./include/Header";
import Footer from "./include/Footer";
import Pagination from "./include/Pagination";
import moment from 'moment';
import { PaystackButton } from 'react-paystack';
const { Option } = Select;

class Subscription extends React.Component {

  constructor(props){
		AuthService.checkProtected();
    super(props);
    this.state = {
      userInfo: AuthService.getAuth().user,
			isLoading: true,
			isRequesting: false,
      isRequestingPayment: false,
      initPayment: false,
      selectedPlan: '',
      totalList: 1,
      currentPage: 1,
      page: 1,
      currentPlan: 'free',
      expirationDate: '',
      transactions: {
        meta: {},
        data: []
      },
      paymentConfig: {},
      paymentStatus: '',
      paymentErrorMessage: '',
      errorMessage: ''
    }
	}

  getTransaction () {
    axios.post(CONSTANTS.API_BASE_URL + "/user/get-subscription-transactions", {
      page: this.state.page
    }, AuthService.getAxiosHeaders())
    .then((response) => {
      let responseInfo = response.data;

      this.setState({
        ...this.state,
        isLoading: false,
        isRequesting: false,
        currentPage: this.state.page,
        totalList:  Math.ceil(responseInfo.data.transactions_list.meta.result_total / 10),
        transactions: responseInfo.data.transactions_list,
        currentPlan: responseInfo.data.current_plan,
        expirationDate: responseInfo.data.expiration_date,
      });   

    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401 || error.response.status === 404){
          window.location = "/not-found";
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("message")){
          errorMessage = errorResponse.message;
        }

        this.setState({
          ...this.state,
          isLoading: false,
          isRequesting: false,
          errorMessage: errorMessage
        });
      }catch(e){
        console.log(error)
        // window.location = "/server-offline";
      }
    });
  }

  validatePayment(response){
    if(response.status === "failed"){
      this.setState({
        ...this.state,
        paymentErrorMessage: 'Error: Payment failed or something went wrong while processing payment. Try again'
      })
      window.modalShow('response-modal');
      return;
    }

    this.setState({
      ...this.state,
      isRequestingPayment: true,
      paymentErrorMessage: ''
    })

    window.modalShow('response-modal');

    axios.post(CONSTANTS.API_BASE_URL + "/user/verify-payment", {
      tx_ref: this.state.paymentConfig.reference
    }, AuthService.getAxiosHeaders())
    .then((response) => {
      let responseInfo = response.data;
      if(responseInfo.data.payment_status === "success"){
        
        this.setState({
          ...this.state,
          isRequestingPayment: false,
          paymentStatus: responseInfo.data.payment_status,
        });

        setTimeout(() => {
          this.getTransaction();
        }, 1000);
        
      }else{
        this.setState({
          ...this.state,
          isRequestingPayment: false,
          paymentStatus: responseInfo.data.payment_status,
          paymentErrorMessage: responseInfo.data.payment_status_message
        });
        
      }

    }).catch((error) => {
      try{
        console.log(error);
        let errorResponse = error.response.data;

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("message")){
          errorMessage = errorResponse.message;
        }

        window.scrollTo({top: 0, behavior: 'smooth'});

        this.setState({
          ...this.state,
          isRequestingPayment: false,
          paymentStatus: "failed",
          paymentErrorMessage: errorMessage
        });

        window.modalShow('response-modal');
      }catch(e){
        console.log(e)
        // window.location = "/server-offline";
      }
    });
  }  

  initSubscriptionPayment (plan) {

    this.setState({
      ...this.state,
      initPayment: true,
      selectedPlan: plan,
      paymentErrorMessage: ''
    })

    window.modalShow('subscription-modal');

    axios.post(CONSTANTS.API_BASE_URL + "/user/init-subscription-payment", {
      plan: plan
    }, AuthService.getAxiosHeaders())
    .then((response) => {
      let responseInfo = response.data;

      if(plan === 'free'){
        window.modalHide();
        this.getTransaction();

        setTimeout(() => {
          window.modalShow('response-modal');
        }, 500);
      }else{
        this.setState({
          ...this.state,
          paymentConfig: responseInfo.data,
          initPayment: false,
        });   
      }

         
    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 307){
          window.location = "/invitations";
          return;
        }

        if(error.response.status === 401 || error.response.status === 404){
          window.location = "/not-found";
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("message")){
          errorMessage = errorResponse.message;
        }

        this.setState({
          ...this.state,
          initPayment: false,
          paymentErrorMessage: errorMessage
        });
      }catch(e){
        console.log(error)
        // window.location = "/server-offline";
      }
    });

    setTimeout(() => {
      this.setState({
        ...this.state,
        initPayment: false
      })
    }, 2500);
  }

  componentDidMount () {
    let url_string = window.location.href;
    let url = new URL(url_string);
    let params = window.parse_query_string(url.search.replace(/^(\?)/,""));
    
    let page = params.hasOwnProperty('p') ? params.p : 1;
    
    this.setState({
      ...this.state,
      page: page
    })

    setTimeout(() => {
      this.getTransaction();
    }, 800);
  }
	

  render(){
    let paymentConfig = {};

    if(!this.state.isLoading){
      // payStack paymentConfig
      paymentConfig = {
        ...this.state.paymentConfig,
        text: 'Make Payment',
        onSuccess: (response) => {
          console.log(response);
          window.modalHide();
          this.validatePayment(response);
        },
        onClose: () => {
          console.log("payment modal closed");
        },
      };
    }

    return (
      <Fragment>
          <MetaTags>
          <title>Subscription - BECE Stats</title>
          </MetaTags>
          <Header activePage={'subscription'} />
          <div className="slim-mainpanel">
            {
              this.state.isLoading ?
              <div className="container">
                <div className="d-flex ht-300 pos-relative align-items-center">
                  <div className="sk-wave">
                    <div className="sk-rect sk-rect1 bg-primary" />
                    <div className="sk-rect sk-rect2 bg-primary" />
                    <div className="sk-rect sk-rect3 bg-primary" />
                    <div className="sk-rect sk-rect4 bg-primary" />
                    <div className="sk-rect sk-rect5 bg-primary" />
                  </div>
                </div>
              </div>
              :
              <div className="container">
                <div className="slim-pageheader">
                  <ol className="breadcrumb slim-breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="/dashboard">Home</a>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">Subscription</li>
                  </ol>
                  <h6 className="slim-pagetitle">Subscription</h6>
                </div>

                <div className="card card-pricing-one px-5">
                  <div className="row">
                    <div className="col-lg">
                      <div className="pricing-item">
                        <h5 className="pricing-title">Free</h5>
                        <div className="pricing-icon">
                          <i className="icon ion-ios-color-wand-outline" />
                        </div>
                        <h2 className="pricing-price mb-0"><small>GHS </small> 0</h2>
                        <p className="mb-4"><small>Billed Yearly</small></p>
                        
                        <ul className="pricing-list">
                          <li><i className="icon ion-ios-checkmark mr-2 tx-24 text-success" />10 Placement Results</li>
                          <li><i className="icon ion-ios-close mr-2 tx-24 text-danger" />School Placement Analytics</li>
                          <li><i className="icon ion-ios-close mr-2 tx-24 text-danger" />Subject Performance Analytics</li>
                        </ul>
                        {
                          this.state.currentPlan === 'free' ?
                          <button className="btn btn-dark btn-pricing d-inline">
                            Current Plan
                          </button>
                          :
                          <button onClick={() => {this.initSubscriptionPayment('free')}} className="btn btn-primary btn-pricing d-inline">
                            Choose Plan
                          </button>
                        }
                      </div>
                    </div>
                    <div className="col-lg mg-t-30 mg-lg-t-0">
                      <div className="pricing-item">
                        <h5 className="pricing-title">Basic</h5>
                        <div className="pricing-icon">
                          <i className="icon ion-ios-briefcase-outline" />
                        </div>
                        <h2 className="pricing-price mb-0"><small>GHS </small> 200</h2>
                        <p className="mb-4"><small>Billed Yearly</small></p>
                        
                        <ul className="pricing-list">
                          <li><i className="icon ion-ios-checkmark mr-2 tx-24 text-success" />Complete Placement Result</li>
                          <li><i className="icon ion-ios-close mr-2 tx-24 text-danger" />School Placement Analytics</li>
                          <li><i className="icon ion-ios-close mr-2 tx-24 text-danger" />Subject Performance Analytics</li>
                        </ul>

                        {
                          this.state.currentPlan === 'basic' ?
                          <button className="btn btn-dark btn-pricing d-inline">
                            Current Plan
                          </button>
                          :
                          <button onClick={() => {this.initSubscriptionPayment('basic')}} className="btn btn-primary btn-pricing d-inline">
                            Choose Plan
                          </button>
                        }
                      </div>
                    </div>
                    <div className="col-lg mg-t-30 mg-lg-t-0">
                      <div className="pricing-item">
                        <h5 className="pricing-title">Pro</h5>
                        <div className="pricing-icon">
                          <i className="icon ion-ios-star-outline" />
                        </div>
                        <h2 className="pricing-price mb-0"><small>GHS </small> 500</h2>
                        <p className="mb-4"><small>Billed Yearly</small></p>
                        
                        <ul className="pricing-list">
                          <li><i className="icon ion-ios-checkmark mr-2 tx-24 text-success" />Complete Placement Result</li>
                          <li><i className="icon ion-ios-checkmark mr-2 tx-24 text-success" />School Placement Analytics</li>
                          <li><i className="icon ion-ios-checkmark mr-2 tx-24 text-success" />Subject Performance Analytics</li>
                        </ul>
                        {
                          this.state.currentPlan === 'pro' ?
                          <button className="btn btn-dark btn-pricing d-inline">
                            Current Plan
                          </button>
                          :
                          <button onClick={() => {this.initSubscriptionPayment('pro')}} className="btn btn-primary btn-pricing d-inline">
                            Choose Plan
                          </button>
                        }
                      </div>
                    </div>
                  </div>
                </div>
                <div className="section-wrapper mg-t-20 mt-5">
                  <label className="section-title">Transactions</label>
                  <p className="mg-b-20 mg-sm-b-40">List of subscription transactions.</p>
                  {
                    this.state.transactions.data.length === 0 ?
                    <div className="row">
                      <div className="col-md-6 offset-3 mb-5" style={{border: "none"}}>
                        <div className="pd-25 tx-center">
                          <div className="card-icon-wrapper primary">
                            <i className="icon ion-ios-browsers-outline" />
                          </div>
                          <p className="mg-b-50">
                            No subscription transactions found.   
                          </p>
                        </div>
                      </div>
                    </div>
                    :
                    <div className="table-responsive">
                      <table className="table table-striped mg-b-0">
                        <thead>
                          <tr>
                            <th>Package</th>
                            <th>Amount</th>
                            <th>Invoice No.#</th>
                            <th>Status</th>
                            <th>Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            this.state.transactions.data.map((data, index) => {
                              let status = <span className="text-warning"><span className="square-8 bg-warning mg-r-5 rounded-circle" /> Pending</span>
                              
                              if(data.status == 's'){
                                status = <span className="text-success"><span className="square-8 bg-success mg-r-5 rounded-circle" /> Successful</span>
                              
                              }else if(data.status == 'f'){
                                status = <span className="text-danger"><span className="square-8 bg-danger mg-r-5 rounded-circle" /> Failed</span>
                              }else if(data.status == 'r'){
                                status = <span className="text-dark"><span className="square-8 bg-danger mg-r-5 rounded-circle" /> Refunded</span>
                              }

                              return (
                                <tr>
                                  <td className="text-capitalize">{data.plan_name}</td>
                                  <td>{data.amount}</td>
                                  <td>{data.invoice_number}</td>
                                  <td>{status}</td>
                                  <td>{data.date}</td>
                                </tr>
                              )
                            })
                          }
                        </tbody>
                      </table>
                      <div class="pt-5">
                        <Pagination pageCount={this.state.totalList} currentPage={this.state.currentPage} link={`/subscription?`} paramsValue={``} />
                      </div>
                    </div>
                  }
                </div>
                
              </div>
            }
          </div>
          <div id="subscription-modal" className="modal fade">
            <div className="modal-dialog modal-md" role="document">
              <div className="modal-content tx-size-sm">
                <div className="modal-body tx-center pd-y-20 pd-x-20">
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                  {
                    this.state.initPayment ?
                    <>
                      <div className="right-panel">
                        <div className="d-flex ht-100 pos-relative align-items-center">
                          <div className="sk-wave">
                            <div className="sk-rect sk-rect1 bg-primary" />
                            <div className="sk-rect sk-rect2 bg-primary" />
                            <div className="sk-rect sk-rect3 bg-primary" />
                            <div className="sk-rect sk-rect4 bg-primary" />
                            <div className="sk-rect sk-rect5 bg-primary" />
                          </div>
                        </div>
                      </div>
                      {
                        this.state.selectedPlan === 'free' ?
                        <>
                          <h4 className="tx-dark tx-semibold mg-b-20">Updating Subscription</h4>
                          <p className="mg-b-20 mg-x-20">Please wait while we update your subscription package.</p>
                        </>
                        :
                        <>
                          <h4 className="tx-dark tx-semibold mg-b-20">Initializing Payment</h4>
                          <p className="mg-b-20 mg-x-20">Please wait while we initialize payment.</p>
                        </>
                      }
                      
                      {/* <button type="button" className="btn btn-success pd-x-25" data-dismiss="modal" aria-label="Close">
                        Continue
                      </button> */}
                    </>
                    :
                    <>
                      <h4 className="tx-dark tx-semibold mg-b-20">Subscription Payment</h4>
                      <p class="px-5">Click on the "Make Payment" button to subscribe to this package for a year.</p>
                      {
                        this.state.selectedPlan === 'basic' &&
                        <>
                          <div className="pricing-icon">
                            <i className="icon ion-ios-briefcase-outline" />
                          </div>
                          <h5 className="pricing-title">Basic</h5>
                          <h2 className="pricing-price mb-0"><small>GHS </small> 200</h2>
                          <p className="mb-4"><small>Billed Yearly</small></p>
                          
                          <ul className="pricing-list">
                            <li><i className="icon ion-ios-checkmark mr-2 tx-24 text-success" />Complete Placement Result</li>
                            <li><i className="icon ion-ios-close mr-2 tx-24 text-danger" />School Placement Analytics</li>
                            <li><i className="icon ion-ios-close mr-2 tx-24 text-danger" />Subject Performance Analytics</li>
                          </ul>
                        </>
                      }

                      {
                        this.state.selectedPlan === 'pro' &&
                        <>
                          <div className="pricing-icon">
                            <i className="icon ion-ios-star-outline" />
                          </div>
                          <h5 className="pricing-title">Pro</h5>
                          <h2 className="pricing-price mb-0"><small>GHS </small> 500</h2>
                          <p className="mb-4"><small>Billed Yearly</small></p>
                          
                          <ul className="pricing-list">
                            <li><i className="icon ion-ios-checkmark mr-2 tx-24 text-success" />Complete Placement Result</li>
                            <li><i className="icon ion-ios-checkmark mr-2 tx-24 text-success" />School Placement Analytics</li>
                            <li><i className="icon ion-ios-checkmark mr-2 tx-24 text-success" />Subject Performance Analytics</li>
                          </ul>
                        </>
                      }
                      <p class="text-danger">{this.state.paymentErrorMessage}</p>
                      <div class="nk-modal-action mt-5">
                        <PaystackButton className="btn btn-lg btn-primary" {...paymentConfig} />
                        <button class="btn btn-lg btn-mw btn-light m-1" data-dismiss="modal">Close</button>
                      </div>
                    </>
                  }
                </div>
              </div>
            </div>
          </div>

          <div id="response-modal" className="modal fade">
            <div className="modal-dialog modal-md" role="document">
              <div className="modal-content tx-size-sm">
                <div className="modal-body tx-center pd-y-20 pd-x-20">
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close" style={{position: "fixed", right: "20px"}}
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                  {
                    this.state.isRequestingPayment ?
                    <>
                      <div className="right-panel">
                        <div className="d-flex ht-100 pos-relative align-items-center">
                          <div className="sk-wave">
                            <div className="sk-rect sk-rect1 bg-primary" />
                            <div className="sk-rect sk-rect2 bg-primary" />
                            <div className="sk-rect sk-rect3 bg-primary" />
                            <div className="sk-rect sk-rect4 bg-primary" />
                            <div className="sk-rect sk-rect5 bg-primary" />
                          </div>
                        </div>
                      </div>
                      <h4 className="tx-dark tx-semibold mg-b-20">Updating Subscription</h4>
                      <p className="mg-b-20 mg-x-20">Please wait while we update your subscription package.</p>
                      
                      {/* <button type="button" className="btn btn-success pd-x-25" data-dismiss="modal" aria-label="Close">
                        Continue
                      </button> */}
                    </>
                    :
                    <>
                      {
                        this.state.paymentErrorMessage.length > 0 ?
                        <>
                          <i className="icon ion-ios-close-outline tx-100 tx-danger lh-1 mg-t-20 d-inline-block" />
                          <h4 className="tx-danger tx-semibold mg-b-20">Payment Failed!</h4>
                          <p className="mg-b-20 mg-x-20">{this.state.paymentErrorMessage}</p>
                        </>
                        :
                        <>
                          <i className="icon ion-ios-checkmark-outline tx-100 tx-success lh-1 mg-t-20 d-inline-block" />
                          {
                            this.state.selectedPlan === 'free' ?
                            <h4 className="tx-success tx-semibold mg-b-20">Subscription Updated!</h4>
                            :
                            <h4 className="tx-success tx-semibold mg-b-20">Payment Successful!</h4>
                          }
                          <p className="mg-b-20 mg-x-20">Subscription plan has been updated successfully.</p>
                        </>
                      }
                      <button
                        type="button"
                        className="btn btn-dark pd-x-25"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        Close
                      </button>
                    </>
                  }
                </div>
              </div>
            </div>
          </div>


          <Footer />
      </Fragment>
    );
  }
}

export default Subscription;