import React, { Fragment } from "react";
import MetaTags from "react-meta-tags";
import AuthService from '../../misc/AuthService';
import CONSTANTS from '../../misc/Constants';
// import Footer from "./includes/Footer";
import axios from 'axios';
import { 
  Input
} from 'antd';
import 'antd/dist/antd.css';
class ForgotPassword extends React.Component {

  constructor(props){
		AuthService.checkGreeter();
    super(props);
    this.state = {
			isLoading: false,
      showForgotPasswordForm: true,
      isRequesting: false,
			email: '',
      errorMessage: ''
    }
	}
	
	forgotPasswordForm(event, name) {
    let value = event.target.value;
    this.setState({
      ...this.state,
      email: value,
    });
  }
	
	submitForgotPasswordForm = e => {
    this.setState({
      ...this.state,
      isRequesting: true,
      errorMessage: '',
    });
    e.preventDefault();

    axios.post(CONSTANTS.API_BASE_URL + "/auth/forgot-password", {email: this.state.email})
    .then((response) => {
      let result = response.data;
      this.setState({
        ...this.state,
        isRequesting: false,
        showForgotPasswordForm: false
      });

    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("message")){
          errorMessage = errorResponse.message;
        }

        this.setState({
          ...this.state,
          isRequesting: false,
          errorMessage: errorMessage
        });
      }catch(e){
        window.location = "/server-offline";
      }
    });

  }

  componentDidMount (){
		// window.initScript(window.NioApp, window.jQuery);
  }

  render(){
    return (
      <Fragment>
          <MetaTags>
          <title>Forgot Password - BECE Stats</title>
          </MetaTags>
          <div className="signin-wrapper">
          {
            this.state.showForgotPasswordForm ?
            <div className="signin-box">
              <h2 className="slim-logo">
                <a href={CONSTANTS.WEB_URL}>
                <img src="/assets/img/becestats_black.png" width={"200px"} />
                </a>
              </h2>
              <h2 className="signin-title-primary">Forgot your password?</h2>
              <h5 className="signin-title-secondary">No worries, enter your email to reset your password.</h5>
              {
                this.state.errorMessage.length > 0 &&
                <div className="example-alert nk-block-head">
                  <div className="alert alert-solid alert-danger"> 
                    {this.state.errorMessage}
                  </div>
                </div>
              }
              <form onSubmit={this.submitForgotPasswordForm} method="POST">
                <div className="form-group">
                  <div className="form-label-group">
                      <label className="form-label" htmlFor="default-01">Email</label>
                  </div>
                  <Input size="large" required type="email" id="email" className="form-control form-control-lg" placeholder="Enter your email address" name="email" value={this.state.email} onChange={(e) => {this.forgotPasswordForm(e,"email");}} />
                </div>
                <div className="form-group text-center">
                  { 
                    !this.state.isRequesting ? 
                    <button type="submit" className="btn btn-lg btn-block btn-primary btn-signin">
                      <span>Submit</span> 
                    </button>
                    :
                    <button type="button" disabled className="btn btn-lg btn-block btn-primary btn-signin">
                      <span>Please Wait...</span> 
                    </button>
                  }
                </div>
                {/* <button className="btn btn-primary btn-block btn-signin">Sign In</button> */}
              </form>
              <p className="mg-b-0">
                Remember your password? <a href="/login">Login</a>
              </p>
            </div>
            :
            <div className="signin-box">
              <h2 className="slim-logo">
                <a href={CONSTANTS.WEB_URL}>
                <img src="/assets/img/becestats_black.png" width={"200px"} />
                </a>
              </h2>
              <h3 className="signin-title-primary mb-5">
               An email has been sent to <a href="#" class="link-primary fw-bolder"> {this.state.email}</a>
                <br />please use the link in the email to reset your password.
              </h3>
              <p className="mg-b-0">
                Remember your password? <a href="/login">Login</a>
              </p>
            </div>
          }
          </div>
      </Fragment>
    );
  }
}

export default ForgotPassword;