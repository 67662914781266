import React, { Fragment } from "react";
import MetaTags from "react-meta-tags";
import AuthService from '../../misc/AuthService';
import CONSTANTS from '../../misc/Constants';
import axios from 'axios';
import { 
  Input,
  Select, 
} from 'antd';
import 'antd/dist/antd.css';
import SideNav from "./include/SideNav";
import Header from "./include/Header";
import Footer from "./include/Footer";
import Pagination from "./include/Pagination";
import moment from 'moment';
import Numeral from 'react-numeral';
const { Option } = Select;

class Placement extends React.Component {

  constructor(props){
		AuthService.checkProtected();
    super(props);
    this.state = {
      userInfo: AuthService.getAuth().user,
			isLoading: true,
			isRequesting: false,
      totalList: 1,
      currentPage: 1,
      upgrade: false,
      page: 1,
      year: '2021',
      search: '',
      category: 'all',
      sortBy: 'name',
      placementInfo: {
        current_year: '',
        stats_years: [],
        placement_info: {
          meta: {},
          data: []
        },
        stats: {
          total_students: 0,
          passed_students: 0,
          failed_students: 0
        },
      }
    }
	}

  filterForm(event, name) {
    let value;
    if(name === "year" || name === "category"){
      value = event;
    }else{
      value = event.target.value;
    }

    this.setState({
      ...this.state,
      [name]: value,
    });
	}

  filterPlacement () {
    this.setState({
      ...this.state,
      page: 1
    })

    setTimeout(() => {
      this.getPlacement();
    }, 500);
  }

  getPlacement () {
    this.setState({
      ...this.state,
      isRequesting: true
    })

    axios.post(CONSTANTS.API_BASE_URL + "/user/get-placement", {
      page: this.state.page,
      search: this.state.search,
      category: this.state.category,
      year: this.state.year,
      sortBy: this.state.sortBy,
    }, AuthService.getAxiosHeaders())
    .then((response) => {
      let responseInfo = response.data;

      this.setState({
        ...this.state,
        isLoading: false,
        isRequesting: false,
        upgrade: false,
        currentPage: this.state.page,
        totalList:  Math.ceil(responseInfo.data.placement_info.meta.result_total / 10),
        placementInfo: responseInfo.data
      });   

    }).catch((error) => {
      try{
        let errorResponse = error.response.data;
        let upgrade = false;

        if(error.response.status === 401 || error.response.status === 404){
          window.location = "/not-found";
          return;
        }

        if(error.response.status === 402){
          upgrade = true;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("message")){
          errorMessage = errorResponse.message;
        }

        this.setState({
          ...this.state,
          isLoading: false,
          isRequesting: false,
          upgrade: upgrade,
          errorMessage: errorMessage
        });
      }catch(e){
        console.log(error)
        // window.location = "/server-offline";
      }
    });
  }

  componentDidMount () {
    let url_string = window.location.href;
    let url = new URL(url_string);
    let params = window.parse_query_string(url.search.replace(/^(\?)/,""));
    
    let page = params.hasOwnProperty('p') ? params.p : 1;
    let search = params.hasOwnProperty('s') ? params.s : null;
    let year = params.hasOwnProperty('y') ? params.y : '2021';
    let category = params.hasOwnProperty('c') ? params.c : 'all';
    let sort = params.hasOwnProperty('st') ? params.st : 'name';

    this.setState({
      ...this.state,
      page: page,
      search: search,
      year: year,
      category: category,
      sortBy: sort
    })

    setTimeout(() => {
      this.getPlacement();
    }, 1500);
    
  }
	

  render(){
    let search = this.state.search !== null ? `&s=${this.state.search}` : ``;
    let year = this.state.year !== null ? `&y=${this.state.year}` : ``;
    let category = this.state.category !== null ? `&c=${this.state.category}` : ``;
    let sortBy = this.state.sortBy !== null ? `&st=${this.state.sortBy}` : ``;
    
    let params = `${search}${year}${category}${sortBy}`;

    return (
      <Fragment>
          <MetaTags>
          <title>Placement List - BECE Stats</title>
          </MetaTags>
          <Header activePage={'placement'} />
          <div className="slim-mainpanel">
            {
              this.state.isLoading ?
              <div className="container">
                <div className="d-flex ht-300 pos-relative align-items-center">
                  <div className="sk-wave">
                    <div className="sk-rect sk-rect1 bg-primary" />
                    <div className="sk-rect sk-rect2 bg-primary" />
                    <div className="sk-rect sk-rect3 bg-primary" />
                    <div className="sk-rect sk-rect4 bg-primary" />
                    <div className="sk-rect sk-rect5 bg-primary" />
                  </div>
                </div>
              </div>
              :
              <div className="container">
                <div className="slim-pageheader">
                  <ol className="breadcrumb slim-breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="/dashboard">Home</a>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">Placement</li>
                  </ol>
                  <h6 className="slim-pagetitle">Placement List</h6>
                </div>
                <div className="card card-dash-chart-one mg-t-20 mg-sm-t-30">
                  <div className="row no-gutters">
                    <div className="col-lg-4 bg-light">
                      <div className="left-panel">
                        <h6 className="slim-card-title mb-4">Search / Filter</h6>
                        <div className="form-group">
                          <div className="form-label-group">
                              <label className="form-label" htmlFor="default-01">Year</label>
                          </div>
                          <Select size="large" defaultValue={this.state.year} style={{ width: "100%" }} onChange={(e) => {this.filterForm(e,"year");}}>
                            {
                              this.state.placementInfo.stats_years.map((data, index) => {
                                return(
                                  <Option value={data} key={index}>{data}</Option>
                                )
                              })
                            }
                          </Select>
                        </div>
                        <div className="form-group">
                          <div className="form-label-group">
                              <label className="form-label" htmlFor="default-01">Student Name</label>
                          </div>
                          <Input size="large" type="text" style={{ width: "100%" }} id="year" value={this.state.search} className="form-control form-control-lg" onChange={(e) => {this.filterForm(e,"search");}} placeholder="Enter student name" name="student" />
                        </div>
                        <div className="form-group">
                          <div className="form-label-group">
                              <label className="form-label" htmlFor="default-01">Category</label>
                          </div>
                          <Select size="large" defaultValue={this.state.category} style={{ width: "100%" }} onChange={(e) => {this.filterForm(e,"category");}}>
                            <Option value="all">All</Option>
                            <Option value="a">Category A</Option>
                            <Option value="b">Category B</Option>
                            <Option value="c">Category C</Option>
                          </Select>
                        </div>
                        <div className="form-group">
                          <div className="form-label-group">
                              <label className="form-label" htmlFor="default-01">Sort By</label>
                          </div>
                          <label className="rdiobox">
                            <input type="radio" name="sort" value={"name"} defaultChecked={true} onChange={(e) => {this.filterForm(e,"sortBy");}} />
                            <span>Student Name</span>
                          </label>
                          <label className="rdiobox mg-t-15">
                            <input type="radio" value={"aggregate_asc"} name="sort" onChange={(e) => {this.filterForm(e,"sortBy");}} />
                            <span>Aggregate Ascending</span>
                          </label>
                          <label className="rdiobox mg-t-15">
                            <input type="radio" value={"aggregate_desc"} name="sort" onChange={(e) => {this.filterForm(e,"sortBy");}} />
                            <span>Aggregate Descending</span>
                          </label>
                        </div>
                        {
                          this.state.isRequesting ?
                          <button type="button" disabled className="btn btn-block btn-primary mg-b-10 mt-5">
                            <span>Please Wait...</span> 
                          </button>
                          :
                          <button onClick={() => {this.filterPlacement()}} className="btn btn-primary btn-block mg-b-10 mt-5">Submit</button>
                        }
                        
                      </div>
                    </div>
                    <div className="col-lg-8">
                      {
                        this.state.isRequesting ?
                        <div className="right-panel">
                          <div className="d-flex ht-300 pos-relative align-items-center">
                            <div className="sk-wave">
                              <div className="sk-rect sk-rect1 bg-primary" />
                              <div className="sk-rect sk-rect2 bg-primary" />
                              <div className="sk-rect sk-rect3 bg-primary" />
                              <div className="sk-rect sk-rect4 bg-primary" />
                              <div className="sk-rect sk-rect5 bg-primary" />
                            </div>
                          </div>
                        </div>
                        :
                        <>
                        {
                          this.state.upgrade ?
                          <div className="right-panel">
                            <div className="row">
                              <div className="col-md-6 offset-3 mb-5" style={{border: "none"}}>
                                <div className="pd-25 tx-center">
                                  <div className="card-icon-wrapper primary">
                                    <i className="icon ion-ios-color-wand-outline" />
                                  </div>
                                  <h4 className="tx-gray-800 mg-b-5">Upgrade Plan</h4>
                                  <p className="mg-b-50">
                                    Upgrade your plan to get access to this feature.    
                                  </p>
                                  <a href="/subscription" className="btn btn-dark btn-block text-uppercase tx-bold">
                                    Upgrade plan
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                          :
                          <div className="right-panel">
                            <div className="card-body p-0">
                              <h6 className="slim-card-title tx-dark mb-2">Total Students</h6>
                              <h2 className="tx-lato tx-inverse">
                                <Numeral value={this.state.placementInfo.placement_info.meta.result_total.toString()} format={"0,0"}/>
                              </h2>
                            </div>

                            <hr className="mg-t-30 mg-b-40" />
                            {
                              this.state.placementInfo.placement_info.data.length == 0 ?
                              <div className="row">
                                <div className="col-md-6 offset-3 mb-5" style={{border: "none"}}>
                                  <div className="pd-25 tx-center">
                                    <div className="card-icon-wrapper primary">
                                      <i className="icon ion-ios-information-outline" />
                                    </div>
                                    {/* <h4 className="tx-gray-800 mg-b-5">No result found</h4> */}
                                    <p className="mg-b-50">
                                      No result found    
                                    </p>
                                  </div>
                                </div>
                              </div>
                              :
                              <>
                                <div className="table-responsive">
                                  <table className="table table-striped mg-b-0">
                                    <thead>
                                      <tr>
                                        <th>#</th>
                                        <th>Name</th>
                                        <th>Aggregate</th>
                                        <th>School</th>
                                        <th>Program</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {
                                        this.state.placementInfo.placement_info.data.map((data, index) => {
                                          let counter = this.state.page == 1 ? index + 1 : ((this.state.page - 1) * 10) + (index + 1);
                                          return (
                                            <tr key={index}>
                                              <th scope="row">{counter}</th>
                                              <td>{data.student_name}<br/><small className="text-muted">ID: <b>{data.index_number}</b></small></td>
                                              <td>{data.aggregate}</td>
                                              <td>{data.school_placed} <small className="text-muted"><i>(Cat. {data.category})</i></small></td>
                                              <td>{data.programme}</td>
                                            </tr>
                                          )
                                        })
                                      }
                                    </tbody>
                                  </table>
                                </div>

                                <div class="pt-5">
                                  <Pagination pageCount={this.state.totalList} currentPage={this.state.currentPage} link={`/placement?`} paramsValue={params} />
                                </div>
                              </>
                            }
                            
                          </div>
                        }
                        </>
                      }
                      
                    </div>
                  </div>
                </div>


              </div>
            }
          </div>
          <Footer />
      </Fragment>
    );
  }
}

export default Placement;